import {createStore, applyMiddleware } from "redux";
// import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddlewar from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "./sagas";
import rootReducer from "./reducers";

const sagaMiddlewar = createSagaMiddlewar();

const store = createStore(
  rootReducer,
  composeWithDevTools({ trace: true })(applyMiddleware(sagaMiddlewar))
);

sagaMiddlewar.run(rootSaga);

export default store;

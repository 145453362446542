import {
  ERROR_FETCHING_MODULE_DOCUMENTS_ACTION,
  ERROR_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  ERROR_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
  START_FETCHING_MODULE_DOCUMENTS_ACTION,
  START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  START_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
  SUCCESS_FETCHING_MODULE_DOCUMENTS_ACTION,
  SUCCESS_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  START_DELETE_MODULE_DOCUMENT_ACTION,
  SUCCESS_DELETE_MODULE_DOCUMENT_ACTION,
  FAILURE_DELETE_MODULE_DOCUMENT_ACTION,
  SUCCESS_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
  START_CREATE_MODULE_DOCUMENT_ACTION,
  SUCCESS_CREATE_MODULE_DOCUMENT_ACTION,
  FAILURE_CREATE_MODULE_DOCUMENT_ACTION,
  START_CLONE_MODULE_DOCUMENT_ACTION,
  SUCCESS_CLONE_MODULE_DOCUMENT_ACTION,
  FAILURE_CLONE_MODULE_DOCUMENT_ACTION,
  START_UPDATE_MODULE_DOCUMENT_ACTION,
  SUCCESS_UPDATE_MODULE_DOCUMENT_ACTION,
  FAILURE_UPDATE_MODULE_DOCUMENT_ACTION,
  START_FETCH_DISCUSSION_ENTRIES_ACTION,
  SUCCESS_FETCH_DISCUSSION_ENTRIES_ACTION,
  FAILURE_FETCH_DISCUSSION_ENTRIES_ACTION,
  START_MODULE_RESOURCE_NODE_BY_ID,
  SUCCESS_MODULE_RESOURCE_NODE_BY_ID,
  ERROR_MODULE_RESOURCE_NODE_BY_ID,
} from "../actions/module-documents";

const initState = {
  module_documents_list_data: {
    isLoading: false,
    isError: false,
    data: {},
    meta: {},
  },
  module_document_by_id_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  module_document_delete: {
    isLoading: false,
    isError: false,
    data: {},
  },
  update_module_resource_node_by_id: {
    isLoading: false,
    isError: false,
    data: {},
  },
  module_document_create: {
    isLoading: false,
    isError: false,
    data: null,
  },
  module_document_clone: {
    isLoading: false,
    isError: false,
    data: null,
  },
  module_document_update: {
    isLoading: false,
    isError: false,
    data: {},
  },
  discussion_entries: {
    isLoading: false,
    isError: false,
    data: {},
  },
  get_module_resource_node_by_id: {
    isLoading: false,
    isError: false,
    data: [],
  },
};

export const moduleDocumentsReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_FETCHING_MODULE_DOCUMENTS_ACTION: {
      return {
        ...prevState,
        module_documents_list_data: {
          isLoading: true,
          isError: false,
          data: {},
          meta: {},
        },
      };
    }
    case SUCCESS_FETCHING_MODULE_DOCUMENTS_ACTION: {
      return {
        ...prevState,
        module_documents_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
          meta: action.meta,
        },
      };
    }
    case ERROR_FETCHING_MODULE_DOCUMENTS_ACTION: {
      return {
        ...prevState,
        module_documents_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
          meta: {},
        },
      };
    }
    case START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION: {
      return {
        ...prevState,
        module_document_by_id_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION: {
      return {
        ...prevState,
        module_document_by_id_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION: {
      return {
        ...prevState,
        module_document_by_id_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_DELETE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_delete: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }

    case START_UPDATING_MODULE_RESOURCE_NODE_BY_ID: {
      return {
        ...prevState,
        update_module_resource_node_by_id: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_DELETE_MODULE_DOCUMENT_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_document_delete: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_DELETE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_delete: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case SUCCESS_UPDATING_MODULE_RESOURCE_NODE_BY_ID: {
      return {
        ...prevState,
        update_module_resource_node_by_id: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_UPDATING_MODULE_RESOURCE_NODE_BY_ID: {
      return {
        ...prevState,
        update_module_resource_node_by_id: {
          isLoading: false,
          isError: action.errorMessage,
        },
      };
    }
    case START_CREATE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_create: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }
    case SUCCESS_CREATE_MODULE_DOCUMENT_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_document_create: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CREATE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_create: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_CLONE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_clone: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }
    case SUCCESS_CLONE_MODULE_DOCUMENT_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_document_clone: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }
    case FAILURE_CLONE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_clone: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_UPDATE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_update: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_UPDATE_MODULE_DOCUMENT_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_document_update: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_UPDATE_MODULE_DOCUMENT_ACTION: {
      return {
        ...prevState,
        module_document_update: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_FETCH_DISCUSSION_ENTRIES_ACTION: {
      return {
        ...prevState,
        discussion_entries: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_FETCH_DISCUSSION_ENTRIES_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        discussion_entries: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_FETCH_DISCUSSION_ENTRIES_ACTION: {
      return {
        ...prevState,
        discussion_entries: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_MODULE_RESOURCE_NODE_BY_ID: {
      return {
        ...prevState,
        get_module_resource_node_by_id: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    }
    case SUCCESS_MODULE_RESOURCE_NODE_BY_ID: {
      return {
        ...prevState,
        get_module_resource_node_by_id: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_MODULE_RESOURCE_NODE_BY_ID: {
      return {
        ...prevState,
        get_module_resource_node_by_id: {
          isLoading: false,
          isError: action.errorMessage,
        },
      };
    }
    default:
      return prevState;
  }
};

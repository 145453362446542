import api from "../api";

export function fetchLoginRequest(payload) {
  return api.post(`/sessions/login`, { paramObj: payload });
}

export function fetchRecoveryPasswordRequest(payload) {
  return api.post(`/sessions/password_recovery`, { paramObj: payload });
}

export function fetchUpdateUserProfileRequest(payload) {
  return api.put(`/users/${payload?.user?.id}`, { paramObj: payload });
}

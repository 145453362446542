import React, { useEffect, useState, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import "../../styles/timeline.scss";
import {
  useResourcesList,
  useViewResourceNodeModule,
} from "../../rest-client/selectorHook";
import {
  START_ADD_CANVAS_IMAGE_UPLOAD,
  START_DISCUSS_ENTRIES_ACTION,
  START_POLL_QUESTIONS_ACTION,
  SUBMIT_PAGE_RESOURCE_NODE_REQUESTED,
  VIEW_RESOURCE_NODE_REQUESTED,
} from "../../rest-client/actions/view-resource-node";
import ConfirmSubmitModal from "../Modal/ConfirmSubmitModal";
import { createImageFileObjectHook } from "../../commonHook";
import ResourceComponets from "./ResourceComponets";
import { START_MODULE_RESOURCE_NODE_BY_ID } from "../../rest-client/actions/module-documents";
import { useModuleDocuments } from "../../rest-client/selectorHook";

export default function TimeLineView({ moduleId, timelineData, userRole }) {
  const canvasRef = useRef(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [resourceId, setResourceId] = useState("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [formTextData, setFormTextData] = useState([]);
  const [selectedPollId, setSelectedPollId] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    poll_question_id: null,
    poll_question_option_ids: [],
  });
  const [selectRadio, setSelectRadio] = useState({
    poll_question_id: null,
    poll_question_option_ids: [],
  });
  const [isCanvasTextResponse, setIsCanvasTextResponse] = useState(false);
  const [isPollTextResponse, setIsPollTextResponse] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const {
    get_module_resource_node_by_id: { data: moduleResourceNodeData },
  } = useModuleDocuments();
  const {
    fetch_resource_nodes: { data: fetchedResourcesNodesList },
  } = useResourcesList();

  const memoisedResourceListData = React.useMemo(() => {
    return fetchedResourcesNodesList;
  }, [fetchedResourcesNodesList]);

  React.useEffect(() => {
    if (moduleResourceNodeData && userRole === "Learner") {
      const item = moduleResourceNodeData?.find(
        (e) => e.enabled === true && e.has_been_submitted === false
      );
      const itemIndex = moduleResourceNodeData.indexOf(item);
      setActiveItemIndex(
        itemIndex !== -1 ? itemIndex : moduleResourceNodeData.length - 1
      );
    }
  }, [moduleResourceNodeData, userRole]);
  const path =
    userRole === "Teacher" || userRole === "Learner"
      ? "/dashboard"
      : "/dashboard/learning";
  const { url } = useRouteMatch(path);
  const history = useHistory();

  const dispatch = useDispatch();
  const fecthResourceData = useViewResourceNodeModule();

  const redirectionPathUrl =
    userRole === "Teacher"
      ? "manage-sessions"
      : userRole === "Learner"
      ? "index"
      : "index/modules";

  function isFormData(data) {
    if (data.length === 0) {
      return false;
    }
    return data
      .filter((item) => item.discussionType === "text")
      .every((e) => e.content !== "");
  }

  function nextPageSubmitApiCall() {
    if (moduleId) {
      dispatch({
        type: SUBMIT_PAGE_RESOURCE_NODE_REQUESTED,
        payload: {
          moduleId: moduleId,
          resourceId: resourceId
            ? resourceId
            : timelineData?.resource_nodes[activeItemIndex]?.id,
          setIsSubmit: setIsSubmit,
        },
      });
      setIsPollTextResponse(false);
      setIsCanvasTextResponse(false);
      setSelectedCheckboxes({
        poll_question_id: null,
        poll_question_option_ids: [],
      });
      setSelectRadio({
        poll_question_id: null,
        poll_question_option_ids: [],
      });
    }
  }

  function clickNextButton() {
    setIsSubmit(false);
    setActiveItemIndex(
      activeItemIndex < timelineData.resource_nodes.length - 1
        ? activeItemIndex + 1
        : timelineData.resource_nodes.length - 1
    );
    const nextResourceId =
      timelineData?.resource_nodes[activeItemIndex + 1]?.id || "";
    setResourceId(nextResourceId);
  }

  useEffect(() => {
    if (activeItemIndex !== -1 && activeItemIndex !== null) {
      dispatch({
        type: VIEW_RESOURCE_NODE_REQUESTED,
        payload: {
          moduleId: moduleId,
          resourceId: resourceId
            ? resourceId
            : timelineData?.resource_nodes[activeItemIndex]?.id,
        },
      });
    }
  }, [moduleId, resourceId, activeItemIndex]);

  React.useEffect(() => {
    dispatch({
      type: START_MODULE_RESOURCE_NODE_BY_ID,
      payload: {
        moduleId: moduleId,
      },
    });
  }, [moduleId]);

  useEffect(() => {
    if (isSubmit === true) {
      if (activeItemIndex === timelineData.resource_nodes.length - 1) {
        const redirectionUrl =
          userRole === "Teacher"
            ? "manage-sessions"
            : userRole === "Learner"
            ? timelineData?.resource_nodes[activeItemIndex]?.resource
                ?.resource_type === "poll"
              ? "results"
              : "index"
            : "index/modules";
        history.push(`${url}/${redirectionUrl}`);
        setIsSubmit(false);
      } else {
        clickNextButton();
      }
    }
  }, [isSubmit]);

  const handleTimelineItemClick = (index, id) => {
    setActiveItemIndex(index);
    setResourceId(id);
  };

  const handlePrevClick = () => {
    setActiveItemIndex(activeItemIndex > 0 ? activeItemIndex - 1 : 0);
    const prevResourceId =
      timelineData?.resource_nodes[activeItemIndex - 1]?.id || "";
    setResourceId(prevResourceId);
  };

  const handleNextClick = () => {
    if (
      fecthResourceData?.view_resource_node_data?.data?.resource_node?.resource
        ?.discussions.length > 0
    ) {
      if (isFormData(formTextData)) {
        formTextData?.map((item) =>
          dispatch({
            type: START_DISCUSS_ENTRIES_ACTION,
            payload: {
              discussion_entry: {
                id: item?.id,
                content: item?.content.trimStart().trimEnd(),
                module_id: moduleId,
              },
            },
          })
        );
      } else {
        toast.error("Please enter your answer");
      }
    } else {
      nextPageSubmitApiCall();
    }
  };

  const saveImage = () => {
    const newDataURL = canvasRef.current.toDataURL();
    // Generate a timestamp to make the filename or metadata unique
    const timestamp = new Date().getTime();
    let canvasData = createImageFileObjectHook(
      newDataURL,
      `canvas-image-${timestamp}`
    );
    return canvasData;
  };

  useEffect(() => {
    if (
      Object.keys(fecthResourceData?.discussion_entries_data?.data).length >
        0 &&
      fecthResourceData?.view_resource_node_data?.data?.resource_node?.resource
        ?.resource_type === "page"
    ) {
      setFormTextData([]);
      nextPageSubmitApiCall();
    }

    if (
      Object.keys(fecthResourceData?.discussion_entries_data?.data).length >
        0 &&
      !isCanvasTextResponse &&
      fecthResourceData?.view_resource_node_data?.data?.resource_node?.resource
        ?.resource_type === "canvas"
    ) {
      setFormTextData([]);
      if (
        !(
          fecthResourceData?.view_resource_node_data?.data?.resource_node
            ?.resource?.discussions.length > 0
        )
      ) {
        nextPageSubmitApiCall();
      }
    }

    if (
      Object.keys(fecthResourceData?.discussion_entries_data?.data).length >
        0 &&
      !isPollTextResponse &&
      fecthResourceData?.view_resource_node_data?.data?.resource_node?.resource
        ?.resource_type === "poll"
    ) {
      setFormTextData([]);
      if (
        !(
          fecthResourceData?.view_resource_node_data?.data?.resource_node
            ?.resource?.discussions.length > 0
        )
      ) {
        nextPageSubmitApiCall();
      }
    }
  }, [fecthResourceData?.discussion_entries_data?.data]);

  useEffect(() => {
    if (Object.keys(fecthResourceData?.poll_questions_data?.data).length > 0) {
      nextPageSubmitApiCall();
    }
  }, [fecthResourceData?.poll_questions_data?.data]);

  useEffect(() => {
    if (
      Object.keys(fecthResourceData?.upload_canvas_image_data?.data).length >
        0 &&
      fecthResourceData?.upload_canvas_image_data?.data?.type === "image"
    ) {
      let formId =
        fecthResourceData?.view_resource_node_data?.data?.resource_node?.resource?.discussions?.filter(
          (item) => item?.discussion_type === "canvas"
        );
      if (formId) {
        dispatch({
          type: START_DISCUSS_ENTRIES_ACTION,
          payload: {
            discussion_entry: {
              id: formId[0]?.id || "",
              media_id: fecthResourceData?.upload_canvas_image_data?.data?.id,
              module_id: moduleId,
            },
          },
        });
        nextPageSubmitApiCall();
      }
    }
  }, [fecthResourceData?.upload_canvas_image_data?.data]);

  const submitRecordHandler = () => {
    if (
      fecthResourceData?.view_resource_node_data?.data?.resource_node
        ?.has_been_submitted === false &&
      fecthResourceData?.view_resource_node_data?.data?.resource_node?.resource
        ?.resource_type === "poll"
    ) {
      if (
        fecthResourceData?.view_resource_node_data?.data?.resource_node
          ?.resource?.discussions.length > 0
      ) {
        if (isFormData(formTextData)) {
          setIsPollTextResponse(true);
          formTextData
            ?.filter((item) => item.discussionType === "text")
            .map((item) =>
              dispatch({
                type: START_DISCUSS_ENTRIES_ACTION,
                payload: {
                  discussion_entry: {
                    id: item?.id,
                    content: item?.content,
                    module_id: moduleId,
                  },
                },
              })
            );
        } else {
          toast.error("Please enter your answer");
        }
      }

      const pollQuestionResponse = {
        id: selectedPollId,
        poll_questions: [selectRadio, selectedCheckboxes],
      };
      if (pollQuestionResponse) {
        dispatch({
          type: START_POLL_QUESTIONS_ACTION,
          payload: {
            poll_question_response: pollQuestionResponse,
            setIsOpenConfirmModal: setIsOpenConfirmModal,
          },
        });
      }
    } else if (
      fecthResourceData?.view_resource_node_data?.data?.resource_node
        ?.has_been_submitted === false &&
      fecthResourceData?.view_resource_node_data?.data?.resource_node?.resource
        ?.resource_type === "canvas"
    ) {
      let canvasImgData = saveImage();
      if (
        fecthResourceData?.view_resource_node_data?.data?.resource_node
          ?.resource?.discussions.length > 0
      ) {
        if (isFormData(formTextData)) {
          setIsCanvasTextResponse(true);
          formTextData
            ?.filter((item) => item.discussionType === "text")
            .map((item) =>
              dispatch({
                type: START_DISCUSS_ENTRIES_ACTION,
                payload: {
                  discussion_entry: {
                    id: item?.id,
                    content: item?.content,
                    module_id: moduleId,
                  },
                },
              })
            );
        }
      }

      if (canvasImgData) {
        dispatch({
          type: START_ADD_CANVAS_IMAGE_UPLOAD,
          payload: {
            canvasImageResponse: canvasImgData,
            setIsOpenConfirmModal: setIsOpenConfirmModal,
          },
        });
      }
    }
  };

  const handleSubmitPoll = () => {
    if (
      selectedCheckboxes?.poll_question_option_ids.length > 1 &&
      selectRadio?.poll_question_option_ids.length > 0
    ) {
      setIsOpenConfirmModal(true);
    } else {
      toast.error("Please answer all polls before submitting");
      setIsOpenConfirmModal(false);
    }
  };

  const fetchData =
    fecthResourceData?.view_resource_node_data?.data?.resource_node;

  return timelineData?.resource_nodes.length > 0 ? (
    <>
      {memoisedResourceListData?.resource_nodes?.length > 0 ? (
        <>
          <ol className="timeline">
            {memoisedResourceListData?.resource_nodes.map((data, index) => (
              <li
                className={
                  activeItemIndex === index
                    ? data.resource?.is_stopping_point === true
                      ? "circle cursor-pointer"
                      : "active cursor-pointer"
                    : data.resource?.is_stopping_point === true
                    ? data?.enabled === true
                      ? "circle cursor-pointer"
                      : "circle pointer-events-none"
                    : data?.enabled === true &&
                      data?.has_been_submitted === true
                    ? "prev-node cursor-pointer"
                    : data?.enabled === false
                    ? "pointer-events-none"
                    : "cursor-pointer"
                }
                onClick={() => handleTimelineItemClick(index, data.id)}
                key={data.id}
              >
                <div className="timeline-title">
                  <p
                    className={
                      activeItemIndex === index
                        ? "text-active capitalize"
                        : "capitalize"
                    }
                  >
                    {data.resource?.nickname ? data.resource?.nickname : ""}
                  </p>
                </div>
              </li>
            ))}
          </ol>
        </>
      ) : null}

      <div
        className={classNames(
          "layout-container m-auto w-full border-box flex flex-row !p-0",
          {
            "!block":
              fecthResourceData?.view_resource_node_data?.data?.resource_node
                ?.resource?.resource_type === "results",
          }
        )}
      >
        <div className="layout">
          <ResourceComponets
            resource={
              fecthResourceData?.view_resource_node_data?.data?.resource_node
                ?.resource
            }
            isLoadingViewResourceNode={
              fecthResourceData?.view_resource_node_data?.isLoading
            }
            canvasRef={canvasRef}
            selectedCheckboxes={selectedCheckboxes}
            setSelectedCheckboxes={setSelectedCheckboxes}
            selectRadio={selectRadio}
            setSelectRadio={setSelectRadio}
            setSelectedPollId={setSelectedPollId}
            userRole={userRole}
            setFormTextData={setFormTextData}
          />
        </div>
      </div>

      {fecthResourceData?.view_resource_node_data?.data?.resource_node
        ?.resource ? (
        <div className="timeline-buttons">
          {activeItemIndex === 0 ? (
            <span></span>
          ) : (
            <button onClick={handlePrevClick}>Previous</button>
          )}

          {fetchData?.resource?.resource_type === "poll" ? (
            fetchData?.has_been_submitted === false ? (
              <button
                onClick={() => {
                  if (
                    fetchData?.resource?.discussions.length > 0 &&
                    fetchData?.resource?.discussions.some(
                      (discussion) => discussion.discussion_type === "text"
                    )
                  ) {
                    if (!isFormData(formTextData)) {
                      toast.error("Please enter your answer");
                    } else {
                      handleSubmitPoll();
                    }
                  } else {
                    handleSubmitPoll();
                  }
                }}
              >
                Submit
              </button>
            ) : fetchData?.has_been_submitted === true &&
              activeItemIndex === timelineData.resource_nodes.length - 1 ? (
              <button
                onClick={() => {
                  const redirectionPollPathUrl =
                    userRole === "Teacher"
                      ? "manage-sessions"
                      : userRole === "Learner"
                      ? "results"
                      : "index/modules";
                  history.push(`${url}/${redirectionPollPathUrl}`);
                }}
              >
                Finish
              </button>
            ) : (
              <button onClick={nextPageSubmitApiCall}>Next</button>
            )
          ) : fetchData?.resource?.resource_type === "canvas" ? (
            fetchData?.has_been_submitted === false ? (
              <button
                onClick={() => {
                  if (fetchData?.resource?.discussions.length > 0) {
                    const discussionTypeDiscussions =
                      fetchData?.resource?.discussions.filter(
                        (discussion) => discussion.discussion_type === "text"
                      );

                    if (discussionTypeDiscussions.length > 0) {
                      if (isFormData(formTextData)) {
                        setIsOpenConfirmModal(true);
                      } else {
                        toast.error("Please enter your answer");
                        setIsOpenConfirmModal(false);
                      }
                    } else {
                      setIsOpenConfirmModal(true);
                    }
                  } else {
                    setIsOpenConfirmModal(true);
                  }
                }}
              >
                Submit
              </button>
            ) : fetchData?.has_been_submitted === true &&
              activeItemIndex === timelineData.resource_nodes.length - 1 ? (
              <button
                onClick={() => {
                  history.push(`${url}/${redirectionPathUrl}`);
                }}
              >
                Finish
              </button>
            ) : (
              <button onClick={nextPageSubmitApiCall}>Next</button>
            )
          ) : fetchData?.resource?.resource_type !== "poll" &&
            fetchData?.resource?.resource_type !== "canvas" ? (
            fetchData?.has_been_submitted === false ? (
              <button onClick={handleNextClick}>
                {activeItemIndex === timelineData.resource_nodes.length - 1
                  ? "Finish"
                  : "Next"}
              </button>
            ) : fetchData?.has_been_submitted === true &&
              activeItemIndex === timelineData.resource_nodes.length - 1 ? (
              <button
                onClick={() => {
                  history.push(`${url}/${redirectionPathUrl}`);
                }}
              >
                Finish
              </button>
            ) : (
              <button onClick={nextPageSubmitApiCall}>Next</button>
            )
          ) : null}
        </div>
      ) : (
        ""
      )}
      {isOpenConfirmModal && (
        <ConfirmSubmitModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          submitRecordHandler={submitRecordHandler}
          resourceType={fetchData?.resource?.resource_type}
        />
      )}
    </>
  ) : (
    <div className="no-details-message text-base">No details available</div>
  );
}

export const START_FETCHING_RESOURCES_LIST_ACTION = `START_FETCHING_RESOURCES_LIST_ACTION`;
export const ERROR_FETCHING_RESOURCES_LIST_ACTION = `ERROR_FETCHING_RESOURCES_LIST_ACTION`;
export const SUCCESS_FETCHING_RESOURCES_LIST_ACTION = `SUCCESS_FETCHING_RESOURCES_LIST_ACTION`;

export const START_CLONE_RESOURCE_ACTION = "START_CLONE_RESOURCE_ACTION";
export const SUCCESS_CLONE_RESOURCE_ACTION = "SUCCESS_CLONE_RESOURCE_ACTION";
export const FAILURE_CLONE_RESOURCE_ACTION = "FAILURE_CLONE_RESOURCE_ACTION";

export const START_DELETE_RESOURCE_ACTION = "START_DELETE_RESOURCE_ACTION";
export const SUCCESS_DELETE_RESOURCE_ACTION = "SUCCESS_DELETE_RESOURCE_ACTION";
export const FAILURE_DELETE_RESOURCE_ACTION = "FAILURE_DELETE_RESOURCE_ACTION";

export const START_SHOW_RESOURCE_ACTION = "START_SHOW_RESOURCE_ACTION";
export const SUCCESS_SHOW_RESOURCE_ACTION = "SUCCESS_SHOW_RESOURCE_ACTION";
export const FAILURE_SHOW_RESOURCE_ACTION = "FAILURE_SHOW_RESOURCE_ACTION";

export const START_ADD_RESOURCE_ACTION = "START_ADD_RESOURCE_ACTION";
export const SUCCESS_ADD_RESOURCE_ACTION = "SUCCESS_ADD_RESOURCE_ACTION";
export const FAILURE_ADD_RESOURCE_ACTION = "FAILURE_ADD_RESOURCE_ACTION";

export const START_ADD_DISCUSSION_PROMPT_ACTION =
  "START_ADD_DISCUSSION_PROMPT_ACTION";
export const SUCCESS_ADD_DISCUSSION_PROMPT_ACTION =
  "SUCCESS_ADD_DISCUSSION_PROMPT_ACTION";
export const FAILURE_ADD_DISCUSSION_PROMPT_ACTION =
  "FAILURE_ADD_DISCUSSION_PROMPT_ACTION";

export const START_DELETE_DISCUSSION_PROMPT_ACTION =
  "START_DELETE_DISCUSSION_PROMPT_ACTION";
export const SUCCESS_DELETE_DISCUSSION_PROMPT_ACTION =
  "SUCCESS_DELETE_DISCUSSION_PROMPT_ACTION";
export const FAILURE_DELETE_DISCUSSION_PROMPT_ACTION =
  "FAILURE_DELETE_DISCUSSION_PROMPT_ACTION";

export const START_ADD_POLL_ACTION = "START_ADD_POLL_ACTION";
export const SUCCESS_ADD_POLL_ACTION = "SUCCESS_ADD_POLL_ACTION";
export const FAILURE_ADD_POLL_ACTION = "FAILURE_ADD_POLL_ACTION";

export const START_ADD_CANVAS_IMG_ACTION = "START_ADD_CANVAS_IMG_ACTION";
export const SUCCESS_ADD_CANVAS_IMG_ACTION = "SUCCESS_ADD_CANVAS_IMG_ACTION";
export const FAILURE_ADD_CANVAS_IMG_ACTION = "FAILURE_ADD_CANVAS_IMG_ACTION";

export const START_ADD_CANVAS_BG_IMG_ACTION = "START_ADD_CANVAS_BG_IMG_ACTION";
export const SUCCESS_ADD_CANVAS_BG_IMG_ACTION =
  "SUCCESS_ADD_CANVAS_BG_IMG_ACTION";
export const FAILURE_ADD_CANVAS_BG_IMG_ACTION =
  "FAILURE_ADD_CANVAS_BG_IMG_ACTION";

export const START_UPDATE_RESOURCE_ACTION = "START_UPDATE_RESOURCE_ACTION";
export const SUCCESS_UPDATE_RESOURCE_ACTION = "SUCCESS_UPDATE_RESOURCE_ACTION";
export const FAILURE_UPDATE_RESOURCE_ACTION = "FAILURE_UPDATE_RESOURCE_ACTION";

export const START_FETCHING_RESOURCE_BY_ID_ACTION = `START_FETCHING_RESOURCE_BY_ID_ACTION`;
export const ERROR_FETCHING_RESOURCE_BY_ID_ACTION = `ERROR_FETCHING_RESOURCE_BY_ID_ACTION`;
export const SUCCESS_FETCHING_RESOURCE_BY_ID_ACTION = `SUCCESS_FETCHING_RESOURCE_BY_ID_ACTION`;

export const START_UPDATING_POLL_BY_ID_ACTION = `START_UPDATING_POLL_BY_ID_ACTION`;
export const ERROR_UPDATING_POLL_BY_ID_ACTION = `ERROR_UPDATING_POLL_BY_ID_ACTION`;
export const SUCCESS_UPDATING_POLL_BY_ID_ACTION = `SUCCESS_UPDATING_POLL_BY_ID_ACTION`;

export const START_FETCHING_RESOURCE_NODE_ACTION = `START_FETCHING_RESOURCE_NODE_ACTION`;
export const ERROR_FETCHING_RESOURCE_NODE_ACTION = `ERROR_FETCHING_RESOURCE_NODE_ACTION`;
export const SUCCESS_FETCHING_RESOURCE_NODE_ACTION = `SUCCESS_FETCHING_RESOURCE_NODE_ACTION`;

export const LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION =
  "LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION";
import React, { useEffect, useRef } from "react";
import parse from "html-react-parser";
import TextArea from "../TextAreaComponent/TextArea";

export default function PageResource({
  htmlContent,
  discussions,
  setFormTextData,
}) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const imgElements = containerRef.current.getElementsByTagName("img");

      for (const imgElement of imgElements) {
        const divElement = document.createElement("div");
        divElement.style.textAlign = "center";
        imgElement.parentNode.replaceChild(divElement, imgElement);
        divElement.appendChild(imgElement);
      }
    }
  }, []);
  return (
    <>
      <div ref={containerRef}>{parse(htmlContent)}</div>
      {Array.isArray(discussions) && discussions.length > 0 ? (
        <TextArea discussions={discussions} setFormTextData={setFormTextData} />
      ) : null}
    </>
  );
}

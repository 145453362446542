import axios from "axios";
const baseURL = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  baseURL: baseURL,
  timeout: 300000, // 5 secs
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus(status) {
    return (
      (status >= 200 && status < 300) ||
      status === 401 ||
      status === 422 ||
      status === 404 ||
      status === 403 ||
      status === 400
    );
  },
});

const updateAxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 300000, // 5 secs
  headers: {
    "Content-Type": "multipart/form-data",
  },
  validateStatus(status) {
    return (
      (status >= 200 && status < 300) ||
      status === 401 ||
      status === 422 ||
      status === 404 ||
      status === 403 ||
      status === 400
    );
  },
});

function getAccessTokenFromLocalStorage() {
  const { localStorage } = window;
  const token = localStorage.getItem("access_token");
  return token;
}

function get(url, { params } = {}) {
  instance.defaults.headers.common.Authorization = `Bearer ${getAccessTokenFromLocalStorage()}`;
  return instance
    .get(url, { params })
    .then((response) => {
      if (response.status === 200) {
        return { status: true, data: response.data };
      }
      if (response.status === 204) {
        return { status: true, data: undefined };
      }
      if (response.status === 401) {
        return { status: false, unauthenticated: true };
      }
      if (response.status === 404 || response.status === 400) {
        return { ...response, status: false };
      }
      return { status: false, message: "Something went wrong!" };
    })
    .catch((error) => {
      const { message } = error;
      return {
        status: false,
        message,
      };
    });
}

function post(url, { paramObj = {} } = {}) {
  instance.defaults.headers.common.Authorization = `Bearer ${getAccessTokenFromLocalStorage()}`;
  return instance
    .post(url, paramObj)
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return { status: true, data: response.data };
      }
      if (response.status === 401) {
        return { status: false, unauthenticated: true };
      }
      if (response.status === 404 || response.status === 400) {
        return { ...response, status: false };
      }
      if (response.status === 403) {
        return { status: false, unauthorized: true };
      }
      return { status: false, message: response?.data?.message };
    })
    .catch((error) => {
      const { message } = error;
      return {
        status: false,
        message,
        code: error?.response?.status,
      };
    });
}

function put(url, { paramObj = {} } = {}) {
  instance.defaults.headers.common.Authorization = `Bearer ${getAccessTokenFromLocalStorage()}`;
  return instance
    .put(url, paramObj)
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        return { status: true, ...response };
      }
      if (response.status === 401) {
        return { status: false, unauthenticated: true };
      }
      if (
        response.status === 404 ||
        response.status === 400 ||
        response.status === 422
      ) {
        return { ...response, status: false };
      }
      return { status: false, message: "Something went wrong!" };
    })
    .catch((error) => {
      const { message } = error;
      return {
        status: false,
        message,
      };
    });
}

function delet(url, { paramObj = {} } = {}) {
  instance.defaults.headers.common.Authorization = `Bearer ${getAccessTokenFromLocalStorage()}`;

  return instance
    .delete(url, { paramObj })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return { status: true, data: response.data };
      }
      if (response.status === 401) {
        return { status: false, unauthenticated: true };
      }
      if (response.status === 404 || response.status === 400) {
        return { ...response, status: false };
      }
      return { status: false, message: "Something went wrong!" };
    })
    .catch((error) => {
      const { message } = error;
      return {
        status: false,
        message,
        code: error?.response?.status,
      };
    });
}

function uploadImage(url, { paramObj = {} } = {}) {
  updateAxiosInstance.defaults.headers.common.Authorization = `Bearer ${getAccessTokenFromLocalStorage()}`;
  return updateAxiosInstance
    .post(url, paramObj)
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return { status: true, data: response.data };
      }
      if (response.status === 401) {
        return { status: false, unauthenticated: true };
      }
      if (response.status === 404 || response.status === 400) {
        return { ...response, status: false };
      }
      if (response.status === 403) {
        return { status: false, unauthorized: true };
      }
      return { status: false, message: response?.data?.message };
    })
    .catch((error) => {
      const { message } = error;
      return {
        status: false,
        message,
        code: error?.response?.status,
      };
    });
}

export default {
  get,
  post,
  put,
  delet,
  uploadImage,
  instance,
};

import React, { useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Icon from "../Button/Icon";
import "../../styles/view_results.scss";
import { getTheInnerHTMLHook } from "../../commonHook";
import moment from "moment";

export default function ViewResultCanvasImageModal({
  isOpenResultCanvasModal,
  setIsOpenResultCanvasModal,
  discussionEntriesData,
}) {
  const onCloseModal = React.useCallback(() => {
    setIsOpenResultCanvasModal(false);
  }, []);

  const closeIcon = (
    <div className="w-full flex justify-between">
      <Icon label="close" title="" />
    </div>
  );

  const ImageViewer = ({ src }) => {
    useEffect(() => {
      const getImage = async () => {
        if (
          src.endsWith(".jpg") ||
          src.endsWith(".jpeg") ||
          src.endsWith(".png")
        ) {
          const imgElement = document.getElementById(`my-img`);
          imgElement.src = src;
        } else {
          const response = await fetch(src);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const imageUrl = reader.result;
            const decodedData = atob(imageUrl.split(",")[1]);
            const imgElement = document.getElementById(`my-img`);
            imgElement.src = decodedData;
          };
        }
      };
      getImage();
    }, [src]);
    return (
      <>
        <img id={`my-img`} alt="canvas-result-image" className="max-w-full" />
      </>
    );
  };

  return (
    <Modal
      center
      styles={{
        modal: {
          width: "100%",
          maxWidth: "90%",
          borderRadius: "4px",
          backgroundColor: "white",
        },
      }}
      open={isOpenResultCanvasModal}
      onClose={onCloseModal}
      closeIcon={closeIcon}
      focusTrapped={false}
    >
      <div className="">
        <div className="w-webkit absolute top-0 right-0 bg-theme-blue h-12 pt-4 pl-5">
          <div className="font-lato flex flex-start text-white-87 text-xl font-normal w-1/3">
            <div>Canvas Posts & Discussions</div>
          </div>
        </div>
        <h2 className="text-black-87 mt-20">
          {Array.isArray(discussionEntriesData?.discussion_entries) &&
          discussionEntriesData?.discussion_entries?.length > 0
            ? `${discussionEntriesData?.discussion_entries[0]?.group?.title} - ${discussionEntriesData?.discussion_entries[0]?.module_document?.nickname}`
            : "-"}
        </h2>
        {discussionEntriesData?.discussion_entries?.map((e) => (
          <div key={e?.id}>
            {e?.discussion_type === "canvas" ? (
              <div className="md-card box-border flex flex-col m-2 bg-white rounded-sm text-black-87">
                <div
                  className="px-4 pt-4 text-gray-38"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  <span>{moment(e.updated_at).format("MM/DD/YY hh:mm A")}</span>
                </div>
                {e?.media?.url && (
                  <div className="block p-4">
                    <ImageViewer src={e?.media?.url} />
                  </div>
                )}
              </div>
            ) : e.discussion_type === "text" ? (
              <div>
                <h3 className="text-black-87">
                  {getTheInnerHTMLHook(e?.discussion_title) || "-"}
                </h3>
                <div className="md-card box-border flex flex-col m-2 bg-white rounded-sm text-black-87 px-4">
                  <div
                    className="pt-4 text-gray-38"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    <span>
                      {moment(e.updated_at).format("MM/DD/YY hh:mm A")}
                    </span>
                  </div>
                  <p className="text-black-87">{e?.content || "-"}</p>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </Modal>
  );
}

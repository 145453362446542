import { toast } from "react-toastify";

export const uploadImageCallback = (file) => {
  const maxSize = 2097152; // 2 MB
  if (file.size > maxSize) {
    toast.error(
      "The file size exceeds the limit of 2mb. Please reduce the file size and try again."
    );
    return Promise.reject(
      new Error(`File size should be less than ${maxSize} bytes`)
    );
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve({ data: { link: reader.result } });
    };

    reader.onerror = () => {
      reject(reader.error);
    };
  });
};

import React from "react";
import classNames from "classnames";

export default function OutlinedButton({
  label,
  onClickHandler,
  className,
  type,
  disabled,
}) {
  return (
    <div className={classNames(className, "flex box-border items-center mb-3")}>
      <button
        className={classNames(
          "default-linked-button text-theme-blue cursor-pointer align-middle items-center text-center box-border select-none outline-none py-0 px-2 font-medium font-lato text-sm border border-solid border-theme-blue",
          {
            "pointer-events-none text-gray-38 opacity-50": disabled,
          }
        )}
        onClick={onClickHandler}
        type={type ? type : "submit"}
        disabled={disabled}
      >
        <span className="ng-binding ng-scope">{label}</span>
      </button>
    </div>
  );
}

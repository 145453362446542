import React from "react";
import Icon from "../../components/Button/Icon";
import classNames from "classnames";

export default function Pagination({
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  return (
    <div className="flex justify-end align-middle items-center mt-5">
      <button
        className={classNames("border-none bg-transparent", {
          "pointer-events-none": currentPage === 1,
        })}
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
      >
        <Icon
          label="keyboard_double_arrow_left"
          title="Go to First Page"
          className={classNames("text-light-gray !mr-0")}
        />
      </button>
      <button
        className={classNames("border-none bg-transparent", {
          "pointer-events-none": currentPage === 1,
        })}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage == 1}
      >
        <Icon
          label="navigate_before"
          title="Previous Page"
          className={classNames("text-light-gray")}
        />
      </button>
      <div className="text-light-gray mb-1">
        {currentPage} / {totalPages}
      </div>
      <button
        className={classNames("border-none bg-transparent", {
          "pointer-events-none": currentPage == totalPages,
        })}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage == totalPages}
      >
        <Icon
          label="navigate_next"
          title="Next Page"
          className={classNames("text-light-gray")}
        />
      </button>
      <button
        className={classNames("border-none bg-transparent", {
          "pointer-events-none": currentPage == totalPages,
        })}
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
      >
        <Icon
          label="keyboard_double_arrow_right"
          title="Go to Last Page"
          className={classNames("text-light-gray !ml-0")}
        />
      </button>
    </div>
  );
}

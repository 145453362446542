import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import classNames from "classnames";
import Icon from "../../../components/Button/Icon";
import DefaultButton from "../../../components/Button/DefaultButton";
import "../../../styles/landingPage.scss";
import { START_MODULE_SET_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/module-sets";
import { START_CREATE_MODULE_DOCUMENT_ACTION } from "../../../rest-client/actions/module-documents";
import { useDispatch } from "react-redux";
import { useModuleSet } from "../../../rest-client/selectorHook";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import Layout from "../../../components/Layout";

export default function AddModuleForm() {
  const history = useHistory();
  const { url } = useRouteMatch(`/dashboard/learning`);

  const backClickHandler = React.useCallback(() => {
    history.push(`${url}/index/modules`);
  }, [history, url]);

  const dispatch = useDispatch();

  // API call to fetch module set list and populate inside dropdown
  React.useEffect(() => {
    dispatch({
      type: START_MODULE_SET_NON_PAGINATED_LIST_ACTION,
    });
  }, []);

  const {
    module_sets_non_paginated_list_data: { data: moduleSetListData },
  } = useModuleSet();

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const initialFormValues = {
    title: "",
    nickname: "",
    module_set: "",
    module_categories: [],
  };

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter Title";
    }
    if (!values.nickname) {
      error.nickname = "Please enter Nickname";
    }
    if (!values.module_set) {
      error.module_set = "Please select Module Set";
    }
    if (!values.module_categories || values.module_categories.length === 0) {
      error.module_categories = "At least one module category is required";
    } else {
      values.module_categories.forEach((category, index) => {
        if (!category.value) {
          error.module_categories = error.module_categories || [];
          error.module_categories[
            index
          ] = `Please enter value for ${category.name}`;
        }
      });
    }
    return error;
  }, []);

  const updateInitialValuesHandler = (selectedModuleSet, values) => {
    values.module_categories = [];
    const selectedModuleSetObj = moduleSetListData.find(
      (obj) => obj["id"] === selectedModuleSet?.value
    );
    selectedModuleSetObj.module_categories.map((category) =>
      values.module_categories.push({
        name: category.name.trimStart().trimEnd(),
        slug: category.slug,
        value: "",
      })
    );
  };

  const createModuleDocumentHandler = React.useCallback(
    (values) => {
      const module_set_categories = [];
      values.module_categories.map((module_category) => {
        module_set_categories.push({
          name: module_category.name,
          slug: module_category.slug,
          value: module_category.value.trimStart().trimEnd(),
        });
      });
      const createModuleDocumentData = {
        module_document: {
          title: values.title.trimStart().trimEnd(),
          nickname: values.nickname.trimStart().trimEnd(),
          module_set_id: values.module_set?.value,
          module_categories: module_set_categories,
        },
      };
      dispatch({
        type: START_CREATE_MODULE_DOCUMENT_ACTION,
        payload: { createModuleDocumentData, history },
      });
    },
    [dispatch]
  );

  const moduleSetOptions = moduleSetListData.map((module_set, index) => {
    return {
      label: module_set.title,
      value: module_set.id,
    };
  });

  return (
    <Layout>
      <div className="flex py-3 text-base font-lato px-6 cursor-pointer">
        <button
          className="border-none bg-transparent"
          onClick={backClickHandler}
        >
          <Icon
            label="arrow_back_ios"
            title=""
            className={classNames("text-light-gray")}
          />
        </button>
        <span className="text-slate-600" onClick={backClickHandler}>
          Back to Modules
        </span>
      </div>
      <div className="edit-profile-text"></div>
      <h2 className="form_title ml-10 mt-10">Module Information</h2>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={createModuleDocumentHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato mx-10 mt-10">
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 w-30">
                <div className="field-holder">
                  <Field
                    type="text"
                    id="title"
                    className={classNames("input-class", {
                      "error-input-class": errors.title && touched.title,
                    })}
                    value={values.title}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "title",
                        e.target.value.trimStart()
                      );
                    }}
                    tabIndex="1"
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="title"
                    className={classNames("label-class", {
                      "error-label-class": errors.title && touched.title,
                    })}
                  >
                    Title *
                  </label>
                  {errors.title && touched.title && (
                    <p className="error-label-class mt-25">
                      {errors.title ?? "-"}
                    </p>
                  )}
                </div>
                <div className="field-holder">
                  <Field
                    type="text"
                    id="nickname"
                    className={classNames("input-class", {
                      "error-input-class": errors.nickname && touched.nickname,
                    })}
                    required
                    value={values.nickname}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "nickname",
                        e.target.value.trimStart()
                      );
                    }}
                    tabIndex="3"
                    maxLength={50}
                  />
                  <label
                    htmlFor="nickname"
                    className={classNames("label-class", {
                      "error-label-class": errors.nickname && touched.nickname,
                    })}
                  >
                    Nickname *
                  </label>
                  {errors.nickname && touched.nickname && (
                    <p className="error-label-class mt-25">
                      {errors.nickname ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-full md:w-70 md:mx-10">
                <div className="mt-2 mb-10">
                  <Field name="module_set">
                    {({ field, form, meta }) => (
                      <SingleSelect
                        field={field}
                        form={form}
                        meta={meta}
                        options={moduleSetOptions}
                        placeholder="Module Set*"
                        onChange={(value) => {
                          updateInitialValuesHandler(value, form.values);
                        }}
                        tabIndex="2"
                      />
                    )}
                  </Field>
                </div>
                {values.module_set && (
                  <>
                    <FieldArray
                      name="module_categories"
                      render={(arrayHelpers) => {
                        const module_categories = values.module_categories;
                        return (
                          <>
                            {module_categories.map((module_category, index) => (
                              <div key={index}>
                                <div className="field-holder">
                                  <Field
                                    type="text"
                                    id={`module_categories.${index}.value`}
                                    name={`module_categories.${index}.value`}
                                    className={classNames("input-class", {
                                      "error-input-class":
                                        touched.module_categories &&
                                        touched.module_categories[index] &&
                                        errors.module_categories &&
                                        errors.module_categories[index],
                                    })}
                                    maxLength={50}
                                    tabIndex={`${index + 4}`}
                                    required
                                    value={
                                      values.module_categories[index].value
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        `module_categories.${index}.value`,
                                        e.target.value.trimStart()
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`module_categories.${index}.value`}
                                    className={classNames("label-class", {
                                      "error-label-class":
                                        touched.module_categories &&
                                        touched.module_categories[index] &&
                                        errors.module_categories &&
                                        errors.module_categories[index],
                                    })}
                                  >
                                    {module_category.name} *
                                  </label>
                                  {touched.module_categories &&
                                    touched.module_categories[index] &&
                                    errors.module_categories &&
                                    errors.module_categories[index] && (
                                      <p className="error-label-class mt-25">
                                        {errors.module_categories[index] ?? "-"}
                                      </p>
                                    )}
                                </div>
                              </div>
                            ))}
                          </>
                        );
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-center	md:justify-end md:mx-10 mt-10">
              <DefaultButton label="Submit Module" />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}

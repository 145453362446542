import React from "react";
import { useTable, usePagination } from "react-table";
import { ClipLoader } from "react-spinners";
import { PAGE_SIZE } from "../../utils";

export default function AdministrationListTable({
  columns,
  dataListLoading,
  listData,
  listFor,
}) {
  const tableInstance = useTable(
    { columns, data: listData, initialState: { pageSize: PAGE_SIZE } },
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;
  return (
    <>
      {dataListLoading ? (
        <div className="flex flex-col justify-center items-center h-32">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">
            Fetching {listFor}...
          </p>
        </div>
      ) : (
        <table
          {...getTableProps()}
          className="w-full overflow-hidden text-sm z-0 overflow-y-scroll border-collapse"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="opacity-50 text-left p-2"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="table-row-group align-middle border-inherit"
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="overflow-x-auto relative"
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="align-middle text-left py-4 px-2"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}

import React from "react";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";
import { useHistory, useRouteMatch } from "react-router-dom";
import Layout from "../../../components/Layout";
import ViewResultsSubComponent from "../../../components/ResourceTypes/ViewResultsSubComponent";

export default function ViewResults() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const { url } = useRouteMatch(`/dashboard`);
  const history = useHistory();
  const backToManageSessionClickHandler = React.useCallback(() => {
    history.push(
      `${url}/${userData.role === "Teacher" ? "manage-sessions" : "index"}`
    );
  }, [history, url]);

  return (
    <>
      <Layout>
        <div className="flex py-3 text-base font-lato px-6">
          <button
            className="border-none bg-transparent"
            onClick={backToManageSessionClickHandler}
          >
            <Icon
              label="arrow_back_ios"
              title=""
              className={classNames("text-light-gray")}
            />
          </button>
          <span className="text-slate-600">
            Back to{" "}
            {userData.role === "Teacher"
              ? "Manage Sessions"
              : userData?.role === "Learner"
              ? "Dashboard"
              : "-"}
          </span>
        </div>
        <div className="edit-profile-text"></div>
        <ViewResultsSubComponent />
      </Layout>
    </>
  );
}

import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getTheInnerHTMLHook } from "../../commonHook";
import "../../styles/view_results.scss";

export default function PieChartsResult({ pieChartAnalysisData }) {
  const analysisData = React.useMemo(() => {
    const data = pieChartAnalysisData?.map((e) => {
      return {
        name: getTheInnerHTMLHook(e.text),
        count: e.meta_responses.count,
      };
    });
    return data;
  }, [pieChartAnalysisData]);

  console.log("analysis data ", analysisData);

  const style = {
    top: 0,
    left: 0,
    lineHeight: "24px",
    borderRadius: "5px",
    textOverflow: "ellipsis",
    position: "relative",
  };
  const COLORS = [
    "rgb(31, 119, 180)",
    "rgb(174, 199, 232)",
    "rgb(255, 127, 14)",
    "rgb(255, 187, 120)",
    "rgb(44, 160, 44)",
    "rgb(193, 205, 35)",
    "rgb(203, 51, 59)",
  ];

  const RADIAN = Math.PI / 180;

  const truncate = (str, max) => {
    return str.length > max ? str.substr(0, max - 1) + "..." : str;
  };
  return (
    <ResponsiveContainer width="90%" height={400}>
      <PieChart className="flex flex-col items-center">
        <Pie
          data={analysisData}
          labelLine={false}
          startAngle={90}
          endAngle={-270}
          outerRadius="80%"
          fill="#8884d8"
          dataKey="count"
          legendType="circle"
        >
          {analysisData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          iconSize={10}
          height={120}
          layout="vertical"
          verticalAlign="middle"
          wrapperStyle={style}
          formatter={(value) => truncate(value, 25)}
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}

import React from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import iconLoginButton from "../../images/icn_loginbtn.svg";
import Icon from "../../components/Button/Icon";
import DefaultLoginForm from "../../components/LoginForm/DefaultLoginForm";
import ResetPasswordForm from "../../components/LoginForm/ResetPasswordForm";
import { useDispatch } from "react-redux";
import {
  LOCALLY_UPDATE_DELETED_USER_ERROR_ACTION,
  LOCALLY_UPDATE_RECOVERY_PASSWORD_ERROR_ACTION,
} from "../../rest-client/actions/login";
import "../../styles/responsive-modal.scss";

function LoginModal({ isOpenLoginModal, setIsOpenLoginModal }) {
  const [showResetPasswordForm, setShowResetPasswordForm] =
    React.useState(false);
  const dispatch = useDispatch();
  const customClass = "custom-login-modal-class"; //import class from "responsive-modal.scss file"
  const closeIcon = (
    <div className="w-full flex justify-between">
      <Icon label="close" title="" />
    </div>
  );
  const resetRecoveryPasswordError = React.useCallback(() => {
    dispatch({
      type: LOCALLY_UPDATE_RECOVERY_PASSWORD_ERROR_ACTION,
    });
  }, [dispatch]);

  const deletedUserErrorHandler = React.useCallback(() => {
    dispatch({
      type: LOCALLY_UPDATE_DELETED_USER_ERROR_ACTION,
    });
  }, [dispatch]);

  const onCloseModal = React.useCallback(() => {
    setIsOpenLoginModal(false);
    setShowResetPasswordForm(false);
    if (showResetPasswordForm) {
      resetRecoveryPasswordError();
    } else {
      deletedUserErrorHandler();
    }
  }, [showResetPasswordForm]);
  return (
    <Modal
      center
      classNames={{
        modal: customClass,
      }}
      open={isOpenLoginModal}
      onClose={onCloseModal}
      closeIcon={closeIcon}
    >
      <div className="flex flex-col">
        <div className="bg-theme-blue">
          <div className="font-lato text-white-87 text-xl font-normal">
            <div className="p-4 flex flex-row">
              <img
                className="h-6 mt-1 mr-1.5"
                src={iconLoginButton}
                alt="lab-icon"
              />
              <div>{showResetPasswordForm ? "Reset Password" : "Log in"}</div>
            </div>
          </div>
        </div>
        <div className="px-4 py-2 bg-white">
        <div class="bg-theme-blue bg-opacity-25 p-2 text-black-87 text-center">
          If you get <span class="font-bold">"Wrong credentials"</span>“ repeatedly, or can’t log in, it is not working at the moment and we are actively fixing it this week.
        </div>
          {showResetPasswordForm ? (
            <ResetPasswordForm setIsOpenLoginModal={setIsOpenLoginModal} />
          ) : (
            <DefaultLoginForm setIsOpenLoginModal={setIsOpenLoginModal} />
          )}
          {showResetPasswordForm ? (
            <div className="flex justify-end mt-4 font-lato text-base text-black-87 cursor-pointer mb-2.5">
              <button
                className="login-button-without-icon bg-white-250 text-white-87 text-black-87 box-shadow-btn cursor-pointer align-middle items-center text-center box-border select-none outline-none border-0 py-0 px-2 font-medium font-lato text-sm"
                onClick={() => {
                  setShowResetPasswordForm(false);
                  resetRecoveryPasswordError();
                }}
              >
                <span className="">Cancel</span>
              </button>
            </div>
          ) : (
            <div className="w-full flex justify-end mb-2.5">
              <button
                className="mt-6 font-lato text-base outline-none border-0 bg-transparent underline text-black-87 cursor-pointer"
                onClick={() => {
                  setShowResetPasswordForm(true);
                  deletedUserErrorHandler();
                }}
                type="button"
              >
                Forgot your password?
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default LoginModal;

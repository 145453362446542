import React, { useState, useEffect, useRef } from "react";
import "../../styles/tabs.scss";
import ListSets from "./ModuleSets/ListSets";
import { START_MODULE_SET_LIST_ACTION } from "../../rest-client/actions/module-sets";
import { useDispatch } from "react-redux";
import { useModuleSet } from "../../rest-client/selectorHook";
import Layout from "../../components/Layout";
import ModuleSetsModuleTab from "../../components/Tabs/ModuleSetsModuleTab";

export default function TabsView(props) {
  const [searchInput, setSearchInput] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // const payload = searchInput ? { query: searchInput } : null;
    if (searchInput.length === 0 || searchInput.length >= 3) {
      dispatch({
        type: START_MODULE_SET_LIST_ACTION,
        payload: {
          page: currentPage,
          ...(searchInput && {
            query: searchInput,
          }),
        },
      });
    }
  }, [dispatch, searchInput]);

  const {
    module_sets_list_data: {
      isLoading: moduleSetListLoading,
      data: moduleSetListData,
      meta: moduleSetListMeta,
    },
  } = useModuleSet();

  useEffect(() => {
    setTotalPages(moduleSetListMeta?.pages);
  }, [moduleSetListMeta]);

  useEffect(() => {
    if (
      (moduleSetListData.length > 0 || searchInput.length > 0) &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [moduleSetListData, searchInput]);

  return (
    <Layout>
      <ModuleSetsModuleTab />
      <div className="py-4">
        <ListSets
          moduleSetListData={moduleSetListData}
          moduleSetListLoading={moduleSetListLoading}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          inputRef={inputRef}
        />
      </div>
    </Layout>
  );
}

import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

export default function ModuleSetsModuleTab() {
  const history = useHistory();
  const { url } = useRouteMatch(`/dashboard/learning`);

  const checkIsTabActive = React.useCallback(
    (pathName) => {
      return history && history.location.pathname.includes(pathName);
    },
    [history]
  );

  const tabChangeHandler = React.useCallback(
    (path) => {
      history.push(`${url}/${path}`);
    },
    [history]
  );
  const tabData = [
    {
      label: "Module Sets",
      onClick: () => tabChangeHandler(`index/module_sets`),
      isActive: checkIsTabActive("module_sets"),
    },
    {
      label: "Modules",
      onClick: () => tabChangeHandler(`index/modules`),
      isActive: checkIsTabActive("modules"),
    },
  ];
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="nav nav-tabs flex flex-nowrap overflow-x-auto edit-profile-text">
        {tabData.map((tab, idx) => {
          return (
            <button
              key={idx}
              className={`nav-item mb-0 nav-link rounded-t-lg block bg-gray-100 font-medium text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 cursor-pointer hover:bg-blue-100 hover:text-black mx-1 mt-1 ${
                tab.isActive
                  ? "border-blue-theme bg-theme-blue text-white font-bold pointer-events-none	"
                  : ""
              }`}
              // Change the active tab on click.
              onClick={tab.onClick}
            >
              {tab.label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

import React from "react";
import parse from "html-react-parser";
import TextArea from "../TextAreaComponent/TextArea";

export default function PollsResource({
  htmlContent,
  polls,
  discussions,
  selectedCheckboxes,
  setSelectedCheckboxes,
  selectRadio,
  setSelectRadio,
  setSelectedPollId,
  setFormTextData,
}) {
  const handleRadioChange = (pollQuestionId, optionsId, pollId) => {
    const newData = {
      ...selectRadio,
      poll_question_id: pollQuestionId,
      poll_question_option_ids: [optionsId],
    };
    setSelectRadio(newData);
    setSelectedPollId(pollId);
  };

  const handleCheckboxChange = (pollQuestionId, optionId, pollId) => {
    const { poll_question_option_ids = [] } = selectedCheckboxes || {};
    const newSelectedCheckboxes =
      selectedCheckboxes?.poll_question_option_ids.includes(optionId)
        ? selectedCheckboxes?.poll_question_option_ids.filter(
            (id) => id !== optionId
          )
        : [...poll_question_option_ids, optionId];
    const newData = {
      ...selectedCheckboxes,
      poll_question_id: pollQuestionId,
      poll_question_option_ids: newSelectedCheckboxes,
    };
    setSelectedCheckboxes(newData);
    setSelectedPollId(pollId);
  };

  return (
    <>
      <div>{parse(htmlContent)}</div>
      {polls.map((poll) => {
        {
          return poll.poll_questions.map((poll_question) => {
            return (
              <>
                <div key={poll_question.id}>{parse(poll_question.text)}</div>
                {poll_question.poll_question_options.map((option, index) => {
                  const optionId = `${poll_question.id}_${index}`;
                  return (
                    <>
                      {poll_question.question_type === "bar_chart" ? (
                        <div className="flex items-center" key={option.id}>
                          <input
                            type="radio"
                            name={`question_${poll_question.id}`}
                            id={`option_${optionId}_radio`}
                            checked={
                              selectRadio?.poll_question_option_ids[0] ===
                              option.id
                            }
                            onChange={() => {
                              handleRadioChange(
                                poll_question.id,
                                option.id,
                                poll.id
                              );
                            }}
                            className="w-4 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor={`option_${optionId}_radio`}
                            key={index}
                          >
                            <span>{parse(option.text)}</span>
                          </label>
                        </div>
                      ) : (
                        <div className="flex items-center" key={option.id}>
                          <input
                            type="checkbox"
                            name="checkbox_option"
                            id={`option_${optionId}_checkbox`}
                            checked={selectedCheckboxes?.poll_question_option_ids.includes(
                              option.id
                            )}
                            onChange={() =>
                              handleCheckboxChange(
                                poll_question.id,
                                option.id,
                                poll.id
                              )
                            }
                            className="w-4 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor={`option_${optionId}_checkbox`}
                            key={index}
                          >
                            <span>{parse(option.text)}</span>
                          </label>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            );
          });
        }
      })}
      {Array.isArray(discussions) && discussions.length > 0 ? (
        <TextArea discussions={discussions} setFormTextData={setFormTextData} />
      ) : null}
    </>
  );
}

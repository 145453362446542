import {
  START_MODULE_SESSION_LIST_ACTION,
  ERROR_MODULE_SESSION_LIST_ACTION,
  SUCCESS_MODULE_SESSION_LIST_ACTION,
  START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
  SUCCESS_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
  ERROR_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
  START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
  ERROR_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
  SUCCESS_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
  START_MODULE_SESSION_COMPLETE_ACTION,
  ERROR_MODULE_SESSION_COMPLETE_ACTION,
  SUCCESS_MODULE_SESSION_COMPLETE_ACTION,
  START_DELETE_MODULE_SESSION_ACTION,
  SUCCESS_DELETE_MODULE_SESSION_ACTION,
  FAILURE_DELETE_MODULE_SESSION_ACTION,
  START_CREATE_MODULE_SESSION_ACTION,
  SUCCESS_CREATE_MODULE_SESSION_ACTION,
  FAILURE_CREATE_MODULE_SESSION_ACTION,
} from "../actions/module-sessions";

const initState = {
  module_sessions_list_data: {
    isLoading: false,
    isError: false,
    data: [],
  },
  module_sessions_by_classroom_by_module_set_data: {
    isLoading: false,
    isError: false,
    data: [],
  },
  module_session_by_id_with_classroom_id_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  module_session_complete: {
    isLoading: false,
    isError: false,
    data: null,
  },
  module_session_delete: {
    isLoading: false,
    isError: false,
    data: {},
  },
  module_session_create: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const moduleSessionsReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_MODULE_SESSION_LIST_ACTION: {
      return {
        ...prevState,
        module_sessions_list_data: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    }
    case SUCCESS_MODULE_SESSION_LIST_ACTION: {
      return {
        ...prevState,
        module_sessions_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_MODULE_SESSION_LIST_ACTION: {
      return {
        ...prevState,
        module_sessions_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
        },
      };
    }
    case START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION: {
      return {
        ...prevState,
        module_sessions_by_classroom_by_module_set_data: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    }
    case SUCCESS_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION: {
      return {
        ...prevState,
        module_sessions_by_classroom_by_module_set_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION: {
      return {
        ...prevState,
        module_sessions_by_classroom_by_module_set_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
        },
      };
    }
    case START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION: {
      return {
        ...prevState,
        module_session_by_id_with_classroom_id_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION: {
      return {
        ...prevState,
        module_session_by_id_with_classroom_id_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION: {
      return {
        ...prevState,
        module_session_by_id_with_classroom_id_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_MODULE_SESSION_COMPLETE_ACTION: {
      return {
        ...prevState,
        module_session_complete: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_MODULE_SESSION_COMPLETE_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_session_complete: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case ERROR_MODULE_SESSION_COMPLETE_ACTION: {
      return {
        ...prevState,
        module_session_complete: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_DELETE_MODULE_SESSION_ACTION: {
      return {
        ...prevState,
        module_session_delete: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }

    case SUCCESS_DELETE_MODULE_SESSION_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_session_delete: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }

    case FAILURE_DELETE_MODULE_SESSION_ACTION: {
      return {
        ...prevState,
        module_session_delete: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }

    case START_CREATE_MODULE_SESSION_ACTION: {
      return {
        ...prevState,
        module_session_create: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CREATE_MODULE_SESSION_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_session_create: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CREATE_MODULE_SESSION_ACTION: {
      return {
        ...prevState,
        module_session_create: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }

    default:
      return prevState;
  }
};

import React from "react";
import classNames from "classnames";

export default function DefaultButtonLarge({
  label,
  className,
  onClickHandler,
}) {
  return (
    <div className={classNames(className, "flex box-border items-center mb-3")}>
      <button
        className="w-40 h-10 text-white bg-theme-blue btn-shadow cursor-pointer align-middle items-center text-center box-border select-none outline-none border-0 py-0 px-2 font-bold font-lato text-base"
        type="submit"
        onClick={onClickHandler}
      >
        <span className="ng-binding ng-scope">{label}</span>
      </button>
    </div>
  );
}

import {
  START_GROUPS_LIST_ACTION,
  SUCCESS_GROUPS_LIST_ACTION,
  ERROR_GROUPS_LIST_ACTION,
  START_CREATE_GROUP_ACTION,
  SUCCESS_CREATE_GROUP_ACTION,
  FAILURE_CREATE_GROUP_ACTION,
  START_UPDATE_GROUPS_ACTION,
  SUCCESS_UPDATE_GROUPS_ACTION,
  FAILURE_UPDATE_GROUPS_ACTION,
  START_DELETE_GROUPS_ACTION,
  SUCCESS_DELETE_GROUPS_ACTION,
  FAILURE_DELETE_GROUPS_ACTION,
} from "../actions/groups";

const initState = {
  groups_list_data: {
    isLoading: false,
    isError: false,
    data: [],
    meta: {},
  },
  group_create: {
    isLoading: false,
    isError: false,
    data: null,
  },
  group_update: {
    isLoading: false,
    isError: false,
    data: {},
  },
  group_delete: {
    isLoading: false,
    isError: false,
    data: {},
  },
};

export const groupsReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_GROUPS_LIST_ACTION:
      return {
        ...prevState,
        groups_list_data: {
          isLoading: true,
          isError: false,
          data: [],
          meta: {},
        },
      };
    case SUCCESS_GROUPS_LIST_ACTION:
      return {
        ...prevState,
        groups_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
          meta: action.meta,
        },
      };
    case ERROR_GROUPS_LIST_ACTION:
      return {
        ...prevState,
        groups_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
          meta: {},
        },
      };
    case START_CREATE_GROUP_ACTION: {
      return {
        ...prevState,
        group_create: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CREATE_GROUP_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        group_create: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CREATE_GROUP_ACTION: {
      return {
        ...prevState,
        group_create: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_UPDATE_GROUPS_ACTION:
      return {
        ...prevState,
        groups_update: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };

    case SUCCESS_UPDATE_GROUPS_ACTION:
      return {
        ...prevState,
        groups_update: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };

    case FAILURE_UPDATE_GROUPS_ACTION:
      return {
        ...prevState,
        groups_update: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };

    case START_DELETE_GROUPS_ACTION: {
      return {
        ...prevState,
        groups_delete: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }

    case SUCCESS_DELETE_GROUPS_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        groups_delete: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }

    case FAILURE_DELETE_GROUPS_ACTION: {
      return {
        ...prevState,
        groups_delete: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    default:
      return prevState;
  }
};

import { all } from "redux-saga/effects";
import { fetchContactUsActionWatcher } from "./contact-us";
import {
  loginRequestWatcher,
  recoveryPasswordRequestWatcher,
  resetPasswordRequestWatcher,
} from "./login";
import {
  fetchModuleSetListActionWatcher,
  fetchModuleSetUpdateActionWatcher,
  fetchModuleSetDeleteRequestWatcher,
  fetchModuleSetCloneRequestWatcher,
  fetchModuleSetCreateRequestWatcher,
  fetchModuleSetShowRequestWatcher,
  fetchModuleSetByCategoriesActionWatcher,
  fetchModuleSetNonPaginatedListActionWatcher,
} from "./module-sets";
import {
  fetchModuleDocumentByIdRequestWatcher,
  fetchModuleDocumentsRequestWatcher,
  fetchModuleDocumentDeleteRequestWatcher,
  UpdateModuleResourceNodeByIdRequestWatcher,
  fetchModuleDocumentCreateRequestWatcher,
  fetchModuleDocumentCloneRequestWatcher,
  UpdateModuleDocumentRequestWatcher,
  fetchDiscussionEntriesWatcher,
  fetchModuleResourceNodeByIdRequestWatcher,
} from "./module-documents";
import {
  fetchResourcesListRequestWatcher,
  fetchResourceCloneRequestWatcher,
  fetchResourceDeleteRequestWatcher,
  fetchResourceShowRequestWatcher,
  addResourceRequestWatcher,
  addDiscussionPromptRequestWatcher,
  addPollTypeResourceRequestWatcher,
  addCanvasImgRequestWatcher,
  addCanvasBgImgRequestWatcher,
  updateResourceRequestWatcher,
  fetchResourcetByIdRequestWatcher,
  updatePollByIdRequestWatcher,
  fetchDiscussionPromptDeleteRequestWatcher,
  fetchModuleResourceNodeWatcher,
} from "./resources";
import { viewModuleWatcher } from "./view-module";
import {
  submitDiscussionEntriesWatcher,
  submitPollQuestionsWatcher,
  submitResourceNodeWatcher,
  uploadCanvasImageWatcher,
  viewResourceNodeWatcher,
} from "./view-resource-node";
import {
  fetchSchoolsListActionWatcher,
  fetchSchoolCreateRequestWatcher,
  fetchSchoolUpdateActionWatcher,
  fetchSchoolDeleteRequestWatcher,
  fetchSchoolsNonPaginatedListActionWatcher,
} from "./schools";
import {
  fetchTeachersListActionWatcher,
  fetchTeacherCreateRequestWatcher,
  fetchTeacherUpdateActionWatcher,
  fetchTeacherDeleteRequestWatcher,
  fetchTeachersNonPaginatedListActionWatcher,
} from "./teachers";
import {
  fetchGroupsListActionWatcher,
  fetchGroupCreateRequestWatcher,
  fetchGroupUpdateActionWatcher,
  fetchGroupDeleteRequestWatcher,
} from "./groups";
import {
  fetchClassroomsListActionWatcher,
  fetchClassroomCreateRequestWatcher,
  fetchClassroomUpdateActionWatcher,
  fetchClassroomDeleteRequestWatcher,
  fetchClassroomShowRequestWatcher,
  fetchClassroomsNonPaginatedListActionWatcher,
  fetchClassroomExportRequestWatcher,
} from "./classrooms";
import {
  fetchAdministratorsListActionWatcher,
  fetchAdministratorCreateRequestWatcher,
} from "./administrators";
import {
  fetchModuleSessionByClassroomByModuleSetWatcher,
  fetchModuleSessionByIdWithClassroomIdWatcher,
  fetchModuleSessionListActionWatcher,
  fetchModuleSessionCompleteRequestWatcher,
  fetchModuleSessionDeleteRequestWatcher,
  fetchModuleSessionCreateRequestWatcher,
} from "./module-sessions";
import {
  fetchGroupCourseModuleWatcher,
  fetchCourseModuleListWatcher,
} from "./group-course-module";

export default function* rootSaga() {
  yield all([
    fetchContactUsActionWatcher(),
    loginRequestWatcher(),
    recoveryPasswordRequestWatcher(),
    fetchModuleSetListActionWatcher(),
    resetPasswordRequestWatcher(),
    fetchModuleSetUpdateActionWatcher(),
    fetchModuleSetDeleteRequestWatcher(),
    fetchModuleSetCloneRequestWatcher(),
    fetchModuleSetCreateRequestWatcher(),
    fetchModuleSetShowRequestWatcher(),
    fetchModuleSetByCategoriesActionWatcher(),
    fetchModuleSetNonPaginatedListActionWatcher(),
    fetchModuleDocumentsRequestWatcher(),
    fetchDiscussionEntriesWatcher(),

    // resource watchers
    fetchResourcesListRequestWatcher(),
    fetchModuleDocumentByIdRequestWatcher(),
    fetchModuleDocumentDeleteRequestWatcher(),
    UpdateModuleResourceNodeByIdRequestWatcher(),
    fetchModuleDocumentCreateRequestWatcher(),
    fetchModuleDocumentCloneRequestWatcher(),
    UpdateModuleDocumentRequestWatcher(),
    fetchResourceCloneRequestWatcher(),
    fetchResourceDeleteRequestWatcher(),
    fetchResourceShowRequestWatcher(),
    fetchResourcetByIdRequestWatcher(),
    addResourceRequestWatcher(),
    addPollTypeResourceRequestWatcher(),
    addCanvasImgRequestWatcher(),
    addCanvasBgImgRequestWatcher(),
    updateResourceRequestWatcher(),
    addDiscussionPromptRequestWatcher(),
    fetchDiscussionPromptDeleteRequestWatcher(),
    updatePollByIdRequestWatcher(),
    fetchModuleResourceNodeByIdRequestWatcher(),
    fetchModuleResourceNodeWatcher(),

    viewModuleWatcher(),
    viewResourceNodeWatcher(),
    fetchSchoolsListActionWatcher(),
    fetchSchoolsNonPaginatedListActionWatcher(),
    fetchSchoolCreateRequestWatcher(),
    fetchSchoolUpdateActionWatcher(),
    fetchSchoolDeleteRequestWatcher(),
    fetchTeachersListActionWatcher(),
    fetchTeacherCreateRequestWatcher(),
    fetchTeacherUpdateActionWatcher(),
    fetchTeacherDeleteRequestWatcher(),
    fetchTeachersNonPaginatedListActionWatcher(),
    fetchGroupsListActionWatcher(),
    fetchGroupCreateRequestWatcher(),
    fetchGroupUpdateActionWatcher(),
    fetchGroupDeleteRequestWatcher(),
    fetchClassroomsListActionWatcher(),
    fetchClassroomCreateRequestWatcher(),
    fetchClassroomUpdateActionWatcher(),
    fetchClassroomDeleteRequestWatcher(),
    fetchClassroomShowRequestWatcher(),
    fetchClassroomExportRequestWatcher(),
    fetchClassroomsNonPaginatedListActionWatcher(),
    fetchAdministratorsListActionWatcher(),
    fetchAdministratorCreateRequestWatcher(),

    // Module Sessions Watchers
    fetchModuleSessionListActionWatcher(),
    fetchModuleSessionByClassroomByModuleSetWatcher(),
    fetchModuleSessionByIdWithClassroomIdWatcher(),
    fetchModuleSessionCompleteRequestWatcher(),
    fetchModuleSessionDeleteRequestWatcher(),
    fetchModuleSessionCreateRequestWatcher(),

    //Group course module Watchers
    fetchGroupCourseModuleWatcher(),
    fetchCourseModuleListWatcher(),
    submitResourceNodeWatcher(),
    uploadCanvasImageWatcher(),
    submitPollQuestionsWatcher(),
    submitDiscussionEntriesWatcher(),
  ]);
}

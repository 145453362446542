import React, { useState, useEffect, useRef } from "react";
import { START_FETCHING_MODULE_DOCUMENTS_ACTION } from "../../rest-client/actions/module-documents";
import {
  useModuleDocuments,
  useModuleSet,
} from "../../rest-client/selectorHook";
import { useDispatch } from "react-redux";
import ModuleContainer from "../ContentCreation/Resources/ModuleContainer";
import { Formik, Form, Field } from "formik";
import SingleSelect from "../../components/Dropdowns/SingleSelect";
import { START_MODULE_SET_LIST_ACTION } from "../../rest-client/actions/module-sets";
import Layout from "../../components/Layout";

export default function ListModuleDocuments() {
  const dispatch = useDispatch();
  const [moduleOrderType, setModuleOrderType] = useState("asc");
  const [moduleSearchInput, setModuleSearchInput] = useState("");
  const [moduleTotalPages, setModuleTotalPages] = useState(0);
  const [moduleCurrentPage, setModuleCurrentPage] = useState(1);
  const [initialFormVariables, setInitialFormVariables] = React.useState({
    module_set: "",
  });
  const [moduleSetData, setModuleSetData] = React.useState();
  const inputRef = useRef(null);

  const {
    module_sets_list_data: {
      data: moduleSetListData,
    },
  } = useModuleSet();

  const {
    module_documents_list_data: {
      isLoading: isLoadingModuleDocuments,
      data: moduleDocumentsListData,
      meta: moduleDocumentsListMeta,
    },
  } = useModuleDocuments();

  useEffect(() => {
      if (moduleSearchInput.length === 0 || moduleSearchInput.length >= 3) {
        dispatch({
          type: START_FETCHING_MODULE_DOCUMENTS_ACTION,
          payload: {
            orderType: moduleOrderType,
            query: moduleSearchInput,
            filter: { module_set_id: moduleSetData?.id },
            page: moduleCurrentPage,
          },
        });
      }
  }, [
    dispatch,
    moduleOrderType,
    moduleSearchInput,
    moduleSetData,
    moduleCurrentPage,
  ]);

  useEffect(() => {
    if (
      (moduleDocumentsListData.length > 0 || moduleSearchInput.length > 0) &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [moduleDocumentsListData, moduleSearchInput]);

  useEffect(() => {
    setModuleTotalPages(moduleDocumentsListMeta?.pages);
  }, [moduleDocumentsListMeta]);

  React.useEffect(() => {
    dispatch({
      type: START_MODULE_SET_LIST_ACTION,
      payload: null,
    });
  }, []);

  React.useEffect(() => {
    setInitialFormVariables({
      module_set:
        moduleSetListData && moduleSetListData.length > 0
          ? {
              label: moduleSetListData[0].title,
              value: moduleSetListData[0].title,
              id: moduleSetListData[0].id,
            }
          : "",
    });
    if (moduleSetListData.length > 0) {
      setModuleSetData({
        label: moduleSetListData[0].title,
        value: moduleSetListData[0].title,
        id: moduleSetListData[0].id,
      });
    }
  }, [moduleSetListData]);

  const moduleSetOptions = moduleSetListData.map((module_set, index) => {
    return {
      label: module_set.title,
      value: module_set.title,
      id: module_set.id,
    };
  });

  return (
    <Layout>
      <div className="flex flex-col">
        <div className="pt-2 inline-block align-middle ml-2">
          <div className="box-border flex flex-row">
            <div className="flex-1 box-border max-h-full max-width-40-percent"></div>
            <Formik enableReinitialize initialValues={initialFormVariables}>
              {({ values }) => (
                <Form className="font-lato mx-10 mt-10 flex-1 box-border max-h-full max-width-20-percent">
                  <div className="">
                    <Field name="module_set">
                      {({ field, form, meta }) => (
                        <SingleSelect
                          field={field}
                          form={form}
                          meta={meta}
                          options={moduleSetOptions}
                          placeholder="Choose Course"
                          onChange={(value) => {
                            setModuleSetData(value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="flex-1 box-border max-h-full max-width-40-percent"></div>
          </div>
          <div className="overflow-hidden border rounded-lg">
            <div className="flex flex-row	box-border">
              <ModuleContainer
                documentSetData={moduleDocumentsListData.module_documents || []}
                setModuleOrderType={setModuleOrderType}
                moduleOrderType={moduleOrderType}
                setModuleSearchInput={setModuleSearchInput}
                moduleSearchInput={moduleSearchInput}
                isLoadingModuleDocuments={isLoadingModuleDocuments}
                totalPages={moduleTotalPages}
                currentPage={moduleCurrentPage}
                setCurrentPage={setModuleCurrentPage}
                inputRef={inputRef}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

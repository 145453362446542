import { takeLatest, call, put } from "redux-saga/effects";
import {
  ERROR_FETCHING_MODULE_DOCUMENTS_ACTION,
  ERROR_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  ERROR_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
  START_FETCHING_MODULE_DOCUMENTS_ACTION,
  START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  START_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
  SUCCESS_FETCHING_MODULE_DOCUMENTS_ACTION,
  SUCCESS_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  START_DELETE_MODULE_DOCUMENT_ACTION,
  SUCCESS_DELETE_MODULE_DOCUMENT_ACTION,
  FAILURE_DELETE_MODULE_DOCUMENT_ACTION,
  SUCCESS_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
  START_CREATE_MODULE_DOCUMENT_ACTION,
  SUCCESS_CREATE_MODULE_DOCUMENT_ACTION,
  FAILURE_CREATE_MODULE_DOCUMENT_ACTION,
  START_CLONE_MODULE_DOCUMENT_ACTION,
  SUCCESS_CLONE_MODULE_DOCUMENT_ACTION,
  FAILURE_CLONE_MODULE_DOCUMENT_ACTION,
  START_UPDATE_MODULE_DOCUMENT_ACTION,
  SUCCESS_UPDATE_MODULE_DOCUMENT_ACTION,
  FAILURE_UPDATE_MODULE_DOCUMENT_ACTION,
  START_FETCH_DISCUSSION_ENTRIES_ACTION,
  SUCCESS_FETCH_DISCUSSION_ENTRIES_ACTION,
  FAILURE_FETCH_DISCUSSION_ENTRIES_ACTION,
  START_MODULE_RESOURCE_NODE_BY_ID,
  SUCCESS_MODULE_RESOURCE_NODE_BY_ID,
  ERROR_MODULE_RESOURCE_NODE_BY_ID,
} from "../actions/module-documents";
import {
  fetchModuleDocumentByIdRequest,
  fetchModuleDocumentsListRequest,
  deleteModuleDocumentRequest,
  fetchUpdateModuleResourceNodeByIdRequest,
  fetchCreateModuleDocumentRequest,
  fetchModuleDocumentCloneRequest,
  updateModuleDocumentRequest,
  fetchDiscussionEntriesRequest,
  fetchModuleResourceNodeByIdRequest,
} from "../apis/module-documents";
import { fetchResourcesListRequestWorker } from "./resources";
import { toast } from "react-toastify";
import { fetchModuleSetListActionWorker } from "./module-sets";

export function* fetchModuleDocumentsRequestWatcher() {
  yield takeLatest(
    START_FETCHING_MODULE_DOCUMENTS_ACTION,
    fetchModuleDocumentsListWorker
  );
}

function* fetchModuleDocumentsListWorker({ payload }) {
  const fetchModuleDocumentsListResponse = yield call(
    fetchModuleDocumentsListRequest,
    payload
  );
  if (fetchModuleDocumentsListResponse.status) {
    yield put({
      type: SUCCESS_FETCHING_MODULE_DOCUMENTS_ACTION,
      data: fetchModuleDocumentsListResponse.data,
      meta: fetchModuleDocumentsListResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_FETCHING_MODULE_DOCUMENTS_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleDocumentByIdRequestWatcher() {
  yield takeLatest(
    START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
    fetchModuleDocumentByIdRequestWorker
  );
}

function* fetchModuleDocumentByIdRequestWorker({ payload }) {
  const fetchModuleDocumentByIdResponse = yield call(
    fetchModuleDocumentByIdRequest,
    payload
  );
  if (fetchModuleDocumentByIdResponse.status) {
    yield put({
      type: SUCCESS_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
      data: fetchModuleDocumentByIdResponse.data,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleDocumentDeleteRequestWatcher() {
  yield takeLatest(
    START_DELETE_MODULE_DOCUMENT_ACTION,
    fetchModuleDocumentDeleteRequestWorker
  );
}

function* fetchModuleDocumentDeleteRequestWorker({ payload }) {
  const {
    deleteModuleDocumentRequestData,
    currentPage,
    setIsOpenConfirmModal,
  } = payload;
  const deleteModuleDocumentRequestResponse = yield call(
    deleteModuleDocumentRequest,
    deleteModuleDocumentRequestData
  );
  if (deleteModuleDocumentRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_MODULE_DOCUMENT_ACTION,
      data: deleteModuleDocumentRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchModuleDocumentsListWorker, {
      payload: { orderType: "asc", page: currentPage },
    });
    yield call(fetchModuleSetListActionWorker, { payload: null });
    toast.success("Module Deleted Successfully");
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_MODULE_DOCUMENT_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* UpdateModuleResourceNodeByIdRequestWatcher() {
  yield takeLatest(
    START_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
    UpdateModuleResourceNodeByIdRequestWorker
  );
}

export function* UpdateModuleResourceNodeByIdRequestWorker({ payload }) {
  const UpdateModuleResourceNodeByIdRequestResponse = yield call(
    fetchUpdateModuleResourceNodeByIdRequest,
    payload
  );
  if (UpdateModuleResourceNodeByIdRequestResponse.status) {
    yield call(fetchModuleDocumentByIdRequestWorker, { payload });
    yield call(fetchResourcesListRequestWorker, { payload });
    yield put({
      type: SUCCESS_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
      data: UpdateModuleResourceNodeByIdRequestResponse.data,
    });
    toast.success("Module Updated");
  } else {
    const errorMessage =
      UpdateModuleResourceNodeByIdRequestResponse.errorMessage;
    yield put({
      type: ERROR_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleDocumentCreateRequestWatcher() {
  yield takeLatest(
    START_CREATE_MODULE_DOCUMENT_ACTION,
    fetchModuleDocumentCreateWorker
  );
}

function* fetchModuleDocumentCreateWorker({ payload }) {
  const { createModuleDocumentData, history } = payload;
  const fetchModuleDocumentCreateResponse = yield call(
    fetchCreateModuleDocumentRequest,
    createModuleDocumentData
  );
  if (fetchModuleDocumentCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_MODULE_DOCUMENT_ACTION,
      data: fetchModuleDocumentCreateResponse.data.module_document,
    });
    history.push("/dashboard/learning/index/modules");
    toast.success("Module Created Successfully");
  } else {
    const errorMessage =
      fetchModuleDocumentCreateResponse?.message?.length > 0
        ? fetchModuleDocumentCreateResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_MODULE_DOCUMENT_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleDocumentCloneRequestWatcher() {
  yield takeLatest(
    START_CLONE_MODULE_DOCUMENT_ACTION,
    fetchModuleDocumentCloneWorker
  );
}

function* fetchModuleDocumentCloneWorker({ payload }) {
  const { createCloneModuleDocumentData, setIsOpenCloneModal, setCurrentPage } =
    payload;
  const fetchModuleDocumentCloneResponse = yield call(
    fetchModuleDocumentCloneRequest,
    createCloneModuleDocumentData
  );
  if (fetchModuleDocumentCloneResponse.status) {
    yield put({
      type: SUCCESS_CLONE_MODULE_DOCUMENT_ACTION,
      data: fetchModuleDocumentCloneResponse.data.module_document,
    });
    setIsOpenCloneModal(false);
    yield call(fetchModuleDocumentsListWorker, {
      payload: { orderType: "asc" },
    });
    setCurrentPage(1);
    toast.success("Module Cloned Successfully");
  } else {
    const errorMessage =
      fetchModuleDocumentCloneResponse?.message?.length > 0
        ? fetchModuleDocumentCloneResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CLONE_MODULE_DOCUMENT_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* UpdateModuleDocumentRequestWatcher() {
  yield takeLatest(
    START_UPDATE_MODULE_DOCUMENT_ACTION,
    updateModuleDocumentWorker
  );
}

function* updateModuleDocumentWorker({ payload }) {
  const { updateModuleDocumentData, history } = payload;
  const updateModuleDocumentResponse = yield call(
    updateModuleDocumentRequest,
    updateModuleDocumentData
  );
  if (updateModuleDocumentResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_MODULE_DOCUMENT_ACTION,
      data: updateModuleDocumentResponse.data.module_document,
    });
    history.push("/dashboard/learning/index/modules");
    toast.success("Module Updated Successfully");
  } else {
    const errorMessage =
      updateModuleDocumentResponse?.data?.message?.length > 0
        ? updateModuleDocumentResponse.data.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_MODULE_DOCUMENT_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchDiscussionEntriesWatcher() {
  yield takeLatest(
    START_FETCH_DISCUSSION_ENTRIES_ACTION,
    fetchDiscussionEntriesWorker
  );
}

function* fetchDiscussionEntriesWorker({ payload }) {
  const fetchDiscussionEntriesResponse = yield call(
    fetchDiscussionEntriesRequest,
    payload
  );
  if (fetchDiscussionEntriesResponse.status) {
    yield put({
      type: SUCCESS_FETCH_DISCUSSION_ENTRIES_ACTION,
      data: fetchDiscussionEntriesResponse.data,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_FETCH_DISCUSSION_ENTRIES_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleResourceNodeByIdRequestWatcher() {
  yield takeLatest(
    START_MODULE_RESOURCE_NODE_BY_ID,
    fetchModuleResourceNodeByIdRequestWorker
  );
}

function* fetchModuleResourceNodeByIdRequestWorker({ payload }) {
  const fetchModuleResourceNodeByIdRequestResponse = yield call(
    fetchModuleResourceNodeByIdRequest,
    payload
  );
  if (fetchModuleResourceNodeByIdRequestResponse.status) {
    yield put({
      type: SUCCESS_MODULE_RESOURCE_NODE_BY_ID,
      data: fetchModuleResourceNodeByIdRequestResponse?.data?.resource_nodes,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_RESOURCE_NODE_BY_ID,
      errorMessage,
    });
  }
}

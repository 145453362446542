import React from "react";
import Modal from "react-responsive-modal";
import Icon from "../../components/Button/Icon";
import CloneSetForm from "./ModuleSets/CloneSetForm";
import CloneModuleForm from "./Modules/CloneModuleForm";
import CloneResourceForm from "./Resources/CloneResourceForm";
import "../../styles/responsive-modal.scss";

function CloneModal({
  isOpenCloneModal,
  setIsOpenCloneModal,
  clonedObjectData,
  clonedObjectName,
  setCurrentPage,
}) {
  const customClass = "custom-edit-modal-class";
  const onCloseModal = React.useCallback(() => {
    setIsOpenCloneModal(false);
  }, []);

  const closeIcon = (
    <div className="w-full flex justify-between">
      <Icon label="close" title="" />
    </div>
  );

  const getComponent = (value) => {
    switch (value) {
      case "Module Set":
        return (
          <CloneSetForm
            clonedObjectData={clonedObjectData}
            setIsOpenCloneModal={setIsOpenCloneModal}
          />
        );
      case "Module":
        return (
          <CloneModuleForm
            clonedObjectData={clonedObjectData}
            setIsOpenCloneModal={setIsOpenCloneModal}
            setCurrentPage={setCurrentPage}
          />
        );
      case "Resource":
        return (
          <CloneResourceForm
            clonedObjectData={clonedObjectData}
            setIsOpenCloneModal={setIsOpenCloneModal}
            setCurrentPage={setCurrentPage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      center
      classNames={{
        modal: customClass,
      }}
      open={isOpenCloneModal}
      onClose={onCloseModal}
      closeIcon={closeIcon}
    >
      <div className="flex flex-col">
        <div className="bg-theme-blue">
          <div className="font-lato flex flex-start text-white-87 text-xl font-normal w-1/3">
            <div className="p-4">Clone {clonedObjectName}</div>
          </div>
          <div className="px-6 py-0.5 bg-white">
            {getComponent(clonedObjectName)}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CloneModal;

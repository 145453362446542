import React from "react";
import "../../styles/button.scss";
import iconLoginButton from "../../images/icn_loginbtn.svg";
export default function ButtonWithIcon({ openLoginModalHandler }) {
  return (
    <button
      className="login-button bg-theme-blue font-lato text-sm font-medium"
      onClick={openLoginModalHandler}
    >
      <img src={iconLoginButton} alt="icn_login_btn" className="icn-login" />
      <span>Log In</span>
    </button>
  );
}

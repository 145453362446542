import React, { useState } from "react";
import "../../styles/tabs.scss";
import DynamicTabs from "../../components/Tabs/DynamicTabs";
import SchoolPage from "./Schools/SchoolPage";
import TeacherPage from "./Teachers/TeacherPage";
import GroupsPage from "./Groups/GroupsPage";
import AdministratorsPage from "./Administrators/AdministratorsPage";
import ClassroomPage from "./Classrooms/ClassroomsPage";
import Layout from "../../components/Layout";

export default function AdministrationTabs() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const userData = JSON.parse(localStorage.getItem("user"));

  const data =
    userData?.role === "Teacher"
      ? [{ label: "Classrooms" }, { label: "Groups" }]
      : [
          { label: "Schools" },
          { label: "Teachers" },
          { label: "Classrooms" },
          { label: "Groups" },
          { label: "Administrators" },
        ];
  return (
    <Layout>
      <DynamicTabs
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        tabData={data}
      />
      <div className="">
        {(() => {
          switch (data[activeTabIndex].label) {
            case "Schools":
              return <SchoolPage />;
            case "Teachers":
              return <TeacherPage />;
            case "Classrooms":
              return <ClassroomPage />;
            case "Groups":
              return <GroupsPage />;
            case "Administrators":
              return <AdministratorsPage />;
            default:
              return null;
          }
        })()}
      </div>
    </Layout>
  );
}

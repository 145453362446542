import React, { useMemo, useEffect, useRef, useState } from "react";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";
import AdministrationListTable from "../AdministrationListTable";
import EditFormModal from "../EditFormModal";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import { useDispatch } from "react-redux";
import { START_DELETE_TEACHERS_ACTION } from "../../../rest-client/actions/teachers";
import Pagination from "../../../components/Pagination/pagination";

export default function ListTeachers({
  teacherListData,
  teacherListLoading,
  totalPages,
  currentPage,
  setCurrentPage,
}) {
  const [isOpenRowDropDown, setIsOpenRowDropDown] = useState();
  const [isOpenEditFormModal, setIsOpenEditFormModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [teacherRowData, setTeacherRowData] = useState({});
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const teacherData = useMemo(() => {
    return teacherListData;
  }, [teacherListData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenRowDropDown();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const openEditModalHandler = React.useCallback((value) => {
    setIsOpenEditFormModal(true);
    setTeacherRowData(value);
  }, []);

  const openConfirmModalHandler = React.useCallback((value) => {
    setIsOpenConfirmModal(true);
    setTeacherRowData(value);
  }, []);

  const deleteTeacherHandler = React.useCallback(() => {
    const deleteTeacherRequestData = {
      teacher: {
        ...teacherRowData,
      },
      id: teacherRowData.id,
    };
    dispatch({
      type: START_DELETE_TEACHERS_ACTION,
      payload: { deleteTeacherRequestData, setIsOpenConfirmModal },
    });
  }, [dispatch, teacherRowData]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
      },
      {
        Header: "School",
        accessor: (d) => `${d.school?.name ? d.school?.name : ""}`,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Classroom",
        accessor: (d) =>
          d.classrooms.map((classroom) => classroom.title).join(", "),
      },
      {
        Header: "Options",
        accessor: (d, index) => (
          <>
            <button
              className="border-none bg-transparent"
              onClick={() => {
                setIsOpenRowDropDown(d.id);
              }}
            >
              <Icon
                label="settings"
                title=""
                className={classNames("text-slate-900")}
              />
            </button>
            {isOpenRowDropDown !== null && isOpenRowDropDown === d.id && (
              <div
                className={`absolute z-50 right-0 ${
                  index == 0 ? "top-0" : "bottom-1"
                } w-48 py-2 mt-2 bg-white rounded-md shadow-xl cursor-pointer`}
                ref={dropdownRef}
              >
                <div
                  className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100"
                  onClick={() => {
                    openEditModalHandler(d);
                    setIsOpenRowDropDown();
                  }}
                >
                  <Icon
                    label="edit"
                    title=""
                    className={classNames("text-light-gray mx-1")}
                  />
                  <span className="absolute">Edit Teacher</span>
                </div>
                <div
                  className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100"
                  onClick={() => {
                    openConfirmModalHandler(d);
                    setIsOpenRowDropDown();
                  }}
                >
                  <Icon
                    label="delete"
                    title=""
                    className={classNames("text-light-gray mx-1")}
                  />
                  <span className="absolute">Delete Teacher</span>
                </div>
              </div>
            )}
          </>
        ),
      },
    ],
    [isOpenRowDropDown]
  );

  return (
    <>
      {isOpenConfirmModal && (
        <ConfirmModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          deleteRecordHandler={deleteTeacherHandler}
        />
      )}
      {isOpenEditFormModal && (
        <EditFormModal
          isOpenEditFormModal={isOpenEditFormModal}
          setIsOpenEditFormModal={setIsOpenEditFormModal}
          editableObjectData={teacherRowData}
          editableObjectName="Teacher"
          currentPage={currentPage}
        />
      )}
      <AdministrationListTable
        columns={columns}
        dataListLoading={teacherListLoading}
        listData={teacherData}
        listFor={"Teachers"}
      />
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
}

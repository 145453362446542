import React from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { START_CLONE_MODULE_SET_ACTION } from "../../../rest-client/actions/module-sets";
import DefaultButton from "../../../components/Button/DefaultButton";
import OutlinedButton from "../../../components/Button/OutlinedButton";

export default function CloneSetForm({
  clonedObjectData,
  setIsOpenCloneModal,
}) {
  const moduleSetData = clonedObjectData;
  const dispatch = useDispatch();
  const history = useHistory();

  const initialFormValues = {
    title: "",
    nickname: "",
    module_document: {
      concat_type: "suffix",
      concat_text: "",
    },
    resource_options: "copy_resources",
    resource: {
      concat_type: "suffix",
      concat_text: "",
    },
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );
  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter Title";
    }
    if (!values.nickname) {
      error.nickname = "Please enter Nickname";
    }
    if (!values.module_document.concat_text) {
      error.module_document = {
        ...error.module_document,
        concat_text: "Please Enter Text",
      };
    }
    if (
      !values.resource.concat_text &&
      values.resource_options === "clone_resources"
    ) {
      error.resource = {
        ...error.resource,
        concat_text: "Please Enter Text",
      };
    }
    return error;
  }, []);

  const createCloneModuleSetHandler = React.useCallback(
    (values) => {
      if (values) {
        const createCloneModuleSetData = {
          module_set: {
            title: values.title.trimStart().trimEnd(),
            nickname: values.nickname.trimStart().trimEnd(),
            module_document: {
              concat_type: values.module_document.concat_type,
              concat_text: values.module_document.concat_text
                .trimStart()
                .trimEnd(),
            },
            id: moduleSetData.id,
            ...(values.resource_options === "clone_resources" && {
              resource: {
                concat_type: values.resource.concat_type,
                concat_text: values.resource.concat_text.trimStart().trimEnd(),
              },
            }),
          },
        };
        dispatch({
          type: START_CLONE_MODULE_SET_ACTION,
          payload: { createCloneModuleSetData, setIsOpenCloneModal, history },
        });
      }
    },
    [dispatch]
  );

  return (
    <div className="py-10 px-2">
      <p>Original Module Set Title : {moduleSetData.title}</p>
      <p>Original module set nickname: {moduleSetData.nickname}</p>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={createCloneModuleSetHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato mt-10">
            <div className="field-holder">
              <Field
                type="text"
                id="title"
                className={classNames("input-class", {
                  "error-input-class": errors.title && touched.title,
                })}
                value={values.title}
                onChange={(e) => {
                  setFieldDataHandler(
                    setFieldValue,
                    "title",
                    e.target.value.trimStart()
                  );
                }}
                maxLength={50}
                required
              />
              <label
                htmlFor="title"
                className={classNames("label-class", {
                  "error-label-class": errors.title && touched.title,
                })}
              >
                Name cloned module set title *
              </label>
              {errors.title && touched.title && (
                <p className="error-label-class mt-25">{errors.title ?? "-"}</p>
              )}
            </div>
            <div className="field-holder">
              <Field
                type="text"
                id="nickname"
                className={classNames("input-class", {
                  "error-input-class": errors.nickname && touched.nickname,
                })}
                value={values.nickname}
                onChange={(e) => {
                  setFieldDataHandler(
                    setFieldValue,
                    "nickname",
                    e.target.value.trimStart()
                  );
                }}
                maxLength={50}
                required
              />
              <label
                htmlFor="nickname"
                className={classNames("label-class", {
                  "error-label-class": errors.nickname && touched.nickname,
                })}
              >
                Name cloned module set nickname *
              </label>
              {errors.nickname && touched.nickname && (
                <p className="error-label-class mt-25">
                  {errors.nickname ?? "-"}
                </p>
              )}
            </div>
            <p>
              Choose suffix or prefix to add to all module titles in the module
              set:
            </p>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                value="suffix"
                id="concat_type_suffix"
                checked={values.module_document.concat_type === "suffix"}
                onChange={() =>
                  setFieldValue(
                    `module_document.concat_type`,
                    (values.module_document.concat_type = "suffix")
                  )
                }
                className="w-8 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="concat_type_suffix" className="ml-1">
                Suffix
              </label>
            </div>
            <div className="flex items-center">
              <Field
                type="radio"
                value="prefix"
                id="concat_type_prefix"
                checked={values.module_document.concat_type === "prefix"}
                onChange={() =>
                  setFieldValue(
                    `module_document.concat_type`,
                    (values.module_document.concat_type = "prefix")
                  )
                }
                className="w-8 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="concat_type_prefix" className="ml-1">
                Prefix
              </label>
            </div>
            <div className="mt-10">
              <div className="field-holder">
                <Field
                  type="text"
                  id="concat_text"
                  className={classNames("input-class", {
                    "error-input-class": errors.module_document,
                  })}
                  value={values.module_document.concat_text}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      `module_document.concat_text`,
                      e.target.value.trimStart()
                    );
                  }}
                  maxLength={20}
                  required
                />
                <label
                  htmlFor="concat_text"
                  className={classNames("label-class", {
                    "error-label-class": errors.module_document,
                  })}
                >
                  Enter Text *
                </label>
                {errors.module_document?.concat_text && (
                  <p className="error-label-class mt-25">
                    {errors.module_document?.concat_text ?? "-"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                id="copy_resources"
                checked={values.resource_options === "copy_resources"}
                onChange={() =>
                  setFieldValue(`resource_options`, "copy_resources")
                }
                className="w-8 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="copy_resources" className="ml-1">
                Copy resources (use same set of resources)
              </label>
            </div>
            <div className="flex items-center">
              <Field
                type="radio"
                id="clone_resources"
                checked={values.resource_options === "clone_resources"}
                onChange={() =>
                  setFieldValue(`resource_options`, "clone_resources")
                }
                className="w-8 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="clone_resources" className="ml-1">
                Clone resources (new set of resources)
              </label>
            </div>
            {values.resource_options === "clone_resources" ? (
              <>
                <p className="mt-10">
                  Choose suffix or prefix to add to all resource titles in the
                  module:
                </p>
                <div className="flex items-center mb-4">
                  <Field
                    type="radio"
                    value="suffix"
                    id="resource_concat_type_suffix"
                    checked={values.resource.concat_type === "suffix"}
                    onChange={() =>
                      setFieldValue(
                        `resource.concat_type`,
                        (values.resource.concat_type = "suffix")
                      )
                    }
                    className="w-8 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="resource_concat_type_suffix" className="ml-1">
                    Suffix
                  </label>
                </div>
                <div className="flex items-center">
                  <Field
                    type="radio"
                    value="prefix"
                    id="resource_concat_type_prefix"
                    checked={values.resource.concat_type === "prefix"}
                    onChange={() =>
                      setFieldValue(
                        `resource.concat_type`,
                        (values.resource.concat_type = "prefix")
                      )
                    }
                    className="w-8 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="resource_concat_type_prefix" className="ml-1">
                    Prefix
                  </label>
                </div>
                <div className="mt-10">
                  <div className="field-holder">
                    <Field
                      type="text"
                      id="resource_concat_text"
                      className={classNames("input-class", {
                        "error-input-class": errors.resource,
                      })}
                      value={values.resource.concat_text}
                      onChange={(e) => {
                        setFieldDataHandler(
                          setFieldValue,
                          `resource.concat_text`,
                          e.target.value.trimStart()
                        );
                      }}
                      maxLength={20}
                      required
                    />
                    <label
                      htmlFor="resource_concat_text"
                      className={classNames("label-class", {
                        "error-label-class": errors.resource,
                      })}
                    >
                      Enter Text *
                    </label>
                    {errors.resource?.concat_text && (
                      <p className="error-label-class mt-25">
                        {errors.resource?.concat_text ?? "-"}
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : null}
            <div className="flex justify-end mt-10">
              <OutlinedButton
                type="button"
                label="Cancel"
                className="mx-4"
                onClickHandler={() => setIsOpenCloneModal(false)}
              />
              <DefaultButton label="Submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

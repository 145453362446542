export const START_SCHOOLS_LIST_ACTION = `START_SCHOOLS_LIST_ACTION`;
export const ERROR_SCHOOLS_LIST_ACTION = `ERROR_SCHOOLS_LIST_ACTION`;
export const SUCCESS_SCHOOLS_LIST_ACTION = `SUCCESS_SCHOOLS_LIST_ACTION`;

export const START_CREATE_SCHOOL_ACTION = "START_CREATE_SCHOOL_ACTION";
export const SUCCESS_CREATE_SCHOOL_ACTION = "SUCCESS_CREATE_SCHOOL_ACTION";
export const FAILURE_CREATE_SCHOOL_ACTION = "FAILURE_CREATE_SCHOOL_ACTION";

export const START_UPDATE_SCHOOLS_ACTION = "START_UPDATE_SCHOOLS";
export const SUCCESS_UPDATE_SCHOOLS_ACTION = "SUCCESS_UPDATE_SCHOOLS";
export const FAILURE_UPDATE_SCHOOLS_ACTION = "FAILURE_UPDATE_SCHOOLS";

export const START_DELETE_SCHOOLS_ACTION = "START_DELETE_SCHOOLS_ACTION";
export const SUCCESS_DELETE_SCHOOLS_ACTION = "SUCCESS_DELETE_SCHOOLS_ACTION";
export const FAILURE_DELETE_SCHOOLS_ACTION = "FAILURE_DELETE_SCHOOLS_ACTION";

export const START_SCHOOLS_NON_PAGINATED_LIST_ACTION = `START_SCHOOLS_NON_PAGINATED_LIST_ACTION`;
export const ERROR_SCHOOLS_NON_PAGINATED_LIST_ACTION = `ERROR_SCHOOLS_NON_PAGINATED_LIST_ACTION`;
export const SUCCESS_SCHOOLS_NON_PAGINATED_LIST_ACTION = `SUCCESS_SCHOOLS_NON_PAGINATED_LIST_ACTION`;

import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useHistory, useRouteMatch } from "react-router-dom";
import SingleSelect from "../../components/Dropdowns/SingleSelect";
import Layout from "../../components/Layout";
import { useGroupCourseModule } from "../../rest-client/selectorHook";
import {
  GROUP_COURSE_MODULE_LIST_REQUESTED,
  GROUP_COURSE_MODULE_REQUESTED,
} from "../../rest-client/actions/group-course-module";
import "../../styles/groupDashboradPage.scss";
import scienceIcon from "../../images/science_filled.svg";
import completedIcon from "../../images/completed-text.svg";
import Icon from "../../components/Button/Icon";
import { LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION } from "../../rest-client/actions/resources";

const GroupDashboard = () => {
  const [dropdownData, setDropdownData] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const path =
    userData?.role === "Teacher" || userData?.role === "Learner"
      ? "/dashboard"
      : "/dashboard/learning";
  const { url } = useRouteMatch(path);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    group_course_module_data: {
      isLoading: courseModuleLoading,
      data: courseModuleData,
    },
    group_course_module_list_data: { data: courseModuleListData },
  } = useGroupCourseModule();

  useEffect(() => {
    dispatch({
      type: GROUP_COURSE_MODULE_REQUESTED,
    });
  }, []);

  useEffect(() => {
    if (dropdownData) {
      dispatch({
        type: GROUP_COURSE_MODULE_LIST_REQUESTED,
        payload: dropdownData?.value,
      });
    } else if (courseModuleData?.module_sets) {
      dispatch({
        type: GROUP_COURSE_MODULE_LIST_REQUESTED,
        payload: courseModuleData?.module_sets[0]?.id,
      });
    }
  }, [dropdownData, courseModuleData]);

  const courseModuleOption =
    courseModuleData &&
    courseModuleData?.module_sets?.map((courseModule) => {
      return {
        label: courseModule?.title,
        value: courseModule?.id,
      };
    });

  const initialValues = {
    selectValue: courseModuleData?.module_sets
      ? {
          label: courseModuleData?.module_sets[0]?.title,
          value: courseModuleData?.module_sets[0]?.id,
        }
      : "",
  };

  return (
    <Layout>
      {courseModuleLoading ? (
        <div className="flex flex-col justify-center items-center h-32">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col justify-center">
          <div className="m-2.5 p-2.5 flex flex-row justify-center items-center group-box">
            {/* <h2 className="lable-text">Choose Course</h2> */}
            <div className="flex-1 box-border max-h-full max-width-35-percent"></div>
            <Formik enableReinitialize initialValues={initialValues}>
              {({ values, setFieldValue }) => (
                <Form className="max-h-full max-width-30-percent">
                  <div className="">
                    <Field name="selectValue">
                      {({ field, form, meta }) => {
                        return (
                          <SingleSelect
                            field={field}
                            form={form}
                            meta={meta}
                            options={courseModuleOption}
                            placeholder="Choose Course"
                            onChange={(value) => {
                              setDropdownData(value);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="flex-1 box-border max-h-full max-width-35-percent"></div>
          </div>
          {courseModuleListData?.module_documents && (
            <div className="m-2.5 p-2.5 flex flex-col justify-center my-0 mx-auto group-box">
              <h2 className="text-gray-600 text-2xl font-medium ml-2.5">
                Launch Module
              </h2>
              <div className="flex flex-col flex-wrap justify-center items-center my-5 module-container">
                {courseModuleListData &&
                  courseModuleListData?.module_documents?.map((data) => (
                    <div
                      className="flex shadow-md mx-2.5 my-1.5 focus:outline-none box cursor-pointer"
                      key={data?.id}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`${url}/index/module/${data?.id}`);
                        dispatch({
                          type: LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION,
                        });
                      }}
                    >
                      <div
                        className={
                          data?.module_session?.state === "completed"
                            ? "image-box flex justify-center items-center bg-theme-blue opacity-70 image-box"
                            : "image-box flex justify-center items-center bg-theme-blue image-box"
                        }
                      >
                        <img src={scienceIcon} alt="science-filled-icon" />
                      </div>
                      <div className="flex justify-between items-center py-1.5 text-box">
                        <p
                          className={
                            data?.module_session?.state === "completed"
                              ? "opacity-70 text-gray-600 text-base leading-normal pl-5 capitalize"
                              : "text-gray-600 text-base leading-normal pl-5 capitalize"
                          }
                        >
                          {data?.title}
                        </p>
                        <div className="flex items-center mr-2.5">
                          {data?.module_session?.state === "completed" ? (
                            <>
                              <img
                                src={completedIcon}
                                alt="science-filled-icon"
                                className="mr-1.5 w-20"
                              />
                              <div className="cursor-pointer">
                                <Icon
                                  label="visibility"
                                  title=""
                                  className={classNames("mx-1")}
                                />
                              </div>
                            </>
                          ) : (
                            <div className="cursor-pointer">
                              <Icon
                                label="visibility"
                                title=""
                                className={classNames("mx-1")}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default GroupDashboard;

import {
  FAILURE_ADD_CANVAS_IMAGE_UPLOAD,
  FAILURE_DISCUSS_ENTRIES_ACTION,
  FAILURE_POLL_QUESTIONS_ACTION,
  START_ADD_CANVAS_IMAGE_UPLOAD,
  START_DISCUSS_ENTRIES_ACTION,
  START_POLL_QUESTIONS_ACTION,
  SUBMIT_CANVAS_RESOURCE_NODE_FAILURE,
  SUBMIT_CANVAS_RESOURCE_NODE_REQUESTED,
  SUBMIT_CANVAS_RESOURCE_NODE_SUCCESS,
  SUBMIT_PAGE_RESOURCE_NODE_FAILURE,
  SUBMIT_PAGE_RESOURCE_NODE_REQUESTED,
  SUBMIT_PAGE_RESOURCE_NODE_SUCCESS,
  SUBMIT_POLL_RESOURCE_NODE_FAILURE,
  SUBMIT_POLL_RESOURCE_NODE_REQUESTED,
  SUBMIT_POLL_RESOURCE_NODE_SUCCESS,
  SUCCESS_ADD_CANVAS_IMAGE_UPLOAD,
  SUCCESS_DISCUSS_ENTRIES_ACTION,
  SUCCESS_POLL_QUESTIONS_ACTION,
  VIEW_RESOURCE_NODE_FAILURE,
  VIEW_RESOURCE_NODE_REQUESTED,
  VIEW_RESOURCE_NODE_SUCCESS,
} from "../actions/view-resource-node";

const initState = {
  view_resource_node_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  submit_page_resource_node_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  submit_canvas_resource_node_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  submit_poll_resource_node_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  upload_canvas_image_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  poll_questions_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  discussion_entries_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
};

export const viewResourceNodeReducer = (prevState = initState, action) => {
  switch (action.type) {
    //for viewing resource node
    case VIEW_RESOURCE_NODE_REQUESTED:
      return {
        ...prevState,
        view_resource_node_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case VIEW_RESOURCE_NODE_SUCCESS:
      return {
        ...prevState,
        view_resource_node_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case VIEW_RESOURCE_NODE_FAILURE:
      return {
        ...prevState,
        view_resource_node_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };
    //for page resource
    case SUBMIT_PAGE_RESOURCE_NODE_REQUESTED:
      return {
        ...prevState,
        submit_page_resource_node_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case SUBMIT_PAGE_RESOURCE_NODE_SUCCESS:
      return {
        ...prevState,
        submit_page_resource_node_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case SUBMIT_PAGE_RESOURCE_NODE_FAILURE:
      return {
        ...prevState,
        submit_page_resource_node_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };
    //for canvas resource
    case SUBMIT_CANVAS_RESOURCE_NODE_REQUESTED:
      return {
        ...prevState,
        submit_canvas_resource_node_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case SUBMIT_CANVAS_RESOURCE_NODE_SUCCESS:
      return {
        ...prevState,
        submit_canvas_resource_node_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case SUBMIT_CANVAS_RESOURCE_NODE_FAILURE:
      return {
        ...prevState,
        submit_canvas_resource_node_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };
    //for poll resource
    case SUBMIT_POLL_RESOURCE_NODE_REQUESTED:
      return {
        ...prevState,
        submit_poll_resource_node_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case SUBMIT_POLL_RESOURCE_NODE_SUCCESS:
      return {
        ...prevState,
        submit_poll_resource_node_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case SUBMIT_POLL_RESOURCE_NODE_FAILURE:
      return {
        ...prevState,
        submit_poll_resource_node_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };
    //for canvas image upload
    case START_ADD_CANVAS_IMAGE_UPLOAD:
      return {
        ...prevState,
        upload_canvas_image_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case SUCCESS_ADD_CANVAS_IMAGE_UPLOAD:
      return {
        ...prevState,
        upload_canvas_image_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case FAILURE_ADD_CANVAS_IMAGE_UPLOAD:
      return {
        ...prevState,
        upload_canvas_image_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };

    //for poll questions
    case START_POLL_QUESTIONS_ACTION:
      return {
        ...prevState,
        poll_questions_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case SUCCESS_POLL_QUESTIONS_ACTION:
      return {
        ...prevState,
        poll_questions_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case FAILURE_POLL_QUESTIONS_ACTION:
      return {
        ...prevState,
        poll_questions_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };

    //for discussion entries
    case START_DISCUSS_ENTRIES_ACTION:
      return {
        ...prevState,
        discussion_entries_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case SUCCESS_DISCUSS_ENTRIES_ACTION:
      return {
        ...prevState,
        discussion_entries_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case FAILURE_DISCUSS_ENTRIES_ACTION:
      return {
        ...prevState,
        discussion_entries_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };

    default:
      return prevState;
  }
};

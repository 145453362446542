import React, { useMemo, useEffect, useRef, useState } from "react";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";
import AdministrationListTable from "../AdministrationListTable";
import EditFormModal from "../EditFormModal";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import { useDispatch } from "react-redux";
import { START_DELETE_SCHOOLS_ACTION } from "../../../rest-client/actions/schools";
import Pagination from "../../../components/Pagination/pagination";

export default function ListSchools({
  schoolListData,
  schoolListLoading,
  totalPages,
  currentPage,
  setCurrentPage,
}) {
  const [isOpenRowDropDown, setIsOpenRowDropDown] = useState();
  const [isOpenEditFormModal, setIsOpenEditFormModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [schoolRowData, setSchoolRowData] = useState({});
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const schoolData = useMemo(() => {
    return schoolListData;
  }, [schoolListData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenRowDropDown();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const openEditModalHandler = React.useCallback((value) => {
    setIsOpenEditFormModal(true);
    setSchoolRowData(value);
  }, []);

  const openConfirmModalHandler = React.useCallback((value) => {
    setIsOpenConfirmModal(true);
    setSchoolRowData(value);
  }, []);

  const deleteSchoolHandler = React.useCallback(() => {
    const deleteSchoolRequestData = {
      school: {
        ...schoolRowData,
      },
      id: schoolRowData.id,
    };
    dispatch({
      type: START_DELETE_SCHOOLS_ACTION,
      payload: { deleteSchoolRequestData, setIsOpenConfirmModal },
    });
  }, [dispatch, schoolRowData]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: (d) =>
          `${d.address.street1}, ${d.address.city}, ${d.address.state}, ${d.address.zip}, ${d.address.country}`,
      },
      {
        Header: "Teachers",
        accessor: (d) =>
          d.teachers
            .map((teacher) => `${teacher.first_name} ${teacher.last_name}`)
            .join(", "),
      },
      {
        Header: "Options",
        accessor: (d, index) => (
          <>
            <button
              className="border-none bg-transparent"
              onClick={() => {
                setIsOpenRowDropDown(d.id);
              }}
            >
              <Icon
                label="settings"
                title=""
                className={classNames("text-slate-900")}
              />
            </button>
            {isOpenRowDropDown !== null && isOpenRowDropDown === d.id && (
              <div
                className={`absolute z-50 right-0 ${
                  index == 0 ? "top-0" : "bottom-1"
                } w-48 py-2 mt-2 bg-white rounded-md shadow-xl cursor-pointer`}
                ref={dropdownRef}
              >
                <div
                  className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100"
                  onClick={() => openEditModalHandler(d)}
                >
                  <Icon
                    label="edit"
                    title=""
                    className={classNames("text-light-gray mx-1")}
                  />
                  <span className="absolute">Edit School</span>
                </div>
                <div
                  className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100"
                  onClick={() => openConfirmModalHandler(d)}
                >
                  <Icon
                    label="delete"
                    title=""
                    className={classNames("text-light-gray mx-1")}
                  />
                  <span className="absolute">Delete School</span>
                </div>
              </div>
            )}
          </>
        ),
      },
    ],
    [isOpenRowDropDown]
  );
  return (
    <>
      {isOpenConfirmModal && (
        <ConfirmModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          deleteRecordHandler={deleteSchoolHandler}
        />
      )}
      {isOpenEditFormModal && (
        <EditFormModal
          isOpenEditFormModal={isOpenEditFormModal}
          setIsOpenEditFormModal={setIsOpenEditFormModal}
          editableObjectData={schoolRowData}
          editableObjectName="School"
          currentPage={currentPage}
        />
      )}
      <AdministrationListTable
        columns={columns}
        dataListLoading={schoolListLoading}
        listData={schoolData}
        listFor={"Schools"}
      />
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
}

export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const START_RECOVERY_PASSWORD = "START_RECOVERY_PASSWORD";
export const SUCCESS_RECOVERY_PASSWORD = "SUCCESS_RECOVERY_PASSWORD";
export const FAILURE_RECOVERY_PASSWORD = "FAILURE_RECOVERY_PASSWORD";

export const START_UPDATE_USER_PROFILE = "START_UPDATE_USER_PROFILE";
export const SUCCESS_UPDATE_USER_PROFILE = "SUCCESS_UPDATE_USER_PROFILE";
export const FAILURE_UPDATE_USER_PROFILE = "FAILURE_UPDATE_USER_PROFILE";

export const LOCALLY_UPDATE_RECOVERY_PASSWORD_ERROR_ACTION =
  "LOCALLY_UPDATE_RECOVERY_PASSWORD_ERROR_ACTION";
export const LOCALLY_UPDATE_DELETED_USER_ERROR_ACTION =
  "LOCALLY_UPDATE_DELETED_USER_ERROR_ACTION";

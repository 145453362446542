import React from "react";
import { Formik, Form, Field } from "formik";
import parse from "html-react-parser";
import classNames from "classnames";

const TextArea = ({ discussions, setFormTextData }) => {
  const initialFormValues = discussions.reduce((newObj, curr) => {
    newObj[`text_area_${curr.id}`] = "";
    return newObj;
  }, {});

  const validationHandler = React.useCallback((values) => {
    const error = {};
    discussions.map((e) => {
      if (!values[`text_area_${e.id}`]) {
        error[`text_area_${e.id}`] = "Please enter your answer";
      }
    });
    return error;
  }, []);

  const handleBlur = (e, discussionId, values) => {
    const updatedValue = e.target.value;
    const updatedData = discussions.map((discussion) => {
      if (discussion.id === discussionId) {
        return {
          id: discussion.id,
          content: updatedValue,
          discussionType: discussion.discussion_type,
        };
      } else {
        return {
          id: discussion.id,
          content: values[`text_area_${discussion.id}`],
          discussionType: discussion.discussion_type,
        };
      }
    });

    setFormTextData(updatedData);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={() => {}}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form className="font-lato">
            {discussions.map((discussion) => (
              <>
                {discussion.title && (
                  <>
                    <p>{parse(discussion.title)} </p>
                    <div className="relative p-0.5 mt-6 mx-0 align-middle mb-2">
                      <Field
                        id={`text_area_${discussion.id}`}
                        type="text"
                        as="textarea"
                        className={classNames(
                          "font-lato w-full text-area-input border-0 outline-none mt-0 pl-1 pt-0.5 pb-px pr-0.5 h-28 rounded-none border-solid mb-8 bg-theme-gray",
                          {
                            "error-input-class":
                              errors[`text_area_${discussion.id}`] &&
                              touched[`text_area_${discussion.id}`],
                          }
                        )}
                        required
                        value={values[`text_area_${discussion.id}`]}
                        onChange={(e) => {
                          setFieldValue(
                            `text_area_${discussion.id}`,
                            e.target.value.trimStart()
                          );
                        }}
                        onBlur={(e) => handleBlur(e, discussion.id, values)}
                      />
                      <label
                        className={classNames(
                          "text-area-label flex items-center cursor-text absolute top-2 left-2 font-lato",
                          {
                            "error-label-class":
                              errors[`text_area_${discussion.id}`] &&
                              touched[`text_area_${discussion.id}`],
                          }
                        )}
                        htmlFor={`text_area_${discussion.id}`}
                      >
                        Discussion Answer*
                      </label>
                    </div>
                  </>
                )}
              </>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TextArea;

import api from "../api";

export function fetchModuleSessionsListAction(payload) {
  return api.get(`/classrooms/${payload?.classroom_id}/module_sessions`);
}

export function fetchModuleSessionsByClassroomByModuleSetRequest(payload) {
  return api.get(
    `/classrooms/${payload?.classroom_id}/module_sessions?module_set_id=${payload?.module_set_id}`
  );
}

export function fetchModuleSessionsByIdWithClassroomRequest(payload) {
  return api.get(
    `/classrooms/${payload?.classroom_id}/module_sessions/${payload?.module_session_id}/results`
  );
}
export function fetchModuleSessionCompleteRequest(payload) {
  return api.post(
    `/classrooms/${payload?.module_session?.id}/module_sessions/${payload?.module_session?.session_id}/complete`,
    {
      paramObj: payload,
    }
  );
}

export function deleteModuleSessionRequest(payload) {
  return api.delet(
    `/classrooms/${payload?.module_session?.id}/module_sessions/${payload?.module_session?.session_id}`
  );
}

export function fetchCreateModuleSessionRequest(payload) {
  return api.post(
    `/classrooms/${payload?.module_session?.id}/module_sessions`,
    {
      paramObj: payload,
    }
  );
}

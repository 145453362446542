import React from "react";
import { Formik, Form, Field } from "formik";
import "../../styles/landingPage.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { LOGIN_REQUESTED } from "../../rest-client/actions/login";
import { useLogin } from "../../rest-client/selectorHook";
import { useHistory } from "react-router-dom";

export default function DefaultLoginForm({ setIsOpenLoginModal }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    user_login_data: { isError: userLoginError },
  } = useLogin();

  const initialFormValues = {
    email_username: "",
    password: "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );
  const loginRequestHandler = React.useCallback(
    (values) => {
      if (values) {
        const loginRequestData = {
          email: values.email_username.trimStart().trimEnd(),
          password: values.password,
        };
        dispatch({
          type: LOGIN_REQUESTED,
          payload: { loginRequestData, setIsOpenLoginModal, history },
        });
      }
    },
    [dispatch]
  );
  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.email_username) {
      error.email_username = "Please enter your email or username";
    }
    if (!values.password) {
      error.password = "Please enter your password";
    }
    return error;
  }, []);
  return (
    <>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={loginRequestHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato">
            <>
              <div className="mt-10 flex flex-column box-border w-full">
                <div className="w-2/5 mx-2.5 relative align-middle p-0.5 my-4">
                  <Field
                    id="email_username"
                    type="text"
                    name="email_username"
                    className={classNames(
                      "w-full input-class-login border-0 outline-none mt-0 pl-0 pt-0.5 pb-px pr-0.5 h-[26px] rounded-none border-solid",
                      {
                        "error-input-class":
                          errors.email_username && touched.email_username,
                      }
                    )}
                    required
                    value={values.email_username}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "email_username",
                        e.target.value.trimStart()
                      );
                    }}
                  />
                  {errors.email_username && touched.email_username && (
                    <p className="error-label-class mt-25">
                      {errors.email_username ?? "-"}
                    </p>
                  )}
                  <label
                    className={classNames(
                      "label-class-login flex items-center cursor-text absolute top-0 left-0.5 font-lato mt-1",
                      {
                        "error-label-class":
                          errors.email_username && touched.email_username,
                      }
                    )}
                    htmlFor="email_username"
                  >
                    Email / Username
                  </label>
                </div>
                <div className="w-2/5 mx-2.5 relative align-middle p-0.5 my-4">
                  <Field
                    type="password"
                    name="password"
                    ng-required="!login.forgot"
                    className={classNames(
                      "w-full input-class-login border-0 outline-none mt-0 pl-0 pt-0.5 pb-px pr-0.5 h-[26px] rounded-none border-solid",
                      {
                        "error-input-class":
                          errors.password && touched.password,
                      }
                    )}
                    id="password"
                    required
                    value={values.password}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "password",
                        e.target.value.trimStart()
                      );
                    }}
                  />
                  {errors.password && touched.password && (
                    <p className="error-label-class mt-25">
                      {errors.password ?? "-"}
                    </p>
                  )}
                  <label
                    className={classNames(
                      "label-class-login cursor-text flex items-center absolute top-0 left-0.5 font-lato mt-1",
                      {
                        "error-label-class":
                          errors.password && touched.password,
                      }
                    )}
                    htmlFor="password"
                  >
                    Password
                  </label>
                </div>
                <div className="flex box-border items-center mb-3">
                  <button
                    className="login-button-without-icon text-white-87 bg-theme-blue box-shadow-btn cursor-pointer align-middle items-center text-center box-border select-none outline-none border-0 py-0 px-2 font-medium font-lato text-sm"
                    type="submit"
                  >
                    <span className="ng-binding ng-scope">Login</span>
                  </button>
                </div>
              </div>
              {userLoginError ? (
                <div className="ml-3 error-label-class">{userLoginError}</div>
              ) : null}
            </>
          </Form>
        )}
      </Formik>
    </>
  );
}

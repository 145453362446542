import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Icon from "../../../components/Button/Icon";
import "../../../styles/landingPage.scss";
import { START_MODULE_SET_LIST_ACTION } from "../../../rest-client/actions/module-sets";
import { useDispatch } from "react-redux";
import { useModuleSet } from "../../../rest-client/selectorHook";
import { Formik, Form, Field } from "formik";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import OutlinedButton from "../../../components/Button/OutlinedButton";
import DefaultButton from "../../../components/Button/DefaultButton";
import "../../../styles/responsive-modal.scss";

export default function FilterModulesModal({
  isOpenFilterModal,
  setIsOpenFilterModal,
  setModuleFilterInput,
  setCurrentPage,
  moduleSetFilterInputValue,
  setmoduleSetFilterInputValue
}) {
  const initialFormVariables = {
    module_set: moduleSetFilterInputValue ? moduleSetFilterInputValue : "",
  };
  const customClass = "custom-filter-modal-class";
  const closeIcon = (
    <div className="w-full flex justify-between">
      <Icon label="close" title="" />
    </div>
  );

  const onCloseModal = React.useCallback(() => {
    setIsOpenFilterModal(false);
  }, []);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({
      type: START_MODULE_SET_LIST_ACTION,
      payload: null,
    });
  }, []);

  const {
    module_sets_list_data: { data: moduleSetListData },
  } = useModuleSet();

  const moduleSetOptions = moduleSetListData.map((module_set) => {
    return {
      label: module_set.title,
      value: module_set.title,
      id: module_set.id,
    };
  });

  return (
    <Modal
      center
      classNames={{
        modal: customClass,
      }}
      open={isOpenFilterModal}
      onClose={onCloseModal}
      closeIcon={closeIcon}
      focusTrapped={false}
    >
      <div className="flex flex-col">
        <div className="bg-theme-blue">
          <div className="font-lato flex flex-start text-white-87 text-xl font-normal">
            <div className="p-4">Filter Modules</div>
          </div>
          <div className="px-4 py-2 bg-white">
            <Formik enableReinitialize initialValues={initialFormVariables}>
              {({ values }) => (
                <Form className="">
                  <div className="flex-1 w-70 mx-10 py-1 mt-10 h-32 edit-dropdown-modal">
                    <Field name="module_set">
                      {({ field, form, meta }) => (
                        <SingleSelect
                          field={field}
                          form={form}
                          meta={meta}
                          options={moduleSetOptions}
                          placeholder="Choose Course"
                          onChange={(value) => {
                            setmoduleSetFilterInputValue(value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="flex justify-end mt-18 p-4">
                    <OutlinedButton
                      label="Reset"
                      className="mx-4"
                      disabled={!values.module_set.value}
                      onClickHandler={() => {
                        setmoduleSetFilterInputValue("");
                        setModuleFilterInput("");
                        onCloseModal();
                      }}
                      type="reset"
                    />
                    <DefaultButton
                      label="Apply"
                      disabled={!values.module_set.value}
                      onClickHandler={() => {
                        setModuleFilterInput(moduleSetFilterInputValue.id);
                        setCurrentPage(1);
                        onCloseModal();
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}

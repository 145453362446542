export const START_CLASSROOMS_LIST_ACTION = `START_CLASSROOMS_LIST_ACTION`;
export const ERROR_CLASSROOMS_LIST_ACTION = `ERROR_CLASSROOMS_LIST_ACTION`;
export const SUCCESS_CLASSROOMS_LIST_ACTION = `SUCCESS_CLASSROOMS_LIST_ACTION`;

export const START_CREATE_CLASSROOM_ACTION = "START_CREATE_CLASSROOM_ACTION";
export const SUCCESS_CREATE_CLASSROOM_ACTION =
  "SUCCESS_CREATE_CLASSROOM_ACTION";
export const FAILURE_CREATE_CLASSROOM_ACTION =
  "FAILURE_CREATE_CLASSROOM_ACTION";

export const START_UPDATE_CLASSROOMS_ACTION = "START_UPDATE_CLASSROOMS";
export const SUCCESS_UPDATE_CLASSROOMS_ACTION = "SUCCESS_UPDATE_CLASSROOMS";
export const FAILURE_UPDATE_CLASSROOMS_ACTION = "FAILURE_UPDATE_CLASSROOMS";

export const START_DELETE_CLASSROOMS_ACTION = "START_DELETE_CLASSROOMS_ACTION";
export const SUCCESS_DELETE_CLASSROOMS_ACTION =
  "SUCCESS_DELETE_CLASSROOMS_ACTION";
export const FAILURE_DELETE_CLASSROOMS_ACTION =
  "FAILURE_DELETE_CLASSROOMS_ACTION";

export const START_EXPORT_CLASSROOM_ACTION = "START_EXPORT_CLASSROOM_ACTION";
export const SUCCESS_EXPORT_CLASSROOM_ACTION =
  "SUCCESS_EXPORT_CLASSROOM_ACTION";
export const FAILURE_EXPORT_CLASSROOM_ACTION =
  "FAILURE_EXPORT_CLASSROOM_ACTION";

export const START_SHOW_CLASSROOM_ACTION = "START_SHOW_CLASSROOM_ACTION";
export const SUCCESS_SHOW_CLASSROOM_ACTION = "SUCCESS_SHOW_CLASSROOM_ACTION";
export const FAILURE_SHOW_CLASSROOM_ACTION = "FAILURE_SHOW_CLASSROOM_ACTION";

export const START_CLASSROOMS_NON_PAGINATED_LIST_ACTION = `START_CLASSROOMS_NON_PAGINATED_LIST_ACTION`;
export const ERROR_CLASSROOMS_NON_PAGINATED_LIST_ACTION = `ERROR_CLASSROOMS_NON_PAGINATED_LIST_ACTION`;
export const SUCCESS_CLASSROOMS_NON_PAGINATED_LIST_ACTION = `SUCCESS_CLASSROOMS_NON_PAGINATED_LIST_ACTION`;

import React from "react";
import "../../styles/profilePage.scss";
import { Formik, Form, Field } from "formik";
import classNames from "classnames";
import DefaultButton from "../../components/Button/DefaultButton";
import { useLogin } from "../../rest-client/selectorHook";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_UPDATE_USER_PROFILE } from "../../rest-client/actions/login";

export default function ProfilePageForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user_login_data: { data: userData },
  } = useLogin();

  const initialFormValues = {
    firstName: userData ? userData.first_name : "",
    lastName: userData ? userData.last_name : "",
    password: "",
    confirmPassword: "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const updateUserProfileRequestHandler = React.useCallback(
    (values) => {
      if (values) {
        const updateUserProfileRequestData = {
          user: {
            ...userData,
            first_name: values.firstName.trimStart().trimEnd(),
            last_name: values.lastName.trimStart().trimEnd(),
            password: values.password,
            confirmPassword: values.confirmPassword,
          },
        };
        dispatch({
          type: START_UPDATE_USER_PROFILE,
          payload: { updateUserProfileRequestData, history },
        });
      }
    },
    [dispatch, userData]
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.firstName) {
      error.firstName = "Please enter First Name";
    }
    if (!values.lastName) {
      error.lastName = "Please enter Last Name";
    }
    if (!values.password) {
      error.password = "Please enter password";
    }
    if (values.password.length < 7) {
      error.password = "Password must have at least 6 characters";
    }
    if (/\s/.test(values.password)) {
      error.password = "Password should not contain spaces";
    }
    if (!values.confirmPassword) {
      error.confirmPassword = "Should be same as the password";
    } else if (values.password !== values.confirmPassword) {
      error.confirmPassword = "Passwords do not match";
    }
    return error;
  }, []);

  return (
    <div className="px-6 py-6">
      <div
        className="bg-white w-full h-screen shadow rounded-md overflow-y-auto"
        style={{
          "--tw-shadow":
            "0 -1px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 3px 2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Formik
          initialValues={initialFormValues}
          validate={validationHandler}
          onSubmit={updateUserProfileRequestHandler}
          validateOnChange={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            /* and other goodies */
          }) => (
            <div className="flex flex-col box-border pt-20 pb-10 justify-center">
              <Form className="font-lato">
                <div className="mx-auto w-2/4">
                  <div className="relative p-0.5 my-4 mx-0 align-middle">
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      className={classNames(
                        "input-class-edit border-0 outline-none mt-0 pl-0 pt-0.5 pb-px pr-0.5 h-5 rounded-none border-solid box-border",
                        {
                          "error-input-class-edit":
                            errors.firstName && touched.firstName,
                        }
                      )}
                      value={values.firstName}
                      onChange={(e) => {
                        setFieldDataHandler(
                          setFieldValue,
                          "firstName",
                          e.target.value.trimStart()
                        );
                      }}
                      required
                    />
                    {errors.firstName && touched.firstName && (
                      <p className="error-label-class-edit mt-25 text-xm">
                        {errors.firstName ?? "-"}
                      </p>
                    )}
                    <label
                      htmlFor="firstName"
                      className={classNames(
                        "label-class-edit absolute top-0 left-0.5 font-lato flex items-center cursor-text",
                        {
                          "error-label-class-edit":
                            errors.firstName && touched.firstName,
                        }
                      )}
                    >
                      First Name
                    </label>
                  </div>
                  <div className="relative p-0.5 my-4 mx-0 align-middle">
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      className={classNames(
                        "input-class-edit border-0 outline-none mt-0 pl-0 pt-0.5 pb-px pr-0.5 h-5 rounded-none border-solid box-border",
                        {
                          "error-input-class-edit":
                            errors.lastName && touched.lastName,
                        }
                      )}
                      required
                      value={values.lastName}
                      onChange={(e) => {
                        setFieldDataHandler(
                          setFieldValue,
                          "lastName",
                          e.target.value.trimStart()
                        );
                      }}
                    />
                    {errors.lastName && touched.lastName && (
                      <p className="error-label-class-edit mt-25 text-xm">
                        {errors.lastName ?? "-"}
                      </p>
                    )}
                    <label
                      htmlFor="lastName"
                      className={classNames(
                        "label-class-edit absolute top-0 left-0.5 font-lato flex items-center cursor-text",
                        {
                          "error-label-class-edit":
                            errors.lastName && touched.lastName,
                        }
                      )}
                    >
                      Last Name
                    </label>
                  </div>
                  <div
                    className={classNames(
                      "relative p-0.5 my-4 mx-0 align-middle mb-6",
                      {
                        "!mb-4": errors.password && touched.password,
                      }
                    )}
                  >
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      className={classNames(
                        "input-class-edit border-0 outline-none mt-0 pl-0 pt-0.5 pb-px pr-0.5 h-5 rounded-none border-solid box-border",
                        {
                          "error-input-class-edit":
                            errors.password && touched.password,
                        }
                      )}
                      value={values.password}
                      onChange={(e) => {
                        setFieldDataHandler(
                          setFieldValue,
                          "password",
                          e.target.value.trimStart()
                        );
                      }}
                      required
                    />
                    {errors.password && touched.password && (
                      <p className="error-label-class-edit mt-25 text-xm">
                        {errors.password ?? "-"}
                      </p>
                    )}
                    <label
                      htmlFor="password"
                      className={classNames(
                        "label-class-edit absolute top-0 left-0.5 font-lato flex items-center cursor-text",
                        {
                          "error-label-class-edit":
                            errors.password && touched.password,
                          "top-25": values.password,
                        }
                      )}
                    >
                      Password
                    </label>
                  </div>
                  <div
                    className={classNames(
                      "relative p-0.5 my-4 mx-0 align-middle mb-6",
                      {
                        "!mb-4":
                          errors.confirmPassword && touched.confirmPassword,
                      }
                    )}
                  >
                    <Field
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      className={classNames(
                        "input-class-edit border-0 outline-none mt-0 pl-0 pt-0.5 pb-px pr-0.5 h-5 rounded-none border-solid box-border",
                        {
                          "error-input-class-edit":
                            errors.confirmPassword && touched.confirmPassword,
                        }
                      )}
                      value={values.confirmPassword}
                      onChange={(e) => {
                        setFieldDataHandler(
                          setFieldValue,
                          "confirmPassword",
                          e.target.value.trimStart()
                        );
                      }}
                      required
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <p className="error-label-class-edit mt-25 text-xm">
                        {errors.confirmPassword ?? "-"}
                      </p>
                    )}
                    <label
                      htmlFor="confirmPassword"
                      className={classNames(
                        "label-class-edit absolute top-0 left-0.5 font-lato flex items-center cursor-text",
                        {
                          "error-label-class-edit":
                            errors.confirmPassword && touched.confirmPassword,
                          "top-25": values.confirmPassword,
                        }
                      )}
                    >
                      Confirm Password
                    </label>
                  </div>
                  <DefaultButton label={"Update"} className="" />
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

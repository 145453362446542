import { call, put, takeLatest } from "redux-saga/effects";
import {
  FAILURE_RECOVERY_PASSWORD,
  FAILURE_UPDATE_USER_PROFILE,
  LOGIN_FAILURE,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  START_RECOVERY_PASSWORD,
  START_UPDATE_USER_PROFILE,
  SUCCESS_RECOVERY_PASSWORD,
  SUCCESS_UPDATE_USER_PROFILE,
} from "../actions/login";
import {
  fetchLoginRequest,
  fetchRecoveryPasswordRequest,
  fetchUpdateUserProfileRequest,
} from "../apis/login";
import { toast } from "react-toastify";

export function* loginRequestWatcher() {
  yield takeLatest(LOGIN_REQUESTED, loginRequestWorker);
}

function* loginRequestWorker({ payload }) {
  const { loginRequestData, setIsOpenLoginModal, history } = payload;
  const fetchLoginRequestResponse = yield call(
    fetchLoginRequest,
    loginRequestData
  );
  if (fetchLoginRequestResponse.status) {
    yield put({
      type: LOGIN_SUCCESS,
      data: fetchLoginRequestResponse.data,
    });
    localStorage.setItem(
      "access_token",
      fetchLoginRequestResponse.data.user.token
    );
    localStorage.setItem(
      "user",
      JSON.stringify(fetchLoginRequestResponse.data.user)
    );
    if (fetchLoginRequestResponse.data.user.has_user_password) {
      if (
        fetchLoginRequestResponse.data.user.role === "admin" ||
        fetchLoginRequestResponse.data.user.role === "superadmin"
      ) {
        history.push("/dashboard/learning/index/module_sets");
      } else if (fetchLoginRequestResponse.data.user.role === "Teacher") {
        history.push("/dashboard/manage-sessions");
      } else if (fetchLoginRequestResponse.data.user.role === "Learner") {
        history.push("/dashboard/index");
      }
    } else {
      history.push("/dashboard/profile");
    }
    setIsOpenLoginModal(false);
  } else {
    const errorMessage = "Wrong credentials";
    yield put({
      type: LOGIN_FAILURE,
      errorMessage,
    });
  }
}

export function* recoveryPasswordRequestWatcher() {
  yield takeLatest(START_RECOVERY_PASSWORD, recoveryPasswordRequestWorker);
}

function* recoveryPasswordRequestWorker({ payload }) {
  const { recoveryPasswordRequestData, setIsOpenLoginModal } = payload;
  const fetchRecoveryPasswordRequestResponse = yield call(
    fetchRecoveryPasswordRequest,
    recoveryPasswordRequestData
  );
  if (fetchRecoveryPasswordRequestResponse.status) {
    yield put({
      type: SUCCESS_RECOVERY_PASSWORD,
      data: fetchRecoveryPasswordRequestResponse.data,
    });
    setIsOpenLoginModal(false);
    toast.success(
      `Password has been sent to ${recoveryPasswordRequestData.user.email}`
    );
  } else {
    const errorMessage = "Record not found";
    yield put({
      type: FAILURE_RECOVERY_PASSWORD,
      errorMessage,
    });
  }
}

export function* resetPasswordRequestWatcher() {
  yield takeLatest(START_UPDATE_USER_PROFILE, updateUserProfileRequestWorker);
}

function* updateUserProfileRequestWorker({ payload }) {
  const { updateUserProfileRequestData, history } = payload;
  const updateUserProfileRequestResponse = yield call(
    fetchUpdateUserProfileRequest,
    updateUserProfileRequestData
  );
  if (updateUserProfileRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_USER_PROFILE,
      data: updateUserProfileRequestResponse.data,
    });
    localStorage.setItem(
      "access_token",
      updateUserProfileRequestResponse.data.user.token
    );
    localStorage.setItem(
      "user",
      JSON.stringify(updateUserProfileRequestResponse.data.user)
    );
    toast.success("Profile updated");
    if (
      updateUserProfileRequestResponse.data.user.role === "admin" ||
      updateUserProfileRequestResponse.data.user.role === "superadmin"
    ) {
      history.push("/dashboard/learning/index/module_sets");
    } else if (updateUserProfileRequestResponse.data.user.role === "Teacher") {
      history.push("/dashboard/manage-sessions");
    }
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_USER_PROFILE,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

import React, { useRef } from "react";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import Icon from "../../../components/Button/Icon";
import PageResource from "../../../components/ResourceTypes/PageResource";
import PollsResource from "../../../components/ResourceTypes/PollsResource";
import CanvasResource from "../../../components/ResourceTypes/CanvasResource";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_SHOW_RESOURCE_ACTION } from "../../../rest-client/actions/resources";
import { useResourcesList } from "../../../rest-client/selectorHook";
import { ClipLoader } from "react-spinners";
import Layout from "../../../components/Layout";

export default function ViewResource() {
  const { url } = useRouteMatch(`/dashboard/learning`);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const canvasRef = useRef(null);

  const backClickHandler = React.useCallback(() => {
    history.push(`${url}/index/modules`);
  }, [history, url]);

  React.useEffect(() => {
    dispatch({
      type: START_SHOW_RESOURCE_ACTION,
      payload: { params },
    });
  }, [dispatch]);

  const {
    resource_show_data: {
      isLoading: resourceShowLoading,
      data: resourceShowData,
    },
  } = useResourcesList();

  const resourceType = resourceShowData?.resource_type;

  const getComponent = () => {
    switch (resourceType) {
      case "page":
        return (
          <PageResource
            htmlContent={resourceShowData.html_content}
            discussions={resourceShowData.discussions}
          />
        );
      case "poll":
        return (
          <PollsResource
            htmlContent={resourceShowData.html_content}
            polls={resourceShowData.polls}
            discussions={resourceShowData.discussions}
          />
        );
      case "canvas":
        return (
          <CanvasResource
            htmlContent={resourceShowData.html_content}
            media={resourceShowData.media}
            discussions={resourceShowData.discussions}
            canvasRef={canvasRef}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Layout>
      {resourceShowLoading ? (
        <div className="flex flex-col justify-center items-center h-32 mt-20">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">Loading...</p>
        </div>
      ) : (
        <>
          <div className="flex py-3 text-base font-lato px-6">
            <button
              className="border-none bg-transparent"
              onClick={backClickHandler}
            >
              <Icon
                label="arrow_back_ios"
                title=""
                className={classNames("text-light-gray")}
              />
            </button>
            <span
              className="text-slate-600 cursor-pointer"
              onClick={backClickHandler}
            >
              Back to Resources
            </span>
          </div>
          <div className="edit-profile-text"></div>
          <div className="p-4 my-2 mx-5">{getComponent()}</div>
        </>
      )}
    </Layout>
  );
}

export const START_ADMINISTRATORS_LIST_ACTION = `START_ADMINISTRATORS_LIST_ACTION`;
export const ERROR_ADMINISTRATORS_LIST_ACTION = `ERROR_ADMINISTRATORS_LIST_ACTION`;
export const SUCCESS_ADMINISTRATORS_LIST_ACTION = `SUCCESS_ADMINISTRATORS_LIST_ACTION`;

export const START_CREATE_ADMINISTRATOR_ACTION =
  "START_CREATE_ADMINISTRATOR_ACTION";
export const SUCCESS_CREATE_ADMINISTRATOR_ACTION =
  "SUCCESS_CREATE_ADMINISTRATOR_ACTION";
export const FAILURE_CREATE_ADMINISTRATOR_ACTION =
  "FAILURE_CREATE_ADMINISTRATOR_ACTION";

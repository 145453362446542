import React, { useState, useEffect, useRef } from "react";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import { useTable, usePagination, useExpanded } from "react-table";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import {
  START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  START_DELETE_MODULE_DOCUMENT_ACTION,
  START_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
} from "../../../rest-client/actions/module-documents";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import CloneModal from "../CloneModal";
import FilterModulesModal from "../Modules/FilterModulesModal";
import dragdropimg from "../../../images/drag&drop.svg";
import "../../../styles/button.scss";
import { ClipLoader } from "react-spinners";
import Pagination from "../../../components/Pagination/pagination";
import {
  CANVAS_TYPE_RESOURCE,
  MODULE_PAGE_SIZE,
  PAGE_TYPE_RESOURCE,
  POLL_TYPE_RESOURCE,
  RESULT_TYPE_RESOURCE,
} from "../../../utils";
import { useModuleDocuments } from "../../../rest-client/selectorHook";

export default function ModuleContainer({
  documentSetData,
  moduleResourcesNodeDataList,
  filteredData,
  setModuleOrderType,
  moduleOrderType,
  moduleSearchInput,
  setModuleSearchInput,
  setModuleFilterInput,
  isLoadingModuleDocuments,
  isLoadingModuleResourcesNode,
  totalPages,
  currentPage,
  setCurrentPage,
  inputRef,
}) {
  const userData = JSON.parse(localStorage.getItem("user"));
  const path =
    userData?.role === "Teacher" ? "/dashboard" : "/dashboard/learning";
  const { url } = useRouteMatch(path);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [isVisible, setVisible] = React.useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenCloneModal, setIsOpenCloneModal] = useState(false);
  const [isOpenRowDropdown, setIsOpenRowDropdown] = useState();
  const [moduleDocumentData, setModuleDocumentData] = useState({});
  const [isOpenFilterModal, setIsOpenFilterModal] = useState();
  const [loadingId, setLoadingId] = React.useState(null);
  const [moduleSetFilterInputValue, setmoduleSetFilterInputValue] =
    useState("");

  const history = useHistory();
  const onHideShowClick = () => {
    setVisible(!isVisible);
  };

  const {
    update_module_resource_node_by_id: {
      isLoading: isLoadingUpdateModuleDocuments,
    },
  } = useModuleDocuments();

  const redirectHandleClick = React.useCallback(() => {
    history.push(`${url}/modules`);
  }, [history, url]);

  // Modal for confirming delete module
  const openConfirmModalHandler = React.useCallback((value) => {
    setIsOpenConfirmModal(true);
    setModuleDocumentData(value);
  }, []);

  const openCloneModalHandler = React.useCallback((value) => {
    setIsOpenCloneModal(true);
    setModuleDocumentData(value);
  }, []);

  const openFilterModalHandler = React.useCallback(() => {
    setIsOpenFilterModal(true);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenRowDropdown();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: documentSetData || [],
      initialState: {
        expanded: false,
        pageSize: MODULE_PAGE_SIZE,
      },
    },
    useExpanded,
    usePagination
  );
  const { getTableProps, getTableBodyProps, page, prepareRow } = tableInstance;

  const getModuleResourceNodeHandler = React.useCallback((row) => {
    if (row?.original && !row.isExpanded) {
      dispatch({
        type: START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
        payload: {
          module_id: row?.original?.id,
        },
      });
    }
  }, []);

  const deleteModuleDocumentHandler = React.useCallback(() => {
    const deleteModuleDocumentRequestData = {
      module_document: {
        ...moduleDocumentData,
      },
      id: moduleDocumentData.id,
    };
    dispatch({
      type: START_DELETE_MODULE_DOCUMENT_ACTION,
      payload: {
        deleteModuleDocumentRequestData,
        setIsOpenConfirmModal,
        currentPage,
      },
    });
  }, [dispatch, moduleDocumentData]);

  const updateModuleResourceNodeHandler = React.useCallback(
    (row) => {
      if (row?.original) {
        const data = moduleResourcesNodeDataList.find(
          (e) => e.id === row?.original.id
        );
        const payloadData = data.resource_nodes.map((e, index) => ({
          ...e,
          order: index,
        }));
        dispatch({
          type: START_UPDATING_MODULE_RESOURCE_NODE_BY_ID,
          payload: {
            module_id: row?.original?.id,
            resource_nodes: payloadData,
            orderType: "asc",
          },
        });
      }
    },
    [moduleResourcesNodeDataList]
  );

  const resourceNodesData = (arr, resId) => {
    return arr.find((e) => e.id === resId);
  };

  const truncateMiddle = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }

    const prefixLength = Math.floor((maxLength - 3) / 2);
    const suffixLength = maxLength - prefixLength - 3;

    const prefix = str.slice(0, prefixLength);
    const suffix = str.slice(-suffixLength);

    return `${prefix}...${suffix}`;
  };

  return (
    <>
      {isOpenConfirmModal && (
        <ConfirmModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          deleteRecordHandler={deleteModuleDocumentHandler}
        />
      )}
      {isOpenCloneModal && (
        <CloneModal
          isOpenCloneModal={isOpenCloneModal}
          setIsOpenCloneModal={setIsOpenCloneModal}
          clonedObjectData={moduleDocumentData}
          clonedObjectName="Module"
          setCurrentPage={setCurrentPage}
        />
      )}
      {userData?.role === "admin" || userData?.role === "superadmin" ? (
        <>
          {isOpenFilterModal && (
            <FilterModulesModal
              isOpenFilterModal={isOpenFilterModal}
              setIsOpenFilterModal={setIsOpenFilterModal}
              setModuleFilterInput={setModuleFilterInput}
              setCurrentPage={setCurrentPage}
              moduleSetFilterInputValue={moduleSetFilterInputValue}
              setmoduleSetFilterInputValue={setmoduleSetFilterInputValue}
            />
          )}
        </>
      ) : null}
      {isLoadingModuleDocuments ? (
        <div
          className={classNames(
            "flex flex-col mt-20 justify-center items-center h-32",
            {
              "w-1/2":
                userData?.role === "admin" || userData?.role === "superadmin",
              "w-full": userData?.role === "Teacher",
            }
          )}
        >
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">
            Fetching Modules...
          </p>
        </div>
      ) : (
        <>
          <div className={userData?.role !== "Teacher" ? "w-1/2" : "w-full"}>
            <div className="flex flex-row justify-between items-center w-full">
              <div className="flex justify-start align-middle text-center items-center">
                <h3 className="form_title p-2 ml-2">Modules</h3>
              </div>
              <div className="py-7 flex items-center justify-end">
                {userData?.role === "admin" ||
                userData?.role === "superadmin" ? (
                  <button
                    className="border-none bg-transparent"
                    onClick={redirectHandleClick}
                  >
                    <Icon
                      label="add"
                      title="Add Modules"
                      className="text-light-gray"
                    />
                  </button>
                ) : null}
                <button
                  className="border-none bg-transparent"
                  onClick={() => {
                    setModuleOrderType(
                      moduleOrderType === "desc" ? "asc" : "desc"
                    );
                  }}
                >
                  <Icon
                    label="sort_by_alpha"
                    title="Sort Modules"
                    className="text-light-gray"
                  />
                </button>
                {userData?.role === "admin" ||
                userData?.role === "superadmin" ? (
                  <button
                    className="border-none bg-transparent"
                    onClick={() => {
                      openFilterModalHandler();
                    }}
                  >
                    <Icon
                      label="filter_alt"
                      title="Filter Modules"
                      className="text-light-gray"
                    />
                  </button>
                ) : null}
                <button
                  className="border-none bg-transparent"
                  onClick={onHideShowClick}
                >
                  <Icon
                    label="search"
                    title="Search"
                    className={classNames("text-light-gray")}
                  />
                </button>
                <div>
                  <input
                    type="text"
                    id="search"
                    className="search-input text-base w-full"
                    placeholder="Search"
                    style={{ display: isVisible ? "block" : "none" }}
                    // onKeyPress={onKeyPressHandler}
                    value={moduleSearchInput}
                    onChange={(e) => setModuleSearchInput(e.target.value)}
                    ref={inputRef}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                userData?.role !== "Teacher"
                  ? "p-2 ml-2 height-65 overflow-scroll relative module-sets-box"
                  : "px-2 ml-2"
              }
            >
              {documentSetData.length > 0 ? (
                <table
                  {...getTableProps()}
                  className="w-full divide-y divide-gray-200 flex flex-col"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 1rem",
                  }}
                >
                  <tbody
                    {...getTableBodyProps()}
                    className="divide-y divide-gray-200 my-2"
                  >
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={row.getRowProps().key}>
                          <tr
                            {...row.getRowProps()}
                            className="shadow border-spacing-1 border-spacing-x-2.5 flex justify-between mb-3 module-box-row"
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className={
                                    userData?.role !== "Teacher"
                                      ? "py-6 module-box-items text-base  text-gray-800 whitespace-nowrap font-bold  w-1/2 text-left"
                                      : "px-5 py-6 text-base text-gray-800 whitespace-nowrap font-bold  w-1/2 text-left"
                                  }
                                  {...cell.getCellProps()}
                                >
                                  {userData?.role !== "Teacher" && (
                                    <button
                                      className="border-none bg-transparent"
                                      onClick={() => {
                                        row.toggleRowExpanded();
                                        getModuleResourceNodeHandler(row);
                                        setLoadingId(row.original.id);
                                      }}
                                    >
                                      <Icon
                                        className={classNames(
                                          "text-light-gray"
                                        )}
                                        label={
                                          row.isExpanded
                                            ? "expand_less"
                                            : "expand_more"
                                        }
                                      />
                                    </button>
                                  )}
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                            <td
                              className="py-6 flex items-center justify-end"
                              style={{ position: "relative" }}
                            >
                              {userData?.role !== "Teacher" && (
                                <>
                                  <button
                                    className={classNames(
                                      "border-none bg-transparent",
                                      {
                                        "pointer-events-none":
                                          !row.isExpanded ||
                                          isLoadingUpdateModuleDocuments,
                                      }
                                    )}
                                    onClick={() => {
                                      updateModuleResourceNodeHandler(row);
                                    }}
                                    disabled={isLoadingUpdateModuleDocuments}
                                  >
                                    <Icon
                                      label="save"
                                      title="Save Changes"
                                      className={classNames("text-light-gray")}
                                    />
                                  </button>
                                  <button
                                    className="border-none bg-transparent"
                                    id={row.id}
                                    onClick={() => {
                                      setIsOpenRowDropdown(row.original.id);
                                    }}
                                  >
                                    <Icon
                                      label="more_vert"
                                      title=""
                                      className={classNames("text-light-gray")}
                                    />
                                  </button>
                                  {isOpenRowDropdown === row.original.id && (
                                    <div
                                      className="absolute z-50 w-48 py-2 mt-2 bg-white rounded-md shadow-xl dark:bg-gray-800"
                                      ref={dropdownRef}
                                      style={{
                                        top:
                                          index === 0
                                            ? "-20px"
                                            : index === 1
                                            ? "-100px"
                                            : index === 2
                                            ? "-180px"
                                            : index === page.length - 1 ||
                                              index === page.length - 2
                                            ? "-200px"
                                            : 0,
                                        right: 0,
                                        zIndex: 9999,
                                      }}
                                    >
                                      <a
                                        className="cursor-pointer block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIsOpenRowDropdown();
                                          history.push(
                                            `${url}/resource?moduleId=${row.original.id}`
                                          );
                                        }}
                                      >
                                        <Icon
                                          label="note_add"
                                          title=""
                                          className={classNames(
                                            "text-light-gray mx-1"
                                          )}
                                        />
                                        <span className="absolute">
                                          Create Resource
                                        </span>
                                      </a>
                                      <a
                                        href={`${url}/module/${row.original.id}`}
                                        target="_blank"
                                        className="cursor-pointer block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                        onClick={(e) => {
                                          setIsOpenRowDropdown();
                                        }}
                                      >
                                        <Icon
                                          label="visibility"
                                          title=""
                                          className={classNames(
                                            "text-light-gray mx-1"
                                          )}
                                        />
                                        <span className="absolute">
                                          View Module
                                        </span>
                                      </a>
                                      <a
                                        className="cursor-pointer block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIsOpenRowDropdown();
                                          history.push(
                                            `${url}/modules/${row.original.id}/edit`
                                          );
                                        }}
                                      >
                                        <Icon
                                          label="edit"
                                          title=""
                                          className={classNames(
                                            "text-light-gray mx-1"
                                          )}
                                        />
                                        <span className="absolute">
                                          Edit Module
                                        </span>
                                      </a>
                                      <a
                                        className="cursor-pointer block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIsOpenRowDropdown();
                                          openCloneModalHandler(row.original);
                                        }}
                                      >
                                        <Icon
                                          label="content_copy"
                                          title=""
                                          className={classNames(
                                            "text-light-gray mx-1"
                                          )}
                                        />
                                        <span className="absolute">
                                          Clone Module
                                        </span>
                                      </a>
                                      <a
                                        className="cursor-pointer block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setIsOpenRowDropdown();
                                          openConfirmModalHandler(row.original);
                                        }}
                                      >
                                        <Icon
                                          label="delete"
                                          title=""
                                          className={classNames(
                                            "text-light-gray mx-1"
                                          )}
                                        />
                                        <span className="absolute">
                                          Delete Module
                                        </span>
                                      </a>
                                    </div>
                                  )}
                                </>
                              )}
                              {userData?.role === "Teacher" && (
                                <Link
                                  to={`${url}/module/${row.original.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Icon
                                    label="visibility"
                                    title="View"
                                    className={classNames(
                                      "text-light-gray !mx-10"
                                    )}
                                  />
                                </Link>
                              )}
                            </td>
                          </tr>
                          {row.isExpanded && (
                            <Droppable
                              droppableId={`${row.original.id}`}
                              type="person"
                              key={`${row.original.id}`}
                            >
                              {(provided) => (
                                <>
                                  <tr
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="flex flex-col"
                                  >
                                    <td>
                                      {isLoadingModuleResourcesNode &&
                                      loadingId === row.original.id ? (
                                        <div className="flex flex-col justify-center items-center h-32">
                                          <ClipLoader
                                            size={32}
                                            color={"#3e8ede"}
                                          />
                                          <p className="mt-2 font-bold font-muli text-blue-theme">
                                            Loading...
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="p-4 px-5 bg-gray-100 -mt-4">
                                          {resourceNodesData(
                                            moduleResourcesNodeDataList,
                                            row.original.id
                                          ) &&
                                          resourceNodesData(
                                            moduleResourcesNodeDataList,
                                            row.original.id
                                          )?.resource_nodes?.length > 0 ? (
                                            <>
                                              {resourceNodesData(
                                                moduleResourcesNodeDataList,
                                                row.original.id
                                              )?.resource_nodes.map(
                                                (item, index) => {
                                                  return (
                                                    <Draggable
                                                      draggableId={`${item.resource_id}_${row.original.id}`}
                                                      index={index}
                                                      key={`${item.resource_id}_${row.original.id}`}
                                                    >
                                                      {(provided) => (
                                                        <div
                                                          className={classNames(
                                                            "bg-white px-5 py-2 text-base text-slate-700 whitespace-nowrap font-bold mb-2",
                                                            {
                                                              "bg-blue-99":
                                                                item.resource_type
                                                                  ? item.resource_type ===
                                                                    PAGE_TYPE_RESOURCE
                                                                  : item
                                                                      .resource
                                                                      .resource_type ===
                                                                    PAGE_TYPE_RESOURCE,
                                                              "bg-orange-243":
                                                                item.resource_type
                                                                  ? item.resource_type ===
                                                                    POLL_TYPE_RESOURCE
                                                                  : item
                                                                      .resource
                                                                      .resource_type ===
                                                                    POLL_TYPE_RESOURCE,
                                                              "bg-pink-203":
                                                                item.resource_type
                                                                  ? item.resource_type ===
                                                                    RESULT_TYPE_RESOURCE
                                                                  : item
                                                                      .resource
                                                                      .resource_type ===
                                                                    RESULT_TYPE_RESOURCE,
                                                              "bg-purple-80":
                                                                item.resource_type
                                                                  ? item.resource_type ===
                                                                    CANVAS_TYPE_RESOURCE
                                                                  : item
                                                                      .resource
                                                                      .resource_type ===
                                                                    CANVAS_TYPE_RESOURCE,
                                                            }
                                                          )}
                                                          {...provided.dragHandleProps}
                                                          {...provided.draggableProps}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                        >
                                                          <div
                                                            className="flex justify-between"
                                                            title={
                                                              item.title
                                                                ? item.title
                                                                : item.resource
                                                                    .title
                                                            }
                                                          >
                                                            <div>
                                                              {item.title
                                                                ? `${truncateMiddle(
                                                                    item.title,
                                                                    55
                                                                  )}`
                                                                : `${truncateMiddle(
                                                                    item
                                                                      .resource
                                                                      .title,
                                                                    55
                                                                  )}`}
                                                            </div>
                                                            <div>
                                                              <button
                                                                className="border-none bg-transparent"
                                                                onClick={() =>
                                                                  filteredData(
                                                                    item,
                                                                    row.original
                                                                      .id
                                                                  )
                                                                }
                                                              >
                                                                <Icon
                                                                  label="delete"
                                                                  title="Remove Resource"
                                                                  className={classNames(
                                                                    "text-slate-700"
                                                                  )}
                                                                />
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <div className="text-center p-10 text-base font-bold border border-dashed border-slate-300">
                                              <img
                                                src={dragdropimg}
                                                alt="dragdropimg"
                                                className="w-16"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                  {provided.placeholder}
                                </>
                              )}
                            </Droppable>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="text-center p-10 text-base">
                  No module available{" "}
                  {userData?.role === "admin" ||
                  userData?.role === "superadmin" ? (
                    <>
                      "Click <b>+</b> to create one"
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </>
      )}
    </>
  );
}

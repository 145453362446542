import React from "react";
import "../../styles/footer.scss";
import bioScannWhiteLogo from "../../images/img_bioscannlogo_white.svg";
import tuftWhiteImage from  "../../images/white_logo_tufts.png"

export default function Footer() {
  return (
    <div className="py-10 bg-black">
      <div className="footer-bar-container">
        <div className="flex w-full items-center flex-row m-2">
          <div className="min-w-0 flex-1 m-2">
            <img src={bioScannWhiteLogo} alt="alt" />
          </div>
          <div className="m-2 mr-6">
            <img src={tuftWhiteImage} alt="alt" />
          </div>
        </div>
        <div className="divider m-2 divider-clr border-t"></div>
        <div className="flex w-full flex-row m-2">
          <div className="footer-text-cont-left m-2 max-h-full box-border">
            <p className="font-lato text-xs text-clr">
              This material is based upon work supported by the National Science
              Foundation (Award No 1614167.) Any opinions, findings, and
              conclusions or recommendations expressed in this material are
              those of the authors and do not necessarily reflect the views of
              the National Science Foundation.
            </p>
          </div>
          <div className="footer-text-cont-right m-2 max-h-full box-border">
            <p className="font-lato text-xs text-clr text-right mr-4">
              © Copyright 2019 Tufts University
            </p>
          </div>
        </div>
        <div className="divider m-2 divider-clr border-t"></div>
      </div>
    </div>
  );
}

export const START_GROUPS_LIST_ACTION = `START_GROUPS_LIST_ACTION`;
export const ERROR_GROUPS_LIST_ACTION = `ERROR_GROUPS_LIST_ACTION`;
export const SUCCESS_GROUPS_LIST_ACTION = `SUCCESS_GROUPS_LIST_ACTION`;

export const START_CREATE_GROUP_ACTION = "START_CREATE_GROUP_ACTION";
export const SUCCESS_CREATE_GROUP_ACTION = "SUCCESS_CREATE_GROUP_ACTION";
export const FAILURE_CREATE_GROUP_ACTION = "FAILURE_CREATE_GROUP_ACTION";

export const START_UPDATE_GROUPS_ACTION = "START_UPDATE_GROUPS";
export const SUCCESS_UPDATE_GROUPS_ACTION = "SUCCESS_UPDATE_GROUPS";
export const FAILURE_UPDATE_GROUPS_ACTION = "FAILURE_UPDATE_GROUPS";

export const START_DELETE_GROUPS_ACTION = "START_DELETE_GROUPS_ACTION";
export const SUCCESS_DELETE_GROUPS_ACTION = "SUCCESS_DELETE_GROUPS_ACTION";
export const FAILURE_DELETE_GROUPS_ACTION = "FAILURE_DELETE_GROUPS_ACTION";

import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useDispatch } from "react-redux";
import { START_UPDATE_GROUPS_ACTION } from "../../../rest-client/actions/groups";
import { START_CLASSROOMS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/classrooms";
import { useClassrooms } from "../../../rest-client/selectorHook";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import { ClipLoader } from "react-spinners";

export default function EditGroupForm({
  editableObjectData,
  setIsOpenEditFormModal,
  currentPage,
}) {
  const groupData = editableObjectData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: START_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  const {
    classrooms_non_paginated_list_data: {
      isLoading: classroomListLoading,
      data: classroomListData,
    },
  } = useClassrooms();

  const initialFormValues = {
    group_title: groupData?.title ? groupData.title : "",
    group_description: groupData?.description ? groupData.description : "",
    group_classroom: groupData?.classroom
      ? { label: groupData.classroom?.title, value: groupData.classroom?.id }
      : "",
    group_username: groupData?.username ? groupData.username : "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.group_title) {
      error.group_title = "Please enter Title";
    }
    if (!values.group_description) {
      error.group_description = "Please enter Description";
    }
    if (!values.group_classroom) {
      error.group_classroom = "Please select Classroom";
    }
    if (!values.group_username) {
      error.group_username = "Please enter Username";
    } else if (!/^[a-z0-9]+$/.test(values.group_username)) {
      error.group_username = "Only lowercase letters and numbers are allowed";
    }
    if (values.confirm_password !== values.group_password) {
      error.confirm_password = "Passwords do not match";
    }
    return error;
  }, []);

  const updateGroupHandler = React.useCallback(
    (values, { resetForm }) => {
      const updateGroupData = {
        group: {
          ...groupData,
          title: values.group_title.trimStart().trimEnd(),
          description: values.group_description.trimStart().trimEnd(),
          classroom_id: values.group_classroom?.value,
          student: {
            username: values.group_username,
            ...(values.group_password && { password: values.group_password }),
          },
        },
      };
      dispatch({
        type: START_UPDATE_GROUPS_ACTION,
        payload: { updateGroupData, setIsOpenEditFormModal, currentPage },
      });
      resetForm();
    },
    [dispatch]
  );

  const classroomOptions = classroomListData.map((classroom, index) => {
    return {
      label: classroom?.title,
      value: classroom?.id,
    };
  });

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={updateGroupHandler}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="font-lato">
          <div className="mt-16">
            <div className="flex flex-row box-border">
              <div className="flex-1">
                <div className="relative p-0.5 my-2 mx-0 align-middle !mb-1">
                  <Field
                    type="text"
                    id="group_title"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.group_title && touched.group_title,
                    })}
                    value={values.group_title}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "group_title",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="group_title"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.group_title && touched.group_title,
                    })}
                  >
                    Title
                  </label>
                  {errors.group_title && touched.group_title && (
                    <p className="error-label-class mt-25">
                      {errors.group_title ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 ml-10">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="group_description"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.group_description && touched.group_description,
                    })}
                    value={values.group_description}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "group_description",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="group_description"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.group_description && touched.group_description,
                    })}
                  >
                    Description
                  </label>
                  {errors.group_description && touched.group_description && (
                    <p className="error-label-class mt-25">
                      {errors.group_description ?? "-"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row box-border">
              <div className="flex-1">
                <div className="mb-11 edit-dropdown-modal">
                  <Field name="group_classroom">
                    {({ field, form, meta }) => (
                      <SingleSelect
                        field={field}
                        form={form}
                        meta={meta}
                        options={classroomOptions}
                        placeholder="Classroom*"
                        isLoading={classroomListLoading}
                        loadingMessage={() => (
                          <ClipLoader size={32} color={"#3e8ede"} />
                        )}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex-1 ml-10">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="group_username"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.group_username && touched.group_username,
                    })}
                    value={values.group_username}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "group_username",
                        e.target.value.trim()
                      );
                    }}
                    maxLength={20}
                    required
                  />
                  <label
                    htmlFor="group_username"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.group_username && touched.group_username,
                    })}
                  >
                    Username
                  </label>
                  {errors.group_username && touched.group_username && (
                    <p className="error-label-class mt-25">
                      {errors.group_username ?? "-"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row box-border">
              {console.log(values)}
              <div className="flex-1">
                <div className="field-holder">
                  <Field
                    type="password"
                    id="group_password"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.group_password && touched.group_password,
                    })}
                    value={values.group_password}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "group_password",
                        e.target.value.trim()
                      );
                    }}
                    maxLength={20}
                    tabIndex="5"
                  />
                  <label
                    htmlFor="group_password"
                    className={classNames("label-class", {
                      "error-label-class":
                        errors.group_password && touched.group_password,
                    })}
                  >
                    Password
                  </label>
                  {errors.group_password && touched.group_password && (
                    <p className="error-label-class mt-25">
                      {errors.group_password ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 ml-10">
                <div className="field-holder">
                  <Field
                    type="password"
                    id="confirm_password"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.confirm_password && touched.confirm_password,
                    })}
                    value={values.confirm_password}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "confirm_password",
                        e.target.value.trim()
                      );
                    }}
                    maxLength={20}
                    tabIndex="6"
                  />
                  <label
                    htmlFor="confirm_password"
                    className={classNames("label-class", {
                      "error-label-class":
                        errors.confirm_password && touched.confirm_password,
                    })}
                  >
                    Confirm Password
                  </label>
                  {errors.confirm_password && touched.confirm_password && (
                    <p className="error-label-class mt-25">
                      {errors.confirm_password ?? "-"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end ml-10">
            <DefaultButton label="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import DynamicTabs from "../../../components/Tabs/DynamicTabs";
import Layout from "../../../components/Layout";
import { START_CLASSROOMS_LIST_ACTION } from "../../../rest-client/actions/classrooms";
import { START_MODULE_SESSION_LIST_ACTION } from "../../../rest-client/actions/module-sessions";
import {
  useModuleSessions,
  useClassrooms,
} from "../../../rest-client/selectorHook";
import { useDispatch } from "react-redux";
import DefaultButtonLarge from "../../../components/Button/DefaultButtonLarge";
import AssignModulesForm from "./AssignModulesForm";
import ListModuleAssignments from "./ListModuleAssignments";
import { ClipLoader } from "react-spinners";
import { useHistory, useRouteMatch } from "react-router";

export default function SessionTabView() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const dispatch = useDispatch();
  const dispatchStartClassroomsList = useCallback(() => {
    dispatch({
      type: START_CLASSROOMS_LIST_ACTION,
      payload: { orderType: "desc" },
    });
  }, [dispatch]);

  const dispatchStartModuleSessionsList = useCallback(
    (classroomId) => {
      dispatch({
        type: START_MODULE_SESSION_LIST_ACTION,
        payload: {
          classroom_id: classroomId,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchStartClassroomsList();
  }, [dispatchStartClassroomsList]);

  const {
    classrooms_list_data: {
      isLoading: classroomListLoading,
      data: classroomListData,
    },
  } = useClassrooms();

  useEffect(() => {
    if (!classroomListLoading && classroomListData.length > 0) {
      dispatchStartModuleSessionsList(classroomsTabData[activeTabIndex]?.id);
    }
  }, [
    dispatchStartModuleSessionsList,
    classroomListLoading,
    classroomListData,
    activeTabIndex,
  ]);

  const {
    module_sessions_list_data: {
      isLoading: isLoadingModuleSessionsList,
      data: ModuleSessionsListData,
    },
  } = useModuleSessions();

  const classroomsTabData = classroomListData?.map((classroom, index) => {
    return {
      label: classroom?.title,
      id: classroom?.id,
    };
  });

  const { url } = useRouteMatch(`/dashboard`);
  const history = useHistory();

  return (
    <Layout>
      {classroomListLoading ? (
        <div className="flex flex-col justify-center items-center h-32">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">
            Fetching classrooms...
          </p>
        </div>
      ) : (
        <>
          <DynamicTabs
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            tabData={classroomsTabData}
          />
          <div className="flex mt-5 mb-3 pl-5">
            <div className="justify-left">
              <DefaultButtonLarge
                label="Results Page"
                onClickHandler={() => {
                  history.push(
                    `${url}/results?classroom_id=${classroomsTabData[activeTabIndex]?.id}`
                  );
                }}
              />
            </div>
          </div>
          <div className="p-2">
            <div
              className="bg-white w-full shadow rounded-xl"
              style={{
                "--tw-shadow":
                  "0 -1px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 3px 2px rgba(0, 0, 0, 0.05)",
              }}
            >
              <div className="px-5 py-2">
                <h2 className="form_title">Assign Modules</h2>
                <AssignModulesForm
                  classroomId={classroomsTabData[activeTabIndex]?.id}
                  activeTabIndex={activeTabIndex}
                />
              </div>
            </div>
          </div>
          <div className="px-5 py-10">
            <h2 className="form_title mb-5">Module Assignments</h2>
            {isLoadingModuleSessionsList ? (
              <div className="flex flex-col justify-center items-center h-32">
                <ClipLoader size={32} color={"#3e8ede"} />
                <p className="mt-2 font-bold font-muli text-blue-theme">
                  Fetching Module Assignments...
                </p>
              </div>
            ) : ModuleSessionsListData?.length > 0 ? (
              // If ModuleSessionsListData exists, map over it and render the component
              !isLoadingModuleSessionsList &&
              ModuleSessionsListData.map((sessionData) => {
                return (
                  <ListModuleAssignments moduleSessionData={sessionData} />
                );
              })
            ) : (
              <div className="text-center p-10 text-base">
                No module assignments available yet
              </div>
            )}
          </div>
        </>
      )}
    </Layout>
  );
}

// this function is used to get the content of existing data(if any) into html that is according to rich text box
export const handleReplace = (textToBeReplace) => {
  const replacedText = textToBeReplace.replace(
    /<\/?(b|i|u|strike)>/gi,
    (match) => {
      switch (match.toLowerCase()) {
        case "<b>":
          return "<strong>";
        case "</b>":
          return "</strong>";
        case "<i>":
          return "<em>";
        case "</i>":
          return "</em>";
        case "<u>":
          return "<ins>";
        case "</u>":
          return "</ins>";
        case "<strike>":
          return "<del>";
        case "</strike>":
          return "</del>";
        default:
          return match;
      }
    }
  );
  return replacedText;
};

// function - to create File Object
export function createImageFileObjectHook(imageFile, title) {
  const name = title;
  const type = "image/png";
  const blob = new Blob([imageFile], { type });
  return new File([blob], name, { type });
}

// function to get the html content
export function getTheInnerHTMLHook(html) {
  const text = html
    .replace(/[\u200B-\u200D\uFEFF]/g, "")
    .replace(/&[#0]*65279;/g, "") // remove U+FEFF character
    .replace(/&nbsp;+/g, " ") // replace &nbsp; with regular spaces
    .trim() // remove leading and trailing whitespaces
    .replace(/<[^>]*>/g, ""); // remove HTML tags
  return text;
}

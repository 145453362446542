import React from "react";
import PageResource from "../ResourceTypes/PageResource";
import CanvasResource from "../ResourceTypes/CanvasResource";
import PollsResource from "../ResourceTypes/PollsResource";
import ViewResultsSubComponent from "../ResourceTypes/ViewResultsSubComponent";
import { ClipLoader } from "react-spinners";

const ResourceComponets = ({
  resource,
  canvasRef,
  selectedCheckboxes,
  setSelectedCheckboxes,
  selectRadio,
  setSelectRadio,
  setSelectedPollId,
  userRole,
  setFormTextData,
  isLoadingViewResourceNode,
}) => {
  return (
    <>
      {isLoadingViewResourceNode ? (
        <div className="flex flex-col justify-center items-center mt-8">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="m-auto mt-2 font-bold font-muli text-blue-theme">
            Fetching Pages...
          </p>
        </div>
      ) : (
        <>
          {resource?.resource_type === "page" ? (
            <PageResource
              htmlContent={resource.html_content}
              discussions={resource.discussions}
              setFormTextData={setFormTextData}
            />
          ) : resource?.resource_type === "canvas" ? (
            <CanvasResource
              htmlContent={resource.html_content}
              media={resource.media}
              canvasRef={canvasRef}
              discussions={resource.discussions}
              setFormTextData={setFormTextData}
            />
          ) : resource?.resource_type === "results" ? (
            userRole === "admin" ? (
              <p className="text-center text-base font-muli text-blue-theme pt-2 mb-0">
                Results can only be seen for particular classrooms
              </p>
            ) : userRole === "Teacher" ? (
              <p className="text-center text-base font-muli text-blue-theme pt-2 mb-0">
                Please check results for each classroom separately on the result
                page from manage sessions
              </p>
            ) : userRole === "Learner" ? (
              <ViewResultsSubComponent />
            ) : null
          ) : resource?.resource_type === "poll" ? (
            <PollsResource
              htmlContent={resource.html_content}
              polls={resource.polls}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              selectRadio={selectRadio}
              setSelectRadio={setSelectRadio}
              setSelectedPollId={setSelectedPollId}
              discussions={resource.discussions}
              setFormTextData={setFormTextData}
            />
          ) : (
            <p className="text-center text-base font-muli text-blue-theme pt-2 mb-0">
              Please submit previous page
            </p>
          )}
        </>
      )}
    </>
  );
};

export default ResourceComponets;

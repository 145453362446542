import { call, put, takeLatest } from "redux-saga/effects";
import {
  GROUP_COURSE_MODULE_FAILURE,
  GROUP_COURSE_MODULE_LIST_FAILURE,
  GROUP_COURSE_MODULE_LIST_REQUESTED,
  GROUP_COURSE_MODULE_LIST_SUCCESS,
  GROUP_COURSE_MODULE_REQUESTED,
  GROUP_COURSE_MODULE_SUCCESS,
} from "../actions/group-course-module";
import {
  groupCourseModuleListRequest,
  groupCourseModuleRequest,
} from "../apis/group-course-module";

export function* fetchGroupCourseModuleWatcher() {
  yield takeLatest(GROUP_COURSE_MODULE_REQUESTED, fetchGroupCourseModuleWorker);
}

function* fetchGroupCourseModuleWorker() {
  const fetchViewModalRequestResponse = yield call(groupCourseModuleRequest);
  if (fetchViewModalRequestResponse.status) {
    yield put({
      type: GROUP_COURSE_MODULE_SUCCESS,
      data: fetchViewModalRequestResponse.data,
    });
  } else {
    const errorMessage =
      fetchViewModalRequestResponse?.data?.message || "Something went wrong!";

    yield put({
      type: GROUP_COURSE_MODULE_FAILURE,
      errorMessage,
    });
  }
}

export function* fetchCourseModuleListWatcher() {
  yield takeLatest(
    GROUP_COURSE_MODULE_LIST_REQUESTED,
    fetchCourseModuleListWorker
  );
}

function* fetchCourseModuleListWorker({ payload }) {
  const fetchViewModalRequestResponse = yield call(
    groupCourseModuleListRequest,
    payload
  );
  if (fetchViewModalRequestResponse.status) {
    yield put({
      type: GROUP_COURSE_MODULE_LIST_SUCCESS,
      data: fetchViewModalRequestResponse.data,
    });
  } else {
    const errorMessage =
      fetchViewModalRequestResponse?.data?.message || "Something went wrong!";

    yield put({
      type: GROUP_COURSE_MODULE_LIST_FAILURE,
      errorMessage,
    });
  }
}

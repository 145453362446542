import { takeLatest, call, put } from "redux-saga/effects";
import {
  START_ADMINISTRATORS_LIST_ACTION,
  SUCCESS_ADMINISTRATORS_LIST_ACTION,
  ERROR_ADMINISTRATORS_LIST_ACTION,
  START_CREATE_ADMINISTRATOR_ACTION,
  SUCCESS_CREATE_ADMINISTRATOR_ACTION,
  FAILURE_CREATE_ADMINISTRATOR_ACTION,
} from "../actions/administrators";
import {
  fetchAdministratorsListAction,
  fetchCreateAdministratorRequest,
} from "../apis/administrators";
import { toast } from "react-toastify";

export function* fetchAdministratorsListActionWatcher() {
  yield takeLatest(
    START_ADMINISTRATORS_LIST_ACTION,
    fetchAdministratorsListActionWorker
  );
}

function* fetchAdministratorsListActionWorker({ payload }) {
  const fetchAdministratorsListActionResponse = yield call(
    fetchAdministratorsListAction,
    payload
  );
  if (fetchAdministratorsListActionResponse.status) {
    yield put({
      type: SUCCESS_ADMINISTRATORS_LIST_ACTION,
      data: fetchAdministratorsListActionResponse.data.administrators,
      meta: fetchAdministratorsListActionResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_ADMINISTRATORS_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchAdministratorCreateRequestWatcher() {
  yield takeLatest(
    START_CREATE_ADMINISTRATOR_ACTION,
    fetchAdministratorCreateWorker
  );
}

function* fetchAdministratorCreateWorker({ payload }) {
  const { createAdministratorData } = payload;
  const fetchAdministratorCreateResponse = yield call(
    fetchCreateAdministratorRequest,
    createAdministratorData
  );
  if (fetchAdministratorCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_ADMINISTRATOR_ACTION,
      data: fetchAdministratorCreateResponse.data.user,
    });
    yield call(fetchAdministratorsListActionWorker, { payload: null });
    toast.success("Administrator Created Successfully");
  } else {
    const errorMessage = fetchAdministratorCreateResponse?.data?.message
      ? fetchAdministratorCreateResponse.data.message
      : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_ADMINISTRATOR_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

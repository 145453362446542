import React from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useDispatch } from "react-redux";
import { START_UPDATE_SCHOOLS_ACTION } from "../../../rest-client/actions/schools";

export default function EditSchoolForm({
  editableObjectData,
  setIsOpenEditFormModal,
  currentPage,
}) {
  const schoolData = editableObjectData;
  const dispatch = useDispatch();

  const initialFormValues = {
    school_name: schoolData?.name ? schoolData.name : "",
    address: {
      street1_address: schoolData?.address?.street1
        ? schoolData.address.street1
        : "",
      city_address: schoolData?.address?.city ? schoolData?.address?.city : "",
      state_address: schoolData?.address?.state
        ? schoolData?.address?.state
        : "",
      country_address: schoolData?.address?.country
        ? schoolData?.address?.country
        : "",
      zip_address: schoolData?.address?.zip ? schoolData?.address?.zip : "",
    },
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.school_name) {
      error.school_name = "Please enter School Name";
    }
    if (!values.address.city_address) {
      error.address = {
        ...error.address,
        city_address: "Please Enter City",
      };
    }
    if (!values.address.state_address) {
      error.address = {
        ...error.address,
        state_address: "Please Enter State",
      };
    }
    if (!values.address.zip_address) {
      error.address = {
        ...error.address,
        zip_address: "Please Enter Zip",
      };
    }
    if (!values.address.country_address) {
      error.address = {
        ...error.address,
        country_address: "Please Enter Country",
      };
    }
    if (!values.address.street1_address) {
      error.address = {
        ...error.address,
        street1_address: "Please Enter Address",
      };
    }
    return error;
  }, []);

  const updateSchoolHandler = React.useCallback(
    (values, { resetForm }) => {
      const updateSchoolData = {
        school: {
          ...schoolData,
          name: values.school_name.trimStart().trimEnd(),
          address: {
            ...schoolData.address,
            street1: values.address.street1_address.trimStart().trimEnd(),
            city: values.address.city_address.trimStart().trimEnd(),
            state: values.address.state_address.trimStart().trimEnd(),
            zip: values.address.zip_address.trimStart().trimEnd(),
            country: values.address.country_address.trimStart().trimEnd(),
          },
        },
      };
      dispatch({
        type: START_UPDATE_SCHOOLS_ACTION,
        payload: { updateSchoolData, setIsOpenEditFormModal, currentPage },
      });
      resetForm();
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={updateSchoolHandler}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form className="font-lato">
          <div className="mt-12">
            <div className="flex flex-row box-border">
              <div className="flex-1 w-30">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="school_name"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.school_name && touched.school_name,
                    })}
                    value={values.school_name}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "school_name",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={70}
                    required
                  />
                  <label
                    htmlFor="school_name"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.school_name && touched.school_name,
                    })}
                  >
                    Name
                  </label>
                  {errors.school_name && touched.school_name && (
                    <p className="error-label-class mt-25">
                      {errors.school_name ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-70 ml-10">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="street1_address"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.address?.street1_address &&
                        touched?.street1_address,
                    })}
                    value={values.address.street1_address}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        `address.street1_address`,
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="street1_address"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.address?.street1_address &&
                        touched?.street1_address,
                    })}
                  >
                    Address
                  </label>
                  {errors?.address?.street1_address &&
                    touched?.street1_address && (
                      <p className="error-label-class mt-25">
                        {errors?.address?.street1_address ?? "-"}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="flex flex-row box-border">
              <div className="flex-1 w-30">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="city_address"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.address?.city_address && touched?.city_address,
                    })}
                    value={values.address.city_address}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        `address.city_address`,
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={20}
                    required
                  />
                  <label
                    htmlFor="city_address"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.address?.city_address && touched?.city_address,
                    })}
                  >
                    City
                  </label>
                  {errors?.address?.city_address && touched?.city_address && (
                    <p className="error-label-class mt-25">
                      {errors?.address?.city_address ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-70 ml-10">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="state_address"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.address?.state_address && touched?.state_address,
                    })}
                    value={values.address.state_address}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        `address.state_address`,
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={20}
                    required
                  />
                  <label
                    htmlFor="state_address"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.address?.state_address && touched?.state_address,
                    })}
                  >
                    State
                  </label>
                  {errors?.address?.state_address && touched?.state_address && (
                    <p className="error-label-class mt-25">
                      {errors?.address?.state_address ?? "-"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row box-border">
              <div className="flex-1 w-30">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="zip_address"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.address?.zip_address && touched?.zip_address,
                    })}
                    value={values.address.zip_address}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        `address.zip_address`,
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={8}
                    required
                  />
                  <label
                    htmlFor="zip_address"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.address?.zip_address && touched?.zip_address,
                    })}
                  >
                    Zip Code
                  </label>
                  {errors?.address?.zip_address && touched?.zip_address && (
                    <p className="error-label-class mt-25">
                      {errors?.address?.zip_address ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-70 ml-10">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="country_address"
                    className={classNames("input-class", {
                      "error-input-class":
                        errors.address?.country_address &&
                        touched?.country_address,
                    })}
                    value={values.address.country_address}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        `address.country_address`,
                        e.target.value
                      );
                    }}
                    maxLength={20}
                    required
                  />
                  <label
                    htmlFor="country_address"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.address?.country_address &&
                        touched?.country_address,
                    })}
                  >
                    Country
                  </label>
                  {errors?.address?.country_address &&
                    touched?.country_address && (
                      <p className="error-label-class mt-25">
                        {errors?.address?.country_address ?? "-"}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end ml-10">
            <DefaultButton label="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

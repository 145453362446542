import React from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import BarChartAnswerForm from "./BarChartAnswerForm";
import { FieldArray } from "formik";
import PieChartAnswerForm from "./PieChartAnswerForm";
import PieChartQuetionForm from "./PieChartQuetionForm";
import BarChartQuetionForm from "./BarChartQuetionForm";
import classNames from "classnames";
import { handleReplace } from "../../../commonHook";
import { ColorPic } from "../Common/ColorPic";
import "../../../styles/fontSizer.scss";
import "../../../styles/fontFamily.scss";
import { uploadImageCallback } from "../Common/ImageUpload";
import "../../../styles/pollResourcesRichText.scss";

export default function PollTypeResourcesForm({
  setFieldValue,
  values,
  check,
  resourceByIdData,
}) {
  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = React.useState(
    values.poll_type_resource_value
      ? prepareDraft(values.poll_type_resource_value)
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue("poll_type_resource_value", forFormik);
    setEditorState(editorState);
  };

  return (
    <div className="">
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="py-4 m4"
        editorClassName="p-4 border border-solid text-box-border mt-4 h-auto min-hieght-300 custom-html-content-class"
        toolbarClassName="border-0-imp padding-0-imp"
        toolbar={{
          options: [
            "inline",
            "list",
            "history",
            "textAlign",
            "image",
            "colorPicker",
            "fontSize",
            "fontFamily",
            "link",
          ],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline", "strikethrough"],
            bold: {
              className: "m-0-imp border-right-0-imp text-box-border",
            },
            italic: {
              className: "m-0-imp text-box-border",
            },
            underline: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            strikethrough: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          list: {
            options: ["unordered", "ordered", "indent", "outdent"],
            unordered: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            ordered: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            indent: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            outdent: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          fontSize: {
            className: "fontSizer m-0-imp border-right-0-imp text-box-border",
          },
          fontFamily: {
            className: "m-0-imp text-box-border",
            options: [
              "Lato",
              "Gloria Hallelujah",
              "Cedarville Cursive",
              "Rock Salt",
              "Swanky and Moo Moo",
            ],
          },
          history: {
            options: ["undo", "redo"],
            undo: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            redo: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          colorPicker: {
            className: "m-0-imp border-left-0-imp text-box-border",
            component: ColorPic,
          },
          textAlign: {
            options: ["left", "center", "right", "justify"],
            left: {
              className:
                "mx-left-6px-imp m-0-imp border-right-0-imp text-box-border",
            },
            center: { className: "m-0-imp border-right-0-imp text-box-border" },
            right: { className: "m-0-imp border-right-0-imptext-box-border" },
            justify: { className: "m-0-imp text-box-border" },
          },
          image: {
            className:
              "mx-left-6px-imp mr-0px-imp border-right-0-imp text-box-border",
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: uploadImageCallback,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "100%",
            },
          },
          link: {
            options: ["link"],
            link: {
              className:
                "m-0-imp border-left-0-imp text-box-border custom-modal-link-class",
            },
          },
        }}
      />
      {(check && resourceByIdData.polls.length > 0) || !check ? (
        <>
          <BarChartQuetionForm
            value={values.bar_chart_que_value}
            setFieldValue={(val) => setFieldValue("bar_chart_que_value", val)}
          />
          <div className="w-full">
            <FieldArray
              name="bar_chart_answers"
              render={(arrayHelpers) => {
                const bar_chart_answers = values.bar_chart_answers;
                return (
                  <>
                    {bar_chart_answers.map((module_category, index) => (
                      <div
                        key={index}
                        className={classNames("", {
                          "flex justify-between": index >= 2,
                        })}
                      >
                        <BarChartAnswerForm
                          value={
                            bar_chart_answers[index]?.text
                              ? handleReplace(bar_chart_answers[index].text)
                              : values?.bar_chart_answers[index]?.text
                          }
                          setFieldValue={(val) =>
                            setFieldValue(
                              `bar_chart_answers[${index}].text`,
                              val
                            )
                          }
                        />
                        {index >= 2 && (
                          <button
                            className="material-symbols-outlined cursor-pointer ease-in-out border-none bg-transparent text-light-gray text-2xl mt-10"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete"
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            delete_forever
                          </button>
                        )}
                      </div>
                    ))}
                  </>
                );
              }}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="ml-0 textrich-button select-none outline-none px-2 py-0 bg-none font-medium text-sm cursor-pointer opacity-100"
              onClick={() => {
                setFieldValue("bar_chart_answers", [
                  ...values.bar_chart_answers,
                  {
                    text: "",
                  },
                ]);
              }}
            >
              Add Answer
            </button>
          </div>
          <PieChartQuetionForm
            value={values.pie_chart_que_value}
            setFieldValue={(val) => setFieldValue("pie_chart_que_value", val)}
          />
          <div className="w-full">
            <FieldArray
              name="pie_chart_answers"
              render={(arrayHelpers) => {
                const pie_chart_answers = values.pie_chart_answers;
                return (
                  <>
                    {pie_chart_answers.map((module_category, index) => (
                      <div
                        key={index}
                        className={classNames("", {
                          "flex justify-between": index >= 2,
                        })}
                      >
                        <PieChartAnswerForm
                          value={
                            pie_chart_answers[index]?.text
                              ? handleReplace(pie_chart_answers[index].text)
                              : values?.pie_chart_answers[index]?.text
                          }
                          setFieldValue={(val) =>
                            setFieldValue(
                              `pie_chart_answers[${index}].text`,
                              val
                            )
                          }
                        />
                        {index >= 2 && (
                          <button
                            className="material-symbols-outlined cursor-pointer ease-in-out border-none bg-transparent text-light-gray text-2xl mt-10"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            delete_forever
                          </button>
                        )}
                      </div>
                    ))}
                  </>
                );
              }}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="ml-0 textrich-button select-none outline-none px-2 py-0 bg-none font-medium text-sm cursor-pointer opacity-100"
              onClick={() => {
                setFieldValue("pie_chart_answers", [
                  ...values.pie_chart_answers,
                  {
                    text: "",
                  },
                ]);
              }}
            >
              Add Answer
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
}

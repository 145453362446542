import {
  GROUP_COURSE_MODULE_FAILURE,
  GROUP_COURSE_MODULE_LIST_FAILURE,
  GROUP_COURSE_MODULE_LIST_REQUESTED,
  GROUP_COURSE_MODULE_LIST_SUCCESS,
  GROUP_COURSE_MODULE_REQUESTED,
  GROUP_COURSE_MODULE_SUCCESS,
} from "../actions/group-course-module";

const initState = {
  group_course_module_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  group_course_module_list_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
};

export const groupCourseModuleReducer = (prevState = initState, action) => {
  switch (action.type) {
    case GROUP_COURSE_MODULE_REQUESTED:
      return {
        ...prevState,
        group_course_module_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case GROUP_COURSE_MODULE_SUCCESS:
      return {
        ...prevState,
        group_course_module_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case GROUP_COURSE_MODULE_FAILURE:
      return {
        ...prevState,
        group_course_module_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };
    case GROUP_COURSE_MODULE_LIST_REQUESTED:
      return {
        ...prevState,
        group_course_module_list_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case GROUP_COURSE_MODULE_LIST_SUCCESS:
      return {
        ...prevState,
        group_course_module_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case GROUP_COURSE_MODULE_LIST_FAILURE:
      return {
        ...prevState,
        group_course_module_list_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };
    default:
      return prevState;
  }
};

import React, { useEffect, useState, useCallback, useRef } from "react";
import { START_SCHOOLS_LIST_ACTION } from "../../../rest-client/actions/schools";
import { useDispatch } from "react-redux";
import { useSchools } from "../../../rest-client/selectorHook";
import AddSchoolForm from "./AddSchoolForm";
import ListSchools from "./ListSchools";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";

export default function SchoolPage() {
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isVisible, setVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const inputRef = useRef(null);

  const dispatchStartSchoolsList = useCallback(() => {
    if (searchInput.length === 0 || searchInput.length >= 3) {
      dispatch({
        type: START_SCHOOLS_LIST_ACTION,
        payload: {
          page: currentPage,
          ...(searchInput && {
            query: searchInput,
          }),
        },
      });
    }
  }, [dispatch, currentPage, searchInput]);

  useEffect(() => {
    dispatchStartSchoolsList();
  }, [dispatchStartSchoolsList]);

  const {
    schools_list_data: {
      isLoading: schoolListLoading,
      data: schoolListData,
      meta: schoolListMeta,
    },
  } = useSchools();

  useEffect(() => {
    setTotalPages(schoolListMeta?.pages);
  }, [schoolListMeta]);

  const onHideShowClick = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    if (
      (schoolListData.length > 0 || searchInput.length > 0) &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [schoolListData, searchInput]);

  return (
    <div className="mx-2 my-5">
      <div
        className="bg-white w-full shadow-md	rounded-xl"
        style={{
          "--tw-shadow":
            "0 -1px 2px 0 rgba(0, 0, 0, 0.05), 0 2px 3px 2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="px-5 py-5">
          <h2 className="form_title">Add School</h2>
          <AddSchoolForm />
        </div>
      </div>
      <div className="px-5 py-10">
        <div className="flex flex-row	box-border items-center">
          <div className="flex-1 w-20 mb-5">
            <h2 className="form_title">School List</h2>
          </div>
          <div className="flex-1 w-80 text-right">
            <div className="py-7 flex items-center justify-end">
              <button
                className="border-none bg-transparent"
                onClick={onHideShowClick}
              >
                <Icon
                  label="search"
                  title="Search"
                  className={classNames("text-light-gray")}
                />
              </button>
              <div>
                <input
                  type="text"
                  id="search"
                  className="search-input text-base"
                  placeholder="Search"
                  style={{ display: isVisible ? "block" : "none" }}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  ref={inputRef}
                />
              </div>
            </div>
          </div>
        </div>
        <ListSchools
          schoolListData={schoolListData}
          schoolListLoading={schoolListLoading}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

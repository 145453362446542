import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_CREATE_GROUP_ACTION } from "../../../rest-client/actions/groups";
import { START_CLASSROOMS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/classrooms";
import { useClassrooms } from "../../../rest-client/selectorHook";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import { ClipLoader } from "react-spinners";

export default function AddGroupForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: START_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  const {
    classrooms_non_paginated_list_data: {
      isLoading: classroomListLoading,
      data: classroomListData,
    },
  } = useClassrooms();

  const initialFormValues = {
    title: "",
    description: "",
    classroom: "",
    username: "",
    password: "",
    confirm_password: "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter Title";
    }
    if (!values.description) {
      error.description = "Please enter Description";
    }
    if (!values.classroom) {
      error.classroom = "Please select Classroom";
    }
    if (!values.username) {
      error.username = "Please enter Username";
    } else if (!/^[a-z0-9]+$/.test(values.username)) {
      error.username = "Only lowercase letters and numbers are allowed";
    }
    if (!values.password) {
      error.password = "Please enter Password";
    }
    if (!values.confirm_password) {
      error.confirm_password = "Please enter Confirm Passsword";
    } else if (values.confirm_password !== values.password) {
      error.confirm_password = "Passwords do not match";
    }
    return error;
  }, []);

  const createGroupHandler = React.useCallback(
    (values, { resetForm }) => {
      const createGroupData = {
        group: {
          title: values.title.trimStart().trimEnd(),
          description: values.description.trimStart().trimEnd(),
          classroom_id: values.classroom?.value,
          confirmpassword: values.confirm_password,
          student: {
            username: values.username,
            password: values.password,
          },
        },
      };
      dispatch({
        type: START_CREATE_GROUP_ACTION,
        payload: { createGroupData, history },
      });
      resetForm();
    },
    [dispatch]
  );

  const classroomOptions = classroomListData.map((classroom, index) => {
    return {
      label: classroom?.title,
      value: classroom?.id,
    };
  });

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={createGroupHandler}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="font-lato">
          <p className="font-bold opacity-50">
            (*Add only five groups per classroom)
          </p>
          <div className="flex flex-col md:flex-row box-border">
            <div className="flex-1 w-30">
              <div className="field-holder !mb-1">
                <Field
                  type="text"
                  id="title"
                  className={classNames("input-class", {
                    "error-input-class": errors.title && touched.title,
                  })}
                  value={values.title}
                  onChange={(e) => {
                    setFieldDataHandler(setFieldValue, "title", e.target.value);
                  }}
                  maxLength={50}
                  tabIndex="1"
                  required
                />
                <label
                  htmlFor="title"
                  className={classNames("label-class", {
                    "error-label-class": errors.title && touched.title,
                  })}
                >
                  Title *
                </label>
                {errors.title && touched.title && (
                  <p className="error-label-class mt-25">
                    {errors.title ?? "-"}
                  </p>
                )}
              </div>
              <div className="mb-12">
                <Field name="classroom">
                  {({ field, form, meta }) => (
                    <SingleSelect
                      field={field}
                      form={form}
                      meta={meta}
                      options={classroomOptions}
                      placeholder="Classroom*"
                      isLoading={classroomListLoading}
                      tabIndex="3"
                      loadingMessage={() => (
                        <ClipLoader size={32} color={"#3e8ede"} />
                      )}
                    />
                  )}
                </Field>
              </div>
              <div className="field-holder">
                <Field
                  type="password"
                  id="password"
                  className={classNames("input-class", {
                    "error-input-class": errors.password && touched.password,
                  })}
                  value={values.password}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "password",
                      e.target.value.trim()
                    );
                  }}
                  maxLength={20}
                  tabIndex="5"
                  required
                />
                <label
                  htmlFor="password"
                  className={classNames("label-class", {
                    "error-label-class": errors.password && touched.password,
                  })}
                >
                  Password *
                </label>
                {errors.password && touched.password && (
                  <p className="error-label-class mt-25">
                    {errors.password ?? "-"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex-1 w-full md:w-70 md:mx-10">
              <div className="field-holder">
                <Field
                  type="text"
                  id="description"
                  className={classNames("input-class", {
                    "error-input-class":
                      errors.description && touched.description,
                  })}
                  value={values.description}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "description",
                      e.target.value
                    );
                  }}
                  maxLength={50}
                  tabIndex="2"
                  required
                />
                <label
                  htmlFor="description"
                  className={classNames("label-class", {
                    "error-label-class":
                      errors.description && touched.description,
                  })}
                >
                  Description *
                </label>
                {errors.description && touched.description && (
                  <p className="error-label-class mt-25">
                    {errors.description ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="text"
                  id="username"
                  className={classNames("input-class", {
                    "error-input-class": errors.username && touched.username,
                  })}
                  value={values.username}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "username",
                      e.target.value.trim()
                    );
                  }}
                  maxLength={20}
                  tabIndex="4"
                  required
                />
                <label
                  htmlFor="username"
                  className={classNames("label-class", {
                    "error-label-class": errors.username && touched.username,
                  })}
                >
                  Username *
                </label>
                {errors.username && touched.username && (
                  <p className="error-label-class mt-25">
                    {errors.username ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="password"
                  id="confirm_password"
                  className={classNames("input-class", {
                    "error-input-class":
                      errors.confirm_password && touched.confirm_password,
                  })}
                  value={values.confirm_password}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "confirm_password",
                      e.target.value.trim()
                    );
                  }}
                  maxLength={20}
                  tabIndex="6"
                  required
                />
                <label
                  htmlFor="confirm_password"
                  className={classNames("label-class", {
                    "error-label-class":
                      errors.confirm_password && touched.confirm_password,
                  })}
                >
                  Confirm Password *
                </label>
                {errors.confirm_password && touched.confirm_password && (
                  <p className="error-label-class mt-25">
                    {errors.confirm_password ?? "-"}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5	md:justify-end md:mx-10 md:mt-0">
            <DefaultButton label="Create" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

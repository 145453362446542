import React from "react";
import { SketchPicker } from "react-color";
import Icon from "../../Button/Icon";

export function ColorPic({
  expanded,
  onExpandEvent,
  onChange,
  currentState,
  doExpand,
  doCollapse,
}) {
  const handleChangeComplete = (color) => {
    onChange("color", color.hex);
    doExpand();
  };

  const handleClose = () => {
    doCollapse();
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const { color } = currentState;

  const popover = {
    position: "absolute",
    zIndex: "2",
    marginTop: "20px",
    marginLeft: "-40px",
  };

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const renderModal = () => {
    return (
      <div onClick={stopPropagation}>
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
        </div>
      </div>
    );
  };

  return (
    <div
      // className={classNames("demo-color-wrapper")}
      className="rdw-colorpicker-wrapper"
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
    >
      <div
        onClick={onExpandEvent}
        className="rdw-option-wrapper m-0-imp text-box-border "
      >
        <Icon label="format_color_text" title="color-picker" />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  );
}

import {
  START_CONTACT_US_ACTION,
  ERROR_CONTACT_US_ACTION,
  SUCCESS_CONTACT_US_ACTION,
} from "../actions/contact-us";

const initialState = {
  contact_us_action: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export default function contactUs(prevState = initialState, action) {
  switch (action.type) {
    case START_CONTACT_US_ACTION: {
      return {
        ...prevState,
        contact_us_action: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }
    case SUCCESS_CONTACT_US_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        contact_us_action: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }
    case ERROR_CONTACT_US_ACTION: {
      return {
        ...prevState,
        contact_us_action: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }

    default:
      return prevState;
  }
}

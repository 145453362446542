import React from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ColorPic } from "./Common/ColorPic";
import "../../styles/fontSizer.scss";
import "../../styles/fontFamily.scss";
import "../../styles/discussionRichText.scss"

export default function DiscussionPormptForm({ value, setFieldValue }) {
  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [discussionPromptEditorState, setDiscussionPromptEditorState] =
    React.useState(value ? prepareDraft(value) : EditorState.createEmpty());

  const onEditorStateChange = (discussionPromptEditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(discussionPromptEditorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setDiscussionPromptEditorState(discussionPromptEditorState);
  };

  return (
    <>
      <div className="w-full">
        <Editor
          editorState={discussionPromptEditorState}
          onEditorStateChange={onEditorStateChange}
          placeholder="Discussion Prompt"
          wrapperClassName="py-4 w-full"
          editorClassName="p-4 border border-solid text-box-border mt-4 h-auto min-hieght-300 custom-html-content-class"
          toolbarClassName="outline-none border-0-imp padding-0-imp"
          toolbar={{
            options: [
              "inline",
              "list",
              "history",
              "colorPicker",
              "fontSize",
              "fontFamily",
            ],
            inline: {
              inDropdown: false,
              // className:
              //   "border border-solid text-box-border",
              options: ["bold", "italic", "underline", "strikethrough"],
              bold: {
                className: "m-0-imp border-right-0-imp text-box-border",
              },
              italic: {
                className: "m-0-imp text-box-border",
              },
              underline: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              strikethrough: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
            },
            list: {
              options: ["unordered", "ordered"],
              unordered: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              ordered: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
            },
            fontSize: {
              className:
                "font-sizer mr-0px-imp border-right-0-imp text-box-border",
            },
            fontFamily: {
              className: "m-0-imp text-box-border",
              options: [
                "Lato",
                "Gloria Hallelujah",
                "Cedarville Cursive",
                "Rock Salt",
                "Swanky and Moo Moo",
              ],
            },
            history: {
              options: ["undo", "redo"],
              undo: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              redo: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
            },
            colorPicker: {
              component: ColorPic,
              className: "m-0-imp border-left-0-imp text-box-border",
              // colors: [
              //   "rgb(97,189,109)",
              //   "rgb(26,188,156)",
              //   "rgb(84,172,210)",
              //   "rgb(44,130,201)",
              //   "rgb(147,101,184)",
              //   "rgb(71,85,119)",
              //   "rgb(204,204,204)",
              //   "rgb(65,168,95)",
              //   "rgb(0,168,133)",
              //   "rgb(61,142,185)",
              //   "rgb(41,105,176)",
              //   "rgb(85,57,130)",
              //   "rgb(40,50,78)",
              //   "rgb(0,0,0)",
              //   "rgb(247,218,100)",
              //   "rgb(251,160,38)",
              //   "rgb(235,107,86)",
              //   "rgb(226,80,65)",
              //   "rgb(163,143,132)",
              //   "rgb(239,239,239)",
              //   "rgb(255,255,255)",
              //   "rgb(250,197,28)",
              //   "rgb(243,121,52)",
              //   "rgb(209,72,65)",
              //   "rgb(184,49,47)",
              //   "rgb(124,112,107)",
              //   "rgb(209,213,216)",
              // ],
            },
          }}
        />
      </div>
    </>
  );
}

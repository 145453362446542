import React from "react";
import ButtonWithIcon from "../../components/Button/ButtonWithIcon";
import "../../styles/landingPageHeader.scss";
import "../../styles/landingPage.scss";
import bioScannLogo from "../../images/img_bioscannlogo.svg";
import Footer from "../../components/Footer/Footer";
import ContactUsForm from "../../components/Contact/ContactUsForm";
import { useContact } from "../../rest-client/selectorHook";
import LoginModal from "./LoginModal";

export default function LandingPage() {
  const {
    contact_us_action: { data: contactUsData },
  } = useContact();
  const [isOpenLoginModal, setIsOpenLoginModal] = React.useState(false);
  const openLoginModalHandler = React.useCallback(() => {
    setIsOpenLoginModal(true);
  }, []);
  return (
    <>
      {isOpenLoginModal && (
        <LoginModal
          isOpenLoginModal={isOpenLoginModal}
          setIsOpenLoginModal={setIsOpenLoginModal}
        />
      )}
      <div className="">
        <header className="landing-page-header">
          <div className="nav-bar nav-bar-container">
            <img src={bioScannLogo} alt="" />
            <span className="flex-span"></span>
            <div>
              <ButtonWithIcon openLoginModalHandler={openLoginModalHandler} />
            </div>
          </div>
        </header>
        <div className="polygon-bg-style hero-img pt-20">
          <div className="landing-page-container">
            <div className="m-2 flex flex-row box-border font-lato">
              <div className="landing-page-sub-container">
                <h1 className="bio-text text-center font-light text-white">
                  Bioscience <span className="font-bold">Career</span>{" "}
                  <span className="font-bold">Resources</span> For Students and
                  Teachers
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="landing-page-container mb-30">
          <div className="m-2 flex flex-row box-border font-lato mt-10">
            <div className="m-2 flex-span box-border letter-spacing-005">
              <h2 className="md-title mt-0 text-xl font-bold">
                In Active Development and Prototyping
              </h2>
              <p>
                <span className="font-bold">BioSCANN</span> is being developed
                by{" "}
                <span className="font-bold">
                  Tufts University School of Medicine
                </span>{" "}
                for high school teachers and students to create, carry out and
                manage career focused problem-based learning and scientific
                inquiry. In groups, students take on the role of a STEMM
                professional, analyze role-specific data, and use it to make a
                group decision. BioScann is currently undergoing pilot testing
                and prototyping.
              </p>
            </div>
          </div>
        </div>
        <div className="landing-page-sub-container">
          <div className="m-2 flex flex-row box-border">
            <div className="m-2 min-w-0 flex-span box-border">
              <div className="m-2 mb-50 layout-margin divider-clr-section border-t"></div>
            </div>
          </div>
        </div>
        <div className="landing-page-container">
          <div className="pb-10 bioscannbluetheme-img">
            <div className="m-2 flex flex-row box-border">
              <div className="form-cont-left m-2 max-h-full box-border bg-white form-shadow">
                <div className="p-10">
                  {contactUsData?.id ? (
                    <div className="font-lato">
                      <p>Thank you for getting in touch!</p>
                      <p>
                        We appreciate you contacting us about BioSCANN. We will
                        reply by email as soon as possible.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h2 className="md-title text-xl mb-10 letter-spacing-005 font-lato font-medium">
                        Interested in learning more?
                      </h2>
                      <ContactUsForm />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

import { combineReducers } from "redux";
import contactUs from "./contact-us";
import { loginReducer } from "./login";
import { moduleSetsReducer } from "./module-sets";
import { moduleDocumentsReducer } from "./module-documents";
import { resourcesReducer } from "./resources";
import { viewModuleReducer } from "./view-module";
import { viewResourceNodeReducer } from "./view-resource-node";
import { schoolsReducer } from "./schools";
import { teachersReducer } from "./teachers";
import { groupsReducer } from "./groups";
import { classroomsReducer } from "./classrooms";
import { administratorsReducer } from "./administrators";
import { moduleSessionsReducer } from "./module-sessions";
import { groupCourseModuleReducer } from "./group-course-module";

const rootReducer = combineReducers({
  contactUs,
  loginReducer,
  moduleSetsReducer,
  moduleDocumentsReducer,
  resourcesReducer,
  viewModuleReducer,
  viewResourceNodeReducer,
  schoolsReducer,
  teachersReducer,
  groupsReducer,
  classroomsReducer,
  administratorsReducer,
  moduleSessionsReducer,
  groupCourseModuleReducer,
});

export default rootReducer;

import React, { useMemo,  useState } from "react";
import { useTable, useExpanded } from "react-table";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  START_MODULE_SESSION_COMPLETE_ACTION,
  START_DELETE_MODULE_SESSION_ACTION,
} from "../../../rest-client/actions/module-sessions";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import CompleteModuleSessionModal from "../../../components/Modal/CompleteModuleSessionModal";

export default function ListModuleAssignments({ moduleSessionData }) {
  const dispatch = useDispatch();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenConfirmSessionModal, setIsOpenConfirmSessionModal] =
    useState(false);
  const [deletableModuleSessionData, setDeletableModuleSessionData] = useState(
    {}
  );
  const [completableModuleSessionData, setCompletableModuleSessionData] =
    useState({});

  // This is to set dynamic header names according to categories set for each module set
  const dynamicHeaders = useMemo(
    () => [
      {
        Header: "Name",
        accessor: () => moduleSessionData?.module_set?.title,
      },
      ...(moduleSessionData?.categories || []).map((category) => ({
        Header: category.slug.charAt(0).toUpperCase() + category.slug.slice(1),
        accessor: (rowData) =>
          rowData.categories.find((c) => c.slug === category.slug)?.value ?? "",
      })),
    ],
    [moduleSessionData]
  );
  const columns = useMemo(() => [...dynamicHeaders], [dynamicHeaders]);
  //Here useMemo is used to avoid infinite loop error for moduleSessionData
  const tableInstance = useTable(
    useMemo(
      () => ({ columns, data: [moduleSessionData] }),
      [moduleSessionData, columns]
    ),
    useExpanded
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const completeClickHandler = React.useCallback(() => {
    const completeModuleSessionData = {
      module_session: {
        id: completableModuleSessionData?.classroom?.id,
        session_id: completableModuleSessionData?.id,
      },
    };
    dispatch({
      type: START_MODULE_SESSION_COMPLETE_ACTION,
      payload: {
        completeModuleSessionData,
        setIsOpenConfirmSessionModal,
      },
    });
  }, [dispatch, completableModuleSessionData]);

  const openConfirmModalHandler = React.useCallback((value) => {
    setIsOpenConfirmModal(true);
    setDeletableModuleSessionData(value);
  }, []);

  const openConfirmSessionModalHandler = React.useCallback((value) => {
    setIsOpenConfirmSessionModal(true);
    setCompletableModuleSessionData(value);
  }, []);

  const deleteModuleSessionHandler = React.useCallback(() => {
    const deleteModuleSessionData = {
      module_session: {
        id: deletableModuleSessionData?.classroom?.id,
        session_id: deletableModuleSessionData?.id,
      },
    };
    dispatch({
      type: START_DELETE_MODULE_SESSION_ACTION,
      payload: { deleteModuleSessionData, setIsOpenConfirmModal },
    });
  }, [dispatch, deletableModuleSessionData]);

  return (
    <>
      {isOpenConfirmModal && (
        <ConfirmModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          deleteRecordHandler={deleteModuleSessionHandler}
        />
      )}
      {isOpenConfirmSessionModal && (
        <CompleteModuleSessionModal
          isOpenConfirmSessionModal={isOpenConfirmSessionModal}
          setIsOpenConfirmSessionModal={setIsOpenConfirmSessionModal}
          completeClickHandler={completeClickHandler}
        />
      )}
      {moduleSessionData && (
        <div className="flex flex-col">
          <div className="inline-block align-middle">
            <div className="overflow-hidden">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200 border-collapse"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <React.Fragment key={headerGroup.getHeaderGroupProps().key}>
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        <th></th>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps()}
                            className="opacity-50 text-left py-2 text-sm"
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    </React.Fragment>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="table-row-group align-middle border-inherit"
                >
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={row.getRowProps().key}>
                        <tr
                          {...row.getRowProps()}
                          className="overflow-x-auto"
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <td className="w-1">
                            <button
                              className="border-none bg-transparent"
                              onClick={() => row.toggleRowExpanded()}
                            >
                              <Icon
                                label={
                                  row.isExpanded ? "expand_less" : "expand_more"
                                }
                                title=""
                                className={classNames("text-light-gray !m-0")}
                              />
                            </button>
                          </td>
                          {row.cells.map((cell) => {
                            return (
                              <>
                                <td
                                  {...cell.getCellProps({
                                    className: "align-left text-left py-4 w-30",
                                  })}
                                >
                                  {cell.render("Cell")}
                                </td>
                              </>
                            );
                          })}
                          <td className="px-6 py-6 flex items-center justify-end">
                            {/* <span className="text-sm">Complete</span> */}
                            <button
                              className={`border-none bg-transparent`}
                              disabled={row.original.state === "completed"}
                              onClick={() =>
                                openConfirmSessionModalHandler(row.original)
                              }
                            >
                              {row.original.state === "completed" ? (
                                <Icon
                                  label="check_box"
                                  title="Completed"
                                  className={classNames(
                                    `text-light-gray ${
                                      row.original.state === "completed"
                                        ? "!cursor-default !text-green-500"
                                        : ""
                                    }`
                                  )}
                                />
                              ) : (
                                <Icon
                                  label="check_box_outline_blank"
                                  title="Active"
                                  className={classNames("text-light-gray")}
                                />
                              )}
                            </button>
                            <button
                              className="border-none bg-transparent"
                              onClick={() =>
                                openConfirmModalHandler(row.original)
                              }
                            >
                              <Icon
                                label="delete_forever"
                                title="Delete"
                                className={classNames("text-light-gray")}
                              />
                            </button>
                          </td>
                        </tr>
                        {row.isExpanded && (
                          <tr className="">
                            <td colSpan={Object.keys(row.cells).length + 2}>
                              <div className="flex flex-col p-4 px-5 bg-gray-100">
                                {row.original.group_module_documents.map(
                                  (group_module_document) => {
                                    return (
                                      <div className="flex flex-row items-center bg-white px-5 py-2 text-base text-slate-700 mb-2 justify-between w-full">
                                        <div className="flex-shrink-0 w-48 font-bold">
                                          {group_module_document?.group?.title}
                                        </div>
                                        <div className="flex-1">
                                          {
                                            group_module_document.categories[0]
                                              ?.value
                                          }
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

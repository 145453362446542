import {
  START_MODULE_SET_LIST_ACTION,
  ERROR_MODULE_SET_LIST_ACTION,
  SUCCESS_MODULE_SET_LIST_ACTION,
  START_UPDATE_MODULE_SETS_ACTION,
  SUCCESS_UPDATE_MODULE_SETS_ACTION,
  FAILURE_UPDATE_MODULE_SETS_ACTION,
  START_DELETE_MODULE_SETS_ACTION,
  SUCCESS_DELETE_MODULE_SETS_ACTION,
  FAILURE_DELETE_MODULE_SETS_ACTION,
  START_CLONE_MODULE_SET_ACTION,
  SUCCESS_CLONE_MODULE_SET_ACTION,
  FAILURE_CLONE_MODULE_SET_ACTION,
  START_CREATE_MODULE_SET_ACTION,
  SUCCESS_CREATE_MODULE_SET_ACTION,
  FAILURE_CREATE_MODULE_SET_ACTION,
  START_SHOW_MODULE_SET_ACTION,
  SUCCESS_SHOW_MODULE_SET_ACTION,
  FAILURE_SHOW_MODULE_SET_ACTION,
  START_MODULE_SET_BY_CATEGORIES_ACTION,
  ERROR_MODULE_SET_BY_CATEGORIES_ACTION,
  SUCCESS_MODULE_SET_BY_CATEGORIES_ACTION,
  START_MODULE_SET_NON_PAGINATED_LIST_ACTION,
  ERROR_MODULE_SET_NON_PAGINATED_LIST_ACTION,
  SUCCESS_MODULE_SET_NON_PAGINATED_LIST_ACTION,
} from "../actions/module-sets";

const initState = {
  module_sets_list_data: {
    isLoading: false,
    isError: false,
    data: [],
    meta: {},
  },
  module_sets_non_paginated_list_data: {
    isLoading: false,
    isError: false,
    data: [],
  },
  module_set_update: {
    isLoading: false,
    isError: false,
    data: {},
  },
  module_set_delete: {
    isLoading: false,
    isError: false,
    data: {},
  },
  module_set_clone: {
    isLoading: false,
    isError: false,
    data: null,
  },
  module_set_create: {
    isLoading: false,
    isError: false,
    data: null,
  },
  module_set_show_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  module_sets_by_categories_data: {
    isLoading: false,
    isError: false,
    data: [],
  },
};

export const moduleSetsReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_MODULE_SET_LIST_ACTION:
      return {
        ...prevState,
        module_sets_list_data: {
          isLoading: true,
          isError: false,
          data: [],
          meta: {},
        },
      };
    case SUCCESS_MODULE_SET_LIST_ACTION:
      return {
        ...prevState,
        module_sets_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
          meta: action.meta,
        },
      };
    case ERROR_MODULE_SET_LIST_ACTION:
      return {
        ...prevState,
        module_sets_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
          meta: {},
        },
      };
    case START_UPDATE_MODULE_SETS_ACTION:
      return {
        ...prevState,
        module_set_update: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };

    case SUCCESS_UPDATE_MODULE_SETS_ACTION:
      return {
        ...prevState,
        module_set_update: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };

    case FAILURE_UPDATE_MODULE_SETS_ACTION:
      return {
        ...prevState,
        module_set_update: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };

    case START_DELETE_MODULE_SETS_ACTION: {
      return {
        ...prevState,
        module_set_delete: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }

    case SUCCESS_DELETE_MODULE_SETS_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_set_delete: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }

    case FAILURE_DELETE_MODULE_SETS_ACTION: {
      return {
        ...prevState,
        module_set_delete: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }

    case START_CLONE_MODULE_SET_ACTION: {
      return {
        ...prevState,
        module_set_clone: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CLONE_MODULE_SET_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_set_clone: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CLONE_MODULE_SET_ACTION: {
      return {
        ...prevState,
        module_set_clone: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }

    case START_CREATE_MODULE_SET_ACTION: {
      return {
        ...prevState,
        module_set_create: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CREATE_MODULE_SET_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        module_set_create: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CREATE_MODULE_SET_ACTION: {
      return {
        ...prevState,
        module_set_create: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }

    case START_SHOW_MODULE_SET_ACTION:
      return {
        ...prevState,
        module_set_show_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };

    case SUCCESS_SHOW_MODULE_SET_ACTION:
      const { data } = action;
      return {
        ...prevState,
        module_set_show_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    case FAILURE_SHOW_MODULE_SET_ACTION:
      return {
        ...prevState,
        module_set_show_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };

    case START_MODULE_SET_BY_CATEGORIES_ACTION:
      return {
        ...prevState,
        module_sets_by_categories_data: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case SUCCESS_MODULE_SET_BY_CATEGORIES_ACTION:
      return {
        ...prevState,
        module_sets_by_categories_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case ERROR_MODULE_SET_BY_CATEGORIES_ACTION:
      return {
        ...prevState,
        module_sets_by_categories_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
        },
      };
    case START_MODULE_SET_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        module_sets_non_paginated_list_data: {
          isLoading: true,
          isError: false,
          data: [],
          meta: {},
        },
      };
    case SUCCESS_MODULE_SET_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        module_sets_non_paginated_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case ERROR_MODULE_SET_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        module_sets_non_paginated_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
        },
      };
    default:
      return prevState;
  }
};

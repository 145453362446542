import React from "react";
import "../../styles/resources.scss";
import camera from "../../images/camera.svg";
import Icon from "../Button/Icon";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ColorPic } from "./Common/ColorPic";
import "../../styles/fontSizer.scss";
import "../../styles/fontFamily.scss";
import { createImageFileObjectHook } from "../../commonHook";
import { uploadImageCallback } from "./Common/ImageUpload";
import "../../styles/canvasResourcesRichText.scss"

export default function CanvasTypeResourcesForm({
  value,
  setFieldValue,
  setImgData,
  setBgimgData,
  mediaImageResource,
  largeCanvasImageResource,
  setLargeImageFlag,
  setMediaImageFlag,
}) {
  React.useEffect(() => {
    if (largeCanvasImageResource) {
      const image = largeCanvasImageResource.url;
      const title = largeCanvasImageResource.name;
      const imageObject = createImageFileObjectHook(image, title);
      setImgData(imageObject);
      setUploadedFile(title);
    }
  }, []);

  React.useEffect(() => {
    if (mediaImageResource) {
      const image = mediaImageResource.url;
      const title = mediaImageResource.name;
      const imageObject = createImageFileObjectHook(image, title);
      setBgimgData(imageObject);
      setBackgroundImage(image);
    }
  }, []);

  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = React.useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
  };

  const [uploadedFile, setUploadedFile] = React.useState(
    "Upload Image for zoom (1100x825)"
  );
  const [backgroundImage, setBackgroundImage] = React.useState("");
  const handleUploadBgImg = (event) => {
    const imgData = URL.createObjectURL(event.target.files[0]);
    setBgimgData(event.target.files[0]);
    setBackgroundImage(imgData);
    setMediaImageFlag(true);
  };
  const handleFileSelect = (event) => {
    setImgData(event.target.files[0]);
    setUploadedFile(event.target.files[0].name);
    setLargeImageFlag(true);
  };

  return (
    <div className="flex justify-between w-full">
      <div className="w-1/4">
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="py-4 m4"
          editorClassName="p-4 border border-solid text-box-border mt-4 h-auto min-hieght-600 custom-html-content-class"
          toolbarClassName="border-0-imp padding-0-imp"
          toolbar={{
            options: [
              "inline",
              "list",
              "history",
              "textAlign",
              "image",
              "colorPicker",
              "fontSize",
              "fontFamily",
              "link",
            ],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"],
              bold: {
                className: "m-0-imp border-right-0-imp text-box-border",
              },
              italic: {
                className: "m-0-imp text-box-border",
              },
              underline: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              strikethrough: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
            },
            list: {
              options: ["unordered", "ordered", "indent", "outdent"],
              unordered: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              ordered: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              indent: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              outdent: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
            },
            fontSize: {
              className: "fontSizer m-0-imp border-right-0-imp text-box-border",
            },
            fontFamily: {
              className: "m-0-imp text-box-border",
              options: [
                "Lato",
                "Gloria Hallelujah",
                "Cedarville Cursive",
                "Rock Salt",
                "Swanky and Moo Moo",
              ],
            },
            history: {
              options: ["undo", "redo"],
              undo: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
              redo: {
                className: "m-0-imp border-left-0-imp text-box-border",
              },
            },
            colorPicker: {
              className: "m-0-imp border-left-0-imp text-box-border",
              component: ColorPic,
            },
            textAlign: {
              options: ["left", "center", "right", "justify"],
              left: {
                className:
                  "mx-left-6px-imp m-0-imp border-right-0-imp text-box-border",
              },
              center: {
                className: "m-0-imp border-right-0-imp text-box-border",
              },
              right: {
                className: "m-0-imp border-right-0-imp text-box-border",
              },
              justify: { className: "m-0-imp text-box-border" },
            },
            image: {
              className:
                "mx-left-6px-imp mr-0px-imp border-right-0-imp text-box-border",
              urlEnabled: false,
              uploadEnabled: true,
              alignmentEnabled: false,
              uploadCallback: uploadImageCallback,
              previewImage: true,
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
              alt: { present: false, mandatory: false },
              defaultSize: {
                height: "auto",
                width: "100%",
              },
            },
            link: {
              options: ["link"],
              link: { className: "m-0-imp border-left-0-imp text-box-border" },
            },
          }}
        />
      </div>
      <div className="w-3/4 ml-2">
        <div className="mt-36">
          <div className="border border-solid text-box-border canvas-resources text-center h-full relative">
            <div
              className="bg-no-repeat bg-contain w-auto h-full bg-center align-middle"
              style={{
                backgroundImage: `url(${backgroundImage})`,
              }}
            >
              <div className="absolute top-2/4 left-2/4 child-canvas">
                <input
                  type="file"
                  id="actual-btn"
                  hidden
                  onChange={handleUploadBgImg}
                />
                <label for="actual-btn" className="cursor-pointer">
                  <img src={camera} alt="" />
                </label>
                <div className="font-lato text-base text-ccc">
                  Set canvas background image
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex px-4 py-2 justify-between">
          <div className="pl-4 py-1 upload-zoom-btn w-5/12">
            <input
              type="file"
              id="upload-zoom-btn"
              hidden
              onChange={handleFileSelect}
            />
            {/* <span id="file-chosen"> {uploadedFile}</span> */}
            <label
              for="upload-zoom-btn"
              className="cursor-pointer text-blue-theme flex items-center"
            >
              <Icon label="attachment" className="text-blue-theme" />
              {uploadedFile}
            </label>
          </div>
          <button
            className="ml-0 textrich-button select-none outline-none px-2 py-0 bg-none font-medium text-sm cursor-pointer opacity-100"
            type="button"
            onClick={() => {
              setUploadedFile("Upload Image for zoom (1100x825)");
            }}
          >
            Undo
          </button>
        </div>
      </div>
    </div>
  );
}

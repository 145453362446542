import React from "react";
import Icon from "../Button/Icon";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import SingleSelect from "../Dropdowns/SingleSelect";
import OutlinedButton from "../Button/OutlinedButton";
import DefaultButton from "../Button/DefaultButton";
import PieChartsResult from "../Charts/PieChartsResult";
import BarChartResult from "../Charts/BarChartResult";
import ViewResultCanvasImageModal from "../Modal/ViewResultCanvasImageModal";
import {
  useModuleDocuments,
  useModuleSessions,
  useModuleSet,
} from "../../rest-client/selectorHook";
import { START_MODULE_SET_LIST_ACTION } from "../../rest-client/actions/module-sets";
import { useDispatch } from "react-redux";
import {
  START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
  START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
} from "../../rest-client/actions/module-sessions";
import { ClipLoader } from "react-spinners";
import { START_FETCH_DISCUSSION_ENTRIES_ACTION } from "../../rest-client/actions/module-documents";
import { getTheInnerHTMLHook } from "../../commonHook";

export default function ViewResultsSubComponent() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [isOpenResultCanvasModal, setIsOpenResultCanvasModal] =
    React.useState(false);
  const location = useLocation();
  const [initialFormVariables, setInitialFormVariables] = React.useState({
    module_set: "",
  });
  const [moduleSetData, setModuleSetData] = React.useState();
  const [moduleIndex, setModuleIndex] = React.useState(0);
  const [moduleSessionId, setModuleSessionId] = React.useState(null);

  const queryParams = new URLSearchParams(location.search);
  const classroomId = queryParams.get("classroom_id");

  const {
    module_sets_list_data: { data: moduleSetListData },
  } = useModuleSet();

  const {
    module_sessions_by_classroom_by_module_set_data: {
      isLoading: moduleSessionsByClassroomByModuleSetLoading,
      data: moduleSessionsByClassroomByModuleSetData,
    },
    module_session_by_id_with_classroom_id_data: {
      isLoading: moduleSessionByIdWithClassroomIdLoading,
      data: moduleSessionByIdWithClassroomIdData,
    },
  } = useModuleSessions();

  const {
    discussion_entries: { data: discussionEntriesData },
  } = useModuleDocuments();

  React.useEffect(() => {
    setInitialFormVariables({
      module_set:
        moduleSetListData && moduleSetListData.length > 0
          ? {
              label: moduleSetListData[0].title,
              value: moduleSetListData[0].title,
              id: moduleSetListData[0].id,
            }
          : "",
    });
    if (moduleSetListData.length > 0) {
      setModuleSetData({
        label: moduleSetListData[0].title,
        value: moduleSetListData[0].title,
        id: moduleSetListData[0].id,
      });
    }
  }, [moduleSetListData]);

  React.useEffect(() => {
    if (moduleSessionsByClassroomByModuleSetData.length > 0) {
      setModuleSessionId(
        moduleSessionsByClassroomByModuleSetData[moduleIndex].id
      );
    }
  }, [moduleIndex, moduleSessionsByClassroomByModuleSetData]);

  const openResultModalHandler = React.useCallback((value) => {
    setIsOpenResultCanvasModal(true);
    if (value) {
      dispatch({
        type: START_FETCH_DISCUSSION_ENTRIES_ACTION,
        payload: {
          module_document_id: value?.module_document?.id,
          group_id: value?.group?.id,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    dispatch({
      type: START_MODULE_SET_LIST_ACTION,
      payload: null,
    });
  }, []);

  React.useEffect(() => {
    if (moduleSetData) {
      dispatch({
        type: START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
        payload: {
          classroom_id:
            classroomId && userData?.role === "Teacher"
              ? classroomId
              : userData?.classroom?.id,
          module_set_id: moduleSetData.id,
        },
      });
    }
    setModuleIndex(0);
  }, [moduleSetData]);

  React.useEffect(() => {
    if (moduleSessionId) {
      dispatch({
        type: START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
        payload: {
          classroom_id:
            classroomId && userData?.role === "Teacher"
              ? classroomId
              : userData?.classroom?.id,
          module_session_id: moduleSessionId,
        },
      });
    }
    setModuleSessionId(null);
  }, [moduleSessionId, moduleSetData]);

  const pageRefreshHandler = React.useCallback(() => {
    if (moduleSessionsByClassroomByModuleSetData.length > 0) {
      dispatch({
        type: START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
        payload: {
          classroom_id:
            classroomId && userData?.role === "Teacher"
              ? classroomId
              : userData?.classroom?.id,
          module_session_id:
            moduleSessionsByClassroomByModuleSetData[moduleIndex].id,
        },
      });
    }
  }, [moduleIndex, moduleSessionsByClassroomByModuleSetData]);

  const moduleSetOptions = moduleSetListData.map((module_set, index) => {
    return {
      label: module_set.title,
      value: module_set.title,
      id: module_set.id,
    };
  });

  const ImageViewer = ({ src, id }) => {
    React.useEffect(() => {
      const getImage = async () => {
        if (
          src.endsWith(".jpg") ||
          src.endsWith(".jpeg") ||
          src.endsWith(".png")
        ) {
          const imgElement = document.getElementById(`my-img-${id}`);
          if (imgElement) {
            imgElement.src = src;
          }
          imgElement.src = src;
        } else {
          const response = await fetch(src);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const imageUrl = reader.result;
            const decodedData = atob(imageUrl.split(",")[1]);
            const imgElement = document.getElementById(`my-img-${id}`);
            if (imgElement) {
              imgElement.src = decodedData;
            }
          };
        }
      };
      getImage();
    }, [src]);
    return (
      <>
        <img
          id={`my-img-${id}`}
          alt="canvas-result-image"
          className="max-w-full"
        />
      </>
    );
  };

  return (
    <>
      {isOpenResultCanvasModal && (
        <ViewResultCanvasImageModal
          isOpenResultCanvasModal={isOpenResultCanvasModal}
          setIsOpenResultCanvasModal={setIsOpenResultCanvasModal}
          discussionEntriesData={discussionEntriesData}
        />
      )}
      <div className="box-border flex flex-row">
        <div className="flex-1 box-border max-h-full max-width-40-percent"></div>
        <Formik enableReinitialize initialValues={initialFormVariables}>
          {({ values, setFieldValue }) => (
            <Form className="font-lato mx-10 mt-10 flex-1 box-border max-h-full max-width-20-percent">
              <div className="">
                <Field name="module_set">
                  {({ field, form, meta }) => (
                    <SingleSelect
                      field={field}
                      form={form}
                      meta={meta}
                      options={moduleSetOptions}
                      placeholder="Choose Course"
                      onChange={(value) => {
                        setModuleSetData(value);
                      }}
                    />
                  )}
                </Field>
              </div>
            </Form>
          )}
        </Formik>
        <div className="flex-1 box-border max-h-full max-width-40-percent"></div>
      </div>

      {moduleSessionsByClassroomByModuleSetLoading ? (
        <div className="flex flex-col justify-center items-center h-32">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">
            Loading Modules...
          </p>
        </div>
      ) : Array.isArray(moduleSessionsByClassroomByModuleSetData) &&
        moduleSessionsByClassroomByModuleSetData.length > 0 ? (
        <div>
          <div className="mx-10 flex justify-between mt-10">
            <DefaultButton
              label="Previous module"
              type="button"
              disabled={moduleIndex + 1 === 1}
              onClickHandler={() => {
                setModuleIndex((prev) => prev - 1);
              }}
            />
            <OutlinedButton
              label="Refresh Page"
              className=""
              type="button"
              onClickHandler={() => {
                pageRefreshHandler();
              }}
            />
            <DefaultButton
              label="Next module"
              type="button"
              disabled={
                moduleSessionsByClassroomByModuleSetData.length <= 1 ||
                moduleIndex ===
                  moduleSessionsByClassroomByModuleSetData?.length - 1
              }
              onClickHandler={() => {
                setModuleIndex((prev) => prev + 1);
              }}
            />
          </div>
          {moduleSessionByIdWithClassroomIdLoading ? (
            <div className="flex flex-col justify-center items-center h-32">
              <ClipLoader size={32} color={"#3e8ede"} />
              <p className="mt-2 font-bold font-muli text-blue-theme">
                Loading Results...
              </p>
            </div>
          ) : (Array.isArray(
              moduleSessionByIdWithClassroomIdData?.discussion_entries
            ) &&
              moduleSessionByIdWithClassroomIdData?.discussion_entries.length >
                0) ||
            (Array.isArray(moduleSessionByIdWithClassroomIdData?.polls) &&
              moduleSessionByIdWithClassroomIdData?.polls.length > 0) ? (
            <div>
              <div className="flex flex-row flex-wrap ml-10">
                {moduleSessionByIdWithClassroomIdData?.discussion_entries?.map(
                  (e, index) => (
                    <div className="flex flex-col w-4/12" key={index}>
                      <h3 className="text-center">{e.group?.title || "-"}</h3>
                      <div className="md-card box-border flex flex-col grow m-2 bg-white rounded-sm text-black-87">
                        <div className="py-6 px-4 flex flex-auto flex-row">
                          <div className="flex-1 flex-col flex text-2xl font-normal capitalize">
                            {e?.module_document?.nickname || "-"}
                          </div>
                          <button
                            className={classNames("border-none bg-transparent")}
                            onClick={() => {
                              openResultModalHandler(e);
                            }}
                          >
                            <Icon
                              label="zoom_in"
                              className={classNames(
                                "text-light-gray !mr-0 !text-3xl"
                              )}
                              title="Zoom for More Details"
                            />
                          </button>
                        </div>
                        {e?.media?.url && (
                          <div className="block p-2">
                            <ImageViewer src={e?.media?.url} id={e?.id} />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
              {moduleSessionByIdWithClassroomIdData?.polls?.some((e) =>
                e.poll_questions[0].poll_question_options.some(
                  (item) => item.meta_responses.count != 0
                )
              ) ? (
                <>
                  {[...moduleSessionByIdWithClassroomIdData?.polls]
                    ?.reverse()
                    .map((e, index) => (
                      <>
                        {e.poll_questions[0].poll_question_options.some(
                          (e) => e.meta_responses.count != 0
                        ) ||
                        e.poll_questions[1].poll_question_options.some(
                          (e) => e.meta_responses.count != 0
                        ) ? (
                          <div
                            className="box-border flex lg:flex-row md:flex-row sm:flex-col min-[640px]:flex-col mr-4 mb-8 w-full justify-between px-3"
                            key={index}
                          >
                            <div className="min-w-0 box-border flex flex-col justify-center items-center w-full">
                              <div>
                                <h3 className="text-center">
                                  {getTheInnerHTMLHook(
                                    e?.poll_questions[0]?.text
                                  ) || "-"}
                                </h3>
                              </div>
                              <BarChartResult
                                barChartAnalysisData={
                                  e?.poll_questions[0]?.poll_question_options
                                }
                              />
                            </div>
                            <div className="min-w-0 box-border flex flex-col justify-center items-center w-full">
                              <div>
                                <h3 className="text-center">
                                  {getTheInnerHTMLHook(
                                    e?.poll_questions[1]?.text
                                  ) || "-"}
                                </h3>
                              </div>
                              <PieChartsResult
                                pieChartAnalysisData={
                                  e?.poll_questions[1]?.poll_question_options
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))}
                </>
              ) : (
                <div className="text-center p-10 text-base">No Results</div>
              )}
            </div>
          ) : (
            <div className="text-center p-10 text-base">No Results</div>
          )}
        </div>
      ) : (
        <div className="text-center p-10 text-base">No Modules available</div>
      )}
    </>
  );
}

import React from "react";
import classNames from "classnames";

export default function LinkedButton({ label, onClickHandler, className }) {
  return (
    <div
      className={classNames(className, "flex box-border items-center mb-3")}
      onClick={onClickHandler}
    >
      <button className="default-linked-button text-theme-blue cursor-pointer align-middle items-center text-center box-border select-none outline-none border-0 py-0 px-2 font-medium font-lato text-sm">
        <span className="ng-binding ng-scope">{label}</span>
      </button>
    </div>
  );
}

import React from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DefaultButton from "../../../components/Button/DefaultButton";
import { START_CLONE_RESOURCE_ACTION } from "../../../rest-client/actions/resources";
import OutlinedButton from "../../../components/Button/OutlinedButton";

export default function CloneResourceForm({
  clonedObjectData,
  setIsOpenCloneModal,
  setCurrentPage,
}) {
  const resourceData = clonedObjectData;
  const dispatch = useDispatch();
  const history = useHistory();

  const initialFormValues = {
    title: "",
    nickname: "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );
  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title.trim()) {
      error.title = "Please enter Title";
    }
    if (!values.nickname.trim()) {
      error.nickname = "Please enter Nickname";
    }
    return error;
  }, []);

  const createCloneResourceHandler = React.useCallback(
    (values) => {
      if (values) {
        const createCloneResourceData = {
          resource: {
            title: values.title.trimStart().trimEnd(),
            nickname: values.nickname.trimStart().trimEnd(),
            id: resourceData.id,
          },
        };
        dispatch({
          type: START_CLONE_RESOURCE_ACTION,
          payload: {
            createCloneResourceData,
            setIsOpenCloneModal,
            history,
            setCurrentPage,
          },
        });
      }
    },
    [dispatch]
  );
  return (
    <div className="py-10 px-2">
      <p>Original Module Set Title : {resourceData.title}</p>
      <p>Original module set nickname: {resourceData.nickname}</p>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={createCloneResourceHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato mt-10">
            <div className="field-holder">
              <Field
                type="text"
                id="title"
                className={classNames("input-class", {
                  "error-input-class": errors.title,
                })}
                value={values.title}
                onChange={(e) => {
                  setFieldDataHandler(
                    setFieldValue,
                    "title",
                    e.target.value.trimStart()
                  );
                }}
                required
              />
              <label
                htmlFor="title"
                className={classNames("label-class", {
                  "error-label-class": errors.title,
                })}
              >
                Name cloned resource title *
              </label>
              {errors.title && touched.title && (
                <p className="error-label-class mt-25">{errors.title ?? "-"}</p>
              )}
            </div>
            <div className="field-holder">
              <Field
                type="text"
                id="nickname"
                className={classNames("input-class", {
                  "error-input-class": errors.nickname && touched.nickname,
                })}
                value={values.nickname}
                onChange={(e) => {
                  setFieldDataHandler(
                    setFieldValue,
                    "nickname",
                    e.target.value.trimStart()
                  );
                }}
                maxLength={50}
                required
              />
              <label
                htmlFor="nickname"
                className={classNames("label-class", {
                  "error-label-class": errors.nickname && touched.nickname,
                })}
              >
                Name cloned resource nickname *
              </label>
              {errors.nickname && touched.nickname && (
                <p className="error-label-class mt-25">
                  {errors.nickname ?? "-"}
                </p>
              )}
            </div>
            <div className="flex justify-end mt-40">
              <OutlinedButton
                type="button"
                label="Cancel"
                className="mx-4"
                onClickHandler={() => setIsOpenCloneModal(false)}
              />
              <DefaultButton label="Submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

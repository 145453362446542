import React from "react";
import Select from "react-select";

const MultiSelect = ({ field, form, options, meta, ...props }) => {
  const getValue = () => {
    if (field.value) {
      return options.filter((option) => field.value.indexOf(option.value) >= 0);
    } else {
      return [];
    }
  };

  const handleChange = (value) => {
    form.setFieldValue(
      field.name,
      value.map((option) => option.value)
    );
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      borderBottom: `1px solid ${
        meta.error && meta.touched
          ? "rgb(221, 44, 0)"
          : state.isFocused
          ? "rgb(62, 142, 222)"
          : "rgba(0, 0, 0, 0.12)"
      }`,
      borderRadius: "0px",
      boxShadow: "none",
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
    }),
    placeholder: (styles, { isFocused, ...props }) => ({
      ...styles,
      color:
        meta.error && meta.touched
          ? "rgb(221, 44, 0)"
          : isFocused
          ? "rgb(62, 142, 222)"
          : "rgba(0, 0, 0, 0.54)",
    }),
  };

  return (
    <>
      <Select
        options={options}
        isMulti
        onChange={handleChange}
        value={getValue()}
        styles={customStyles}
        {...props}
        onBlur={() => {
          form.setFieldTouched(field.name, true);
        }}
      />
      {meta?.error && meta.touched && (
        <p className="error-label-class mt-0 text-sm">{meta.error ?? "-"}</p>
      )}
    </>
  );
};

export default MultiSelect;

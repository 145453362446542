import api from "../api";

export function fetchModuleDocumentsListRequest(payload) {
  return api.get(
    `/module_documents?${
      payload?.filter?.module_set_id
        ? `module_set_id=${payload?.filter.module_set_id}`
        : ""
    }&order=${payload.orderType}&order_by=title&per_page=8${
      payload?.page && payload?.page > 0 ? `&page=${payload?.page}` : ""
    }${payload?.query ? `&query=${payload.query}` : ""}`
  );
}

export function fetchModuleDocumentByIdRequest({ module_id }) {
  return api.get(`/module_documents/${module_id}`);
}

export function fetchModuleResourceNodeByIdRequest(payload) {
  return api.get(`/module_documents/${payload.moduleId}/resource_nodes`);
}

export function fetchUpdateModuleResourceNodeByIdRequest(payload) {
  return api.post(`/module_documents/${payload.module_id}/resource_nodes`, {
    paramObj: payload,
  });
}

export function deleteModuleDocumentRequest(payload) {
  return api.delet(`/module_documents/${payload?.module_document?.id}`, {
    paramObj: payload,
  });
}

export function fetchCreateModuleDocumentRequest(payload) {
  return api.post(`/module_documents`, {
    paramObj: payload,
  });
}

export function fetchModuleDocumentCloneRequest(payload) {
  return api.post(`/module_documents/${payload?.module_document?.id}/clone`, {
    paramObj: payload,
  });
}

export function updateModuleDocumentRequest(payload) {
  return api.put(`/module_documents/${payload?.module_document?.id}`, {
    paramObj: payload,
  });
}

export function fetchDiscussionEntriesRequest(payload) {
  return api.get(
    `/module_documents/${payload?.module_document_id}/discussion_entries?group_id=${payload?.group_id}`
  );
}

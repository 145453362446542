import { takeLatest, call, put } from "redux-saga/effects";
import {
  START_GROUPS_LIST_ACTION,
  SUCCESS_GROUPS_LIST_ACTION,
  ERROR_GROUPS_LIST_ACTION,
  START_CREATE_GROUP_ACTION,
  SUCCESS_CREATE_GROUP_ACTION,
  FAILURE_CREATE_GROUP_ACTION,
  START_UPDATE_GROUPS_ACTION,
  SUCCESS_UPDATE_GROUPS_ACTION,
  FAILURE_UPDATE_GROUPS_ACTION,
  START_DELETE_GROUPS_ACTION,
  SUCCESS_DELETE_GROUPS_ACTION,
  FAILURE_DELETE_GROUPS_ACTION,
} from "../actions/groups";
import {
  fetchGroupsListAction,
  fetchCreateGroupRequest,
  fetchGroupUpdateAction,
  deleteGroupRequest,
} from "../apis/groups";
import { toast } from "react-toastify";

export function* fetchGroupsListActionWatcher() {
  yield takeLatest(START_GROUPS_LIST_ACTION, fetchGroupsListActionWorker);
}

function* fetchGroupsListActionWorker({ payload }) {
  const fetchGroupsListActionResponse = yield call(
    fetchGroupsListAction,
    payload
  );
  if (fetchGroupsListActionResponse.status) {
    yield put({
      type: SUCCESS_GROUPS_LIST_ACTION,
      data: fetchGroupsListActionResponse.data.groups,
      meta: fetchGroupsListActionResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_GROUPS_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchGroupCreateRequestWatcher() {
  yield takeLatest(START_CREATE_GROUP_ACTION, fetchGroupCreateWorker);
}

function* fetchGroupCreateWorker({ payload }) {
  const { createGroupData } = payload;
  const fetchGroupCreateResponse = yield call(
    fetchCreateGroupRequest,
    createGroupData
  );
  if (fetchGroupCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_GROUP_ACTION,
      data: fetchGroupCreateResponse.data.group,
    });
    yield call(fetchGroupsListActionWorker, { payload: null });
    toast.success("Group Created Successfully");
  } else {
    const errorMessage =
      fetchGroupCreateResponse?.message?.length > 0
        ? fetchGroupCreateResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_GROUP_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchGroupUpdateActionWatcher() {
  yield takeLatest(START_UPDATE_GROUPS_ACTION, fetchGroupUpdateActionWorker);
}

function* fetchGroupUpdateActionWorker({ payload }) {
  const { updateGroupData, currentPage, setIsOpenEditFormModal } = payload;
  const updateGroupRequestResponse = yield call(
    fetchGroupUpdateAction,
    updateGroupData
  );
  if (updateGroupRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_GROUPS_ACTION,
      data: updateGroupRequestResponse.data,
    });
    setIsOpenEditFormModal(false);
    yield call(fetchGroupsListActionWorker, { payload: { page: currentPage } });
    toast.success("Group Updated Successfully");
  } else {
    const errorMessage =
      updateGroupRequestResponse?.data?.message?.length > 0
        ? updateGroupRequestResponse.data.message
        : "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_GROUPS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchGroupDeleteRequestWatcher() {
  yield takeLatest(START_DELETE_GROUPS_ACTION, fetchGroupDeleteRequestWorker);
}

function* fetchGroupDeleteRequestWorker({ payload }) {
  const { deleteGroupRequestData, setIsOpenConfirmModal } = payload;
  const deleteGroupRequestResponse = yield call(
    deleteGroupRequest,
    deleteGroupRequestData
  );
  if (deleteGroupRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_GROUPS_ACTION,
      data: deleteGroupRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchGroupsListActionWorker, { payload: null });
    toast.success("Group Deleted Successfully");
  } else {
    const errorMessage =
      deleteGroupRequestResponse?.message?.length > 0
        ? deleteGroupRequestResponse.message
        : "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_GROUPS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

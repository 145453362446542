export const GROUP_COURSE_MODULE_REQUESTED = "GROUP_COURSE_MODULE_REQUESTED";
export const GROUP_COURSE_MODULE_SUCCESS = "GROUP_COURSE_MODULE_SUCCESS";
export const GROUP_COURSE_MODULE_FAILURE = "GROUP_COURSE_MODULE_FAILURE";

export const GROUP_COURSE_MODULE_LIST_REQUESTED =
  "GROUP_COURSE_MODULE_LIST_REQUESTED";
export const GROUP_COURSE_MODULE_LIST_SUCCESS =
  "GROUP_COURSE_MODULE_LIST_SUCCESS";
export const GROUP_COURSE_MODULE_LIST_FAILURE =
  "GROUP_COURSE_MODULE_LIST_FAILURE";

import {
  START_CLASSROOMS_LIST_ACTION,
  SUCCESS_CLASSROOMS_LIST_ACTION,
  ERROR_CLASSROOMS_LIST_ACTION,
  START_CREATE_CLASSROOM_ACTION,
  SUCCESS_CREATE_CLASSROOM_ACTION,
  FAILURE_CREATE_CLASSROOM_ACTION,
  START_UPDATE_CLASSROOMS_ACTION,
  SUCCESS_UPDATE_CLASSROOMS_ACTION,
  FAILURE_UPDATE_CLASSROOMS_ACTION,
  START_DELETE_CLASSROOMS_ACTION,
  SUCCESS_DELETE_CLASSROOMS_ACTION,
  FAILURE_DELETE_CLASSROOMS_ACTION,
  START_EXPORT_CLASSROOM_ACTION,
  SUCCESS_EXPORT_CLASSROOM_ACTION,
  FAILURE_EXPORT_CLASSROOM_ACTION,
  START_SHOW_CLASSROOM_ACTION,
  SUCCESS_SHOW_CLASSROOM_ACTION,
  FAILURE_SHOW_CLASSROOM_ACTION,
  START_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
  ERROR_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
  SUCCESS_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
} from "../actions/classrooms";

const initState = {
  classrooms_list_data: {
    isLoading: false,
    isError: false,
    data: [],
    meta: {},
  },
  classrooms_non_paginated_list_data: {
    isLoading: false,
    isError: false,
    data: [],
  },
  classroom_create: {
    isLoading: false,
    isError: false,
    data: null,
  },
  classroom_update: {
    isLoading: false,
    isError: false,
    data: {},
  },
  classroom_delete: {
    isLoading: false,
    isError: false,
    data: {},
  },
  classroom_export: {
    isLoading: false,
    isError: false,
    data: null,
  },
  classroom_show_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
};

export const classroomsReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_CLASSROOMS_LIST_ACTION:
      return {
        ...prevState,
        classrooms_list_data: {
          isLoading: true,
          isError: false,
          data: [],
          meta: {},
        },
      };
    case SUCCESS_CLASSROOMS_LIST_ACTION:
      return {
        ...prevState,
        classrooms_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
          meta: action.meta,
        },
      };
    case ERROR_CLASSROOMS_LIST_ACTION:
      return {
        ...prevState,
        classrooms_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
          meta: {},
        },
      };
    case START_CREATE_CLASSROOM_ACTION: {
      return {
        ...prevState,
        classroom_create: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CREATE_CLASSROOM_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        classroom_create: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CREATE_CLASSROOM_ACTION: {
      return {
        ...prevState,
        classroom_create: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_UPDATE_CLASSROOMS_ACTION:
      return {
        ...prevState,
        classrooms_update: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };

    case SUCCESS_UPDATE_CLASSROOMS_ACTION:
      return {
        ...prevState,
        classrooms_update: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };

    case FAILURE_UPDATE_CLASSROOMS_ACTION:
      return {
        ...prevState,
        classrooms_update: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };

    case START_DELETE_CLASSROOMS_ACTION: {
      return {
        ...prevState,
        classrooms_delete: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }

    case SUCCESS_DELETE_CLASSROOMS_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        classrooms_delete: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }

    case FAILURE_DELETE_CLASSROOMS_ACTION: {
      return {
        ...prevState,
        classrooms_delete: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_EXPORT_CLASSROOM_ACTION: {
      return {
        ...prevState,
        classroom_export: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_EXPORT_CLASSROOM_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        classroom_export: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_EXPORT_CLASSROOM_ACTION: {
      return {
        ...prevState,
        classroom_export: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_SHOW_CLASSROOM_ACTION:
      return {
        ...prevState,
        classroom_show_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };

    case SUCCESS_SHOW_CLASSROOM_ACTION:
      const { data } = action;
      return {
        ...prevState,
        classroom_show_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    case FAILURE_SHOW_CLASSROOM_ACTION:
      return {
        ...prevState,
        classroom_show_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    case START_CLASSROOMS_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        classrooms_non_paginated_list_data: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case SUCCESS_CLASSROOMS_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        classrooms_non_paginated_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case ERROR_CLASSROOMS_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        classrooms_non_paginated_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
        },
      };
    default:
      return prevState;
  }
};

import { takeLatest, call, put } from "redux-saga/effects";
import {
  START_SCHOOLS_LIST_ACTION,
  SUCCESS_SCHOOLS_LIST_ACTION,
  ERROR_SCHOOLS_LIST_ACTION,
  START_CREATE_SCHOOL_ACTION,
  SUCCESS_CREATE_SCHOOL_ACTION,
  FAILURE_CREATE_SCHOOL_ACTION,
  START_UPDATE_SCHOOLS_ACTION,
  SUCCESS_UPDATE_SCHOOLS_ACTION,
  FAILURE_UPDATE_SCHOOLS_ACTION,
  START_DELETE_SCHOOLS_ACTION,
  SUCCESS_DELETE_SCHOOLS_ACTION,
  FAILURE_DELETE_SCHOOLS_ACTION,
  START_SCHOOLS_NON_PAGINATED_LIST_ACTION,
  ERROR_SCHOOLS_NON_PAGINATED_LIST_ACTION,
  SUCCESS_SCHOOLS_NON_PAGINATED_LIST_ACTION,
} from "../actions/schools";
import {
  fetchSchoolsListAction,
  fetchCreateSchoolRequest,
  fetchSchoolUpdateAction,
  deleteSchoolRequest,
  fetchSchoolsNonPaginatedListAction,
} from "../apis/schools";
import { toast } from "react-toastify";

export function* fetchSchoolsListActionWatcher() {
  yield takeLatest(START_SCHOOLS_LIST_ACTION, fetchSchoolsListActionWorker);
}

function* fetchSchoolsListActionWorker({ payload }) {
  const fetchSchoolsListActionResponse = yield call(
    fetchSchoolsListAction,
    payload
  );
  if (fetchSchoolsListActionResponse.status) {
    yield put({
      type: SUCCESS_SCHOOLS_LIST_ACTION,
      data: fetchSchoolsListActionResponse.data.schools,
      meta: fetchSchoolsListActionResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_SCHOOLS_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchSchoolsNonPaginatedListActionWatcher() {
  yield takeLatest(
    START_SCHOOLS_NON_PAGINATED_LIST_ACTION,
    fetchSchoolsNonPaginatedListActionWorker
  );
}

function* fetchSchoolsNonPaginatedListActionWorker() {
  const fetchSchoolsListActionResponse = yield call(
    fetchSchoolsNonPaginatedListAction
  );
  if (fetchSchoolsListActionResponse.status) {
    yield put({
      type: SUCCESS_SCHOOLS_NON_PAGINATED_LIST_ACTION,
      data: fetchSchoolsListActionResponse.data.schools,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_SCHOOLS_NON_PAGINATED_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchSchoolCreateRequestWatcher() {
  yield takeLatest(START_CREATE_SCHOOL_ACTION, fetchSchoolCreateWorker);
}

function* fetchSchoolCreateWorker({ payload }) {
  const { createSchoolData } = payload;
  const fetchSchoolCreateResponse = yield call(
    fetchCreateSchoolRequest,
    createSchoolData
  );
  if (fetchSchoolCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_SCHOOL_ACTION,
      data: fetchSchoolCreateResponse.data.school,
    });
    yield call(fetchSchoolsListActionWorker, { payload: null });
    toast.success("School Created Successfully");
  } else {
    const errorMessage =
      fetchSchoolCreateResponse?.message?.length > 0
        ? fetchSchoolCreateResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_SCHOOL_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchSchoolUpdateActionWatcher() {
  yield takeLatest(START_UPDATE_SCHOOLS_ACTION, fetchSchoolUpdateActionWorker);
}

function* fetchSchoolUpdateActionWorker({ payload }) {
  const { updateSchoolData, currentPage, setIsOpenEditFormModal } = payload;
  const updateSchoolRequestResponse = yield call(
    fetchSchoolUpdateAction,
    updateSchoolData
  );
  if (updateSchoolRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_SCHOOLS_ACTION,
      data: updateSchoolRequestResponse.data,
    });
    setIsOpenEditFormModal(false);
    yield call(fetchSchoolsListActionWorker, {
      payload: { page: currentPage },
    });
    toast.success("School Updated Successfully");
  } else {
    const errorMessage =
      updateSchoolRequestResponse?.data?.message?.length > 0
        ? updateSchoolRequestResponse.data.message
        : "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_SCHOOLS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchSchoolDeleteRequestWatcher() {
  yield takeLatest(START_DELETE_SCHOOLS_ACTION, fetchSchoolDeleteRequestWorker);
}

function* fetchSchoolDeleteRequestWorker({ payload }) {
  const { deleteSchoolRequestData, setIsOpenConfirmModal } = payload;
  const deleteSchoolRequestResponse = yield call(
    deleteSchoolRequest,
    deleteSchoolRequestData
  );
  if (deleteSchoolRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_SCHOOLS_ACTION,
      data: deleteSchoolRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchSchoolsListActionWorker, { payload: null });
    toast.success("School Deleted Successfully");
  } else {
    const errorMessage =
      deleteSchoolRequestResponse?.message?.length > 0
        ? deleteSchoolRequestResponse.message
        : "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_SCHOOLS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

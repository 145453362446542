import { useSelector } from "react-redux";

export function useContact() {
  const state = useSelector(({ contactUs } = {}) => contactUs);
  return state;
}

export function useModuleSet() {
  const state = useSelector(({ moduleSetsReducer } = {}) => moduleSetsReducer);
  return state;
}

export function useLogin() {
  const state = useSelector(({ loginReducer } = {}) => loginReducer);
  return state;
}

export function useModuleDocuments() {
  const state = useSelector(
    ({ moduleDocumentsReducer } = {}) => moduleDocumentsReducer
  );
  return state;
}

export function useResourcesList() {
  const state = useSelector(({ resourcesReducer } = {}) => resourcesReducer);
  return state;
}

export function useViewModule() {
  const state = useSelector(({ viewModuleReducer } = {}) => viewModuleReducer);
  return state;
}

export function useViewResourceNodeModule() {
  const state = useSelector(
    ({ viewResourceNodeReducer } = {}) => viewResourceNodeReducer
  );
  return state;
}

export function useSchools() {
  const state = useSelector(({ schoolsReducer } = {}) => schoolsReducer);
  return state;
}

export function useTeachers() {
  const state = useSelector(({ teachersReducer } = {}) => teachersReducer);
  return state;
}

export function useGroups() {
  const state = useSelector(({ groupsReducer } = {}) => groupsReducer);
  return state;
}

export function useClassrooms() {
  const state = useSelector(({ classroomsReducer } = {}) => classroomsReducer);
  return state;
}

export function useAdministrators() {
  const state = useSelector(
    ({ administratorsReducer } = {}) => administratorsReducer
  );
  return state;
}

export function useModuleSessions() {
  const state = useSelector(
    ({ moduleSessionsReducer } = {}) => moduleSessionsReducer
  );
  return state;
}

export function useGroupCourseModule() {
  const state = useSelector(
    ({ groupCourseModuleReducer } = {}) => groupCourseModuleReducer
  );
  return state;
}

import { takeLatest, call, put } from "redux-saga/effects";
import {
  ERROR_FETCHING_RESOURCES_LIST_ACTION,
  START_FETCHING_RESOURCES_LIST_ACTION,
  SUCCESS_FETCHING_RESOURCES_LIST_ACTION,
  START_CLONE_RESOURCE_ACTION,
  SUCCESS_CLONE_RESOURCE_ACTION,
  FAILURE_CLONE_RESOURCE_ACTION,
  START_DELETE_RESOURCE_ACTION,
  SUCCESS_DELETE_RESOURCE_ACTION,
  FAILURE_DELETE_RESOURCE_ACTION,
  START_SHOW_RESOURCE_ACTION,
  SUCCESS_SHOW_RESOURCE_ACTION,
  FAILURE_SHOW_RESOURCE_ACTION,
  START_ADD_RESOURCE_ACTION,
  SUCCESS_ADD_RESOURCE_ACTION,
  FAILURE_ADD_RESOURCE_ACTION,
  START_ADD_DISCUSSION_PROMPT_ACTION,
  SUCCESS_ADD_DISCUSSION_PROMPT_ACTION,
  FAILURE_ADD_DISCUSSION_PROMPT_ACTION,
  START_ADD_POLL_ACTION,
  SUCCESS_ADD_POLL_ACTION,
  FAILURE_ADD_POLL_ACTION,
  START_ADD_CANVAS_IMG_ACTION,
  SUCCESS_ADD_CANVAS_IMG_ACTION,
  FAILURE_ADD_CANVAS_IMG_ACTION,
  START_ADD_CANVAS_BG_IMG_ACTION,
  SUCCESS_ADD_CANVAS_BG_IMG_ACTION,
  FAILURE_ADD_CANVAS_BG_IMG_ACTION,
  START_UPDATE_RESOURCE_ACTION,
  SUCCESS_UPDATE_RESOURCE_ACTION,
  FAILURE_UPDATE_RESOURCE_ACTION,
  START_FETCHING_RESOURCE_BY_ID_ACTION,
  SUCCESS_FETCHING_RESOURCE_BY_ID_ACTION,
  ERROR_FETCHING_RESOURCE_BY_ID_ACTION,
  SUCCESS_UPDATING_POLL_BY_ID_ACTION,
  ERROR_UPDATING_POLL_BY_ID_ACTION,
  START_UPDATING_POLL_BY_ID_ACTION,
  START_DELETE_DISCUSSION_PROMPT_ACTION,
  SUCCESS_DELETE_DISCUSSION_PROMPT_ACTION,
  FAILURE_DELETE_DISCUSSION_PROMPT_ACTION,
  START_FETCHING_RESOURCE_NODE_ACTION,
  ERROR_FETCHING_RESOURCE_NODE_ACTION,
  SUCCESS_FETCHING_RESOURCE_NODE_ACTION,
} from "../actions/resources";
import {
  fetchResourcesListRequest,
  fetchResourceCloneRequest,
  deleteResourceRequest,
  fetchResourceShowRequest,
  addResourcesRequest,
  addDiscussionPromptRequest,
  addPollTypeResourceRequest,
  addCanvasImagesRequest,
  updateResourceRequest,
  fetchResourceByIdRequest,
  updatePollByIdRequest,
  deleteDiscussionPromptRequest,
} from "../apis/resources";
import { toast } from "react-toastify";
import { CANVAS_TYPE_RESOURCE, POLL_TYPE_RESOURCE } from "../../utils";
import { UpdateModuleResourceNodeByIdRequestWorker } from "./module-documents";
import { fetchModuleResourceNodeByIdRequest } from "../apis/module-documents";

export function* fetchResourcesListRequestWatcher() {
  yield takeLatest(
    START_FETCHING_RESOURCES_LIST_ACTION,
    fetchResourcesListRequestWorker
  );
}

export function* fetchResourcesListRequestWorker({ payload }) {
  const fetchResourcesListRequestResponse = yield call(
    fetchResourcesListRequest,
    payload
  );
  if (fetchResourcesListRequestResponse.status) {
    yield put({
      type: SUCCESS_FETCHING_RESOURCES_LIST_ACTION,
      data: fetchResourcesListRequestResponse.data,
      meta: fetchResourcesListRequestResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_FETCHING_RESOURCES_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchResourceCloneRequestWatcher() {
  yield takeLatest(START_CLONE_RESOURCE_ACTION, fetchResourceCloneWorker);
}

function* fetchResourceCloneWorker({ payload }) {
  const { createCloneResourceData, setIsOpenCloneModal, setCurrentPage } =
    payload;
  const fetchResourceCloneResponse = yield call(
    fetchResourceCloneRequest,
    createCloneResourceData
  );
  if (fetchResourceCloneResponse.status) {
    yield put({
      type: SUCCESS_CLONE_RESOURCE_ACTION,
      data: fetchResourceCloneResponse.data.resource,
    });
    setIsOpenCloneModal(false);
    yield call(fetchResourcesListRequestWorker, {
      payload: { orderType: "asc" },
    });
    setCurrentPage(1);
    toast.success("Resource Cloned Successfully");
  } else {
    const errorMessage =
      fetchResourceCloneResponse?.message?.length > 0
        ? fetchResourceCloneResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CLONE_RESOURCE_ACTION,
      errorMessage,
    });
    toast.error("Something Went Wrong");
  }
}

export function* fetchResourceDeleteRequestWatcher() {
  yield takeLatest(
    START_DELETE_RESOURCE_ACTION,
    fetchResourceDeleteRequestWorker
  );
}

function* fetchResourceDeleteRequestWorker({ payload }) {
  const { deleteResourceRequestData, setIsOpenConfirmModal } = payload;
  const deleteResourceRequestResponse = yield call(
    deleteResourceRequest,
    deleteResourceRequestData
  );
  if (deleteResourceRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_RESOURCE_ACTION,
      data: deleteResourceRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchResourcesListRequestWorker, {
      payload: { orderType: "asc", page: payload.currentPage },
    });
    toast.success("Resource Deleted Successfully");
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_RESOURCE_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchResourceShowRequestWatcher() {
  yield takeLatest(START_SHOW_RESOURCE_ACTION, fetchResourceShowRequestWorker);
}

function* fetchResourceShowRequestWorker({ payload }) {
  const {
    params: { id },
  } = payload;

  const fetchResourceShowRequestResponse = yield call(
    fetchResourceShowRequest,
    id
  );
  if (fetchResourceShowRequestResponse.status) {
    yield put({
      type: SUCCESS_SHOW_RESOURCE_ACTION,
      data: fetchResourceShowRequestResponse.data.resource,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_SHOW_RESOURCE_ACTION,
      errorMessage,
    });
  }
}

export function* addDiscussionPromptRequestWatcher() {
  yield takeLatest(
    START_ADD_DISCUSSION_PROMPT_ACTION,
    addDiscussionPromptRequestWorker
  );
}

function* addDiscussionPromptRequestWorker(payload) {
  const addDiscussionPromptRequestResponse = yield call(
    addDiscussionPromptRequest,
    payload
  );
  if (addDiscussionPromptRequestResponse.status) {
    yield put({
      type: SUCCESS_ADD_DISCUSSION_PROMPT_ACTION,
      data: addDiscussionPromptRequestResponse.data.resource,
    });
  } else {
    const errorMessage =
      addDiscussionPromptRequestResponse?.message?.length > 0
        ? addDiscussionPromptRequestResponse.message[0]
        : "Something went wrong";
    yield put({
      type: FAILURE_ADD_DISCUSSION_PROMPT_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchDiscussionPromptDeleteRequestWatcher() {
  yield takeLatest(
    START_DELETE_DISCUSSION_PROMPT_ACTION,
    fetchDiscussionPromptDeleteRequestWorker
  );
}

function* fetchDiscussionPromptDeleteRequestWorker({ payload }) {
  const deleteDiscussionPromptRequestResponse = yield call(
    deleteDiscussionPromptRequest,
    payload
  );
  if (deleteDiscussionPromptRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_DISCUSSION_PROMPT_ACTION,
      data: deleteDiscussionPromptRequestResponse.data,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_DISCUSSION_PROMPT_ACTION,
      errorMessage,
    });
  }
}

export function* addPollTypeResourceRequestWatcher() {
  yield takeLatest(START_ADD_POLL_ACTION, addPollTypeResourceRequestWorker);
}

function* addPollTypeResourceRequestWorker(payload) {
  const addPollTypeResourceRequestResponse = yield call(
    addPollTypeResourceRequest,
    payload
  );
  if (addPollTypeResourceRequestResponse.status) {
    yield put({
      type: SUCCESS_ADD_POLL_ACTION,
      data: addPollTypeResourceRequestResponse.data.resource,
    });
  } else {
    const errorMessage =
      addPollTypeResourceRequestResponse?.message?.length > 0
        ? addPollTypeResourceRequestResponse.message[0]
        : "Something went wrong";
    yield put({
      type: FAILURE_ADD_POLL_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* addCanvasImgRequestWatcher() {
  yield takeLatest(START_ADD_CANVAS_IMG_ACTION, addCanvasImgRequestWorker);
}

function* addCanvasImgRequestWorker(payload) {
  const formData = new FormData();
  formData.append("file", payload.large_image);
  const addCanvasImgRequestResponse = yield call(
    addCanvasImagesRequest,
    formData
  );
  if (addCanvasImgRequestResponse.status) {
    yield put({
      type: SUCCESS_ADD_CANVAS_IMG_ACTION,
      data: addCanvasImgRequestResponse.data.media,
    });
    payload.setImgData(null);
  } else {
    const errorMessage = "Something went wrong";
    yield put({
      type: FAILURE_ADD_CANVAS_IMG_ACTION,
      errorMessage,
    });
    payload.setImgData(null);
    toast.error(errorMessage);
  }
}

export function* addCanvasBgImgRequestWatcher() {
  yield takeLatest(START_ADD_CANVAS_BG_IMG_ACTION, addCanvasBgImgRequestWorker);
}

function* addCanvasBgImgRequestWorker(payload) {
  const formData = new FormData();
  formData.append("file", payload.media_image);
  const addCanvasBgImgRequestResponse = yield call(
    addCanvasImagesRequest,
    formData
  );
  if (addCanvasBgImgRequestResponse.status) {
    yield put({
      type: SUCCESS_ADD_CANVAS_BG_IMG_ACTION,
      data: addCanvasBgImgRequestResponse.data.media,
    });
    payload.setImgData(null);
  } else {
    const errorMessage = "Something went wrong";
    yield put({
      type: FAILURE_ADD_CANVAS_BG_IMG_ACTION,
      errorMessage,
    });
    payload.setImgData(null);
    toast.error(errorMessage);
  }
}

export function* addResourceRequestWatcher() {
  yield takeLatest(START_ADD_RESOURCE_ACTION, addResourceRequestWorker);
}

function* addResourceRequestWorker({ payload }) {
  const {
    addResourcesRequestPayload,
    discussion_prompt,
    resetForm,
    pollTypeResourcesPayload,
    canvasTypeResourcesPayload,
    module_id,
    resourceNodeData,
  } = payload;
  const addResourceRequestResponse = yield call(
    addResourcesRequest,
    addResourcesRequestPayload
  );
  if (addResourceRequestResponse.status) {
    yield put({
      type: SUCCESS_ADD_RESOURCE_ACTION,
      data: addResourceRequestResponse.data.resource,
    });
    const resourceId = addResourceRequestResponse.data.resource.id;
    const resource_type =
      addResourceRequestResponse.data.resource.resource_type;

    if (resourceId && discussion_prompt.length > 0) {
      for (const element of discussion_prompt) {
        const discussion_prompt_payload = {
          discussion: {
            discussionable_id: resourceId,
            discussionable_type: "Resource",
            order: discussion_prompt.indexOf(element),
            title: element.title,
          },
        };
        yield call(addDiscussionPromptRequestWorker, discussion_prompt_payload);
      }
    }
    if (resourceId && resource_type === POLL_TYPE_RESOURCE) {
      const updatedPollTypePayload = {
        poll: {
          ...pollTypeResourcesPayload,
          resource_id: resourceId,
        },
      };
      yield call(addPollTypeResourceRequestWorker, updatedPollTypePayload);
    }
    if (resourceId && resource_type === CANVAS_TYPE_RESOURCE) {
      if (canvasTypeResourcesPayload.large_image) {
        yield call(addCanvasImgRequestWorker, canvasTypeResourcesPayload);
      }
      if (canvasTypeResourcesPayload.media_image) {
        yield call(addCanvasBgImgRequestWorker, canvasTypeResourcesPayload);
      }
    }

    if (module_id && resourceId && resourceNodeData) {
      const payloadForResourceNodes = resourceNodeData;
      const dataToBeAddedForResourceNodes = {
        order: resourceNodeData.length,
        resource_id: resourceId,
      };
      payloadForResourceNodes[resourceNodeData.length] =
        dataToBeAddedForResourceNodes;
      const payload = {
        resource_nodes: payloadForResourceNodes,
        module_id: module_id,
      };
      yield call(UpdateModuleResourceNodeByIdRequestWorker, { payload });
    }
    toast.success("Resource Created Successfully");
    resetForm();
  } else {
    const errorMessage =
      addResourceRequestResponse?.message?.length > 0
        ? addResourceRequestResponse.message[0]
        : "Something went wrong";
    yield put({
      type: FAILURE_ADD_RESOURCE_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* updateResourceRequestWatcher() {
  yield takeLatest(START_UPDATE_RESOURCE_ACTION, updateResourceRequestWorker);
}

function* updateResourceRequestWorker({ payload }) {
  let mediaImageRequestResponse;
  let largeCanvasImageRequestResponse;
  if (payload.mediaImageFlag) {
    const formData = new FormData();
    formData.append("file", payload.canvasTypeResourcesPayload.media_image);
    let response = yield call(addCanvasImagesRequest, formData);
    if (response.status === true) {
      mediaImageRequestResponse = { ...response };
    }
  }

  if (payload.largeImageFlag) {
    const formData = new FormData();
    formData.append("file", payload.canvasTypeResourcesPayload.large_image);
    let response = yield call(addCanvasImagesRequest, formData);
    if (response.status === true) {
      largeCanvasImageRequestResponse = { ...response };
    }
  }
  const discussionData = payload.resourcePutPayload.resource.discussions;
  const updatedPayload = {
    ...payload,
    resourcePutPayload: {
      resource: {
        ...payload.resourcePutPayload.resource,
        ...(largeCanvasImageRequestResponse?.status &&
        largeCanvasImageRequestResponse.data.media
          ? {
              large_canvas_image_id:
                largeCanvasImageRequestResponse.data.media.id,
            }
          : {}),
        ...(mediaImageRequestResponse?.status &&
        mediaImageRequestResponse.data.media
          ? { media_id: mediaImageRequestResponse.data.media.id }
          : {}),
        ...(discussionData.length > 0
          ? {
              discussions: discussionData.map((e) => {
                return {
                  ...e,
                  order: discussionData.indexOf(e),
                };
              }),
            }
          : {}),
      },
    },
  };
  const updateResourceRequestResponse = yield call(
    updateResourceRequest,
    updatedPayload
  );
  if (updateResourceRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_RESOURCE_ACTION,
      data: updateResourceRequestResponse.data.resource,
    });
    yield put({
      type: SUCCESS_ADD_CANVAS_IMG_ACTION,
      data: {},
    });
    yield put({
      type: SUCCESS_ADD_CANVAS_BG_IMG_ACTION,
      data: {},
    });
    if (
      payload.resourcePutPayload.resource.resource_type ===
        POLL_TYPE_RESOURCE &&
      payload.resourcePutPayload.resource.polls.length > 0
    ) {
      yield call(updatePollByIdRequestWorker, payload.resourcePutPayload);
    }
    toast.success("Resource Updated Successfully");
    payload.history.push("/dashboard/learning/index/modules");
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_RESOURCE_ACTION,
      errorMessage,
    });
  }
}

export function* fetchResourcetByIdRequestWatcher() {
  yield takeLatest(
    START_FETCHING_RESOURCE_BY_ID_ACTION,
    fetchResourcetByIdRequestWorker
  );
}

function* fetchResourcetByIdRequestWorker({ payload }) {
  const fetchResourcetByIdRequestResponse = yield call(
    fetchResourceByIdRequest,
    payload
  );
  if (fetchResourcetByIdRequestResponse.status) {
    yield put({
      type: SUCCESS_FETCHING_RESOURCE_BY_ID_ACTION,
      data: fetchResourcetByIdRequestResponse.data.resource,
    });
  } else {
    const errorMessage = "Something went wrong";
    yield put({
      type: ERROR_FETCHING_RESOURCE_BY_ID_ACTION,
      errorMessage,
    });
  }
}

export function* updatePollByIdRequestWatcher() {
  yield takeLatest(
    START_UPDATING_POLL_BY_ID_ACTION,
    updatePollByIdRequestWorker
  );
}

function* updatePollByIdRequestWorker(payload) {
  const pollPayload = {
    poll: {
      ...payload.resource.polls[0],
    },
  };
  const updatePollByIdRequestResponse = yield call(
    updatePollByIdRequest,
    pollPayload
  );
  if (updatePollByIdRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATING_POLL_BY_ID_ACTION,
      data: updatePollByIdRequestResponse.data.resource,
    });
  } else {
    const errorMessage = "Something went wrong";
    yield put({
      type: ERROR_UPDATING_POLL_BY_ID_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleResourceNodeWatcher() {
  yield takeLatest(
    START_FETCHING_RESOURCE_NODE_ACTION,
    fetchModuleResourceNodeWorker
  );
}

export function* fetchModuleResourceNodeWorker({ payload }) {
  const fetchModuleResourceNodeRequestResponse = yield call(
    fetchModuleResourceNodeByIdRequest,
    payload
  );
  if (fetchModuleResourceNodeRequestResponse.status) {
    yield put({
      type: SUCCESS_FETCHING_RESOURCE_NODE_ACTION,
      data: fetchModuleResourceNodeRequestResponse?.data,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_FETCHING_RESOURCE_NODE_ACTION,
      errorMessage,
    });
  }
}

import api from "../api";

export function viewResourceNodeRequest(payload) {
  return api.get(
    `/module_documents/${payload.moduleId}/resource_nodes/${payload.resourceId}`
  );
}

export function submitResourceNodeRequest(payload) {
  return api.post(
    `/module_documents/${payload.module_id}/resource_nodes/${payload.resource_node_id}/submit`,
    { paramObj: payload }
  );
}

export function submitPollQuestionsRequest(payload) {
  return api.post(
    `polls/${payload?.poll_question_response?.id}/poll_question_responses`,
    { paramObj: payload }
  );
}

export function submitDiscussionEntriesRequest(payload) {
  return api.post(
    `discussions/${payload?.discussion_entry?.id}/discussion_entries`,
    {
      paramObj: payload,
    }
  );
}

import React from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ColorPic } from "./Common/ColorPic";
import "../../styles/fontSizer.scss";
import "../../styles/fontFamily.scss";
import "../../styles/pageResourceRichText.scss";
import { uploadImageCallback } from "./Common/ImageUpload";

export default function PageTypeResources({ value, setFieldValue }) {
  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = React.useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
  };

  return (
    <div className="">
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="py-4 m4"
        editorClassName="p-4 border border-solid text-box-border mt-4 h-auto min-hieght-300 custom-html-content-class"
        toolbarClassName="border-0-imp padding-0-imp"
        toolbar={{
          options: [
            "inline",
            "list",
            "history",
            "textAlign",
            "image",
            "colorPicker",
            "fontSize",
            "fontFamily",
            "link",
          ],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline", "strikethrough"],
            bold: {
              className: "m-0-imp border-right-0-imp text-box-border",
            },
            italic: {
              className: "m-0-imp text-box-border",
            },
            underline: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            strikethrough: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          list: {
            options: ["unordered", "ordered", "indent", "outdent"],
            unordered: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            ordered: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            indent: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            outdent: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          fontSize: {
            className: "fontSizer m-0-imp border-right-0-imp text-box-border",
          },
          fontFamily: {
            className: "m-0-imp text-box-border",
            options: [
              "Lato",
              "Gloria Hallelujah",
              "Cedarville Cursive",
              "Rock Salt",
              "Swanky and Moo Moo",
            ],
          },
          history: {
            options: ["undo", "redo"],
            undo: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            redo: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          colorPicker: {
            className: "m-0-imp border-left-0-imp text-box-border",
            component: ColorPic,
          },
          textAlign: {
            options: ["left", "center", "right", "justify"],
            left: {
              className:
                "mx-left-6px-imp m-0-imp border-right-0-imp text-box-border",
            },
            center: { className: "m-0-imp border-right-0-imp text-box-border" },
            right: { className: "m-0-imp border-right-0-imp text-box-border" },
            justify: { className: "m-0-imp text-box-border" },
          },
          image: {
            className:
              "mx-left-6px-imp mr-0px-imp border-right-0-imp text-box-border",
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: uploadImageCallback,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "100%",
            },
          },
          link: {
            options: ["link"],
            link: {
              className:
                "m-0-imp border-left-0-imp text-box-border custom-modal-link-class",
            },
          },
        }}
      />
    </div>
  );
}

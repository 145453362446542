import React from "react";
import ProfilePageForm from "./ProfilePageForm";
import Layout from "../../components/Layout";

export default function ProfilePage() {
  return (
    <Layout>
      <>
        <div className="py-3 text-base font-lato px-6 text-slate-600">
          Edit Profile
        </div>
        <div className="edit-profile-text"></div>
        <ProfilePageForm />
      </>
    </Layout>
  );
}

import React from "react";
import "../../styles/tabs.scss";
import ModulePage from "./Modules/ModulesPage";
import Layout from "../../components/Layout";
import ModuleSetsModuleTab from "../../components/Tabs/ModuleSetsModuleTab";

export default function TabViewModule(props) {
  return (
    <Layout>
      <ModuleSetsModuleTab />
      <div className="py-4">
        <ModulePage />
      </div>
    </Layout>
  );
}

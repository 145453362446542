import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children, exact, path, push, allowedRoles }) => {
  const token = localStorage.getItem("access_token");
  const userData = JSON.parse(localStorage.getItem("user"));
  if (!token) {
    return <Redirect to="/" push={push} />;
  }
  if (!allowedRoles || !Array.isArray(allowedRoles)) {
    // Handle the case when allowedRoles is undefined or not an array
    return <Redirect to="/" push={push} />;
  }
  if (!allowedRoles.includes(userData.role)) {
    return <Redirect to="/" push={push} />;
  }
  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

export default PrivateRoute;

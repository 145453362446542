import { call, put, takeLatest } from "redux-saga/effects";
import {
  VIEW_MODULE_FAILURE,
  VIEW_MODULE_REQUESTED,
  VIEW_MODULE_SUCCESS,
} from "../actions/view-module";

import { viewModuleRequest } from "../apis/view-module";
import {
  SUCCESS_DISCUSS_ENTRIES_ACTION,
  SUCCESS_POLL_QUESTIONS_ACTION,
} from "../actions/view-resource-node";

export function* viewModuleWatcher() {
  yield takeLatest(VIEW_MODULE_REQUESTED, viewModuleWorker);
}

function* viewModuleWorker({ payload }) {
  const fetchViewModalRequestResponse = yield call(viewModuleRequest, payload);
  if (fetchViewModalRequestResponse.status) {
    yield put({
      type: VIEW_MODULE_SUCCESS,
      data: fetchViewModalRequestResponse.data,
    });
    yield put({
      type: SUCCESS_POLL_QUESTIONS_ACTION,
      data: {},
    });
    yield put({
      type: SUCCESS_DISCUSS_ENTRIES_ACTION,
      data: {},
    });
  } else {
    const errorMessage =
      fetchViewModalRequestResponse?.data?.message || "Something went wrong!";

    yield put({
      type: VIEW_MODULE_FAILURE,
      errorMessage,
    });
  }
}

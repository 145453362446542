import { takeLatest, call, put } from "redux-saga/effects";
import {
  START_CLASSROOMS_LIST_ACTION,
  SUCCESS_CLASSROOMS_LIST_ACTION,
  ERROR_CLASSROOMS_LIST_ACTION,
  START_CREATE_CLASSROOM_ACTION,
  SUCCESS_CREATE_CLASSROOM_ACTION,
  FAILURE_CREATE_CLASSROOM_ACTION,
  START_UPDATE_CLASSROOMS_ACTION,
  SUCCESS_UPDATE_CLASSROOMS_ACTION,
  FAILURE_UPDATE_CLASSROOMS_ACTION,
  START_DELETE_CLASSROOMS_ACTION,
  SUCCESS_DELETE_CLASSROOMS_ACTION,
  FAILURE_DELETE_CLASSROOMS_ACTION,
  START_EXPORT_CLASSROOM_ACTION,
  SUCCESS_EXPORT_CLASSROOM_ACTION,
  FAILURE_EXPORT_CLASSROOM_ACTION,
  START_SHOW_CLASSROOM_ACTION,
  SUCCESS_SHOW_CLASSROOM_ACTION,
  FAILURE_SHOW_CLASSROOM_ACTION,
  START_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
  ERROR_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
  SUCCESS_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
} from "../actions/classrooms";
import {
  fetchClassroomsListAction,
  fetchCreateClassroomRequest,
  fetchClassroomUpdateAction,
  deleteClassroomRequest,
  fetchExportClassroomRequest,
  fetchClassroomShowRequest,
  fetchClassroomsNonPaginatedListAction,
} from "../apis/classrooms";
import { toast } from "react-toastify";

export function* fetchClassroomsListActionWatcher() {
  yield takeLatest(
    START_CLASSROOMS_LIST_ACTION,
    fetchClassroomsListActionWorker
  );
}

function* fetchClassroomsListActionWorker({ payload }) {
  const fetchClassroomsListActionResponse = yield call(
    fetchClassroomsListAction,
    payload
  );
  if (fetchClassroomsListActionResponse.status) {
    yield put({
      type: SUCCESS_CLASSROOMS_LIST_ACTION,
      data: fetchClassroomsListActionResponse.data.classrooms,
      meta: fetchClassroomsListActionResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_CLASSROOMS_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchClassroomCreateRequestWatcher() {
  yield takeLatest(START_CREATE_CLASSROOM_ACTION, fetchClassroomCreateWorker);
}

function* fetchClassroomCreateWorker({ payload }) {
  const { createClassroomData } = payload;
  const fetchClassroomCreateResponse = yield call(
    fetchCreateClassroomRequest,
    createClassroomData
  );
  if (fetchClassroomCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_CLASSROOM_ACTION,
      data: fetchClassroomCreateResponse.data.classroom,
    });
    yield call(fetchClassroomsListActionWorker, { payload: null });
    toast.success("Classroom Created Successfully");
  } else {
    const errorMessage =
      fetchClassroomCreateResponse?.message?.length > 0
        ? fetchClassroomCreateResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_CLASSROOM_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchClassroomUpdateActionWatcher() {
  yield takeLatest(
    START_UPDATE_CLASSROOMS_ACTION,
    fetchClassroomUpdateActionWorker
  );
}

function* fetchClassroomUpdateActionWorker({ payload }) {
  const { updateClassroomData, currentPage, setIsOpenEditFormModal } = payload;
  const updateClassroomRequestResponse = yield call(
    fetchClassroomUpdateAction,
    updateClassroomData
  );
  if (updateClassroomRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_CLASSROOMS_ACTION,
      data: updateClassroomRequestResponse.data,
    });
    setIsOpenEditFormModal(false);
    yield call(fetchClassroomsListActionWorker, {
      payload: { page: currentPage },
    });
    toast.success("Classroom Updated Successfully");
  } else {
    const errorMessage =
      updateClassroomRequestResponse?.data?.message?.length > 0
        ? updateClassroomRequestResponse.data.message
        : "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_CLASSROOMS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchClassroomDeleteRequestWatcher() {
  yield takeLatest(
    START_DELETE_CLASSROOMS_ACTION,
    fetchClassroomDeleteRequestWorker
  );
}

function* fetchClassroomDeleteRequestWorker({ payload }) {
  const { deleteClassroomRequestData, setIsOpenConfirmModal } = payload;
  const deleteClassroomRequestResponse = yield call(
    deleteClassroomRequest,
    deleteClassroomRequestData
  );
  if (deleteClassroomRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_CLASSROOMS_ACTION,
      data: deleteClassroomRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchClassroomsListActionWorker, { payload: null });
    toast.success("Classroom Deleted Successfully");
  } else {
    const errorMessage =
      deleteClassroomRequestResponse?.message?.length > 0
        ? deleteClassroomRequestResponse.message
        : "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_CLASSROOMS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchClassroomExportRequestWatcher() {
  yield takeLatest(START_EXPORT_CLASSROOM_ACTION, fetchClassroomExportWorker);
}

function* fetchClassroomExportWorker({ payload }) {
  const fetchClassroomExportResponse = yield call(
    fetchExportClassroomRequest,
    payload
  );
  if (fetchClassroomExportResponse.status) {
    yield put({
      type: SUCCESS_EXPORT_CLASSROOM_ACTION,
      data: fetchClassroomExportResponse.data,
    });
    payload.downloadCSVFile(fetchClassroomExportResponse.data)
    // yield call(fetchClassroomsListActionWorker, { payload: null });
    toast.success("Classroom Exported Successfully");
  } else {
    const errorMessage =
      fetchClassroomExportResponse?.message?.length > 0
        ? fetchClassroomExportResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_EXPORT_CLASSROOM_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchClassroomShowRequestWatcher() {
  yield takeLatest(
    START_SHOW_CLASSROOM_ACTION,
    fetchClassroomShowRequestWorker
  );
}

function* fetchClassroomShowRequestWorker({ payload }) {
  const { id } = payload;

  const fetchClassroomShowRequestResponse = yield call(
    fetchClassroomShowRequest,
    id
  );
  if (fetchClassroomShowRequestResponse.status) {
    yield put({
      type: SUCCESS_SHOW_CLASSROOM_ACTION,
      data: fetchClassroomShowRequestResponse.data.classroom,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_SHOW_CLASSROOM_ACTION,
      errorMessage,
    });
  }
}

export function* fetchClassroomsNonPaginatedListActionWatcher() {
  yield takeLatest(
    START_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
    fetchClassroomsNonPaginatedListActionWorker
  );
}

function* fetchClassroomsNonPaginatedListActionWorker() {
  const fetchClassroomsListActionResponse = yield call(
    fetchClassroomsNonPaginatedListAction
  );
  if (fetchClassroomsListActionResponse.status) {
    yield put({
      type: SUCCESS_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
      data: fetchClassroomsListActionResponse.data.classrooms,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_CLASSROOMS_NON_PAGINATED_LIST_ACTION,
      errorMessage,
    });
  }
}

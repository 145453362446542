import * as React from "react";
import "./../../styles/icon.scss";
import classNames from "classnames";

const Icon = (props) => {
  const label = props.label;
  const tooltip = props.title;
  const style_classes =
    label === "close"
      ? "material-symbols-outlined text-white-87 m-1.5"
      : "material-symbols-outlined icon-button cursor-pointer ease-in-out";

  return (
    <span
      // className={style_classes}
      className={classNames(props.className, style_classes)}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={tooltip}
    >
      {label}
    </span>
  );
};

export default Icon;

import React, { useState, useEffect, useRef } from "react";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";
import { useTable, usePagination } from "react-table";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import { Draggable, Droppable } from "react-beautiful-dnd";
import CloneModal from "../CloneModal";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import { useDispatch } from "react-redux";
import { START_DELETE_RESOURCE_ACTION } from "../../../rest-client/actions/resources";
import { ClipLoader } from "react-spinners";
import Pagination from "../../../components/Pagination/pagination";
import {
  CANVAS_TYPE_RESOURCE,
  PAGE_SIZE,
  PAGE_TYPE_RESOURCE,
  POLL_TYPE_RESOURCE,
  RESULT_TYPE_RESOURCE,
} from "../../../utils";

export default function ResourcesContainer({
  resourcesData,
  resourceOrderType,
  setResourceOrderType,
  resourceSearchInput,
  setResourceSearchInput,
  isLoadingResourcesList,
  totalPages,
  currentPage,
  setCurrentPage,
  inputResourceRef,
}) {
  const { url } = useRouteMatch(`/dashboard/learning`);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenRowDropdown, setIsOpenRowDropdown] = useState();
  const [isOpenCloneModal, setIsOpenCloneModal] = useState(false);
  const [resourceData, setResourceData] = React.useState({});
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isVisibleResourceSearchBox, setIsVisibleResourceSearchBox] =
    useState(false);
  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
      },
    ],
    []
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenRowDropdown();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const tableInstance = useTable(
    {
      columns,
      data: resourcesData || [],
      initialState: { pageSize: PAGE_SIZE },
    },
    usePagination
  );
  const { getTableProps, getTableBodyProps, page, prepareRow } = tableInstance;

  const openCloneModalHandler = React.useCallback((value) => {
    setIsOpenCloneModal(true);
    setResourceData(value);
  }, []);

  const openConfirmModalHandler = React.useCallback((value) => {
    setIsOpenConfirmModal(true);
    setResourceData(value);
  }, []);

  const deleteResourceHandler = React.useCallback(() => {
    const deleteResourceRequestData = {
      resource: {
        ...resourceData,
      },
      id: resourceData.id,
    };
    dispatch({
      type: START_DELETE_RESOURCE_ACTION,
      payload: {
        deleteResourceRequestData,
        setIsOpenConfirmModal,
        currentPage,
      },
    });
  }, [dispatch, resourceData]);

  return isLoadingResourcesList ? (
    <div className="w-1/2 flex flex-col mt-20 justify-center items-center h-32">
      <ClipLoader size={32} color={"#3e8ede"} />
      <p className="mt-2 font-bold font-muli text-blue-theme">
        Fetching Resources...
      </p>
    </div>
  ) : (
    <>
      {isOpenConfirmModal && (
        <ConfirmModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          deleteRecordHandler={deleteResourceHandler}
        />
      )}
      {isOpenCloneModal && (
        <CloneModal
          isOpenCloneModal={isOpenCloneModal}
          setIsOpenCloneModal={setIsOpenCloneModal}
          clonedObjectData={resourceData}
          setCurrentPage={setCurrentPage}
          clonedObjectName="Resource"
        />
      )}
      <div className="w-1/2">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex justify-start align-middle text-center items-center">
            <h3 className="form_title p-2 ml-2">Resources</h3>
          </div>
          <div className="py-7 flex items-center justify-end mr-6">
            <Link
              to={`${url}/resource`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                label="add"
                title="Add Resource"
                className="text-light-gray"
              />
            </Link>
            <button
              className="border-none bg-transparent"
              onClick={() => {
                setResourceOrderType(
                  resourceOrderType === "desc" ? "asc" : "desc"
                );
              }}
            >
              <Icon
                label="sort_by_alpha"
                title="Sort Resources"
                className="text-light-gray"
              />
            </button>
            <button
              className="border-none bg-transparent"
              onClick={() => {
                setIsVisibleResourceSearchBox(!isVisibleResourceSearchBox);
              }}
            >
              <Icon
                label="search"
                title="Search"
                className={classNames("text-light-gray")}
              />
            </button>
            <div>
              <input
                type="text"
                id="search"
                className="search-input text-base w-full"
                placeholder="Search"
                style={{
                  display: isVisibleResourceSearchBox ? "block" : "none",
                }}
                value={resourceSearchInput}
                onChange={(e) => setResourceSearchInput(e.target.value)}
                ref={inputResourceRef}
              />
            </div>
          </div>
        </div>
        <Droppable droppableId="resources" type="person" key="resources">
          {(provided) => (
            <div
              className="p-2 ml-2 mr-6 height-65 overflow-scroll resources-sets-box"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {resourcesData.length > 0 ? (
                <table
                  {...getTableProps()}
                  className="w-full divide-y divide-gray-200"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 1rem",
                  }}
                >
                  <tbody
                    {...getTableBodyProps()}
                    className="divide-y divide-gray-200"
                  >
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <Draggable
                          draggableId={row.original.id}
                          index={index}
                          key={row.original.id}
                        >
                          {(provided) => (
                            <tr
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              {...row.getRowProps()}
                              className={classNames(
                                "shadow border-spacing-1 border-spacing-x-2.5 flex justify-between mb-3 relative",
                                {
                                  "bg-blue-99":
                                    row.original.resource_type ===
                                    PAGE_TYPE_RESOURCE,
                                  "bg-orange-243":
                                    row.original.resource_type ===
                                    POLL_TYPE_RESOURCE,
                                  "bg-pink-203":
                                    row.original.resource_type ===
                                    RESULT_TYPE_RESOURCE,
                                  "bg-purple-80":
                                    row.original.resource_type ===
                                    CANVAS_TYPE_RESOURCE,
                                }
                              )}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...cell.getCellProps({
                                      className:
                                        "pl-6 py-2 text-base text-gray-800 whitespace-nowrap font-bold resources-box-items",
                                    })}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                              <td className="py-2 flex items-center justify-end">
                                {Array.isArray(
                                  row.original?.module_documents
                                ) &&
                                row.original?.module_documents?.length > 0 ? (
                                  <button
                                    className="border-none bg-transparent"
                                    onClick={() => {}}
                                  >
                                    <Icon
                                      label="link"
                                      title={
                                        row.original?.module_documents
                                          ? `Assigned to${row.original?.module_documents.map(
                                              (e) => ` ${e.title}`
                                            )}`
                                          : null
                                      }
                                      className={classNames("text-gray-800")}
                                    />
                                  </button>
                                ) : null}
                                <button
                                  className="border-none bg-transparent"
                                  id={row.id}
                                  onClick={() => {
                                    setIsOpenRowDropdown(row.original.id);
                                  }}
                                >
                                  <Icon
                                    label="more_vert"
                                    title=""
                                    className={classNames("text-black")}
                                  />
                                </button>
                                {isOpenRowDropdown === row.original.id && (
                                  <div
                                    className="absolute z-20 w-48 py-2 mt-2 origin-top-right bg-white rounded-md shadow-xl dark:bg-gray-800"
                                    ref={dropdownRef}
                                    style={{
                                      top:
                                        index === 0
                                          ? "-10px"
                                          : index === 1 || index === 2
                                          ? "-18px"
                                          : index === page.length - 1 ||
                                            index === page.length - 2 ||
                                            index === page.length - 3
                                          ? "-180px"
                                          : 0,
                                      right: 0, // Position dropdown menu at the right edge of the parent <tr>
                                      zIndex: 9999, // Set a high z-index value to ensure the dropdown menu appears on top of other elements
                                    }}
                                  >
                                    {row.original.resource_type !==
                                      "results" && (
                                      <a
                                        href="#"
                                        className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          history.push(
                                            `${url}/resource/${row.original.id}`
                                          );
                                        }}
                                      >
                                        <Icon
                                          label="visibility"
                                          title=""
                                          className={classNames(
                                            "text-light-gray mx-1"
                                          )}
                                        />
                                        <span className="absolute">
                                          View Resource
                                        </span>
                                      </a>
                                    )}
                                    <a
                                      href={`${url}/resource/${row.original.id}/edit`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                      onClick={(e) => {
                                        setIsOpenRowDropdown();
                                      }}
                                    >
                                      <Icon
                                        label="edit"
                                        title=""
                                        className={classNames(
                                          "text-light-gray mx-1"
                                        )}
                                      />
                                      <span className="absolute">
                                        Edit Resource
                                      </span>
                                    </a>
                                    <a
                                      href={"/"}
                                      className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIsOpenRowDropdown();
                                        openCloneModalHandler(row.original);
                                      }}
                                    >
                                      <Icon
                                        label="content_copy"
                                        title=""
                                        className={classNames(
                                          "text-light-gray mx-1"
                                        )}
                                      />
                                      <span className="absolute">
                                        Clone Resource
                                      </span>
                                    </a>
                                    <a
                                      href={"/"}
                                      className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIsOpenRowDropdown();
                                        openConfirmModalHandler(row.original);
                                      }}
                                    >
                                      <Icon
                                        label="delete"
                                        title=""
                                        className={classNames(
                                          "text-light-gray mx-1"
                                        )}
                                      />
                                      <span className="absolute">
                                        Delete Resource
                                      </span>
                                    </a>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                </table>
              ) : (
                <div className="text-center p-10 text-base">
                  No resources available, Click <b>+</b> to create one.
                </div>
              )}
            </div>
          )}
        </Droppable>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
}

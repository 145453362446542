import React from "react";
import { Formik, Form, Field } from "formik";
import "../../styles/landingPage.scss";
import { useDispatch } from "react-redux";
import { START_RECOVERY_PASSWORD } from "../../rest-client/actions/login";
import { useLogin } from "../../rest-client/selectorHook";
import classNames from "classnames";

export default function ResetPasswordForm({ setIsOpenLoginModal }) {
  const dispatch = useDispatch();

  const {
    user_recovery_password: { isError: userRecoveryPasswordError },
  } = useLogin();

  const initialFormValues = {
    recovery_email_username: "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );
  const recoveryPasswordRequestHandler = React.useCallback(
    (values) => {
      if (values) {
        const recoveryPasswordRequestData = {
          user: {
            email: values.recovery_email_username,
          },
          passwordRecovery: "password_recovery",
        };
        dispatch({
          type: START_RECOVERY_PASSWORD,
          payload: {
            recoveryPasswordRequestData,
            setIsOpenLoginModal,
          },
        });
      }
    },
    [dispatch]
  );
  const validationRecoveryPasswordHandler = React.useCallback((values) => {
    const error = {};
    if (!values.recovery_email_username) {
      error.recovery_email_username = "Please enter your email or username";
    }
    return error;
  }, []);
  return (
    <>
      <Formik
        initialValues={initialFormValues}
        validate={validationRecoveryPasswordHandler}
        onSubmit={recoveryPasswordRequestHandler}
      >
        {({
          values,
          errors,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato">
            <>
              <div className="mt-10 flex flex-column box-border w-full">
                <div className="w-4/5 relative align-middle p-0.5 my-4 mr-1.5">
                  <Field
                    type="text"
                    name="recovery_email_username"
                    required
                    className={classNames(
                      "w-full input-class-login border-0 outline-none mt-0 pl-0 pt-0.5 pb-px pr-0.5 h-5 rounded-none border-solid",
                      {
                        "error-input-class": errors.recovery_email_username,
                      }
                    )}
                    id="recovery_email_username"
                    value={values.recovery_email_username}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "recovery_email_username",
                        e.target.value
                      );
                    }}
                  />
                  {errors.recovery_email_username && (
                    <p className="error-label-class mt-25">
                      {errors.recovery_email_username ?? "-"}
                    </p>
                  )}
                  <label
                    className={classNames(
                      "label-class-login flex items-center cursor-text absolute top-0 left-0.5 font-lato",
                      {
                        "error-label-class": errors.recovery_email_username,
                      }
                    )}
                    for="recovery_email_username"
                  >
                    Email / Username
                  </label>
                </div>
                <div className="w-1/7 flex box-border items-center mb-3">
                  <button
                    className="w-30 login-button-without-icon text-white-87 bg-theme-blue box-shadow-btn cursor-pointer align-middle items-center text-center box-border select-none outline-none border-0 py-0 px-2 font-medium font-lato text-sm"
                    type="submit"
                  >
                    <span className="ng-binding ng-scope">Reset Password</span>
                  </button>
                </div>
              </div>
              {userRecoveryPasswordError ? (
                <div className="-mt-1 error-label-class">
                  {userRecoveryPasswordError}
                </div>
              ) : null}
            </>
          </Form>
        )}
      </Formik>
    </>
  );
}

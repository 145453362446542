import React from "react";
import "../../styles/sideNavBar.scss";
import bioScannWhiteLogo from "../../images/img_bioscannlogo_white.svg";
import logout from "../../images/logout.svg";
import tuftsFooterSmallLogo from "../../images/Center_for_Science_Education_Horiz_wh.svg";
import { useHistory, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import Icon from "../Button/Icon";

export default function SideNavBar() {
  // const [role, setRole] = React.useState("teacher");
  const [panelData, setPanelData] = React.useState([]);
  const userData = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();

  const { url } = useRouteMatch(`/dashboard`);

  const getInitials = React.useMemo(() => {
    var parts = userData.full_name.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials;
  }, [userData]);

  const checkIsTabActive = React.useCallback(
    (pathName) => {
      return history && history.location.pathname.includes(pathName);
    },
    [history]
  );

  const tabChangeHandler = React.useCallback(
    (path) => {
      history.push(`${url}/${path}`);
    },
    [history]
  );

  const adminPanelData = React.useMemo(() => {
    return [
      {
        tabId: 1,
        imgAddress: "dvr",
        navTab: "Content Creation",
        isActive: checkIsTabActive("learning"),
        onClick: () => tabChangeHandler(`learning/index/module_sets`),
      },
      {
        tabId: 2,
        imgAddress: "lan",
        navTab: "Administration",
        isActive: checkIsTabActive("administration"),
        onClick: () => tabChangeHandler(`administration`),
      },
      {
        tabId: 3,
        imgAddress: "person",
        navTab: "Profile",
        isActive: checkIsTabActive("profile"),
        onClick: () => tabChangeHandler(`profile`),
      },
    ];
  }, [history.location.pathname]);

  const teacherPanelData = React.useMemo(() => {
    return [
      {
        tabId: 1,
        imgAddress: "calendar_month",
        navTab: "Manage Sessions",
        isActive: checkIsTabActive("manage-sessions"),
        onClick: () => tabChangeHandler(`manage-sessions`),
      },
      {
        tabId: 2,
        imgAddress: "lan",
        navTab: "Classroom Configuration",
        isActive: checkIsTabActive("classroom-configuration"),
        onClick: () => tabChangeHandler(`classroom-configuration`),
      },
      {
        tabId: 3,
        imgAddress: "visibility",
        navTab: "View Modules",
        isActive: checkIsTabActive("modules"),
        onClick: () => tabChangeHandler(`modules`),
      },
      {
        tabId: 4,
        imgAddress: "person",
        navTab: "Profile",
        isActive: checkIsTabActive("profile"),
        onClick: () => tabChangeHandler(`profile`),
      },
    ];
  }, [history.location.pathname]);

  const groupsPanelData = React.useMemo(() => {
    return [
      {
        tabId: 1,
        imgAddress: "dashboard",
        navTab: "Dashboard",
        isActive: checkIsTabActive("index"),
        onClick: () => tabChangeHandler(`index`),
      },
      {
        tabId: 2,
        imgAddress: "table_chart_view",
        navTab: "Results Page",
        isActive: checkIsTabActive("results"),
        onClick: () => tabChangeHandler(`results`),
      },
    ];
  }, [history.location.pathname]);

  React.useEffect(() => {
    if (userData.role === "admin" || userData.role === "superadmin") {
      setPanelData(adminPanelData);
    } else if (userData.role === "Teacher") {
      setPanelData(teacherPanelData);
    } else if (userData.role === "Learner") {
      setPanelData(groupsPanelData);
    }
  }, [userData]);
  function logoutHandler() {
    if (localStorage.getItem("access_token")) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      history.push("/");
    }
  }
  return (
    <div>
      <div className="nav-bar-side font-lato">
        <div className="sidebar">
          <div className="logo-side">
            <img
              src={bioScannWhiteLogo}
              alt="whiteLogo"
              className="menu-icon-side ml-8"
            />
            <div className="nav-divider mt-4"></div>
          </div>
          <div className="flex justify-between pt-4 pl-2 pb-0 items-center">
            <div className="flex-shrink-0 flex items-center justify-center bg-gray-100 w-14 h-14 rounded-full text-theme-blue text-base font-bold">
              {getInitials}
            </div>
            <div className="flex flex-col p-2 text-sm text-white">
              <div className="text-base text-white font-black truncate w-32">
                {userData?.role == "Learner"
                  ? userData?.first_name
                  : userData?.full_name || "-"}
              </div>
              {userData?.role !== "admin" && (
                <div className="text-sm text-white truncate w-36">
                  {userData?.role === "Teacher"
                    ? userData?.school?.name
                    : userData?.classroom?.school?.name}
                </div>
              )}
            </div>
          </div>
          <div className="sidebar-content">
            <ul className="lists">
              {panelData
                ? panelData.map((item) => (
                    <li
                      className="list"
                      key={item.tabId}
                      onClick={item.onClick}
                    >
                      <div
                        className={classNames("nav-link-side cursor-pointer", {
                          "active-nav-side-link": item.isActive,
                        })}
                      >
                        <Icon
                          label={item.imgAddress}
                          className={classNames(
                            "icon-side text-white-side-nav",
                            {
                              "active-icon-side active-link": item.isActive,
                            }
                          )}
                        />

                        <span
                          className={classNames("link", {
                            "active-link": item.isActive,
                          })}
                        >
                          {item.navTab}
                        </span>
                      </div>
                    </li>
                  ))
                : null}
            </ul>
            <div className="bottom-cotent fixed bottom-0 left-6">
              <li className="list">
                <div
                  className="nav-link-side cursor-pointer"
                  onClick={logoutHandler}
                >
                  <img src={logout} alt="logout" className="icon-side pb-5" />
                  <span className="link !font-bold pb-5">Logout</span>
                </div>
              </li>
              <li className="list">
                <div className="w-44 h-auto">
                  <img
                    src={tuftsFooterSmallLogo}
                    alt="tufts logo"
                    className="pb-2"
                  />
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import Icon from "../../../components/Button/Icon";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import TimeLineView from "../../../components/TimeLine/TimeLineView";
import { useDispatch } from "react-redux";
import { VIEW_MODULE_REQUESTED } from "../../../rest-client/actions/view-module";
import { useViewModule } from "../../../rest-client/selectorHook";
import { ClipLoader } from "react-spinners";
import Layout from "../../../components/Layout";
import { LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION } from "../../../rest-client/actions/resources";

export default function ViewModule() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const path =
    userData?.role === "Teacher" || userData?.role === "Learner"
      ? "/dashboard"
      : "/dashboard/learning";
  const { url } = useRouteMatch(path);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const fetchData = useViewModule();

  const backClickHandler = React.useCallback(() => {
    userData?.role === "Teacher"
      ? history.push(`${url}/modules`)
      : userData?.role === "admin"
      ? history.push(`${url}/index/modules`)
      : history.push(`${url}/index`);
  }, [history, url]);

  useEffect(() => {
    if (id) {
      dispatch({
        type: VIEW_MODULE_REQUESTED,
        payload: id,
      });
    }
  }, [id]);

  const deletedUserErrorHandler = React.useCallback(() => {
    dispatch({
      type: LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION,
    });
  }, [dispatch]);

  return (
    <Layout>
      <div className="flex py-3 text-base font-lato px-6">
        <button
          className="border-none bg-transparent cursor-pointer"
          onClick={() => {
            backClickHandler();
            deletedUserErrorHandler();
          }}
        >
          <Icon
            label="arrow_back_ios"
            title=""
            className={classNames("text-light-gray")}
          />
        </button>
        <span
          className="text-slate-600 cursor-pointer"
          onClick={() => {
            backClickHandler();
            deletedUserErrorHandler();
          }}
        >
          Back to {userData?.role === "Learner" ? "Dashboard" : "Modules"}
        </span>
      </div>
      <div className="edit-profile-text"></div>
      {fetchData?.view_module_data?.isLoading ? (
        <div className="flex flex-col justify-center items-center h-32">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">Loading...</p>
        </div>
      ) : (
        fetchData?.view_module_data?.data?.module_document?.resource_nodes && (
          <div className="mt-8">
            <TimeLineView
              moduleId={id}
              timelineData={fetchData?.view_module_data?.data?.module_document}
              userRole={userData?.role}
            />
          </div>
        )
      )}
    </Layout>
  );
}

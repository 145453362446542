import { call, put, takeLatest } from "redux-saga/effects";
import {
  FAILURE_ADD_CANVAS_IMAGE_UPLOAD,
  FAILURE_DISCUSS_ENTRIES_ACTION,
  FAILURE_POLL_QUESTIONS_ACTION,
  START_ADD_CANVAS_IMAGE_UPLOAD,
  START_DISCUSS_ENTRIES_ACTION,
  START_POLL_QUESTIONS_ACTION,
  SUBMIT_PAGE_RESOURCE_NODE_FAILURE,
  SUBMIT_PAGE_RESOURCE_NODE_REQUESTED,
  SUBMIT_PAGE_RESOURCE_NODE_SUCCESS,
  SUCCESS_ADD_CANVAS_IMAGE_UPLOAD,
  SUCCESS_DISCUSS_ENTRIES_ACTION,
  SUCCESS_POLL_QUESTIONS_ACTION,
  VIEW_RESOURCE_NODE_FAILURE,
  VIEW_RESOURCE_NODE_REQUESTED,
  VIEW_RESOURCE_NODE_SUCCESS,
} from "../actions/view-resource-node";
import {
  submitDiscussionEntriesRequest,
  submitPollQuestionsRequest,
  submitResourceNodeRequest,
  viewResourceNodeRequest,
} from "../apis/view-resource-node";
import { addCanvasImagesRequest } from "../apis/resources";
import { toast } from "react-toastify";
import { fetchModuleResourceNodeWorker } from "./resources";

export function* viewResourceNodeWatcher() {
  yield takeLatest(VIEW_RESOURCE_NODE_REQUESTED, viewResourceNodeWorker);
}
function* viewResourceNodeWorker({ payload }) {
  const fetchViewResourceNodeRequestResponse = yield call(
    viewResourceNodeRequest,
    payload
  );

  if (fetchViewResourceNodeRequestResponse.status) {
    yield put({
      type: VIEW_RESOURCE_NODE_SUCCESS,
      data: fetchViewResourceNodeRequestResponse.data,
    });
    yield call(fetchModuleResourceNodeWorker, {
      payload: { moduleId: payload.moduleId },
    });
  } else {
    const errorMessage =
      fetchViewResourceNodeRequestResponse?.data?.message ||
      "Something went wrong!";
    yield put({
      type: VIEW_RESOURCE_NODE_FAILURE,
      errorMessage,
    });
  }
}

export function* submitResourceNodeWatcher() {
  yield takeLatest(
    SUBMIT_PAGE_RESOURCE_NODE_REQUESTED,
    submitResourceNodeWorker
  );
}
function* submitResourceNodeWorker({ payload }) {
  const payloadObj = {
    resource_node_id: payload.resourceId,
    module_id: payload.moduleId,
  };
  const fetchViewResourceNodeRequestResponse = yield call(
    submitResourceNodeRequest,
    payloadObj
  );

  if (fetchViewResourceNodeRequestResponse.status) {
    yield put({
      type: SUBMIT_PAGE_RESOURCE_NODE_SUCCESS,
      data: fetchViewResourceNodeRequestResponse.data,
    });
    payload.setIsSubmit(true);
  } else {
    const errorMessage =
      fetchViewResourceNodeRequestResponse?.data?.message ||
      "Something went wrong!";
    yield put({
      type: SUBMIT_PAGE_RESOURCE_NODE_FAILURE,
      errorMessage,
    });
    payload.setIsSubmit(false);
  }
}

export function* uploadCanvasImageWatcher() {
  yield takeLatest(START_ADD_CANVAS_IMAGE_UPLOAD, uploadCanvasImageWorker);
}

function* uploadCanvasImageWorker({ payload }) {
  const formData = new FormData();
  formData.append("file", payload.canvasImageResponse);
  const addCanvasBgImgRequestResponse = yield call(
    addCanvasImagesRequest,
    formData
  );
  if (addCanvasBgImgRequestResponse.status) {
    yield put({
      type: SUCCESS_ADD_CANVAS_IMAGE_UPLOAD,
      data: addCanvasBgImgRequestResponse.data.media,
    });
    payload.setIsOpenConfirmModal(false);
  } else {
    const errorMessage = "Something went wrong";
    yield put({
      type: FAILURE_ADD_CANVAS_IMAGE_UPLOAD,
      errorMessage,
    });
    payload.setIsOpenConfirmModal(false);
    toast.error(errorMessage);
  }
}

export function* submitPollQuestionsWatcher() {
  yield takeLatest(START_POLL_QUESTIONS_ACTION, submitPollQuestionsWorker);
}
function* submitPollQuestionsWorker({ payload }) {
  const payloadObj = { poll_question_response: payload.poll_question_response };
  const fetchViewResourceNodeRequestResponse = yield call(
    submitPollQuestionsRequest,
    payloadObj
  );

  if (fetchViewResourceNodeRequestResponse.status) {
    yield put({
      type: SUCCESS_POLL_QUESTIONS_ACTION,
      data: fetchViewResourceNodeRequestResponse.data,
    });
    payload.setIsOpenConfirmModal(false);
  } else {
    const errorMessage =
      fetchViewResourceNodeRequestResponse?.message[0] ||
      "Something went wrong!";
    yield put({
      type: FAILURE_POLL_QUESTIONS_ACTION,
      errorMessage,
    });
    payload.setIsOpenConfirmModal(false);
    toast.error(errorMessage);
  }
}

export function* submitDiscussionEntriesWatcher() {
  yield takeLatest(START_DISCUSS_ENTRIES_ACTION, submitDiscussionEntriesWorker);
}
function* submitDiscussionEntriesWorker({ payload }) {
  const fetchViewResourceNodeRequestResponse = yield call(
    submitDiscussionEntriesRequest,
    payload
  );

  if (fetchViewResourceNodeRequestResponse.status) {
    yield put({
      type: SUCCESS_DISCUSS_ENTRIES_ACTION,
      data: fetchViewResourceNodeRequestResponse.data,
    });
  } else {
    const errorMessage =
      fetchViewResourceNodeRequestResponse?.data?.message ||
      "Something went wrong!";
    yield put({
      type: FAILURE_DISCUSS_ENTRIES_ACTION,
      errorMessage,
    });
  }
}

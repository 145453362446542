import { takeLatest, call, put } from "redux-saga/effects";
import {
  ERROR_CONTACT_US_ACTION,
  START_CONTACT_US_ACTION,
  SUCCESS_CONTACT_US_ACTION,
} from "../actions/contact-us";
import { fetchContactUsAction } from "../apis/contact-us";

export function* fetchContactUsActionWatcher() {
  yield takeLatest(START_CONTACT_US_ACTION, fetchContactUsActionWorker);
}

function* fetchContactUsActionWorker({ payload }) {
  const fetchContactUsActionResponse = yield call(
    fetchContactUsAction,
    payload
  );
  if (fetchContactUsActionResponse.status) {
    yield put({
      type: SUCCESS_CONTACT_US_ACTION,
      data: fetchContactUsActionResponse.data.contactus,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_CONTACT_US_ACTION,
      errorMessage,
    });
  }
}

import React, { useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_CREATE_CLASSROOM_ACTION } from "../../../rest-client/actions/classrooms";
import { START_SCHOOLS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/schools";
import { START_MODULE_SET_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/module-sets";
import { START_TEACHERS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/teachers";
import {
  useModuleSet,
  useTeachers,
  useSchools,
} from "../../../rest-client/selectorHook";
import MultiSelect from "../../../components/Dropdowns/MultiSelect";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import { ClipLoader } from "react-spinners";

export default function AddClassroomForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch({
      type: START_SCHOOLS_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  useEffect(() => {
    if (userData?.role === "admin" || userData?.role === "superadmin") {
      dispatch({
        type: START_TEACHERS_NON_PAGINATED_LIST_ACTION,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: START_MODULE_SET_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  const {
    schools_non_paginated_list_data: {
      isLoading: schoolListLoading,
      data: schoolListData,
    },
  } = useSchools();

  const {
    teachers_non_paginated_list_data: {
      isLoading: teacherListLoading,
      data: teacherListData,
    },
  } = useTeachers();

  const {
    module_sets_non_paginated_list_data: {
      isLoading: moduleSetListLoading,
      data: moduleSetListData,
    },
  } = useModuleSet();

  const initialFormValues = {
    title: "",
    description: "",
    teacher: "",
    school: "",
    module_sets: [],
  };

  const setFieldDataHandler = useCallback((setFieldValue, field, value) => {
    setFieldValue(field, value);
  }, []);

  const validationHandler = useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter First Name";
    }
    if (!values.description) {
      error.description = "Please enter Description";
    }
    if (!values.school) {
      error.school = "Please Select School";
    }
    if (userData?.role !== "Teacher" && !values.teacher) {
      error.teacher = "Please Select Teacher";
    }
    if (values.module_sets.length == 0) {
      error.module_sets = "Please Select Module Sets";
    }
    return error;
  }, []);

  const formattedModuleSetValues = (module_sets) => {
    const moduleSetArray = [];
    module_sets.map((module_set_id) => {
      moduleSetArray.push({ id: module_set_id });
    });
    return moduleSetArray;
  };

  const createClassroomHandler = React.useCallback(
    (values, { resetForm }) => {
      const createClassroomData = {
        classroom: {
          title: values.title.trimStart().trimEnd(),
          description: values.description.trimStart().trimEnd(),
          school_id: values.school?.value,
          teacher_id: values.teacher?.value,
          module_sets: formattedModuleSetValues(values.module_sets),
        },
      };
      dispatch({
        type: START_CREATE_CLASSROOM_ACTION,
        payload: { createClassroomData, history },
      });
      resetForm();
    },
    [dispatch]
  );

  const options = moduleSetListData.map((module_set, index) => {
    return {
      label: module_set.title,
      value: module_set.id,
    };
  });

  const schoolOptions = schoolListData.map((school, index) => {
    return {
      label: school.name,
      value: school.id,
    };
  });

  const teacherOptions = teacherListData.map((teacher, index) => {
    return {
      label: teacher.full_name,
      value: teacher.id,
    };
  });

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={createClassroomHandler}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="font-lato">
          <div className="flex flex-col md:flex-row box-border">
            <div className="flex-1 w-30">
              <div className="field-holder !mb-0">
                <Field
                  type="text"
                  id="title"
                  className={classNames("input-class", {
                    "error-input-class": errors.title && touched.title,
                  })}
                  value={values.title}
                  onChange={(e) => {
                    setFieldDataHandler(setFieldValue, "title", e.target.value);
                  }}
                  maxLength={50}
                  tabIndex="1"
                  required
                />
                <label
                  htmlFor="title"
                  className={classNames("label-class", {
                    "error-label-class": errors.title && touched.title,
                  })}
                >
                  Title *
                </label>
                {errors.title && touched.title && (
                  <p className="error-label-class mt-25">
                    {errors.title ?? "-"}
                  </p>
                )}
              </div>
              {userData?.role !== "Teacher" && (
                <div className="">
                  <Field name="teacher">
                    {({ field, form, meta }) => (
                      <SingleSelect
                        field={field}
                        form={form}
                        meta={meta}
                        options={teacherOptions}
                        placeholder="Teacher*"
                        isLoading={teacherListLoading}
                        tabIndex="3"
                        loadingMessage={() => (
                          <ClipLoader size={32} color={"#3e8ede"} />
                        )}
                      />
                    )}
                  </Field>
                </div>
              )}
              <div
                className={userData?.role !== "Teacher" ? "field-holder" : ""}
              >
                <Field name="module_sets">
                  {({ field, form, meta }) => (
                    <MultiSelect
                      field={field}
                      form={form}
                      meta={meta}
                      options={options}
                      placeholder="Assign Course*"
                      isLoading={moduleSetListLoading}
                      tabIndex={userData?.role === "Teacher" ? "3" : "5"}
                      loadingMessage={() => (
                        <ClipLoader size={32} color={"#3e8ede"} />
                      )}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="flex-1 w-full md:w-70 md:mx-10">
              <div className="field-holder !mb-0">
                <Field
                  type="text"
                  id="description"
                  className={classNames("input-class", {
                    "error-input-class":
                      errors.description && touched.description,
                  })}
                  value={values.description}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "description",
                      e.target.value
                    );
                  }}
                  maxLength={50}
                  tabIndex="2"
                  required
                />
                <label
                  htmlFor="description"
                  className={classNames("label-class", {
                    "error-label-class":
                      errors.description && touched.description,
                  })}
                >
                  Description *
                </label>
                {errors.description && touched.description && (
                  <p className="error-label-class mt-25">
                    {errors.description ?? "-"}
                  </p>
                )}
              </div>
              <div>
                <Field name="school">
                  {({ field, form, meta }) => (
                    <SingleSelect
                      field={field}
                      form={form}
                      meta={meta}
                      options={schoolOptions}
                      placeholder="School*"
                      isLoading={schoolListLoading}
                      tabIndex="4"
                      loadingMessage={() => (
                        <ClipLoader size={32} color={"#3e8ede"} />
                      )}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5 md:justify-end md:mx-10 md:mt-10">
            <DefaultButton label="Create" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

import React from "react";
import Select from "react-select";
import "../../styles/landingPage.scss";

const SingleSelect = ({
  field,
  form,
  options,
  meta,
  onChange,
  className,
  tabIndex,
  ...props
}) => {
  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
    {
      /* onChange(value) is to pass any additional function or handler which is need for particular dropdown */
    }
    if (onChange) {
      onChange(value);
    }
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      borderBottom: `1px solid ${
        meta.error && meta.touched
          ? "rgb(221, 44, 0)"
          : state.isFocused
          ? "rgb(62, 142, 222)"
          : "rgba(0, 0, 0, 0.12)"
      }`,
      borderRadius: "0px",
      boxShadow: "none",
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
    }),
    placeholder: (styles, { isFocused, ...props }) => ({
      ...styles,
      color:
        meta.error && meta.touched
          ? "rgb(221, 44, 0)"
          : isFocused
          ? "rgb(62, 142, 222)"
          : "rgba(0, 0, 0, 0.54)",
    }),
  };

  const getValue = () => {
    if (options) {
      return options?.find((option) => option?.value === field?.value?.value);
    } else {
      return "";
    }
  };

  // Set value to null when initial value is an empty string
  const value = field.value === "" ? null : getValue();

  return (
    <>
      <Select
        options={options}
        onChange={handleChange}
        value={value}
        styles={customStyles}
        {...props}
        onBlur={() => {
          form.setFieldTouched(field.name, true);
        }}
        className={className}
        tabIndex={tabIndex}
      />
      {meta?.error && meta?.touched && (
        <p className="error-label-class mt-0 text-sm">{meta?.error ?? "-"}</p>
      )}
    </>
  );
};

export default SingleSelect;

import api from "../api";

export function fetchAdministratorsListAction(payload) {
  return api.get(
    `/administrators${
      payload?.page && payload?.page > 0 ? `?page=${payload?.page}` : ""
    }${payload?.query ? `&query=${payload.query}` : ""}`
  );
}

export function fetchCreateAdministratorRequest(payload) {
  return api.post(`/administrators`, {
    paramObj: payload,
  });
}

import {
  FAILURE_RECOVERY_PASSWORD,
  FAILURE_UPDATE_USER_PROFILE,
  LOGIN_FAILURE,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOCALLY_UPDATE_RECOVERY_PASSWORD_ERROR_ACTION,
  START_RECOVERY_PASSWORD,
  START_UPDATE_USER_PROFILE,
  SUCCESS_RECOVERY_PASSWORD,
  SUCCESS_UPDATE_USER_PROFILE,
  LOCALLY_UPDATE_DELETED_USER_ERROR_ACTION,
} from "../actions/login";

const initState = {
  user_login_data: {
    isLoading: false,
    isError: false,
    token: localStorage.getItem("access_token") || "",
    data: JSON.parse(localStorage.getItem("user")) || {},
    isAuth: false || localStorage.getItem("isAuth"),
  },
  user_recovery_password: {
    isLoading: false,
    isError: false,
    data: "",
  },
  uesr_update_profile: {
    isLoading: false,
    isError: false,
  },
};

export const loginReducer = (prevState = initState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return {
        ...prevState,
        user_login_data: {
          isLoading: true,
          isAuth: false,
        },
      };
    case LOGIN_SUCCESS:
      const {
        data: { user },
      } = action;
      // localStorage.setItem("access_token", user.token);
      return {
        ...prevState,
        user_login_data: {
          isLoading: false,
          isAuth: true,
          token: user.token,
          data: user,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...prevState,
        user_login_data: {
          isLoading: false,
          isError: action.errorMessage,
          isAuth: false,
          token: "",
          data: {},
        },
      };
    case START_RECOVERY_PASSWORD: {
      return {
        ...prevState,
        user_recovery_password: {
          isLoading: true,
          isError: false,
        },
      };
    }
    case SUCCESS_RECOVERY_PASSWORD: {
      const {
        data: { user },
      } = action;
      return {
        ...prevState,
        user_recovery_password: {
          isLoading: false,
          isError: false,
          data: user,
        },
      };
    }
    case FAILURE_RECOVERY_PASSWORD: {
      return {
        ...prevState,
        user_recovery_password: {
          isLoading: false,
          isError: action.errorMessage,
          data: "",
        },
      };
    }
    case START_UPDATE_USER_PROFILE: {
      return {
        ...prevState,
        uesr_update_profile: {
          isLoading: true,
          isError: false,
        },
      };
    }
    case SUCCESS_UPDATE_USER_PROFILE: {
      const {
        data: { user },
      } = action;
      return {
        ...prevState,
        uesr_update_profile: {
          isLoading: false,
          isError: false,
        },
        user_login_data: {
          ...prevState.user_login_data,
          data: user,
        },
      };
    }
    case FAILURE_UPDATE_USER_PROFILE: {
      return {
        ...prevState,
        uesr_update_profile: {
          isLoading: false,
          isError: action.errorMessage,
        },
      };
    }
    case LOCALLY_UPDATE_RECOVERY_PASSWORD_ERROR_ACTION: {
      return {
        ...prevState,
        user_recovery_password: {
          isLoading: false,
          isError: false,
          data: "",
        },
      };
    }
    case LOCALLY_UPDATE_DELETED_USER_ERROR_ACTION: {
      return {
        ...prevState,
        user_login_data: {
          isLoading: false,
          isError: false,
          isAuth: false,
          token: "",
          data: {},
        },
      };
    }

    default:
      return prevState;
  }
};

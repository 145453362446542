import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DefaultButton from "../../../components/Button/DefaultButton";
import { START_MODULE_SET_LIST_ACTION } from "../../../rest-client/actions/module-sets";
import { START_CLONE_MODULE_DOCUMENT_ACTION } from "../../../rest-client/actions/module-documents";
import { useModuleSet } from "../../../rest-client/selectorHook";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import OutlinedButton from "../../../components/Button/OutlinedButton";

export default function CloneModuleForm({
  clonedObjectData,
  setIsOpenCloneModal,
  setCurrentPage,
}) {
  const moduleDocumentData = clonedObjectData;
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    dispatch({
      type: START_MODULE_SET_LIST_ACTION,
      payload: null,
    });
  }, []);

  const {
    module_sets_list_data: { data: moduleSetListData },
  } = useModuleSet();

  const initialFormValues = {
    title: "",
    nickname: "",
    module_set: "",
    module_categories: [],
    resource_options: "copy_resources",
    resource: {
      concat_type: "suffix",
      concat_text: "",
    },
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter Title";
    }
    if (!values.nickname) {
      error.nickname = "Please enter Nickname";
    }
    if (!values.module_set) {
      error.module_set = "Please select Module Set";
    }
    values.module_categories.forEach((module_category, index) => {
      if (!module_category.value) {
        error[
          `module_categories.${index}.value`
        ] = `Please enter value for ${module_category.name}`;
      }
    });
    if (
      !values.resource.concat_text &&
      values.resource_options === "clone_resources"
    ) {
      error.resource = {
        ...error.resource,
        concat_text: "Please Enter Text",
      };
    }
    return error;
  }, []);

  const updateInitialValuesHandler = (selectedModuleSet, values) => {
    values.module_categories = [];
    const selectedModuleSetObj = moduleSetListData.find(
      (obj) => obj["id"] === selectedModuleSet?.value
    );
    selectedModuleSetObj.module_categories.map((category) =>
      values.module_categories.push({
        name: category.name,
        slug: category.slug,
        value: "",
      })
    );
  };

  const createCloneModuleDocumentHandler = React.useCallback(
    (values) => {
      if (values) {
        const module_set_categories = [];
        values.module_categories.map((module_category) => {
          module_set_categories.push({
            ...module_category,
            name: module_category.name,
            slug: module_category.slug,
            value: module_category.value.trimStart().trimEnd(),
          });
        });
        const createCloneModuleDocumentData = {
          module_document: {
            title: values.title.trimStart().trimEnd(),
            nickname: values.nickname.trimStart().trimEnd(),
            module_set_id: values.module_set?.value,
            module_categories: module_set_categories,
            ...(values.resource_options === "clone_resources" && {
              resource: {
                concat_type: values.resource.concat_type,
                concat_text: values.resource.concat_text.trimStart().trimEnd(),
              },
            }),
            id: moduleDocumentData.id,
            clone: "clone",
          },
        };
        dispatch({
          type: START_CLONE_MODULE_DOCUMENT_ACTION,
          payload: {
            createCloneModuleDocumentData,
            setIsOpenCloneModal,
            history,
            setCurrentPage,
          },
        });
      }
    },
    [dispatch]
  );

  const moduleSetOptions = moduleSetListData.map((module_set, index) => {
    return {
      label: module_set.title,
      value: module_set.id,
    };
  });

  return (
    <div className="py-10 px-2">
      <p>Original module title : {moduleDocumentData.title}</p>
      <p>Original module nickname: {moduleDocumentData.nickname}</p>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={createCloneModuleDocumentHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato mt-10">
            <div className="field-holder">
              <Field
                type="text"
                id="title"
                className={classNames("input-class !h-[26px]", {
                  "error-input-class": errors.title && touched.title,
                })}
                value={values.title}
                onChange={(e) => {
                  setFieldDataHandler(
                    setFieldValue,
                    "title",
                    e.target.value.trimStart()
                  );
                }}
                maxLength={50}
                required
              />
              <label
                htmlFor="title"
                className={classNames("label-class mt-1", {
                  "error-label-class": errors.title && touched.title,
                })}
              >
                Name cloned module title *
              </label>
              {errors.title && touched.title && (
                <p className="error-label-class mt-25">{errors.title ?? "-"}</p>
              )}
            </div>
            <div className="field-holder">
              <Field
                type="text"
                id="nickname"
                className={classNames("input-class !h-[26px]", {
                  "error-input-class": errors.nickname && touched.nickname,
                })}
                value={values.nickname}
                onChange={(e) => {
                  setFieldDataHandler(
                    setFieldValue,
                    "nickname",
                    e.target.value.trimStart()
                  );
                }}
                maxLength={50}
                required
              />
              <label
                htmlFor="nickname"
                className={classNames("label-class mt-1", {
                  "error-label-class": errors.nickname && touched.nickname,
                })}
              >
                Name cloned module nickname *
              </label>
              {errors.nickname && touched.nickname && (
                <p className="error-label-class mt-25">
                  {errors.nickname ?? "-"}
                </p>
              )}
            </div>
            <div className="mt-1 mb-10">
              <Field name="module_set">
                {({ field, form, meta }) => (
                  <SingleSelect
                    field={field}
                    form={form}
                    meta={meta}
                    options={moduleSetOptions}
                    placeholder="Module Set*"
                    onChange={(value) => {
                      updateInitialValuesHandler(value, form.values);
                    }}
                  />
                )}
              </Field>
            </div>
            {values.module_set?.value && (
              <>
                <FieldArray
                  name="module_categories"
                  render={(arrayHelpers) => {
                    const module_categories = values.module_categories;
                    return (
                      <>
                        {module_categories.map((module_category, index) => (
                          <div key={index}>
                            <div className="field-holder mt-5">
                              <Field
                                type="text"
                                id={`module_categories.${index}.value`}
                                name={`module_categories.${index}.value`}
                                className={classNames("input-class !h-[26px]", {
                                  "error-input-class":
                                    errors[`module_categories.${index}.value`],
                                })}
                                maxLength={50}
                                required
                                value={values.module_categories[index].value}
                                onChange={(e) =>
                                  setFieldValue(
                                    `module_categories.${index}.value`,
                                    e.target.value.trimStart()
                                  )
                                }
                              />
                              <label
                                htmlFor={`module_categories.${index}.value`}
                                className={classNames("label-class mt-1", {
                                  "error-label-class":
                                    errors[`module_categories.${index}.value`],
                                })}
                              >
                                {module_category.name} *
                              </label>
                              {errors[`module_categories.${index}.value`] && (
                                <p className="error-label-class mt-25">
                                  {errors[`module_categories.${index}.value`] ??
                                    "-"}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    );
                  }}
                />
              </>
            )}
            <div className="flex items-center mb-4">
              <Field
                type="radio"
                id="copy_resources"
                checked={values.resource_options === "copy_resources"}
                onChange={() =>
                  setFieldValue(`resource_options`, "copy_resources")
                }
                className="w-4 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="copy_resources" className="ml-1">
                Copy resources (use same set of resources)
              </label>
            </div>
            <div className="flex items-center">
              <Field
                type="radio"
                id="clone_resources"
                checked={values.resource_options === "clone_resources"}
                onChange={() =>
                  setFieldValue(`resource_options`, "clone_resources")
                }
                className="w-4 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="clone_resources" className="ml-1">
                Clone resources (new set of resources)
              </label>
            </div>
            {values.resource_options === "clone_resources" ? (
              <>
                <p className="mt-10">
                  Choose suffix or prefix to add to all resource titles in the
                  module:
                </p>
                <div className="flex items-center mb-4">
                  <Field
                    type="radio"
                    value="suffix"
                    id="resource_concat_type_suffix"
                    checked={values.resource.concat_type === "suffix"}
                    onChange={() =>
                      setFieldValue(
                        `resource.concat_type`,
                        (values.resource.concat_type = "suffix")
                      )
                    }
                    className="w-4 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="resource_concat_type_suffix" className="ml-1">
                    Suffix
                  </label>
                </div>
                <div className="flex items-center">
                  <Field
                    type="radio"
                    value="prefix"
                    id="resource_concat_type_prefix"
                    checked={values.resource.concat_type === "prefix"}
                    onChange={() =>
                      setFieldValue(
                        `resource.concat_type`,
                        (values.resource.concat_type = "prefix")
                      )
                    }
                    className="w-4 h-4 text-primary-115 bg-gray-100 border-gray-300 focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="resource_concat_type_prefix" className="ml-1">
                    Prefix
                  </label>
                </div>
                <div className="mt-10">
                  <div className="field-holder">
                    <Field
                      type="text"
                      id="resource_concat_text"
                      className={classNames("input-class !h-[26px]", {
                        "error-input-class": errors.resource,
                      })}
                      value={values.resource.concat_text}
                      onChange={(e) => {
                        setFieldDataHandler(
                          setFieldValue,
                          `resource.concat_text`,
                          e.target.value
                        );
                      }}
                      maxLength={20}
                      required
                    />
                    <label
                      htmlFor="resource_concat_text"
                      className={classNames("label-class mt-1", {
                        "error-label-class": errors.resource,
                      })}
                    >
                      Enter Text *
                    </label>
                    {errors.resource?.concat_text && (
                      <p className="error-label-class mt-25">
                        {errors.resource?.concat_text ?? "-"}
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : null}
            <div className="flex justify-end mt-10">
              <OutlinedButton
                type="button"
                label="Cancel"
                className="mx-4"
                onClickHandler={() => setIsOpenCloneModal(false)}
              />
              <DefaultButton label="Submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

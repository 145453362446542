import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import "../../../styles/landingPage.scss";
import "../../../styles/module_sets.scss";
import classNames from "classnames";
import Icon from "../../../components/Button/Icon";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_CREATE_MODULE_SET_ACTION } from "../../../rest-client/actions/module-sets";
import Layout from "../../../components/Layout";

export default function AddSetsForm() {
  const { url } = useRouteMatch(`/dashboard/learning`);
  const history = useHistory();
  const dispatch = useDispatch();
  const module_set_categories = [];

  const initialFormValues = {
    title: "",
    nickname: "",
    module_categories: [
      {
        name: "",
        tag_to_all_groups: false,
        tag_numeric_value: false,
      },
    ],
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter Title";
    }
    if (!values.nickname) {
      error.nickname = "Please enter Nickname";
    }
    if (!values.module_categories || values.module_categories.length === 0) {
      error.module_categories = "At least one module category is required";
    } else {
      values.module_categories.forEach((category, index) => {
        if (!category.name) {
          error.module_categories = error.module_categories || [];
          error.module_categories[index] = "Please enter Category Name";
        }
      });
    }
    return error;
  }, []);

  const createCategoryFieldValue = (module_category) => {
    if (
      module_category.tag_to_all_groups === true &&
      module_category.tag_numeric_value === true
    ) {
      return "integer";
    } else if (
      module_category.tag_to_all_groups === false &&
      module_category.tag_numeric_value === false
    ) {
      return "text";
    } else if (
      module_category.tag_to_all_groups === true &&
      module_category.tag_numeric_value === false
    ) {
      return "text";
    } else if (
      module_category.tag_to_all_groups === false &&
      module_category.tag_numeric_value === true
    ) {
      return "integer";
    } else {
      return "text";
    }
  };

  const createModuleSetHandler = React.useCallback(
    (values) => {
      values.module_categories.map((module_category) => {
        module_set_categories.push({
          name: module_category.name.trimStart().trimEnd(),
          field_type: createCategoryFieldValue(module_category),
          is_main_query:
            module_category.tag_to_all_groups === true ? true : false,
        });
      });
      const createModuleSetData = {
        module_set: {
          title: values.title.trimStart().trimEnd(),
          nickname: values.nickname.trimStart().trimEnd(),
          module_categories: module_set_categories,
        },
      };

      dispatch({
        type: START_CREATE_MODULE_SET_ACTION,
        payload: { createModuleSetData, history },
      });
    },
    [dispatch]
  );

  const backClickHandler = React.useCallback(() => {
    history.push(`${url}/index/module_sets`);
  }, [history, url]);

  return (
    <Layout>
      <div className="flex py-3 text-base font-lato px-6">
        <button
          className="border-none bg-transparent"
          onClick={backClickHandler}
        >
          <Icon
            label="arrow_back_ios"
            title=""
            className={classNames("text-light-gray")}
          />
        </button>
        <span
          className="text-slate-600 cursor-pointer"
          onClick={backClickHandler}
        >
          Back to Module Sets
        </span>
      </div>
      <div className="edit-profile-text"></div>
      <h2 className="form_title ml-10 mt-10">Module Set Information</h2>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={createModuleSetHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato mx-10 mt-10">
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 w-30">
                <div className="field-holder">
                  <Field
                    type="text"
                    id="title"
                    className={classNames("input-class", {
                      "error-input-class": errors.title && touched.title,
                    })}
                    value={values.title}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "title",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    tabIndex="1"
                    required
                  />
                  <label
                    htmlFor="title"
                    className={classNames("label-class", {
                      "error-label-class": errors.title && touched.title,
                    })}
                  >
                    Title *
                  </label>
                  {errors.title && touched.title && (
                    <p className="error-label-class mt-25">
                      {errors.title ?? "-"}
                    </p>
                  )}
                </div>
                <div className="field-holder">
                  <Field
                    type="text"
                    id="nickname"
                    className={classNames("input-class", {
                      "error-input-class": errors.nickname && touched.nickname,
                    })}
                    required
                    value={values.nickname}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "nickname",
                        e.target.value.trimStart()
                      );
                    }}
                    tabIndex="3"
                    maxLength={50}
                  />
                  <label
                    htmlFor="nickname"
                    className={classNames("label-class", {
                      "error-label-class": errors.nickname && touched.nickname,
                    })}
                  >
                    Nickname *
                  </label>
                  {errors.nickname && touched.nickname && (
                    <p className="error-label-class mt-25">
                      {errors.nickname ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-full md:w-70 md:mx-10">
                <FieldArray
                  name="module_categories"
                  render={(arrayHelpers) => {
                    const module_categories = values.module_categories;
                    return (
                      <>
                        {module_categories.map((module_category, index) => (
                          <div key={index}>
                            <div className="field-holder mt-5">
                              <Field
                                type="text"
                                id={`module_categories.${index}.name`}
                                name={`module_categories.${index}.name`}
                                className={classNames("input-class", {
                                  "error-input-class":
                                    touched.module_categories &&
                                    touched.module_categories[index] &&
                                    errors.module_categories &&
                                    errors.module_categories[index],
                                })}
                                maxLength={20}
                                tabIndex="2"
                                required
                                value={values.module_categories[index].name}
                                onChange={(e) =>
                                  setFieldValue(
                                    `module_categories.${index}.name`,
                                    e.target.value.trimStart()
                                  )
                                }
                              />
                              <label
                                htmlFor={`module_categories.${index}.name`}
                                className={classNames("label-class", {
                                  "error-label-class":
                                    touched.module_categories &&
                                    touched.module_categories[index] &&
                                    errors.module_categories &&
                                    errors.module_categories[index],
                                })}
                              >
                                Enter Keyword Category Name *
                              </label>
                              {touched.module_categories &&
                                touched.module_categories[index] &&
                                errors.module_categories &&
                                errors.module_categories[index] && (
                                  <p className="error-label-class mt-25">
                                    {errors.module_categories[index] ?? "-"}
                                  </p>
                                )}
                            </div>
                            <div className="field-holder flex">
                              <label
                                className="container-check text-sm  justify-items-center"
                                htmlFor={`check.${index}`}
                              >
                                <span>
                                  Apply tag to all groups in classroom
                                </span>
                                <input
                                  type="checkbox"
                                  name={`module_categories.${index}.tag_to_all_groups`}
                                  id={`check.${index}`}
                                  checked={
                                    values.module_categories[index]
                                      .tag_to_all_groups
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      `module_categories.${index}.tag_to_all_groups`,
                                      !values.module_categories[index]
                                        .tag_to_all_groups
                                    )
                                  }
                                  tabIndex="4"
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className="container-check text-sm  mx-4"
                                htmlFor={`check1.${index}`}
                              >
                                <span>Numerical Value</span>
                                <input
                                  type="checkbox"
                                  id={`check1.${index}`}
                                  name={`module_categories.${index}.tag_to_all_groups`}
                                  checked={
                                    values.module_categories[index]
                                      .tag_numeric_value
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      `module_categories.${index}.tag_numeric_value`,
                                      !values.module_categories[index]
                                        .tag_numeric_value
                                    )
                                  }
                                  tabIndex="5"
                                />
                                <span className="checkmark"></span>
                              </label>
                              {index > 0 && (
                                <button
                                  className="material-symbols-outlined cursor-pointer ease-in-out border-none bg-transparent text-light-gray text-2xl"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  type="button"
                                  title="Delete"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  delete_forever
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    );
                  }}
                />
                <button
                  className="ml-0 submit-button cursor-pointer opacity-100"
                  type="button"
                  onClick={() => {
                    setFieldValue("module_categories", [
                      ...values.module_categories,
                      {
                        name: "",
                        tag_to_all_groups: false,
                        tag_numeric_value: false,
                      },
                    ]);
                  }}
                >
                  Add Category
                </button>
              </div>
            </div>
            <div className="flex justify-center	md:justify-end md:mx-10 mt-20">
              <DefaultButton label="Submit Module Set" />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}

import React, { useMemo } from "react";
import AdministrationListTable from "../AdministrationListTable";
import Pagination from "../../../components/Pagination/pagination";

export default function ListAdministratorsForm({
  administratorListData,
  administratorListLoading,
  totalPages,
  currentPage,
  setCurrentPage,
}) {
  const administratorData = useMemo(() => {
    return administratorListData;
  }, [administratorListData]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );
  return (
    <>
      <AdministrationListTable
        columns={columns}
        dataListLoading={administratorListLoading}
        listData={administratorData}
        listFor={"Administrators"}
      />
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
}

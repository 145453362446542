import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import { getTheInnerHTMLHook } from "../../commonHook";

const BarChartResult = ({ barChartAnalysisData }) => {
  const analysisData = React.useMemo(() => {
    const data = barChartAnalysisData?.map((e) => {
      return {
        name: getTheInnerHTMLHook(e.text),
        count: e.meta_responses.count,
      };
    });
    return data;
  }, [barChartAnalysisData]);

  const CustomToolTip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="bg-white pt-1 pr-2 pb-4 pl-2">
          <p>{label}</p>
          {payload[0].dataKey + ": " + payload[0].value}
        </div>
      );
    }
    return null;
  };

  const truncate = (str, max) => {
    return str.length > max ? `${str.substring(0, max)}...` : str;
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    const truncatedValue = truncate(payload.value, 12);
    return (
      <text
        x={x}
        y={y}
        dy={5}
        textAnchor="end"
        fill="#666"
        fontSize={14}
        transform={`rotate(-45, ${x}, ${y})`}
      >
        {truncatedValue}
      </text>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={analysisData}
        margin={{ bottom: 80, left: 20, right: 20 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          angle={-45}
          dataKey="name"
          interval={0}
          tick={<CustomXAxisTick />}
          tickMargin={5}
        >
          <Label value="Answer" offset={-60} position="insideBottom" />
        </XAxis>
        <YAxis
          label={{
            value: "Count",
            angle: -90,
            position: "insideLeft",
            dy: -20,
          }}
          allowDecimals={false}
        />
        <Tooltip cursor={false} content={<CustomToolTip />} />
        <Bar dataKey="count" fill="#3e8ede" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartResult;

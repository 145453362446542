import React from "react";
import { Formik, Form, Field } from "formik";
import "../../styles/landingPage.scss";
import { START_CONTACT_US_ACTION } from "../../rest-client/actions/contact-us";
import { useDispatch } from "react-redux";
import classNames from "classnames";

export default function ContactUsForm() {
  const dispatch = useDispatch();

  const initialFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    highschool_interest: false,
    middleschool_interest: false,
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const contactUsRequestHandler = React.useCallback(
    (values) => {
      if (values) {
        const contactUsRequestData = {
          contactus: {
            first_name: values.firstName.trimStart().trimEnd(),
            last_name: values.lastName.trimStart().trimEnd(),
            ...(values.highschool_interest
              ? { highschool_interest: values.highschool_interest }
              : {}),
            ...(values.middleschool_interest
              ? { middleschool_interest: values.middleschool_interest }
              : {}),
            email: values.email.trimStart().trimEnd(),
          },
        };
        dispatch({
          type: START_CONTACT_US_ACTION,
          payload: contactUsRequestData,
        });
      }
    },
    [dispatch]
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.firstName) {
      error.firstName = "Please enter First Name";
    }
    if (!values.lastName) {
      error.lastName = "Please enter Last Name";
    }
    if (!values.email) {
      error.email = "Please enter Email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      error.email = "Invalid email address";
    }
    if (!values.highschool_interest && !values.middleschool_interest) {
      error.highschool_interest =
        "Please select at least one school level of interest";
      error.middleschool_interest =
        "Please select at least one school level of interest";
    }
    return error;
  }, []);

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={contactUsRequestHandler}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <Form className="font-lato">
          <div
            className={classNames(
              "relative p-0.5 mt-4 mx-0 align-middle mb-6",
              {
                "!mb-4": errors.firstName && touched.firstName,
              }
            )}
          >
            <Field
              type="text"
              id="firstName"
              className={classNames("input-class", {
                "error-input-class": errors.firstName && touched.firstName,
              })}
              value={values.firstName}
              onChange={(e) => {
                setFieldDataHandler(setFieldValue, "firstName", e.target.value.trimStart());
              }}
              required
            />
            <label
              htmlFor="firstName"
              className={classNames("label-class", {
                "error-label-class": errors.firstName && touched.firstName,
              })}
            >
              First Name *
            </label>
            {errors.firstName && touched.firstName && (
              <p className="error-label-class mt-25 -ml-0.5">
                {errors.firstName ?? "-"}
              </p>
            )}
          </div>
          <div
            className={classNames(
              "relative p-0.5 my-4 mx-0 align-middle mb-6",
              {
                "!mb-4": errors.lastName && touched.lastName,
              }
            )}
          >
            <Field
              type="text"
              id="lastName"
              className={classNames("input-class", {
                "error-input-class": errors.lastName && touched.lastName,
              })}
              required
              value={values.lastName}
              onChange={(e) => {
                setFieldDataHandler(setFieldValue, "lastName", e.target.value.trimStart());
              }}
            />
            <label
              htmlFor="lastName"
              className={classNames("label-class", {
                "error-label-class": errors.lastName && touched.lastName,
              })}
            >
              Last Name *
            </label>
            {errors.lastName && touched.lastName && (
              <p className="error-label-class mt-25 -ml-0.5">
                {errors.lastName ?? "-"}
              </p>
            )}
          </div>
          <div
            className={classNames(
              "relative p-0.5 my-4 mx-0 align-middle mb-6",
              {
                "!mb-4": errors.email && touched.email,
              }
            )}
          >
            <Field
              type="text"
              id="email"
              className={classNames("input-class", {
                "error-input-class": errors.email && touched.email,
              })}
              value={values.email}
              onChange={(e) => {
                setFieldDataHandler(setFieldValue, "email", e.target.value.trimStart());
              }}
              required
            />
            <label
              htmlFor="email"
              className={classNames("label-class", {
                "error-label-class": errors.email && touched.email,
              })}
            >
              Email *
            </label>
            {errors.email && touched.email && (
              <p className="error-label-class mt-25 -ml-0.5">
                {errors.email ?? "-"}
              </p>
            )}
          </div>
          <p className="text-black-87">
            I am interested in learning more about BioScann for … (check all
            that apply)
          </p>
          <div className="flex flex-row box-border">
            <div className="flex-1">
              <label
                className="container-check text-base mt-3.5"
                htmlFor="highschool_interest"
              >
                High School
                <input
                  type="checkbox"
                  id="highschool_interest"
                  checked={values.checked}
                  value="Option 1"
                  onChange={() =>
                    setFieldValue(
                      "highschool_interest",
                      !values.highschool_interest
                    )
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="flex-1">
              <label
                className="container-check text-base mt-3.5"
                htmlFor="middleschool_interest"
              >
                Middle School
                <input
                  type="checkbox"
                  id="middleschool_interest"
                  checked={values.checked}
                  value="Option 2"
                  onChange={() =>
                    setFieldValue(
                      "middleschool_interest",
                      !values.middleschool_interest
                    )
                  }
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="flex justify-between box-border">
            <button
              className={classNames(
                "submit-button cursor-pointer opacity-100",
                {
                  "pointer-events-none opacity-60": !dirty || !isValid,
                }
              )}
              type="submit"
              disabled={!dirty || !isValid}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export const START_TEACHERS_LIST_ACTION = `START_TEACHERS_LIST_ACTION`;
export const ERROR_TEACHERS_LIST_ACTION = `ERROR_TEACHERS_LIST_ACTION`;
export const SUCCESS_TEACHERS_LIST_ACTION = `SUCCESS_TEACHERS_LIST_ACTION`;

export const START_CREATE_TEACHER_ACTION = "START_CREATE_TEACHER_ACTION";
export const SUCCESS_CREATE_TEACHER_ACTION = "SUCCESS_CREATE_TEACHER_ACTION";
export const FAILURE_CREATE_TEACHER_ACTION = "FAILURE_CREATE_TEACHER_ACTION";

export const START_UPDATE_TEACHERS_ACTION = "START_UPDATE_TEACHERS";
export const SUCCESS_UPDATE_TEACHERS_ACTION = "SUCCESS_UPDATE_TEACHERS";
export const FAILURE_UPDATE_TEACHERS_ACTION = "FAILURE_UPDATE_TEACHERS";

export const START_DELETE_TEACHERS_ACTION = "START_DELETE_TEACHERS_ACTION";
export const SUCCESS_DELETE_TEACHERS_ACTION = "SUCCESS_DELETE_TEACHERS_ACTION";
export const FAILURE_DELETE_TEACHERS_ACTION = "FAILURE_DELETE_TEACHERS_ACTION";

export const START_TEACHERS_NON_PAGINATED_LIST_ACTION = `START_TEACHERS_NON_PAGINATED_LIST_ACTION`;
export const ERROR_TEACHERS_NON_PAGINATED_LIST_ACTION = `ERROR_TEACHERS_NON_PAGINATED_LIST_ACTION`;
export const SUCCESS_TEACHERS_NON_PAGINATED_LIST_ACTION = `SUCCESS_TEACHERS_NON_PAGINATED_LIST_ACTION`;

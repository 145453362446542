import React from "react";
import classNames from "classnames";

export default function DefaultButton({
  label,
  className,
  imgSrc,
  type,
  disabled,
  onClickHandler,
}) {
  return (
    <div className={classNames(className, "flex box-border items-center mb-3")}>
      <button
        className={classNames(
          "default-button text-white-87 bg-theme-blue btn-shadow cursor-pointer align-middle items-center text-center box-border select-none outline-none border-0 py-0 px-2 font-medium font-lato text-sm",
          {
            "bg-theme-gray !shadow-none pointer-events-none text-gray-38 opacity-70":
              disabled,
          }
        )}
        type={type ? type : "submit"}
        disabled={disabled}
        onClick={onClickHandler}
      >
        {imgSrc ? (
          <img src={imgSrc} alt="icn_login_btn" className="icn-login" />
        ) : null}
        <span className="ng-binding ng-scope">{label}</span>
      </button>
    </div>
  );
}

import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ColorPic } from "../Common/ColorPic";
import "../../../styles/fontSizer.scss";
import "../../../styles/fontFamily.scss";

export default function PieChartQuetionForm({ value, setFieldValue }) {
  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [piechartQuetionEditorState, setPiechartQuetionEditorState] =
    React.useState(value ? prepareDraft(value) : EditorState.createEmpty());

  const onEditorStateChange = (piechartQuetionEditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(piechartQuetionEditorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setPiechartQuetionEditorState(piechartQuetionEditorState);
  };
  return (
    <div>
      <Editor
        editorState={piechartQuetionEditorState}
        onEditorStateChange={onEditorStateChange}
        placeholder="Enter pie chart poll quetion"
        wrapperClassName="py-4 w-full"
        editorClassName="p-4 border border-solid text-box-border mt-4 h-auto min-hieght-300"
        toolbarClassName="outline-none border-0-imp padding-0-imp"
        toolbar={{
          options: [
            "inline",
            "list",
            "history",
            "colorPicker",
            "fontSize",
            "fontFamily",
          ],
          inline: {
            inDropdown: false,
            // className:
            //   "border border-solid text-box-border",
            options: ["bold", "italic", "underline", "strikethrough"],
            bold: {
              className: "m-0-imp border-right-0-imp text-box-border",
            },
            italic: {
              className: "m-0-imp text-box-border",
            },
            underline: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            strikethrough: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          list: {
            options: ["unordered", "ordered", "indent", "outdent"],
            unordered: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            ordered: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            indent: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            outdent: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          fontSize: {
            className: "fontSizer m-0-imp border-right-0-imp text-box-border",
          },
          fontFamily: {
            className: "m-0-imp text-box-border",
            options: [
              "Lato",
              "Gloria Hallelujah",
              "Cedarville Cursive",
              "Rock Salt",
              "Swanky and Moo Moo",
            ],
          },
          history: {
            options: ["undo", "redo"],
            undo: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
            redo: {
              className: "m-0-imp border-left-0-imp text-box-border",
            },
          },
          colorPicker: {
            className: "m-0-imp border-left-0-imp text-box-border",
            component: ColorPic,
          },
        }}
      />
    </div>
  );
}

export const START_MODULE_SESSION_LIST_ACTION = `START_MODULE_SESSION_LIST_ACTION`;
export const ERROR_MODULE_SESSION_LIST_ACTION = `ERROR_MODULE_SESSION_LIST_ACTION`;
export const SUCCESS_MODULE_SESSION_LIST_ACTION = `SUCCESS_MODULE_SESSION_LIST_ACTION`;

export const START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION = `START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION`;
export const ERROR_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION = `ERROR_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION`;
export const SUCCESS_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION = `SUCCESS_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION`;

export const START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION = `START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION`;
export const ERROR_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION = `ERROR_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION`;
export const SUCCESS_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION = `SUCCESS_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION`;

export const START_MODULE_SESSION_COMPLETE_ACTION = `START_MODULE_SESSION_COMPLETE_ACTION`;
export const ERROR_MODULE_SESSION_COMPLETE_ACTION = `ERROR_MODULE_SESSION_COMPLETE_ACTION`;
export const SUCCESS_MODULE_SESSION_COMPLETE_ACTION = `SUCCESS_MODULE_SESSION_COMPLETE_ACTION`;

export const START_DELETE_MODULE_SESSION_ACTION =
  "START_DELETE_MODULE_SESSION_ACTION";
export const SUCCESS_DELETE_MODULE_SESSION_ACTION =
  "SUCCESS_DELETE_MODULE_SESSION_ACTION";
export const FAILURE_DELETE_MODULE_SESSION_ACTION =
  "FAILURE_DELETE_MODULE_SESSION_ACTION";

export const START_CREATE_MODULE_SESSION_ACTION =
  "START_CREATE_MODULE_SESSION_ACTION";
export const SUCCESS_CREATE_MODULE_SESSION_ACTION =
  "SUCCESS_CREATE_MODULE_SESSION_ACTION";
export const FAILURE_CREATE_MODULE_SESSION_ACTION =
  "FAILURE_CREATE_MODULE_SESSION_ACTION";

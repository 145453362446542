import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import "../../../styles/landingPage.scss";
import Icon from "../../../components/Button/Icon";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import PageTypeResources from "../../../components/ResourcesTypesForms/PageTypeResourcesForm";
import PollTypeResources from "../../../components/ResourcesTypesForms/PollTypeForm/PollTypeResourcesForm";
import CanvasTypeResourcesForm from "../../../components/ResourcesTypesForms/CanvasTypeResourcesForm";
import DiscussionPormptForm from "../../../components/ResourcesTypesForms/DiscussionPormptForm";
import { useDispatch } from "react-redux";
import {
  START_ADD_RESOURCE_ACTION,
  START_DELETE_DISCUSSION_PROMPT_ACTION,
  START_FETCHING_RESOURCE_BY_ID_ACTION,
  START_UPDATE_RESOURCE_ACTION,
} from "../../../rest-client/actions/resources";
import {
  CANVAS_TYPE_RESOURCE,
  PAGE_TYPE_RESOURCE,
  PIE_CHART_ANWERS,
  POLL_TYPE_RESOURCE,
  RESULT_TYPE_RESOURCE,
} from "../../../utils";
import {
  useModuleDocuments,
  useResourcesList,
} from "../../../rest-client/selectorHook";
import defaultImage from "../../../images/bioScanBlankImg.png";
import { ClipLoader } from "react-spinners";
import { handleReplace } from "../../../commonHook";
import submitSvg from "../../../images/icn_submit.svg";
import { START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION } from "../../../rest-client/actions/module-documents";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import Layout from "../../../components/Layout";

export default function AddResourceForm() {
  const {
    add_resource_data: { data: resourceData },
    add_canvas_bg_img_data: { data: mediaImageData },
    add_canvas_img_data: { data: largeImgData },
    resource_by_id_data: {
      data: resourceByIdData,
      isLoading: resourceByIdDataLoading,
    },
    update_resource_data: { isLoading: updateResourceLoading },
  } = useResourcesList();
  const {
    module_document_by_id_data: { data: moduleDocumentData },
  } = useModuleDocuments();

  const dispatch = useDispatch();
  const { url } = useRouteMatch(`/dashboard/learning`);
  const history = useHistory();
  const params = useParams();

  const urlParams = new URLSearchParams(history?.location?.search);
  const moduleId = urlParams.has("moduleId") ? urlParams.get("moduleId") : "";

  const check =
    typeof resourceByIdData === "object" &&
    resourceByIdData !== undefined &&
    !Object.keys(resourceByIdData).length == 0;

  const [imgData, setImgData] = React.useState(null);
  const [selectType, setSelectType] = React.useState("");

  function createFileObject(imageFile) {
    const name = "img.png";
    const type = "image/png";
    const blob = new Blob([imageFile], { type });
    return new File([blob], name, { type });
  }

  const imageFile = defaultImage;
  const fileObject = createFileObject(imageFile);
  const [bgimgData, setBgimgData] = React.useState(fileObject);
  const [largeImageFlag, setLargeImageFlag] = React.useState(false);
  const [mediaImageFlag, setMediaImageFlag] = React.useState(false);

  React.useEffect(() => {
    dispatch({
      type: START_FETCHING_RESOURCE_BY_ID_ACTION,
      payload: {
        resource_id: params.id ? params.id : "",
      },
    });
  }, []);

  React.useEffect(() => {
    dispatch({
      type: START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
      payload: {
        module_id: moduleId,
      },
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (
      resourceData.id &&
      mediaImageData.id &&
      selectType === CANVAS_TYPE_RESOURCE
    ) {
      setBgimgData(fileObject);
      const resourcePutPayload = {
        resource: {
          ...resourceData,
          ...(mediaImageData.id ? { media_id: mediaImageData.id } : {}),
          ...(largeImgData.id
            ? { large_canvas_image_id: largeImgData.id }
            : {}),
        },
      };
      dispatch({
        type: START_UPDATE_RESOURCE_ACTION,
        payload: { resourcePutPayload, id: resourceData.id },
      });
      setSelectType("");
    }
  }, [mediaImageData.id, largeImgData.id]);

  const resourceTypeData = [
    { value: PAGE_TYPE_RESOURCE, label: "Page" },
    {
      id: 2,
      value: CANVAS_TYPE_RESOURCE,
      label: "Canvas",
    },
    {
      id: 2,
      value: RESULT_TYPE_RESOURCE,
      label: "Results",
    },
    {
      id: 3,
      value: POLL_TYPE_RESOURCE,
      label: "Poll",
    },
  ];

  const initialFormValues = {
    resource_title: check ? resourceByIdData.title : "",
    resource_nickname: check ? resourceByIdData.nickname : "",
    resource_module_set: check
      ? {
          value: resourceByIdData.resource_type,
          label: resourceByIdData.resource_type,
        }
      : "",
    student_tracker_checkbox: check ? resourceByIdData.show_nickname : false,
    stopping_point_checkbox: check ? resourceByIdData.is_stopping_point : false,
    discussion_prompt: check ? resourceByIdData.discussions : [],
    page_type_resource_value: check
      ? handleReplace(resourceByIdData.html_content)
      : "",
    poll_type_resource_value: check
      ? handleReplace(resourceByIdData.html_content)
      : "",
    canvas_type_resource_value: check
      ? handleReplace(resourceByIdData.html_content)
      : "",
    pie_chart_que_value:
      check && resourceByIdData.polls[0]
        ? handleReplace(resourceByIdData.polls[0]?.poll_questions[1]?.text)
        : "",
    bar_chart_que_value:
      check && resourceByIdData.polls[0]
        ? handleReplace(resourceByIdData.polls[0]?.poll_questions[0]?.text)
        : "",
    bar_chart_answers:
      check && resourceByIdData.polls[0]
        ? resourceByIdData.polls[0]?.poll_questions[0]?.poll_question_options
        : [
            {
              text: "",
            },
          ],
    pie_chart_answers:
      check && resourceByIdData.polls[0]
        ? resourceByIdData.polls[0]?.poll_questions[1]?.poll_question_options
        : [
            {
              text: "",
            },
            {
              text: "",
            },
          ],
  };

  const backToResourcesClickHandler = React.useCallback(() => {
    history.push(`${url}/index/modules`);
  }, [history, url]);

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.resource_title) {
      error.resource_title = "Please enter Resource Title";
    }
    if (!values.resource_nickname) {
      error.resource_nickname = "Please enter Resource Nickname";
    }
    if (!values.resource_module_set) {
      error.resource_module_set = "Please select Resource Type";
    }
    return error;
  }, []);

  const addResourcesRequestHandler = React.useCallback(
    (values, { resetForm }) => {
      if (values) {
        const html_content =
          values.resource_module_set.value === PAGE_TYPE_RESOURCE
            ? values.page_type_resource_value
            : values.resource_module_set.value === POLL_TYPE_RESOURCE
            ? values.poll_type_resource_value
            : values.resource_module_set.value === CANVAS_TYPE_RESOURCE
            ? values.canvas_type_resource_value
            : values.discussion_prompt.length === 0
            ? "\n            "
            : "";
        const addResourcesRequestPayload = {
          resource: {
            html_content: html_content,
            title: values.resource_title.trimStart().trimEnd(),
            nickname: values.resource_nickname.trimStart().trimEnd(),
            resource_type: values.resource_module_set.value,
            show_nickname: values.student_tracker_checkbox ? true : false,
            is_stopping_point: values.stopping_point_checkbox ? true : false,
          },
        };
        const newValueForBar = values.bar_chart_answers.filter((e) => e.text);
        const newValueForPie = values.pie_chart_answers.filter((e) => e.text);
        const pollTypeResourcesPayload = {
          text: "",
          poll_questions: [
            {
              text: values.bar_chart_que_value,
              question_type: "bar_chart",
              allowed_answers_number: 1,
              poll_question_options: newValueForBar,
            },
            {
              text: values.pie_chart_que_value,
              question_type: "pie_chart",
              allowed_answers_number: PIE_CHART_ANWERS,
              poll_question_options: newValueForPie,
            },
          ],
        };
        const canvasTypeResourcesPayload = {
          media_image: bgimgData,
          large_image: imgData,
          setImgData,
          setBgimgData,
        };
        const resourceNodeData =
          moduleDocumentData.module_document &&
          moduleDocumentData.module_document.resource_nodes;

        const resourcePutPayload = {
          resource: {
            ...resourceByIdData,
            title: values.resource_title.trimStart().trimEnd(),
            nickname: values.resource_nickname.trimStart().trimEnd(),
            resource_type: values.resource_module_set.value,
            show_nickname: values.student_tracker_checkbox,
            is_stopping_point: values.stopping_point_checkbox,
            discussions: values.discussion_prompt,
            html_content:
              values.resource_module_set.value === PAGE_TYPE_RESOURCE
                ? values.page_type_resource_value
                : values.resource_module_set.value === POLL_TYPE_RESOURCE
                ? values.poll_type_resource_value
                : values.resource_module_set.value === CANVAS_TYPE_RESOURCE
                ? values.canvas_type_resource_value
                : resourceByIdData?.html_content,
            polls:
              check && resourceByIdData?.polls?.length > 0
                ? [
                    {
                      ...resourceByIdData.polls[0],
                      poll_questions: [
                        {
                          ...resourceByIdData.polls[0]?.poll_questions[0],
                          text: values.bar_chart_que_value,
                          poll_question_options: newValueForBar,
                        },
                        {
                          ...resourceByIdData.polls[0]?.poll_questions[1],
                          text: values.pie_chart_que_value,
                          poll_question_options: newValueForPie,
                        },
                      ],
                    },
                  ]
                : [],
            ...(mediaImageData.id ? { media_id: mediaImageData.id } : {}),
            ...(largeImgData.id
              ? { large_canvas_image_id: largeImgData.id }
              : {}),
          },
        };

        if (check) {
          dispatch({
            type: START_UPDATE_RESOURCE_ACTION,
            payload: {
              id: resourceByIdData.id,
              resourcePutPayload,
              canvasTypeResourcesPayload,
              largeImageFlag,
              mediaImageFlag,
              history,
            },
          });
          setLargeImageFlag(false);
          setMediaImageFlag(false);
        } else {
          dispatch({
            type: START_ADD_RESOURCE_ACTION,
            payload: {
              addResourcesRequestPayload,
              discussion_prompt: values.discussion_prompt,
              pollTypeResourcesPayload,
              resetForm,
              canvasTypeResourcesPayload,
              module_id: resourceNodeData && moduleId,
              resourceNodeData,
            },
          });
        }
      }
    },
    [
      dispatch,
      imgData,
      bgimgData,
      resourceByIdData,
      moduleDocumentData,
      largeImageFlag,
      mediaImageFlag,
    ]
  );

  const deleteDiscussionHandler = React.useCallback((data) => {
    if (data.id) {
      dispatch({
        type: START_DELETE_DISCUSSION_PROMPT_ACTION,
        payload: data,
      });
    }
  }, []);

  return (
    <Layout>
      {resourceByIdDataLoading ? (
        <div className="flex flex-col mt-8 mjustify-center items-center h-32">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">
            Loading ...
          </p>
        </div>
      ) : (
        <>
          <div className="flex py-3 text-base font-lato px-6">
            <button
              className="border-none bg-transparent"
              onClick={backToResourcesClickHandler}
              type="button"
            >
              <Icon
                label="arrow_back_ios"
                title=""
                className={classNames("text-light-gray")}
              />
            </button>
            <span
              className="text-slate-600 cursor-pointer"
              onClick={backToResourcesClickHandler}
            >
              Back to Resources
            </span>
          </div>
          <div className="edit-profile-text"></div>
          <h2 className="text-black-117 font-21px letter-spacing-005 font-medium ml-10 mt-10">
            Resource Information
          </h2>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validate={validationHandler}
            onSubmit={addResourcesRequestHandler}
          >
            {({
              values,
              errors,
              touched,
              resetForm,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form className="font-lato mx-10 mt-10">
                <div className="flex flex-row box-border">
                  <div className="flex-1 w-30">
                    <div
                      className={classNames(
                        "relative p-0.5 my-4 mx-0 align-middle mb-8",
                        {
                          "mb-6":
                            errors.resource_title && touched.resource_title,
                        }
                      )}
                    >
                      <Field
                        type="text"
                        id="resource_title"
                        className={classNames("input-class", {
                          "error-input-class":
                            errors.resource_title && touched.resource_title,
                        })}
                        value={values.resource_title}
                        onChange={(e) => {
                          setFieldDataHandler(
                            setFieldValue,
                            "resource_title",
                            e.target.value.trimStart()
                          );
                        }}
                        tabIndex="1"
                        required
                      />
                      <label
                        htmlFor="resource_title"
                        className={classNames("label-class", {
                          "error-label-class":
                            errors.resource_title && touched.resource_title,
                        })}
                      >
                        Resource Title
                      </label>
                      {errors.resource_title && touched.resource_title && (
                        <p className="error-label-class mt-25">
                          {errors.resource_title ?? "-"}
                        </p>
                      )}
                    </div>
                    <div className="relative p-0.5 my-4 mx-0 align-middle">
                      <Field
                        type="text"
                        id="resource_nickname"
                        className={classNames("input-class", {
                          "error-input-class":
                            errors.resource_nickname &&
                            touched.resource_nickname,
                        })}
                        required
                        value={values.resource_nickname}
                        tabIndex="3"
                        onChange={(e) => {
                          setFieldDataHandler(
                            setFieldValue,
                            "resource_nickname",
                            e.target.value.trimStart()
                          );
                        }}
                      />
                      <label
                        htmlFor="resource_nickname"
                        className={classNames("label-class", {
                          "error-label-class":
                            errors.resource_nickname &&
                            touched.resource_nickname,
                        })}
                      >
                        Resource Nickname
                      </label>
                      {errors.resource_nickname &&
                        touched.resource_nickname && (
                          <p className="error-label-class mt-25">
                            {errors.resource_nickname ?? "-"}
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="flex-1 w-70 ml-10">
                    <div
                      className={classNames(
                        "relative p-0.5 my-4 mx-0 align-middle mt-1 mb-14",
                        {
                          "!mb-6":
                            errors.resource_module_set &&
                            touched.resource_module_set,
                        }
                      )}
                    >
                      <Field name="resource_module_set">
                        {({ field, form, meta }) => (
                          <SingleSelect
                            field={field}
                            form={form}
                            meta={meta}
                            options={resourceTypeData}
                            placeholder="Type Of Resource"
                            onChange={({ value }) => {
                              setSelectType(value);
                              if (value === RESULT_TYPE_RESOURCE) {
                                setFieldValue("discussion_prompt", []);
                              }
                            }}
                            tabIndex="2"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="relative p-0.5 my-4 mx-0 align-middle flex flex-col -mt-2">
                      <label
                        className="container-check text-sm mb-4"
                        htmlFor="student_tracker_checkbox"
                        style={{
                          width: "58%",
                        }}
                      >
                        <span className="text-base">
                          Include resource name in student tracker
                        </span>
                        <input
                          type="checkbox"
                          id="student_tracker_checkbox"
                          checked={values.student_tracker_checkbox}
                          tabIndex="4"
                          onChange={() =>
                            setFieldValue(
                              "student_tracker_checkbox",
                              !values.student_tracker_checkbox
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        className="container-check text-sm"
                        htmlFor="stopping_point_checkbox"
                        style={{
                          width: "48%",
                        }}
                      >
                        <span className="text-base">
                          Mark resource as a stopping point
                        </span>
                        <input
                          type="checkbox"
                          id="stopping_point_checkbox"
                          checked={values.stopping_point_checkbox}
                          tabIndex="5"
                          onChange={() =>
                            setFieldValue(
                              "stopping_point_checkbox",
                              !values.stopping_point_checkbox
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                {values.resource_module_set.value === PAGE_TYPE_RESOURCE ? (
                  <PageTypeResources
                    value={values.page_type_resource_value}
                    setFieldValue={(val) =>
                      setFieldValue("page_type_resource_value", val)
                    }
                  />
                ) : values.resource_module_set.value === POLL_TYPE_RESOURCE ? (
                  <PollTypeResources
                    setFieldValue={setFieldValue}
                    values={values}
                    check={check}
                    resourceByIdData={resourceByIdData}
                  />
                ) : values.resource_module_set.value ===
                  CANVAS_TYPE_RESOURCE ? (
                  <CanvasTypeResourcesForm
                    value={values.canvas_type_resource_value}
                    setFieldValue={(val) =>
                      setFieldValue("canvas_type_resource_value", val)
                    }
                    setImgData={setImgData}
                    setBgimgData={setBgimgData}
                    mediaImageResource={check && resourceByIdData?.media}
                    largeCanvasImageResource={
                      check && resourceByIdData?.large_canvas_image
                    }
                    setLargeImageFlag={setLargeImageFlag}
                    setMediaImageFlag={setMediaImageFlag}
                  />
                ) : null}
                <div className="w-full">
                  <FieldArray
                    name="discussion_prompt"
                    render={(arrayHelpers) => {
                      const discussion_prompt = values.discussion_prompt;
                      return (
                        <>
                          {discussion_prompt.map((module_category, index) => (
                            <>
                              {discussion_prompt[index].title !== null ? (
                                <div
                                  key={index}
                                  className="flex justify-between"
                                >
                                  <DiscussionPormptForm
                                    value={
                                      discussion_prompt[index].title
                                        ? handleReplace(
                                            discussion_prompt[index].title
                                          )
                                        : values.discussion_prompt[index].title
                                    }
                                    setFieldValue={(val) =>
                                      setFieldValue(
                                        `discussion_prompt[${index}].title`,
                                        val
                                      )
                                    }
                                  />
                                  {index >= 0 && (
                                    <button
                                      className="material-symbols-outlined cursor-pointer ease-in-out border-none bg-transparent text-light-gray text-2xl"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      type="button"
                                      title="Delete"
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                        if (check) {
                                          deleteDiscussionHandler(
                                            values.discussion_prompt[index]
                                          );
                                        }
                                      }}
                                    >
                                      delete_forever
                                    </button>
                                  )}
                                </div>
                              ) : null}
                            </>
                          ))}
                        </>
                      );
                    }}
                  />
                  <button
                    className={classNames(
                      "ml-0 submit-button cursor-pointer opacity-100",
                      {
                        "pointer-events-none opacity-50":
                          values.resource_module_set.value ===
                          RESULT_TYPE_RESOURCE,
                      }
                    )}
                    type="button"
                    disabled={
                      values.resource_module_set.value === RESULT_TYPE_RESOURCE
                    }
                    onClick={() => {
                      setFieldValue("discussion_prompt", [
                        ...values.discussion_prompt,
                        {
                          title: "",
                        },
                      ]);
                    }}
                  >
                    Add Discussion Prompt
                  </button>
                </div>
                <div className="flex justify-end mt-20">
                  <DefaultButton
                    label={check ? "Update Resource" : "Submit Resource"}
                    imgSrc={submitSvg}
                    disabled={updateResourceLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Layout>
  );
}

import React, { useState, useEffect, useRef } from "react";
import ModuleContainer from "../Resources/ModuleContainer";
import ResourcesContainer from "../Resources/ResourcesContainer";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { START_FETCHING_MODULE_DOCUMENTS_ACTION } from "../../../rest-client/actions/module-documents";
import {
  useModuleDocuments,
  useResourcesList,
} from "../../../rest-client/selectorHook";
import { START_FETCHING_RESOURCES_LIST_ACTION } from "../../../rest-client/actions/resources";
import "../../../styles/modulesContainer.scss";

export default function ModulePage() {
  const dispatch = useDispatch();
  const [moduleOrderType, setModuleOrderType] = useState("asc");
  const [resourceOrderType, setResourceOrderType] = useState("asc");
  const [moduleSearchInput, setModuleSearchInput] = useState("");
  const [moduleFilterInput, setModuleFilterInput] = useState();
  const [resourceSearchInput, setResourceSearchInput] = useState("");
  const [moduleTotalPages, setModuleTotalPages] = useState(0);
  const [moduleCurrentPage, setModuleCurrentPage] = useState(1);
  const [resourceTotalPages, setResourceTotalPages] = useState(0);
  const [resourceCurrentPage, setResourceCurrentPage] = useState(1);
  const inputRef = useRef(null);
  const inputResourceRef = useRef(null);

  const {
    module_documents_list_data: {
      isLoading: isLoadingModuleDocuments,
      data: moduleDocumentsListData,
      meta: moduleDocumentsListMeta,
    },
    module_document_by_id_data: {
      isLoading: isLoadingModuleResourcesNode,
      data: moduleResourcesNodeData,
    },
  } = useModuleDocuments();

  const {
    resources_list_data: {
      isLoading: isLoadingResourcesList,
      data: ResourcesListData,
      meta: ResourcesListMeta,
    },
  } = useResourcesList();

  useEffect(() => {
    if (moduleSearchInput.length === 0 || moduleSearchInput.length >= 3) {
      dispatch({
        type: START_FETCHING_MODULE_DOCUMENTS_ACTION,
        payload: {
          orderType: moduleOrderType,
          query: moduleSearchInput,
          filter: { module_set_id: moduleFilterInput },
          page: moduleCurrentPage,
        },
      });
    }
  }, [
    dispatch,
    moduleOrderType,
    moduleSearchInput,
    moduleFilterInput,
    moduleCurrentPage,
  ]);

  useEffect(() => {
    if (
      (moduleDocumentsListData.length > 0 || moduleSearchInput.length > 0) &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [moduleDocumentsListData, moduleSearchInput]);

  useEffect(() => {
    if (resourceSearchInput.length === 0 || resourceSearchInput.length >= 3) {
      dispatch({
        type: START_FETCHING_RESOURCES_LIST_ACTION,
        payload: {
          orderType: resourceOrderType,
          query: resourceSearchInput,
          page: resourceCurrentPage,
        },
      });
    }
  }, [dispatch, resourceOrderType, resourceSearchInput, resourceCurrentPage]);

  useEffect(() => {
    if (
      (ResourcesListData.length > 0 || resourceSearchInput.length > 0) &&
      inputResourceRef.current
    ) {
      inputResourceRef.current.focus();
    }
  }, [ResourcesListData, resourceSearchInput]);

  useEffect(() => {
    setModuleTotalPages(moduleDocumentsListMeta?.pages);
    setResourceTotalPages(ResourcesListMeta?.pages);
  }, [moduleDocumentsListMeta, ResourcesListMeta]);

  const [dataState, setUpdateDataState] = React.useState([]);

  function insertObjectIfNotExists(arr, obj) {
    const index = arr.findIndex((item) => item?.id === obj?.id);
    if (index === -1) {
      return [...arr, obj];
    } else {
      return arr;
    }
  }

  React.useEffect(() => {
    if (moduleResourcesNodeData.module_document) {
      const newArray = insertObjectIfNotExists(
        dataState,
        moduleResourcesNodeData.module_document
      );
      setUpdateDataState(newArray);
    }
  }, [moduleResourcesNodeData]);

  const filteredData = React.useCallback(
    (item, id) => {
      if (item) {
        const data = dataState.find((e) => e.id === id);
        const dataToBeFiltered = data.resource_nodes.filter(
          (e) => e.resource_id !== item.resource_id
        );
        const updatedArray = dataState.map((e) => {
          if (e.id === id) {
            return {
              ...e,
              resource_nodes: dataToBeFiltered,
            };
          } else {
            return e;
          }
        });
        setUpdateDataState(updatedArray);
      } else {
        setUpdateDataState(dataState);
      }
    },
    [dataState]
  );

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceItems = [...ResourcesListData.resources];
      const data = dataState.find((e) => e.id === destination.droppableId);
      const destItems = [...data.resource_nodes];
      const [removed] = sourceItems.splice(source.index, 1);
      if (destItems.filter((e) => e.resource_id === removed.id).length > 0) {
        return;
      }
      destItems.splice(destination.index, 0, {
        title: removed.title,
        resource_id: removed.id,
        resource_type: removed.resource_type,
      });
      const updatedArray = dataState.map((e) => {
        if (e.id === destination.droppableId) {
          return {
            ...e,
            resource_nodes: destItems,
          };
        } else {
          return e;
        }
      });
      setUpdateDataState(updatedArray);
    }
    if (source.droppableId === destination.droppableId) {
      const data = dataState.find((e) => e.id === destination.droppableId);
      const copiedItems = Array.from(data.resource_nodes);
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      const updatedArray = dataState.map((e) => {
        if (e.id === destination.droppableId) {
          return {
            ...e,
            resource_nodes: copiedItems,
          };
        } else {
          return e;
        }
      });
      setUpdateDataState(updatedArray);
    }
  };
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="flex flex-col">
          <div className="pt-2 inline-block align-middle mt-8 ml-2 md:ml-1">
            <div className="overflow-hidden border rounded-lg">
              <div className="flex flex-row w-full box-border">
                <ModuleContainer
                  documentSetData={
                    moduleDocumentsListData.module_documents || []
                  }
                  moduleResourcesNodeDataList={dataState}
                  filteredData={filteredData}
                  setModuleOrderType={setModuleOrderType}
                  moduleOrderType={moduleOrderType}
                  moduleSearchInput={moduleSearchInput}
                  setModuleSearchInput={setModuleSearchInput}
                  setModuleFilterInput={setModuleFilterInput}
                  isLoadingModuleDocuments={isLoadingModuleDocuments}
                  isLoadingModuleResourcesNode={isLoadingModuleResourcesNode}
                  totalPages={moduleTotalPages}
                  currentPage={moduleCurrentPage}
                  setCurrentPage={setModuleCurrentPage}
                  inputRef={inputRef}
                />
                <div className="partition-line p-2 mr-2 h-20"></div>
                <ResourcesContainer
                  resourcesData={ResourcesListData?.resources || []}
                  resourceOrderType={resourceOrderType}
                  setResourceOrderType={setResourceOrderType}
                  resourceSearchInput={resourceSearchInput}
                  setResourceSearchInput={setResourceSearchInput}
                  isLoadingResourcesList={isLoadingResourcesList}
                  totalPages={resourceTotalPages}
                  currentPage={resourceCurrentPage}
                  setCurrentPage={setResourceCurrentPage}
                  inputResourceRef={inputResourceRef}
                />
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
    </>
  );
}

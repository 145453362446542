import {
  START_ADMINISTRATORS_LIST_ACTION,
  SUCCESS_ADMINISTRATORS_LIST_ACTION,
  ERROR_ADMINISTRATORS_LIST_ACTION,
  START_CREATE_ADMINISTRATOR_ACTION,
  SUCCESS_CREATE_ADMINISTRATOR_ACTION,
  FAILURE_CREATE_ADMINISTRATOR_ACTION,
} from "../actions/administrators";

const initState = {
  administrators_list_data: {
    isLoading: false,
    isError: false,
    data: [],
    meta: {},
  },
  administrator_create: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const administratorsReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_ADMINISTRATORS_LIST_ACTION:
      return {
        ...prevState,
        administrators_list_data: {
          isLoading: true,
          isError: false,
          data: [],
          meta: {},
        },
      };
    case SUCCESS_ADMINISTRATORS_LIST_ACTION:
      return {
        ...prevState,
        administrators_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
          meta: action.meta,
        },
      };
    case ERROR_ADMINISTRATORS_LIST_ACTION:
      return {
        ...prevState,
        administrators_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
          meta: {},
        },
      };
    case START_CREATE_ADMINISTRATOR_ACTION: {
      return {
        ...prevState,
        administrator_create: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CREATE_ADMINISTRATOR_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        administrator_create: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CREATE_ADMINISTRATOR_ACTION: {
      return {
        ...prevState,
        administrator_create: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    default:
      return prevState;
  }
};

import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_CREATE_TEACHER_ACTION } from "../../../rest-client/actions/teachers";
import { START_SCHOOLS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/schools";
import { useSchools } from "../../../rest-client/selectorHook";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import { ClipLoader } from "react-spinners";

export default function AddTeacherForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: START_SCHOOLS_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  const {
    schools_non_paginated_list_data: {
      isLoading: schoolListLoading,
      data: schoolListData,
    },
  } = useSchools();

  const initialFormValues = {
    first_name: "",
    last_name: "",
    email: "",
    school: "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.first_name) {
      error.first_name = "Please enter First Name";
    }
    if (!values.last_name) {
      error.last_name = "Please enter Last Name";
    }
    if (!values.email) {
      error.email = "Please enter Email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      error.email = "Invalid email address";
    }
    if (!values.school) {
      error.school = "Please Select School";
    }
    return error;
  }, []);

  const createTeacherHandler = React.useCallback(
    (values, { resetForm }) => {
      const createTeacherData = {
        teacher: {
          first_name: values.first_name.trimStart().trimEnd(),
          last_name: values.last_name.trimStart().trimEnd(),
          email: values.email.trimStart().trimEnd(),
          school_id: values.school?.value,
        },
      };
      dispatch({
        type: START_CREATE_TEACHER_ACTION,
        payload: { createTeacherData, history },
      });
      resetForm();
    },
    [dispatch]
  );

  const schoolOptions = schoolListData.map((school, index) => {
    return {
      label: school.name,
      value: school.id,
    };
  });

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={createTeacherHandler}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="font-lato">
          <div className="flex flex-col md:flex-row box-border">
            <div className="flex-1 w-30">
              <div className="field-holder">
                <Field
                  type="text"
                  id="first_name"
                  className={classNames("input-class", {
                    "error-input-class":
                      errors.first_name && touched.first_name,
                  })}
                  value={values.first_name}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "first_name",
                      e.target.value
                    );
                  }}
                  maxLength={50}
                  tabIndex="1"
                  required
                />
                <label
                  htmlFor="first_name"
                  className={classNames("label-class", {
                    "error-label-class":
                      errors.first_name && touched.first_name,
                  })}
                >
                  First Name *
                </label>
                {errors.first_name && touched.first_name && (
                  <p className="error-label-class mt-25">
                    {errors.first_name ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="text"
                  id="email"
                  className={classNames("input-class", {
                    "error-input-class": errors.email && touched.email,
                  })}
                  value={values.email}
                  onChange={(e) => {
                    setFieldDataHandler(setFieldValue, "email", e.target.value);
                  }}
                  maxLength={50}
                  tabIndex="3"
                  required
                />
                <label
                  htmlFor="email"
                  className={classNames("label-class", {
                    "error-label-class": errors.email && touched.email,
                  })}
                >
                  Email *
                </label>
                {errors.email && touched.email && (
                  <p className="error-label-class mt-25">
                    {errors.email ?? "-"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex-1 w-full md:w-70 md:mx-10">
              <div className="field-holder !mb-1">
                <Field
                  type="text"
                  id="last_name"
                  className={classNames("input-class", {
                    "error-input-class": errors.last_name && touched.last_name,
                  })}
                  value={values.last_name}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "last_name",
                      e.target.value
                    );
                  }}
                  maxLength={50}
                  tabIndex="2"
                  required
                />
                <label
                  htmlFor="last_name"
                  className={classNames("label-class", {
                    "error-label-class": errors.last_name && touched.last_name,
                  })}
                >
                  Last Name *
                </label>
                {errors.last_name && touched.last_name && (
                  <p className="error-label-class mt-25">
                    {errors.last_name ?? "-"}
                  </p>
                )}
              </div>
              <div>
                <Field name="school">
                  {({ field, form, meta }) => (
                    <SingleSelect
                      field={field}
                      form={form}
                      meta={meta}
                      options={schoolOptions}
                      placeholder="School*"
                      isLoading={schoolListLoading}
                      tabIndex="4"
                      loadingMessage={() => (
                        <ClipLoader size={32} color={"#3e8ede"} />
                      )}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5	md:justify-end md:mx-10 md:mt-0">
            <DefaultButton label="Create" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

import React from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import Icon from "../Button/Icon";
import LinkedButton from "../Button/LinkedButton";
import { CANVAS_TYPE_RESOURCE } from "../../utils";
import "../../styles/responsive-modal.scss";

function ConfirmSubmitModal({
  isOpenConfirmModal,
  setIsOpenConfirmModal,
  submitRecordHandler,
  resourceType,
}) {
  const customClass = "custom-modal-class"; //import class from "responsive-modal.scss file"
  const onCloseModal = React.useCallback(() => {
    setIsOpenConfirmModal(false);
  }, []);

  const closeIcon = (
    <div className="w-full flex justify-between">
      <Icon label="close" title="" />
    </div>
  );
  return (
    <Modal
      center
      classNames={{
        modal: customClass,
      }}
      open={isOpenConfirmModal}
      onClose={onCloseModal}
      closeIcon={closeIcon}
      focusTrapped={false}
    >
      <div class="flex flex-col">
        <div class="bg-theme-blue">
          <div className="font-lato flex flex-start text-white-87 text-xl font-normal w-1/3">
            <div className="p-4">Confirm</div>
          </div>
        </div>
        <div class="px-4 py-2 bg-white">
          <div className="mt-8 text-black-87">
            {resourceType === CANVAS_TYPE_RESOURCE
              ? "Are you sure you want to submit? You will not be able to delete or resubmit your response."
              : "Do you want to submit this?"}
          </div>
          <div className="flex justify-end mt-8">
            <LinkedButton
              label="Cancel"
              className="mx-4"
              onClickHandler={onCloseModal}
            />
            <LinkedButton label="Submit" onClickHandler={submitRecordHandler} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmSubmitModal;

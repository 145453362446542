import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./rest-client/store";
import { AuthContextProvider } from "./provider/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes/Routes";

export default function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AuthContextProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="toaster-class"
          />
          <Routes />
        </AuthContextProvider>
      </Provider>
    </BrowserRouter>
  );
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage/LandingPage";
import TabsView from "../pages/ContentCreation/TabsView";
import PrivateRoute from "./PrivateRoutes";
import NotFoundPage from "../pages/NotFoundPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import AddSetsForm from "../pages/ContentCreation/ModuleSets/AddSetsForm";
import AddModuleForm from "../pages/ContentCreation/Modules/AddModuleForm";
import EditSetForm from "../pages/ContentCreation/ModuleSets/EditSetForm";
import AddResourceForm from "../pages/ContentCreation/Resources/AddResourceForm";
import EditModuleForm from "../pages/ContentCreation/Modules/EditModuleForm";
import AdministrationTabs from "../pages/Administration/AdministrationTabs";
import ViewModule from "../pages/ContentCreation/Modules/ViewModule";
import ViewResource from "../pages/ContentCreation/Resources/ViewResource";
import ListModuleDocuments from "../pages/Teachers/ListModuleDocuments";
import SessionTabView from "../pages/Teachers/ManageSessions/SessionTabsView";
import ViewResults from "../pages/Teachers/ViewResults/ViewResults";
import GroupDashboard from "../pages/Group/GroupDashboard";
import TabViewModule from "../pages/ContentCreation/TabViewModule";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <LandingPage />
      </Route>

      {/* Routes for Admin */}
      <PrivateRoute
        exact
        path="/dashboard/learning/index/module_sets"
        allowedRoles={["admin", "superadmin"]}
      >
        <TabsView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/index/modules"
        allowedRoles={["admin", "superadmin"]}
      >
        <TabViewModule />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/profile"
        allowedRoles={["admin", "superadmin", "Teacher"]}
      >
        <ProfilePage />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/module_sets"
        allowedRoles={["admin", "superadmin"]}
      >
        <AddSetsForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/modules"
        allowedRoles={["admin", "superadmin"]}
      >
        <AddModuleForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/module_sets/:id/edit"
        allowedRoles={["admin", "superadmin"]}
      >
        <EditSetForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/resource"
        allowedRoles={["admin", "superadmin"]}
      >
        <AddResourceForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/resource/:id/edit"
        allowedRoles={["admin", "superadmin"]}
      >
        <AddResourceForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/modules/:id/edit"
        allowedRoles={["admin", "superadmin"]}
      >
        <EditModuleForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/administration"
        allowedRoles={["admin", "superadmin"]}
      >
        <AdministrationTabs />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/module/:id"
        allowedRoles={["admin", "superadmin"]}
      >
        <ViewModule />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/learning/resource/:id"
        allowedRoles={["admin", "superadmin"]}
      >
        <ViewResource />
      </PrivateRoute>

      {/* Routes for Teacher */}
      <PrivateRoute exact path="/dashboard/modules" allowedRoles={["Teacher"]}>
        <ListModuleDocuments />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/manage-sessions"
        allowedRoles={["Teacher"]}
      >
        <SessionTabView />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/module/:id"
        allowedRoles={["Teacher"]}
      >
        <ViewModule />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/classroom-configuration"
        allowedRoles={["Teacher"]}
      >
        <AdministrationTabs />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/results"
        allowedRoles={["Teacher", "Learner"]}
      >
        <ViewResults />
      </PrivateRoute>

      {/* Routes for Group */}
      <PrivateRoute exact path="/dashboard/index" allowedRoles={["Learner"]}>
        <GroupDashboard />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/index/module/:id"
        allowedRoles={["Learner"]}
      >
        <ViewModule />
      </PrivateRoute>
      <PrivateRoute component={NotFoundPage} />
    </Switch>
  );
}

import React, { useCallback, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import { START_SHOW_CLASSROOM_ACTION } from "../../../rest-client/actions/classrooms";
import { START_MODULE_SET_BY_CATEGORIES_ACTION } from "../../../rest-client/actions/module-sets";
import { useDispatch } from "react-redux";
import { useClassrooms, useModuleSet } from "../../../rest-client/selectorHook";
import DefaultButton from "../../../components/Button/DefaultButton";
import { START_CREATE_MODULE_SESSION_ACTION } from "../../../rest-client/actions/module-sessions";

export default function AssignModulesForm({ classroomId, activeTabIndex }) {
  const dispatch = useDispatch();
  const [courseValue, setCourseValue] = useState({});
  const [selectedModuleCategoriesValue, setSelectedModuleCategoryValue] =
    useState([]);
  const [
    selectedGroupModuleCategoriesValue,
    setSelectedGroupModuleCategoriesValue,
  ] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({
    course:
      courseValue && Object.keys(courseValue).length > 0 ? courseValue : null,
    module_categories: [],
    group_module_categories: [],
  });
  const [originalCategoryValues, setOriginalCategoryValues] = useState(null);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState([]);

  const dispatchStartClassroomShow = useCallback(() => {
    dispatch({
      type: START_SHOW_CLASSROOM_ACTION,
      payload: { id: classroomId ? classroomId : "" },
    });
  }, [dispatch, classroomId]);

  const dispatchStartModuleSetsByCategories = useCallback(
    (category_payload) => {
      dispatch({
        type: START_MODULE_SET_BY_CATEGORIES_ACTION,
        payload: category_payload,
      });
    },
    [dispatch]
  );

  // This reset the initial values if tab is changed on manage session page
  useEffect(() => {
    setInitialValues({
      course: null,
      module_categories: [],
      group_module_categories: [],
    });
    setOriginalCategoryValues(null);
  }, [activeTabIndex]);

  useEffect(() => {
    if (classroomId) {
      dispatchStartClassroomShow();
    }
  }, [dispatchStartClassroomShow, classroomId]);

  const {
    classroom_show_data: { data: classroomShowData },
  } = useClassrooms();

  // On Choose Course Fetch Categories List
  const fetchModuleSetCategories = (selectedModuleSet) => {
    const selectedModuleSetObj = classroomShowData?.module_sets?.find(
      (obj) => obj["id"] === selectedModuleSet?.value
    );
    dispatchStartModuleSetsByCategories({
      module_set_id: selectedModuleSetObj?.id,
      categories: [],
    });
  };

  // On Any of the category selected from category dropdown, fetch updated categories list
  const fetchUpdatedModuleCategories = (selectedValue, module_category) => {
    const category_payload = [
      ...selectedCategoryValue.filter(
        (item) => item.slug !== module_category?.slug
      ),
      {
        slug: module_category?.slug,
        value: selectedValue?.label,
        index: selectedValue?.value,
        group_id: null,
        is_main_query: module_category?.is_main_query,
      },
    ];
    setSelectedCategoryValue(category_payload);
    dispatchStartModuleSetsByCategories({
      module_set_id: courseValue?.value,
      categories: category_payload,
    });
  };

  const {
    module_sets_by_categories_data: { data: setsByCategoriesData },
  } = useModuleSet();

  // Set Original values of module categories, Going Forward these will be used to compare values with updated api values
  useEffect(() => {
    if (
      (initialValues && !originalCategoryValues) ||
      originalCategoryValues?.module_categories?.length === 0 ||
      originalCategoryValues?.course?.value !== courseValue?.value
    ) {
      setOriginalCategoryValues(initialValues);
      if (originalCategoryValues?.course?.value !== courseValue?.value) {
        setIsButtonDisabled(true);
        setSelectedCategoryValue([]);
      }
    }
  });

  // Set initial Values and change everytime api data updated to set updated options for rest of the dropdowns
  useEffect(() => {
    if (setsByCategoriesData) {
      let updatedModuleCategories = null;
      let updatedGroupModuleCategories = null;
      if (
        originalCategoryValues &&
        originalCategoryValues.module_categories?.length > 0 &&
        originalCategoryValues.group_module_categories?.length > 0 &&
        originalCategoryValues?.course?.value === courseValue?.value
      ) {
        // This is set when any one of the category is chosen when again when api is called, we need to update the
        // categories json with what we receive from api. These are is_main_query: true categories
        updatedModuleCategories = originalCategoryValues?.module_categories.map(
          (category) => {
            const apiCategory = setsByCategoriesData.find(
              (apiCategory) => apiCategory.id === category.id
            );
            const selected_category_value = selectedModuleCategoriesValue?.find(
              (selected_category) => selected_category.id === category.id
            );
            if (apiCategory && apiCategory.is_main_query) {
              return {
                ...category,
                module_collection: apiCategory.module_collection,
                facet_values: apiCategory.facet_values,
                ...(selected_category_value &&
                  selected_category_value?.selected_value && {
                    selected_value: selected_category_value?.selected_value,
                  }),
              };
            }
            return {
              ...category,
              ...(selected_category_value &&
                selected_category_value?.selected_value && {
                  selected_value: selected_category_value?.selected_value,
                }),
            };
          }
        );
        // This is set when any one of the group category is chosen again when api is called, we need to update the
        // group categories json with what we receive from api. These are is_main_query: false categories tied with groups
        updatedGroupModuleCategories =
          originalCategoryValues?.group_module_categories.map((group) => {
            const apiCategory = setsByCategoriesData.find(
              (apiCategory) => apiCategory?.id === group?.category?.id
            );
            const selected_grp_category_value =
              selectedGroupModuleCategoriesValue?.find(
                (selected_category) =>
                  selected_category?.id === group?.category?.id
              );
            if (apiCategory && !apiCategory?.is_main_query) {
              return {
                ...group,
                category: {
                  ...group?.category,
                  module_collection: apiCategory?.module_collection,
                  facet_values: apiCategory?.facet_values,
                  ...(selected_grp_category_value &&
                    selected_grp_category_value?.selected_value && {
                      selected_value:
                        selected_grp_category_value?.selected_value,
                    }),
                },
              };
            }
            return {
              ...group,
              ...(selected_grp_category_value &&
                selected_grp_category_value?.selected_value && {
                  selected_value: selected_grp_category_value?.selected_value,
                }),
            };
          });
      }
      setInitialValues({
        course:
          courseValue && Object.keys(courseValue).length > 0
            ? courseValue
            : null,
        module_categories: updatedModuleCategories
          ? updatedModuleCategories
          : setsByCategoriesData?.filter((category) => category.is_main_query),
        group_module_categories: updatedGroupModuleCategories
          ? updatedGroupModuleCategories
          : classroomShowData?.groups?.map((group) => {
              return {
                group_id: group.id,
                group_title: group.title,
                category: setsByCategoriesData
                  ? setsByCategoriesData.filter(
                      (category) => category.is_main_query === false
                    )[0]
                  : [],
              };
            }) || [],
        classroom_id: classroomShowData?.id,
      });
    }
  }, [setsByCategoriesData]);

  const validationHandler = React.useCallback(
    (values) => {
      const error = {};
      if (!values.course) {
        error.course = "Please choose the course first";
      }
      values.module_categories.forEach((module_category, index) => {
        if (!module_category.selected_value) {
          error[
            `module_categories.${index}.selected_value`
          ] = `Please select option for ${module_category?.name}`;
        }
      });
      values.group_module_categories.forEach((group_module_category, index) => {
        if (!group_module_category.selected_value) {
          error[
            `group_module_categories.${index}.selected_value`
          ] = `Please select option for ${group_module_category?.category?.name}`;
        }
      });
      Object.keys(error).length > 0
        ? setIsButtonDisabled(true)
        : setIsButtonDisabled(false);
      return error;
    },
    [setIsButtonDisabled]
  );

  const createModuleAssigmentHandler = React.useCallback(
    (values, { resetForm }) => {
      const createModuleSessionData = {
        module_session: {
          id: values?.classroom_id,
          module_set_id: values?.course?.value,
          categories: values?.module_categories?.map((module_category) => {
            return {
              slug: module_category?.slug,
              value: module_category?.selected_value?.label,
            };
          }),
          group_module_documents: values?.group_module_categories?.map(
            (group_module_category) => {
              return {
                group_id: group_module_category?.group_id,
                module_id: group_module_category?.selected_value?.value,
                categories: [
                  {
                    slug: group_module_category?.category?.slug,
                    value: group_module_category?.selected_value?.label,
                    index: group_module_category?.group_id,
                    group_id: group_module_category?.group_id,
                    is_main_query:
                      group_module_category?.category?.is_main_query,
                  },
                ],
              };
            }
          ),
        },
      };
      dispatch({
        type: START_CREATE_MODULE_SESSION_ACTION,
        payload: { createModuleSessionData },
      });
      setInitialValues({});
      setOriginalCategoryValues({});
      setCourseValue({});
    },
    [dispatch]
  );

  const courseOptions = classroomShowData?.module_sets?.map(
    (module_set, index) => {
      return {
        label: module_set.title,
        value: module_set.id,
      };
    }
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validationHandler}
      onSubmit={createModuleAssigmentHandler}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form className="font-lato">
          <div className="flex flex-col md:flex-row box-border">
            <div className="flex-1">
              <div className="">
                <Field name="course">
                  {({ field, form, meta }) => (
                    <SingleSelect
                      field={field}
                      form={form}
                      meta={meta}
                      options={courseOptions}
                      placeholder="Choose Course*"
                      onChange={(value) => {
                        fetchModuleSetCategories(value);
                        setCourseValue(value);
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="flex-1 w-full md:w-70 md:mx-10"></div>
          </div>
          <div className="flex flex-col md:flex-row box-border">
            {values?.module_categories?.length > 0 &&
              values.module_categories.map((module_category, index) => {
                return (
                  <div
                    key={module_category.name}
                    className={`flex-1 ${
                      values.module_categories.filter(
                        (category) => category.is_main_query
                      ).length > 1
                        ? "w-full md:w-1/2 mr-10 mb-2 mt-2"
                        : "w-30-custom mr-4 mb-2 mt-2"
                    }`}
                  >
                    <Field name={`module_categories.${index}.selected_value`}>
                      {({ field, form, meta }) => (
                        <SingleSelect
                          field={field}
                          form={form}
                          meta={meta}
                          options={module_category.module_collection?.map(
                            (module_collection) => ({
                              label: module_collection?.facet_value,
                              value: module_collection?.id,
                            })
                          )}
                          placeholder={module_category.name}
                          className={`${
                            values.module_categories.filter(
                              (category) => category.is_main_query
                            ).length > 1
                              ? ""
                              : "custom-width"
                          }`}
                          onChange={(value) => {
                            setSelectedModuleCategoryValue((prevState) => [
                              ...prevState.filter(
                                (category) =>
                                  category.name !== module_category.name
                              ),
                              {
                                ...module_category,
                                ...{ [`selected_value`]: value },
                              },
                            ]);
                            fetchUpdatedModuleCategories(
                              value,
                              module_category
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                );
              })}
          </div>
          <div className="flex flex-col box-border p-2 mt-5">
            {values?.group_module_categories?.map(
              (group_module_category, index) => {
                return (
                  <div className="flex text-base mb-2 items-center">
                    <div className="flex flex-row mr-5">
                      <label>{group_module_category?.group_title}</label>
                    </div>
                    {group_module_category?.category && (
                      <div
                        className="flex-1 flex-row"
                        key={group_module_category?.group_id}
                      >
                        <Field
                          name={`group_module_categories.${index}.selected_value`}
                        >
                          {({ field, form, meta }) => (
                            <SingleSelect
                              field={field}
                              form={form}
                              meta={meta}
                              options={
                                values.module_categories[0]?.selected_value
                                  ? group_module_category?.category
                                      ?.module_collection
                                    ? group_module_category?.category?.module_collection.map(
                                        (module_collection) => ({
                                          label: module_collection?.facet_value,
                                          value: module_collection?.id,
                                        })
                                      )
                                    : []
                                  : []
                              }
                              placeholder={
                                group_module_category?.category?.name
                              }
                              className={"custom-width"}
                              onChange={(value) => {
                                setSelectedGroupModuleCategoriesValue(
                                  (prevState) => [
                                    ...prevState.filter(
                                      (category) =>
                                        category.group_id !==
                                        group_module_category.group_id
                                    ),
                                    {
                                      ...group_module_category,
                                      ...{ [`selected_value`]: value },
                                    },
                                  ]
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
          <div className="flex justify-center mt-5	md:justify-end md:mx-10">
            <DefaultButton label="Run" disabled={isButtonDisabled} />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export const START_FETCHING_MODULE_DOCUMENTS_ACTION = `START_FETCHING_MODULE_DOCUMENTS_ACTION`;
export const ERROR_FETCHING_MODULE_DOCUMENTS_ACTION = `ERROR_FETCHING_MODULE_DOCUMENTS_ACTION`;
export const SUCCESS_FETCHING_MODULE_DOCUMENTS_ACTION = `SUCCESS_FETCHING_MODULE_DOCUMENTS_ACTION`;

export const START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION = `START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION`;
export const ERROR_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION = `ERROR_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION`;
export const SUCCESS_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION = `SUCCESS_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION`;

export const START_DELETE_MODULE_DOCUMENT_ACTION =
  "START_DELETE_MODULE_DOCUMENT_ACTION";
export const SUCCESS_DELETE_MODULE_DOCUMENT_ACTION =
  "SUCCESS_DELETE_MODULE_DOCUMENT_ACTION";
export const FAILURE_DELETE_MODULE_DOCUMENT_ACTION =
  "FAILURE_DELETE_MODULE_DOCUMENT_ACTION";

export const START_UPDATING_MODULE_RESOURCE_NODE_BY_ID = `START_UPDATING_MODULE_RESOURCE_NODE_BY_ID`;
export const ERROR_UPDATING_MODULE_RESOURCE_NODE_BY_ID = `ERROR_UPDATING_MODULE_RESOURCE_NODE_BY_ID`;
export const SUCCESS_UPDATING_MODULE_RESOURCE_NODE_BY_ID = `SUCCESS_UPDATING_MODULE_RESOURCE_NODE_BY_ID`;

export const START_CREATE_MODULE_DOCUMENT_ACTION =
  "START_CREATE_MODULE_DOCUMENT_ACTION";
export const SUCCESS_CREATE_MODULE_DOCUMENT_ACTION =
  "SUCCESS_CREATE_MODULE_DOCUMENT_ACTION";
export const FAILURE_CREATE_MODULE_DOCUMENT_ACTION =
  "FAILURE_CREATE_MODULE_DOCUMENT_ACTION";

export const START_CLONE_MODULE_DOCUMENT_ACTION =
  "START_CLONE_MODULE_DOCUMENT_ACTION";
export const SUCCESS_CLONE_MODULE_DOCUMENT_ACTION =
  "SUCCESS_CLONE_MODULE_DOCUMENT_ACTION";
export const FAILURE_CLONE_MODULE_DOCUMENT_ACTION =
  "FAILURE_CLONE_MODULE_DOCUMENT_ACTION";

export const START_UPDATE_MODULE_DOCUMENT_ACTION =
  "START_UPDATE_MODULE_DOCUMENT_ACTION";
export const SUCCESS_UPDATE_MODULE_DOCUMENT_ACTION =
  "SUCCESS_UPDATE_MODULE_DOCUMENT_ACTION";
export const FAILURE_UPDATE_MODULE_DOCUMENT_ACTION =
  "FAILURE_UPDATE_MODULE_DOCUMENT_ACTION";

export const START_FETCH_DISCUSSION_ENTRIES_ACTION =
  "START_FETCH_DISCUSSION_ENTRIES_ACTION";
export const SUCCESS_FETCH_DISCUSSION_ENTRIES_ACTION =
  "SUCCESS_FETCH_DISCUSSION_ENTRIES_ACTION";
export const FAILURE_FETCH_DISCUSSION_ENTRIES_ACTION =
  "FAILURE_FETCH_DISCUSSION_ENTRIES_ACTION";

export const START_MODULE_RESOURCE_NODE_BY_ID = `START_MODULE_RESOURCE_NODE_BY_ID`;
export const ERROR_MODULE_RESOURCE_NODE_BY_ID = `ERROR_MODULE_RESOURCE_NODE_BY_ID`;
export const SUCCESS_MODULE_RESOURCE_NODE_BY_ID = `SUCCESS_MODULE_RESOURCE_NODE_BY_ID`;

import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import TextArea from "../TextAreaComponent/TextArea";
import OutlinedButton from "../Button/OutlinedButton";
import "../../styles/canvasContainer.scss";

export default function CanvasResource({
  htmlContent,
  media,
  canvasRef,
  discussions,
  setFormTextData,
}) {
  const [color, setColor] = useState("#000000");
  const [image, setImage] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [lines, setLines] = useState([]);

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    if (newColor) {
      setColor(newColor);
    }
  };
  useEffect(() => {
    loadImage();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Redraw the canvas with the updated color
    draw(ctx);
  }, [color]);

  useEffect(() => {
    const canvas = canvasRef?.current;
    const ctx = canvas?.getContext("2d");

    let updatedCtx = draw(ctx);
    setContext(() => updatedCtx);

    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchmove", handleTouchMove);
    canvas.addEventListener("touchend", handleTouchEnd);

    return () => {
      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchmove", handleTouchMove);
      canvas.removeEventListener("touchend", handleTouchEnd);
    };
  }, [image, color, canvasRef]);

  // This draws the image on the canvas and scale factor fits in any resolution image into given canvas size
  const draw = (ctx) => {
    if (image) {
      const canvas = canvasRef.current;
      const canvasWidth = 800; // Desired canvas width
      const canvasHeight = 600; // Desired canvas height

      // Calculate the aspect ratio of the image
      const imageAspectRatio = image.width / image.height;

      // Calculate the dimensions that maintain the aspect ratio within the canvas size
      let newWidth = canvasWidth;
      let newHeight = canvasWidth / imageAspectRatio;

      if (newHeight > canvasHeight) {
        newHeight = canvasHeight;
        newWidth = canvasHeight * imageAspectRatio;
      }

      // Center the resized image on the canvas
      const x = (canvasWidth - newWidth) / 2;
      const y = (canvasHeight - newHeight) / 2;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // ctx.imageSmoothingEnabled = false;
      ctx.drawImage(image, x, y, newWidth, newHeight);
      for (const line of lines) {
        ctx.beginPath();
        ctx.moveTo(line.startX, line.startY);
        ctx.lineTo(line.endX, line.endY);
        ctx.strokeStyle = line.color; // Set the color for each line
        ctx.lineWidth = 3;
        ctx.stroke();
      }
      return ctx;
    }
  };

  // function isBase64ImageLink(url) {
  //   return /^data:image\/\w+;base64,/.test(url);
  // }

  async function isBase64ImageLink(url) {
    const response = await fetch(url);
    const contentType = response.headers.get("Content-Type");
    return (
      contentType.startsWith("data:image/") && contentType.includes(";base64,")
    );
  }

  const loadImage = async () => {
    const img = new Image();
    await isBase64ImageLink(media.url).then((isBase64) => {
      if (isBase64) {
        let imageUrl = media.url;
        const regex = /^data:image\/([a-z]+);base64,([^\s]+)$/i;
        let imageUrlData = imageUrl;
        if (regex.test(imageUrl)) {
          // check if the URL contains base64-encoded image data
          const [, format, data] = imageUrl.match(regex); // extract the image format and base64-encoded data using destructuring assignment
          imageUrlData = `data:image/${format};base64,${data}`; // reconstruct the image URL with the correct image format prefix
          img.src = imageUrlData;
          img.crossOrigin = "anonymous";
          img.onload = () => {
            setImage(img);
          };
        }
      } else {
        img.src = media.url;
        img.crossOrigin = "anonymous";
        img.onload = () => {
          setImage(img);
        };
      }
    });
  };

  const handleMouseDown = (event) => {
    setIsDrawing(true);
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setLastX(x);
    setLastY(y);
  };

  // On mouse move the lines will be drawn and the stroke color will be picked from color input
  const handleMouseMove = (event) => {
    if (isDrawing) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      context.beginPath();
      context.moveTo(lastX, lastY);
      context.lineTo(x, y);
      context.strokeStyle = color;
      context.lineWidth = 3;
      context.stroke();
      setLastX(x);
      setLastY(y);
      setLines([
        ...lines,
        { startX: lastX, startY: lastY, endX: x, endY: y, color: color },
      ]);
    }
  };

  // This is to stop drawing when mouse is up
  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  // To Remove/Erase the lines drawn on canvas
  const handleClearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    loadImage();
    draw(context);
    setLines([]);
  };

  const handleTouchStart = (event) => {
    event.preventDefault();
    setIsDrawing(true);
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.touches[0].clientX - rect.left;
    const y = event.touches[0].clientY - rect.top;
    setLastX(x);
    setLastY(y);
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    if (isDrawing) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.touches[0].clientX - rect.left;
      const y = event.touches[0].clientY - rect.top;
      context.beginPath();
      context.moveTo(lastX, lastY);
      context.lineTo(x, y);
      context.strokeStyle = color;
      context.lineWidth = 3;
      context.stroke();
      setLastX(x);
      setLastY(y);
      setLines([
        ...lines,
        { startX: lastX, startY: lastY, endX: x, endY: y, color: color },
      ]);
    }
  };

  const handleTouchEnd = (event) => {
    event.preventDefault();
    setIsDrawing(false);
  };

  const removeLastLine = () => {
    // Remove the last line from the array
    const lastLine = lines.pop();

    // Clear the lines layer
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // Draw the image first
    draw(context);

    // Redraw all the lines except for the last one
    for (const line of lines) {
      context.beginPath();
      context.moveTo(line.startX, line.startY);
      context.lineTo(line.endX, line.endY);
      context.strokeStyle = line.color; // Use the color from each line
      context.lineWidth = 3;
      context.stroke();
    }
  };

  return (
    <>
      <div className="flex flex-wrap md:flex-col xl:flex-row w-full">
        <div className="xl:w-1/5 md:w-full md:max-w-full">
          {parse(htmlContent)}
        </div>
        <div className="xl:w-4/5 md:w-full md:max-w-full">
          <div className="xl:ml-5 lg:ml-2 md:ml-1">
            <div className="flex justify-between">
              <div className="flex items-center">
                <p>Pick a color</p>
                <input
                  type="color"
                  value={color}
                  onChange={handleColorChange}
                  className="m-5 w-24 h-10"
                />
              </div>
              <div className="flex items-center">
                <OutlinedButton
                  label="Undo"
                  className="m-4"
                  onClickHandler={removeLastLine}
                />
                <OutlinedButton
                  label="Clear"
                  className="m-4"
                  onClickHandler={handleClearCanvas}
                />
              </div>
            </div>

            <canvas
              ref={canvasRef}
              className="border border-solid border-gray-300 canvas-container"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            />
          </div>
        </div>
      </div>
      {Array.isArray(discussions) && discussions.length > 0 ? (
        <TextArea discussions={discussions} setFormTextData={setFormTextData} />
      ) : null}
    </>
  );
}

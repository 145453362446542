import React, { useMemo, useState } from "react";
import { useTable, usePagination, useExpanded } from "react-table";
import "../../../styles/module_sets.scss";
import Icon from "../../../components/Button/Icon";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import {
  START_DELETE_MODULE_SETS_ACTION,
  START_UPDATE_MODULE_SETS_ACTION,
} from "../../../rest-client/actions/module-sets";
import { START_DELETE_MODULE_DOCUMENT_ACTION } from "../../../rest-client/actions/module-documents";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import CloneModal from "../CloneModal";
import { ClipLoader } from "react-spinners";
import { PAGE_SIZE } from "../../../utils";

export default function ListSets({
  moduleSetListData,
  searchInput,
  setSearchInput,
  moduleSetListLoading,
  totalPages,
  currentPage,
  setCurrentPage,
  inputRef,
}) {
  const data = useMemo(() => {
    return moduleSetListData;
  }, [moduleSetListData]);

  const { url } = useRouteMatch(`/dashboard/learning`);
  const [isVisible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [moduleSetData, setModuleSetData] = useState({});
  const [moduleData, setModuleData] = useState({});
  const [isModuleDelete, setIsModuleDelete] = useState(false);
  const [isOpenCloneModal, setIsOpenCloneModal] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      autoResetExpanded: false,
      data: data,
      initialState: { pageSize: PAGE_SIZE },
    },
    useExpanded,
    usePagination
  );
  const { getTableProps, getTableBodyProps, page, prepareRow } = tableInstance;

  const history = useHistory();

  const redirectHandleClick = React.useCallback(() => {
    history.push(`${url}/module_sets`);
  }, [history, url]);

  const onHideShowClick = () => {
    setVisible(!isVisible);
  };

  const unlockClickHandler = React.useCallback(
    (module_set_data) => {
      const updateModuleSetRequestData = {
        module_set: {
          ...module_set_data,
          state_event: module_set_data.state === "locked" ? "unlock" : "lock",
        },
        id: module_set_data.id,
      };
      dispatch({
        type: START_UPDATE_MODULE_SETS_ACTION,
        payload: {
          updateModuleSetRequestData,
          history,
          isMainRecordUpdate: false,
        },
      });
    },
    [dispatch]
  );

  const openConfirmModalHandler = React.useCallback((value, recordType) => {
    setIsOpenConfirmModal(true);
    if (recordType === "moduleSet") {
      setModuleSetData(value);
    } else {
      setModuleData(value);
      setIsModuleDelete(true);
    }
  }, []);

  const openCloneModalHandler = React.useCallback((value) => {
    setIsOpenCloneModal(true);
    setModuleSetData(value);
  }, []);

  const deleteModuleSetHandler = React.useCallback(() => {
    const deleteModuleSetRequestData = {
      module_set: {
        ...moduleSetData,
      },
      id: moduleSetData.id,
    };
    dispatch({
      type: START_DELETE_MODULE_SETS_ACTION,
      payload: { deleteModuleSetRequestData, setIsOpenConfirmModal },
    });
  }, [dispatch, moduleSetData]);

  const deleteModuleDocumentHandler = React.useCallback(() => {
    const deleteModuleDocumentRequestData = {
      module_document: {
        ...moduleData,
      },
      id: moduleData.id,
    };
    dispatch({
      type: START_DELETE_MODULE_DOCUMENT_ACTION,
      payload: {
        deleteModuleDocumentRequestData,
        setIsOpenConfirmModal,
        currentPage,
      },
    });
  }, [dispatch, moduleData]);

  return (
    <>
      {isOpenConfirmModal && (
        <ConfirmModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          deleteRecordHandler={
            isModuleDelete
              ? deleteModuleDocumentHandler
              : deleteModuleSetHandler
          }
        />
      )}
      {isOpenCloneModal && (
        <CloneModal
          isOpenCloneModal={isOpenCloneModal}
          setIsOpenCloneModal={setIsOpenCloneModal}
          clonedObjectData={moduleSetData}
          clonedObjectName="Module Set"
        />
      )}
      {moduleSetListLoading ? (
        <div className="flex flex-col justify-center items-center h-32">
          <ClipLoader size={32} color={"#3e8ede"} />
          <p className="mt-2 font-bold font-muli text-blue-theme">
            Fetching Module Sets...
          </p>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="inline-block align-middle p-5">
            <div className="overflow-hidden border rounded-lg">
              <div className="flex flex-row	box-border">
                <div className="flex-1 w-20 mb-5">
                  <h3 className="form_title p-2">Module Sets</h3>
                </div>
                <div className="flex-1 w-80 text-right">
                  <div className="py-7 flex items-center justify-end">
                    <button
                      className="border-none bg-transparent"
                      onClick={redirectHandleClick}
                    >
                      <Icon
                        label="add"
                        title="Add New Module Set"
                        className={classNames("text-light-gray")}
                      />
                    </button>
                    <button
                      className="border-none bg-transparent"
                      onClick={onHideShowClick}
                    >
                      <Icon
                        label="search"
                        title="Search"
                        className={classNames("text-light-gray")}
                      />
                    </button>
                    <div>
                      <input
                        type="text"
                        id="search"
                        className="search-input text-base"
                        placeholder="Search"
                        style={{ display: isVisible ? "block" : "none" }}
                        // onKeyPress={onKeyPressHandler}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        ref={inputRef}
                      />
                    </div>
                    <button
                      className={classNames("border-none bg-transparent", {
                        "pointer-events-none": currentPage === 1,
                      })}
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <Icon
                        label="navigate_before"
                        title="Previous Page"
                        className={classNames("text-light-gray")}
                      />
                    </button>
                    <div className="text-light-gray mb-1">
                      {currentPage} / {totalPages}
                    </div>
                    <button
                      className={classNames("border-none bg-transparent", {
                        "pointer-events-none": currentPage == totalPages,
                      })}
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage == totalPages}
                    >
                      <Icon
                        label="navigate_next"
                        title="Next Page"
                        className={classNames("text-light-gray")}
                      />
                    </button>
                  </div>
                </div>
              </div>
              {data?.length > 0 ? (
                <table
                  {...getTableProps()}
                  className="min-w-full divide-y divide-gray-200 flex flex-col"
                >
                  <tbody
                    {...getTableBodyProps()}
                    className="divide-y divide-gray-200"
                  >
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={row.getRowProps().key}>
                          <tr
                            {...row.getRowProps()}
                            className="shadow border-spacing-1 border-spacing-x-2.5 flex justify-between"
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps({
                                    className:
                                      "px-6 py-6 text-base text-gray-800 whitespace-nowrap font-bold",
                                  })}
                                >
                                  <button
                                    className="border-none bg-transparent"
                                    onClick={() => row.toggleRowExpanded()}
                                  >
                                    <Icon
                                      label={
                                        row.isExpanded
                                          ? "expand_less"
                                          : "expand_more"
                                      }
                                      title=""
                                      className={classNames(
                                        "text-light-gray !m-0"
                                      )}
                                    />
                                  </button>
                                  ​{cell.render("Cell")}
                                </td>
                              );
                            })}
                            <td className="px-6 py-6 flex items-center justify-end">
                              <button
                                className="border-none bg-transparent"
                                onClick={() => unlockClickHandler(row.original)}
                              >
                                {row.original.state === "unlocked" ? (
                                  <Icon
                                    label="lock_open"
                                    title="Unlocked"
                                    className={classNames("text-green-500")}
                                  />
                                ) : (
                                  <Icon
                                    label="lock"
                                    title="Locked"
                                    className={classNames("text-red-400")}
                                  />
                                )}
                              </button>
                              <button
                                className="border-none bg-transparent"
                                onClick={() =>
                                  openCloneModalHandler(row.original)
                                }
                              >
                                <Icon
                                  label="content_copy"
                                  title="Clone"
                                  className={classNames("text-light-gray")}
                                />
                              </button>
                              <button
                                className="border-none bg-transparent"
                                onClick={(e) => {
                                  history.push(
                                    `${url}/module_sets/${row.original.id}/edit`
                                  );
                                }}
                              >
                                <Icon
                                  label="edit"
                                  title="Edit"
                                  className={classNames("text-light-gray")}
                                />
                              </button>
                              <button
                                className="border-none bg-transparent"
                                onClick={() => {
                                  openConfirmModalHandler(
                                    row.original,
                                    "moduleSet"
                                  );
                                  setIsModuleDelete(false);
                                }}
                              >
                                <Icon
                                  label="delete"
                                  title="Delete"
                                  className={classNames("text-light-gray")}
                                />
                              </button>
                            </td>
                          </tr>
                          {row.isExpanded && (
                            <tr className="flex flex-col">
                              <td>
                                <div className="p-4 px-5 bg-gray-100">
                                  {row.original.module_documents.length > 0 ? (
                                    <>
                                      <div className="flex">
                                        <h3 className="text-slate-700 p-2">
                                          Modules
                                        </h3>
                                      </div>
                                      <div>
                                        {row.original.module_documents.map(
                                          (module_document) => {
                                            return (
                                              <div className="flex bg-white px-5 py-2 text-base text-slate-700 whitespace-nowrap font-bold mb-2 justify-between">
                                                <div className="">
                                                  {module_document.title}
                                                </div>
                                                <div>
                                                  <Link
                                                    to={`${url}/module/${module_document.id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <Icon
                                                      label="visibility"
                                                      title="View"
                                                      className={classNames(
                                                        "text-light-gray"
                                                      )}
                                                    />
                                                  </Link>
                                                  <button
                                                    className="border-none bg-transparent"
                                                    onClick={() => {
                                                      openConfirmModalHandler(
                                                        module_document,
                                                        "moduleDocument"
                                                      );
                                                      setIsModuleDelete(true);
                                                    }}
                                                  >
                                                    <Icon
                                                      label="delete"
                                                      title="Delete"
                                                      className={classNames(
                                                        "text-light-gray"
                                                      )}
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="text-center p-10 text-base font-bold">
                                      Modules are not assigned yet to this
                                      Module Set.
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="text-center p-10 text-base">
                  No module sets available, Click <b>+</b> to create one.
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

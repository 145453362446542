import api from "../api";

export function fetchResourcesListRequest(payload) {
  return api.get(
    `/resources?order=${payload.orderType}&order_by=title&per_page=20${
      payload?.page && payload?.page > 0 ? `&page=${payload?.page}` : ""
    }${payload?.query ? `&query=${payload.query}` : ""}`
  );
}

export function fetchResourceCloneRequest(payload) {
  return api.post(`/resources/${payload?.resource?.id}/clone`, {
    paramObj: payload,
  });
}

export function deleteResourceRequest(payload) {
  return api.delet(`/resources/${payload?.resource?.id}`, {
    paramObj: payload,
  });
}

export function fetchResourceShowRequest(id) {
  return api.get(`/resources/${id}`);
}

export function updateResourceRequest(payload) {
  return api.put(`/resources/${payload.id}`, {
    paramObj: payload.resourcePutPayload,
  });
}

// export function fetchupdateResourceRequest(payload) {
//   return api.put(`/resources/${payload.id}`, {
//     paramObj: payload,
//   });
// }

export function addResourcesRequest(payload) {
  return api.post(`/resources`, {
    paramObj: payload,
  });
}

export function addDiscussionPromptRequest(payload) {
  return api.post(`/discussions`, {
    paramObj: payload,
  });
}

export function deleteDiscussionPromptRequest(payload) {
  return api.delet(
    `/discussions/${payload?.id}?discussion_type=${
      payload?.discussion_type
    }&order=${payload?.order}&title=${encodeURIComponent(payload?.title)}`,
    {
      paramObj: payload,
    }
  );
}

export function addPollTypeResourceRequest(payload) {
  return api.post(`/polls`, {
    paramObj: payload,
  });
}

export function addCanvasImagesRequest(payload) {
  return api.uploadImage(`/media`, {
    paramObj: payload,
  });
}

export function fetchResourceByIdRequest({ resource_id }) {
  return api.get(`/resources/${resource_id}`);
}

export function updatePollByIdRequest(payload) {
  return api.put(`/polls/${payload.poll.id}`, {
    paramObj: payload,
  });
}

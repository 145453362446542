import {
  ERROR_FETCHING_RESOURCES_LIST_ACTION,
  START_FETCHING_RESOURCES_LIST_ACTION,
  SUCCESS_FETCHING_RESOURCES_LIST_ACTION,
  START_CLONE_RESOURCE_ACTION,
  SUCCESS_CLONE_RESOURCE_ACTION,
  FAILURE_CLONE_RESOURCE_ACTION,
  START_DELETE_RESOURCE_ACTION,
  SUCCESS_DELETE_RESOURCE_ACTION,
  FAILURE_DELETE_RESOURCE_ACTION,
  START_SHOW_RESOURCE_ACTION,
  SUCCESS_SHOW_RESOURCE_ACTION,
  FAILURE_SHOW_RESOURCE_ACTION,
  START_ADD_RESOURCE_ACTION,
  SUCCESS_ADD_RESOURCE_ACTION,
  FAILURE_ADD_RESOURCE_ACTION,
  START_ADD_DISCUSSION_PROMPT_ACTION,
  SUCCESS_ADD_DISCUSSION_PROMPT_ACTION,
  FAILURE_ADD_DISCUSSION_PROMPT_ACTION,
  START_ADD_POLL_ACTION,
  SUCCESS_ADD_POLL_ACTION,
  FAILURE_ADD_POLL_ACTION,
  START_ADD_CANVAS_IMG_ACTION,
  SUCCESS_ADD_CANVAS_IMG_ACTION,
  FAILURE_ADD_CANVAS_IMG_ACTION,
  START_ADD_CANVAS_BG_IMG_ACTION,
  SUCCESS_ADD_CANVAS_BG_IMG_ACTION,
  FAILURE_ADD_CANVAS_BG_IMG_ACTION,
  START_UPDATE_RESOURCE_ACTION,
  SUCCESS_UPDATE_RESOURCE_ACTION,
  FAILURE_UPDATE_RESOURCE_ACTION,
  START_FETCHING_RESOURCE_BY_ID_ACTION,
  SUCCESS_FETCHING_RESOURCE_BY_ID_ACTION,
  ERROR_FETCHING_RESOURCE_BY_ID_ACTION,
  START_UPDATING_POLL_BY_ID_ACTION,
  SUCCESS_UPDATING_POLL_BY_ID_ACTION,
  ERROR_UPDATING_POLL_BY_ID_ACTION,
  START_DELETE_DISCUSSION_PROMPT_ACTION,
  SUCCESS_DELETE_DISCUSSION_PROMPT_ACTION,
  FAILURE_DELETE_DISCUSSION_PROMPT_ACTION,
  START_FETCHING_RESOURCE_NODE_ACTION,
  SUCCESS_FETCHING_RESOURCE_NODE_ACTION,
  ERROR_FETCHING_RESOURCE_NODE_ACTION,
  LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION,
} from "../actions/resources";

const initState = {
  resources_list_data: {
    isLoading: false,
    isError: false,
    data: {},
    meta: {},
  },
  resource_clone: {
    isLoading: false,
    isError: false,
    data: null,
  },
  resource_delete: {
    isLoading: false,
    isError: false,
    data: {},
  },
  resource_show_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  add_resource_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  add_discussion_prompt_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  delete_discussion_prompt_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  add_poll_type_resource_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  add_canvas_img_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  add_canvas_bg_img_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  update_resource_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  resource_by_id_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
  update_poll_data_by_id: {
    isLoading: false,
    isError: false,
    data: {},
  },
  fetch_resource_nodes: {
    isLoading: false,
    isError: false,
    data: {},
  },
};

export const resourcesReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_FETCHING_RESOURCES_LIST_ACTION: {
      return {
        ...prevState,
        resources_list_data: {
          isLoading: true,
          isError: false,
          data: {},
          meta: {},
        },
      };
    }
    case SUCCESS_FETCHING_RESOURCES_LIST_ACTION: {
      return {
        ...prevState,
        resources_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
          meta: action.meta,
        },
      };
    }
    case ERROR_FETCHING_RESOURCES_LIST_ACTION: {
      return {
        ...prevState,
        resources_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
          meta: {},
        },
      };
    }
    case START_CLONE_RESOURCE_ACTION: {
      return {
        ...prevState,
        resource_clone: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CLONE_RESOURCE_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        resource_clone: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CLONE_RESOURCE_ACTION: {
      return {
        ...prevState,
        resource_clone: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_DELETE_RESOURCE_ACTION: {
      return {
        ...prevState,
        resource_delete: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_DELETE_RESOURCE_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        resource_delete: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_DELETE_RESOURCE_ACTION: {
      return {
        ...prevState,
        resource_delete: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_SHOW_RESOURCE_ACTION:
      return {
        ...prevState,
        resource_show_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };

    case SUCCESS_SHOW_RESOURCE_ACTION:
      const { data } = action;
      return {
        ...prevState,
        resource_show_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    case FAILURE_SHOW_RESOURCE_ACTION:
      return {
        ...prevState,
        resource_show_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    case START_ADD_RESOURCE_ACTION: {
      return {
        ...prevState,
        add_resource_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_ADD_RESOURCE_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        add_resource_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_ADD_RESOURCE_ACTION: {
      return {
        ...prevState,
        add_resource_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_ADD_DISCUSSION_PROMPT_ACTION: {
      return {
        ...prevState,
        add_discussion_prompt_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_ADD_DISCUSSION_PROMPT_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        add_discussion_prompt_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_ADD_DISCUSSION_PROMPT_ACTION: {
      return {
        ...prevState,
        add_discussion_prompt_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_DELETE_DISCUSSION_PROMPT_ACTION: {
      return {
        ...prevState,
        delete_discussion_prompt_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_DELETE_DISCUSSION_PROMPT_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        delete_discussion_prompt_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_DELETE_DISCUSSION_PROMPT_ACTION: {
      return {
        ...prevState,
        delete_discussion_prompt_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_ADD_POLL_ACTION: {
      return {
        ...prevState,
        add_poll_type_resource_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_ADD_POLL_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        add_poll_type_resource_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_ADD_POLL_ACTION: {
      return {
        ...prevState,
        add_poll_type_resource_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_ADD_CANVAS_IMG_ACTION: {
      return {
        ...prevState,
        add_canvas_img_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_ADD_CANVAS_IMG_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        add_canvas_img_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_ADD_CANVAS_IMG_ACTION: {
      return {
        ...prevState,
        add_canvas_img_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_ADD_CANVAS_BG_IMG_ACTION: {
      return {
        ...prevState,
        add_canvas_bg_img_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_ADD_CANVAS_BG_IMG_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        add_canvas_bg_img_data: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }
    case FAILURE_ADD_CANVAS_BG_IMG_ACTION: {
      return {
        ...prevState,
        add_canvas_bg_img_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_UPDATE_RESOURCE_ACTION: {
      return {
        ...prevState,
        update_resource_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_UPDATE_RESOURCE_ACTION: {
      return {
        ...prevState,
        update_resource_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case FAILURE_UPDATE_RESOURCE_ACTION: {
      return {
        ...prevState,
        update_resource_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_FETCHING_RESOURCE_BY_ID_ACTION: {
      return {
        ...prevState,
        resource_by_id_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_FETCHING_RESOURCE_BY_ID_ACTION: {
      return {
        ...prevState,
        resource_by_id_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_FETCHING_RESOURCE_BY_ID_ACTION: {
      return {
        ...prevState,
        resource_by_id_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_UPDATING_POLL_BY_ID_ACTION: {
      return {
        ...prevState,
        update_poll_data_by_id: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_UPDATING_POLL_BY_ID_ACTION: {
      return {
        ...prevState,
        update_poll_data_by_id: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_UPDATING_POLL_BY_ID_ACTION: {
      return {
        ...prevState,
        update_poll_data_by_id: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_FETCHING_RESOURCE_NODE_ACTION: {
      return {
        ...prevState,
        fetch_resource_nodes: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }
    case SUCCESS_FETCHING_RESOURCE_NODE_ACTION: {
      return {
        ...prevState,
        fetch_resource_nodes: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    }
    case ERROR_FETCHING_RESOURCE_NODE_ACTION: {
      return {
        ...prevState,
        fetch_resource_nodes: {
          isLoading: false,
          isError: action.errorMessage,
        },
      };
    }
    case LOCALLY_UPDATE_RESOURCE_LIST_DATA_ACTION: {
      return {
        ...prevState,
        fetch_resource_nodes: {
          isLoading: false,
          isError: false,
          data: {},
        },
      };
    }
    default:
      return prevState;
  }
};

import { takeLatest, call, put } from "redux-saga/effects";
import {
  START_MODULE_SET_LIST_ACTION,
  ERROR_MODULE_SET_LIST_ACTION,
  SUCCESS_MODULE_SET_LIST_ACTION,
  START_UPDATE_MODULE_SETS_ACTION,
  SUCCESS_UPDATE_MODULE_SETS_ACTION,
  FAILURE_UPDATE_MODULE_SETS_ACTION,
  START_DELETE_MODULE_SETS_ACTION,
  SUCCESS_DELETE_MODULE_SETS_ACTION,
  FAILURE_DELETE_MODULE_SETS_ACTION,
  START_CLONE_MODULE_SET_ACTION,
  SUCCESS_CLONE_MODULE_SET_ACTION,
  FAILURE_CLONE_MODULE_SET_ACTION,
  START_CREATE_MODULE_SET_ACTION,
  SUCCESS_CREATE_MODULE_SET_ACTION,
  FAILURE_CREATE_MODULE_SET_ACTION,
  START_SHOW_MODULE_SET_ACTION,
  SUCCESS_SHOW_MODULE_SET_ACTION,
  FAILURE_SHOW_MODULE_SET_ACTION,
  START_MODULE_SET_BY_CATEGORIES_ACTION,
  ERROR_MODULE_SET_BY_CATEGORIES_ACTION,
  SUCCESS_MODULE_SET_BY_CATEGORIES_ACTION,
  START_MODULE_SET_NON_PAGINATED_LIST_ACTION,
  ERROR_MODULE_SET_NON_PAGINATED_LIST_ACTION,
  SUCCESS_MODULE_SET_NON_PAGINATED_LIST_ACTION,
} from "../actions/module-sets";
import {
  deleteModuleSetRequest,
  fetchModuleSetListAction,
  fetchModuleSetUpdateAction,
  fetchModuleSetCloneRequest,
  fetchCreateModuleSetRequest,
  fetchModuleSetShowRequest,
  fetchModuleSetByCategoriesRequest,
  fetchModuleSetNonPaginatedListAction,
} from "../apis/module-sets";
import { toast } from "react-toastify";

export function* fetchModuleSetListActionWatcher() {
  yield takeLatest(
    START_MODULE_SET_LIST_ACTION,
    fetchModuleSetListActionWorker
  );
}

export function* fetchModuleSetListActionWorker({ payload }) {
  const fetchModuleSetListActionResponse = yield call(
    fetchModuleSetListAction,
    payload
  );
  if (fetchModuleSetListActionResponse.status) {
    yield put({
      type: SUCCESS_MODULE_SET_LIST_ACTION,
      data: fetchModuleSetListActionResponse.data.module_sets,
      meta: fetchModuleSetListActionResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_SET_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleSetNonPaginatedListActionWatcher() {
  yield takeLatest(
    START_MODULE_SET_NON_PAGINATED_LIST_ACTION,
    fetchModuleSetNonPaginatedListActionWorker
  );
}

function* fetchModuleSetNonPaginatedListActionWorker() {
  const fetchModuleSetListActionResponse = yield call(
    fetchModuleSetNonPaginatedListAction
  );
  if (fetchModuleSetListActionResponse.status) {
    yield put({
      type: SUCCESS_MODULE_SET_NON_PAGINATED_LIST_ACTION,
      data: fetchModuleSetListActionResponse.data.module_sets,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_SET_NON_PAGINATED_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleSetUpdateActionWatcher() {
  yield takeLatest(
    START_UPDATE_MODULE_SETS_ACTION,
    fetchModuleSetUpdateActionWorker
  );
}

function* fetchModuleSetUpdateActionWorker({ payload }) {
  const { updateModuleSetRequestData, history, isMainRecordUpdate } = payload;
  const updateModuleSetRequestResponse = yield call(
    fetchModuleSetUpdateAction,
    updateModuleSetRequestData
  );
  if (updateModuleSetRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_MODULE_SETS_ACTION,
      data: updateModuleSetRequestResponse.data,
    });
    if (isMainRecordUpdate) {
      history.push("/dashboard/learning/index/module_sets");
    } else {
      yield call(fetchModuleSetListActionWorker, { payload: null });
    }
    toast.success("Module Set Updated Successfully");
  } else {
    const errorMessage =
      updateModuleSetRequestResponse?.data?.message?.length > 0
        ? updateModuleSetRequestResponse.data.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_MODULE_SETS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleSetDeleteRequestWatcher() {
  yield takeLatest(
    START_DELETE_MODULE_SETS_ACTION,
    fetchModuleSetDeleteRequestWorker
  );
}

function* fetchModuleSetDeleteRequestWorker({ payload }) {
  const { deleteModuleSetRequestData, setIsOpenConfirmModal } = payload;
  const deleteModuleSetRequestResponse = yield call(
    deleteModuleSetRequest,
    deleteModuleSetRequestData
  );
  if (deleteModuleSetRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_MODULE_SETS_ACTION,
      data: deleteModuleSetRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchModuleSetListActionWorker, { payload: null });
    toast.success("Module set deleted");
  } else {
    const errorMessage =
      deleteModuleSetRequestResponse?.message?.length > 0
        ? deleteModuleSetRequestResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_MODULE_SETS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleSetCloneRequestWatcher() {
  yield takeLatest(START_CLONE_MODULE_SET_ACTION, fetchModuleSetCloneWorker);
}

function* fetchModuleSetCloneWorker({ payload }) {
  const { createCloneModuleSetData, setIsOpenCloneModal } = payload;
  const fetchModuleSetCloneResponse = yield call(
    fetchModuleSetCloneRequest,
    createCloneModuleSetData
  );
  if (fetchModuleSetCloneResponse.status) {
    yield put({
      type: SUCCESS_CLONE_MODULE_SET_ACTION,
      data: fetchModuleSetCloneResponse.data.module_set,
    });
    setIsOpenCloneModal(false);
    yield call(fetchModuleSetListActionWorker, { payload: null });
    toast.success("Module Set Cloned Successfully");
  } else {
    const errorMessage =
      fetchModuleSetCloneResponse?.message?.length > 0
        ? fetchModuleSetCloneResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CLONE_MODULE_SET_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleSetCreateRequestWatcher() {
  yield takeLatest(START_CREATE_MODULE_SET_ACTION, fetchModuleSetCreateWorker);
}

function* fetchModuleSetCreateWorker({ payload }) {
  const { createModuleSetData, history } = payload;
  const fetchModuleSetCreateResponse = yield call(
    fetchCreateModuleSetRequest,
    createModuleSetData
  );
  if (fetchModuleSetCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_MODULE_SET_ACTION,
      data: fetchModuleSetCreateResponse.data.module_set,
    });
    history.push("/dashboard/learning/index/module_sets");
    toast.success("Module Set Created Successfully");
  } else {
    const errorMessage =
      fetchModuleSetCreateResponse?.message?.length > 0
        ? fetchModuleSetCreateResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_MODULE_SET_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleSetShowRequestWatcher() {
  yield takeLatest(
    START_SHOW_MODULE_SET_ACTION,
    fetchModuleSetShowRequestWorker
  );
}

function* fetchModuleSetShowRequestWorker({ payload }) {
  const {
    params: { id },
  } = payload;

  const fetchModuleSetShowRequestResponse = yield call(
    fetchModuleSetShowRequest,
    id
  );
  if (fetchModuleSetShowRequestResponse.status) {
    yield put({
      type: SUCCESS_SHOW_MODULE_SET_ACTION,
      data: fetchModuleSetShowRequestResponse.data.module_set,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: FAILURE_SHOW_MODULE_SET_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleSetByCategoriesActionWatcher() {
  yield takeLatest(
    START_MODULE_SET_BY_CATEGORIES_ACTION,
    fetchModuleSetByCategoriesActionWorker
  );
}

function* fetchModuleSetByCategoriesActionWorker({ payload }) {
  const fetchModuleSetByCategoriesActionResponse = yield call(
    fetchModuleSetByCategoriesRequest,
    payload
  );
  if (fetchModuleSetByCategoriesActionResponse.status) {
    yield put({
      type: SUCCESS_MODULE_SET_BY_CATEGORIES_ACTION,
      data: fetchModuleSetByCategoriesActionResponse.data.module_categories,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_SET_BY_CATEGORIES_ACTION,
      errorMessage,
    });
  }
}

import { takeLatest, call, put } from "redux-saga/effects";
import {
  START_MODULE_SESSION_LIST_ACTION,
  ERROR_MODULE_SESSION_LIST_ACTION,
  SUCCESS_MODULE_SESSION_LIST_ACTION,
  START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
  SUCCESS_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
  ERROR_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
  START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
  SUCCESS_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
  ERROR_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
  START_MODULE_SESSION_COMPLETE_ACTION,
  ERROR_MODULE_SESSION_COMPLETE_ACTION,
  SUCCESS_MODULE_SESSION_COMPLETE_ACTION,
  START_DELETE_MODULE_SESSION_ACTION,
  SUCCESS_DELETE_MODULE_SESSION_ACTION,
  FAILURE_DELETE_MODULE_SESSION_ACTION,
  START_CREATE_MODULE_SESSION_ACTION,
  SUCCESS_CREATE_MODULE_SESSION_ACTION,
  FAILURE_CREATE_MODULE_SESSION_ACTION,
} from "../actions/module-sessions";

import {
  fetchModuleSessionsByClassroomByModuleSetRequest,
  fetchModuleSessionsByIdWithClassroomRequest,
  fetchModuleSessionsListAction,
  fetchModuleSessionCompleteRequest,
  deleteModuleSessionRequest,
  fetchCreateModuleSessionRequest,
} from "../apis/module-sessions";
import { toast } from "react-toastify";

export function* fetchModuleSessionListActionWatcher() {
  yield takeLatest(
    START_MODULE_SESSION_LIST_ACTION,
    fetchModuleSessionListActionWorker
  );
}

function* fetchModuleSessionListActionWorker({ payload }) {
  const fetchModuleSessionListActionResponse = yield call(
    fetchModuleSessionsListAction,
    payload
  );
  if (fetchModuleSessionListActionResponse.status) {
    yield put({
      type: SUCCESS_MODULE_SESSION_LIST_ACTION,
      data: fetchModuleSessionListActionResponse.data.module_sessions,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_SESSION_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleSessionByClassroomByModuleSetWatcher() {
  yield takeLatest(
    START_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
    fetchModuleSessionByClassroomByModuleSetWorker
  );
}

function* fetchModuleSessionByClassroomByModuleSetWorker({ payload }) {
  const fetchModuleSessionByClassroomByModuleSetResponse = yield call(
    fetchModuleSessionsByClassroomByModuleSetRequest,
    payload
  );
  if (fetchModuleSessionByClassroomByModuleSetResponse.status) {
    yield put({
      type: SUCCESS_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
      data: fetchModuleSessionByClassroomByModuleSetResponse.data
        .module_sessions,
    });
    yield put({
      type: SUCCESS_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
      data: {},
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_SESSION_BY_CLASSROOM_BY_MODULE_SET_ACTION,
      errorMessage,
    });
  }
}

export function* fetchModuleSessionByIdWithClassroomIdWatcher() {
  yield takeLatest(
    START_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
    fetchModuleSessionByIdWithClassroomIdWorker
  );
}

function* fetchModuleSessionByIdWithClassroomIdWorker({ payload }) {
  const fetchModuleSessionByIdWithClassroomIdResponse = yield call(
    fetchModuleSessionsByIdWithClassroomRequest,
    payload
  );
  if (fetchModuleSessionByIdWithClassroomIdResponse.status) {
    yield put({
      type: SUCCESS_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
      data: fetchModuleSessionByIdWithClassroomIdResponse.data.results,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_SESSION_BY_ID_WITH_CLASSROOM_ID_ACTION,
      errorMessage,
    });
  }
}
export function* fetchModuleSessionCompleteRequestWatcher() {
  yield takeLatest(
    START_MODULE_SESSION_COMPLETE_ACTION,
    fetchModuleSessionCompleteWorker
  );
}

function* fetchModuleSessionCompleteWorker({ payload }) {
  const { completeModuleSessionData, setIsOpenConfirmSessionModal } = payload;
  const fetchModuleSessionCompleteResponse = yield call(
    fetchModuleSessionCompleteRequest,
    completeModuleSessionData
  );
  if (fetchModuleSessionCompleteResponse.status) {
    yield put({
      type: SUCCESS_MODULE_SESSION_COMPLETE_ACTION,
      data: fetchModuleSessionCompleteResponse.data.module_session,
    });
    setIsOpenConfirmSessionModal(false);
    yield call(fetchModuleSessionListActionWorker, {
      payload: { classroom_id: completeModuleSessionData?.module_session?.id },
    });
    toast.success("Module Session marked as completed");
  } else {
    const errorMessage = fetchModuleSessionCompleteResponse?.message
      ? fetchModuleSessionCompleteResponse?.message[0]
      : "Something Went Wrong";
    yield put({
      type: ERROR_MODULE_SESSION_COMPLETE_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleSessionDeleteRequestWatcher() {
  yield takeLatest(
    START_DELETE_MODULE_SESSION_ACTION,
    fetchModuleSessionDeleteRequestWorker
  );
}

function* fetchModuleSessionDeleteRequestWorker({ payload }) {
  const { deleteModuleSessionData, setIsOpenConfirmModal } = payload;
  const deleteModuleSessionRequestResponse = yield call(
    deleteModuleSessionRequest,
    deleteModuleSessionData
  );
  if (deleteModuleSessionRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_MODULE_SESSION_ACTION,
      data: deleteModuleSessionRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchModuleSessionListActionWorker, {
      payload: { classroom_id: deleteModuleSessionData?.module_session?.id },
    });
    toast.success("Module Session Deleted Successfully");
  } else {
    const errorMessage =
      deleteModuleSessionRequestResponse?.message?.length > 0
        ? deleteModuleSessionRequestResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_MODULE_SESSION_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchModuleSessionCreateRequestWatcher() {
  yield takeLatest(
    START_CREATE_MODULE_SESSION_ACTION,
    fetchModuleSessionCreateWorker
  );
}

function* fetchModuleSessionCreateWorker({ payload }) {
  const { createModuleSessionData } = payload;
  const fetchModuleSessionCreateResponse = yield call(
    fetchCreateModuleSessionRequest,
    createModuleSessionData
  );
  if (fetchModuleSessionCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_MODULE_SESSION_ACTION,
      data: fetchModuleSessionCreateResponse.data.module_session,
    });
    yield call(fetchModuleSessionListActionWorker, {
      payload: {
        classroom_id: createModuleSessionData?.module_session?.id,
      },
    });
    toast.success("Modules Assigned Successfully");
  } else {
    const errorMessage =
      fetchModuleSessionCreateResponse?.message?.length > 0
        ? fetchModuleSessionCreateResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_MODULE_SESSION_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

import React from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import Icon from "../Button/Icon";
import LinkedButton from "../Button/LinkedButton";
import "../../styles/responsive-modal.scss";

function ConfirmModal({
  isOpenConfirmModal,
  setIsOpenConfirmModal,
  deleteRecordHandler,
}) {
  const customClass = "custom-modal-class"; //import class from "responsive-modal.scss file"
  const onCloseModal = React.useCallback(() => {
    setIsOpenConfirmModal(false);
  }, []);

  const closeIcon = (
    <div className="w-full flex justify-between">
      <Icon label="close" title="" />
    </div>
  );
  return (
    <Modal
      center
      classNames={{
        modal: customClass,
      }}
      open={isOpenConfirmModal}
      onClose={onCloseModal}
      closeIcon={closeIcon}
      focusTrapped={false}
    >
      <div className="flex flex-col">
        <div className="bg-theme-blue">
          <div className="font-lato flex flex-start text-white-87 text-xl font-normal w-1/3">
            <div className="p-4">Confirm</div>
          </div>
        </div>
        <div className="px-4 py-2 bg-white">
          <div className="mt-8 text-black-87">
            Are you sure you want to delete this?
          </div>
          <div className="flex justify-end mt-8">
            <LinkedButton
              label="Cancel"
              className="mx-4"
              onClickHandler={onCloseModal}
            />
            <LinkedButton label="Delete" onClickHandler={deleteRecordHandler} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmModal;

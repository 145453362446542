import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useDispatch } from "react-redux";
import { START_UPDATE_TEACHERS_ACTION } from "../../../rest-client/actions/teachers";
import { START_SCHOOLS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/schools";
import { useSchools } from "../../../rest-client/selectorHook";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";

export default function EditTeacherForm({
  editableObjectData,
  setIsOpenEditFormModal,
  currentPage,
}) {
  const teacherData = editableObjectData;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: START_SCHOOLS_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  const {
    schools_non_paginated_list_data: {
      data: schoolListData,
    },
  } = useSchools();

  const initialFormValues = {
    first_name_teacher: teacherData?.first_name ? teacherData.first_name : "",
    last_name_teacher: teacherData?.last_name ? teacherData.last_name : "",
    email_teacher: teacherData?.email ? teacherData.email : "",
    school_teacher: teacherData?.school_id
      ? {
          label: teacherData?.school?.name,
          value: teacherData?.school_id,
        }
      : "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.first_name_teacher) {
      error.first_name_teacher = "Please enter First Name";
    }
    if (!values.last_name_teacher) {
      error.last_name_teacher = "Please enter Last Name";
    }
    if (!values.email_teacher) {
      error.email_teacher = "Please enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email_teacher)
    ) {
      error.email_teacher = "Invalid email address";
    }
    if (!values.school_teacher) {
      error.school_teacher = "Please Select School";
    }
    return error;
  }, []);

  const schoolOptions = schoolListData.map((school, index) => {
    return {
      label: school.name,
      value: school.id,
    };
  });

  const updateTeacherHandler = React.useCallback(
    (values, { resetForm }) => {
      const updateTeacherData = {
        teacher: {
          ...teacherData,
          first_name: values.first_name_teacher.trimStart().trimEnd(),
          last_name: values.last_name_teacher.trimStart().trimEnd(),
          email: values.email_teacher.trimStart().trimEnd(),
          school_id: values.school_teacher?.value,
        },
      };
      dispatch({
        type: START_UPDATE_TEACHERS_ACTION,
        payload: { updateTeacherData, setIsOpenEditFormModal, currentPage },
      });
      resetForm();
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={updateTeacherHandler}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form className="font-lato">
          <div className="mt-16">
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 w-30">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="first_name_teacher"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.first_name_teacher && touched.first_name_teacher,
                    })}
                    value={values.first_name_teacher}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "first_name_teacher",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="first_name_teacher"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.first_name_teacher && touched.first_name_teacher,
                    })}
                  >
                    First Name
                  </label>
                  {errors.first_name_teacher && touched.first_name_teacher && (
                    <p className="error-label-class mt-25">
                      {errors.first_name_teacher ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-full md:w-70 md:ml-10">
                <div className="relative p-0.5 my-2 mx-0 align-middle !mb-1">
                  <Field
                    type="text"
                    id="last_name_teacher"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.last_name_teacher && touched.last_name_teacher,
                    })}
                    value={values.last_name_teacher}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "last_name_teacher",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="last_name_teacher"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.last_name_teacher && touched.last_name_teacher,
                    })}
                  >
                    Last Name
                  </label>
                  {errors.last_name_teacher && touched.last_name_teacher && (
                    <p className="error-label-class mt-25">
                      {errors.last_name_teacher ?? "-"}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 w-30">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="email_teacher"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.email_teacher && touched.email_teacher,
                    })}
                    value={values.email_teacher}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "email_teacher",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="email_teacher"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.email_teacher && touched.email_teacher,
                    })}
                  >
                    Email
                  </label>
                  {errors.email_teacher && touched.email_teacher && (
                    <p className="error-label-class mt-25">
                      {errors.email_teacher ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-full md:w-70 md:ml-10">
                <div className="edit-dropdown-modal">
                  <Field name="school_teacher">
                    {({ field, form, meta }) => (
                      <SingleSelect
                        field={field}
                        form={form}
                        meta={meta}
                        options={schoolOptions}
                        placeholder="School"
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end ml-10">
            <DefaultButton label="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

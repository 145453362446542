import {
  START_SCHOOLS_LIST_ACTION,
  SUCCESS_SCHOOLS_LIST_ACTION,
  ERROR_SCHOOLS_LIST_ACTION,
  START_CREATE_SCHOOL_ACTION,
  SUCCESS_CREATE_SCHOOL_ACTION,
  FAILURE_CREATE_SCHOOL_ACTION,
  START_UPDATE_SCHOOLS_ACTION,
  SUCCESS_UPDATE_SCHOOLS_ACTION,
  FAILURE_UPDATE_SCHOOLS_ACTION,
  START_DELETE_SCHOOLS_ACTION,
  SUCCESS_DELETE_SCHOOLS_ACTION,
  FAILURE_DELETE_SCHOOLS_ACTION,
  START_SCHOOLS_NON_PAGINATED_LIST_ACTION,
  ERROR_SCHOOLS_NON_PAGINATED_LIST_ACTION,
  SUCCESS_SCHOOLS_NON_PAGINATED_LIST_ACTION,
} from "../actions/schools";

const initState = {
  schools_list_data: {
    isLoading: false,
    isError: false,
    data: [],
    meta: {},
  },
  schools_non_paginated_list_data: {
    isLoading: false,
    isError: false,
    data: [],
  },
  school_create: {
    isLoading: false,
    isError: false,
    data: null,
  },
  school_update: {
    isLoading: false,
    isError: false,
    data: {},
  },
  school_delete: {
    isLoading: false,
    isError: false,
    data: {},
  },
};

export const schoolsReducer = (prevState = initState, action) => {
  switch (action.type) {
    case START_SCHOOLS_LIST_ACTION:
      return {
        ...prevState,
        schools_list_data: {
          isLoading: true,
          isError: false,
          data: [],
          meta: {},
        },
      };
    case SUCCESS_SCHOOLS_LIST_ACTION:
      return {
        ...prevState,
        schools_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
          meta: action.meta,
        },
      };
    case ERROR_SCHOOLS_LIST_ACTION:
      return {
        ...prevState,
        schools_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
          meta: {},
        },
      };
    case START_CREATE_SCHOOL_ACTION: {
      return {
        ...prevState,
        school_create: {
          isLoading: true,
          isError: false,
          data: null,
        },
      };
    }

    case SUCCESS_CREATE_SCHOOL_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        school_create: {
          isLoading: false,
          isError: false,
          data,
        },
      };
    }

    case FAILURE_CREATE_SCHOOL_ACTION: {
      return {
        ...prevState,
        school_create: {
          isLoading: false,
          isError: action.errorMessage,
          data: null,
        },
      };
    }
    case START_UPDATE_SCHOOLS_ACTION:
      return {
        ...prevState,
        schools_update: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };

    case SUCCESS_UPDATE_SCHOOLS_ACTION:
      return {
        ...prevState,
        schools_update: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };

    case FAILURE_UPDATE_SCHOOLS_ACTION:
      return {
        ...prevState,
        schools_update: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };

    case START_DELETE_SCHOOLS_ACTION: {
      return {
        ...prevState,
        schools_delete: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    }

    case SUCCESS_DELETE_SCHOOLS_ACTION: {
      const { data } = action;
      return {
        ...prevState,
        schools_delete: {
          isLoading: false,
          isError: false,
          data: data,
        },
      };
    }

    case FAILURE_DELETE_SCHOOLS_ACTION: {
      return {
        ...prevState,
        schools_delete: {
          isLoading: false,
          isError: action.errorMessage,
          data: {},
        },
      };
    }
    case START_SCHOOLS_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        schools_non_paginated_list_data: {
          isLoading: true,
          isError: false,
          data: [],
        },
      };
    case SUCCESS_SCHOOLS_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        schools_non_paginated_list_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case ERROR_SCHOOLS_NON_PAGINATED_LIST_ACTION:
      return {
        ...prevState,
        schools_non_paginated_list_data: {
          isLoading: false,
          isError: action.errorMessage,
          data: [],
        },
      };
    default:
      return prevState;
  }
};

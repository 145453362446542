import {
  VIEW_MODULE_FAILURE,
  VIEW_MODULE_REQUESTED,
  VIEW_MODULE_SUCCESS,
} from "../actions/view-module";

const initState = {
  view_module_data: {
    isLoading: false,
    isError: false,
    data: {},
  },
};

export const viewModuleReducer = (prevState = initState, action) => {
  switch (action.type) {
    case VIEW_MODULE_REQUESTED:
      return {
        ...prevState,
        view_module_data: {
          isLoading: true,
          isError: false,
          data: {},
        },
      };
    case VIEW_MODULE_SUCCESS:
      return {
        ...prevState,
        view_module_data: {
          isLoading: false,
          isError: false,
          data: action.data,
        },
      };
    case VIEW_MODULE_FAILURE:
      return {
        ...prevState,
        view_module_data: {
          isLoading: false,
          isError: true,
          data: action.errorMessage,
        },
      };
    default:
      return prevState;
  }
};

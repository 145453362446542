import { takeLatest, call, put } from "redux-saga/effects";
import {
  START_TEACHERS_LIST_ACTION,
  SUCCESS_TEACHERS_LIST_ACTION,
  ERROR_TEACHERS_LIST_ACTION,
  START_CREATE_TEACHER_ACTION,
  SUCCESS_CREATE_TEACHER_ACTION,
  FAILURE_CREATE_TEACHER_ACTION,
  START_UPDATE_TEACHERS_ACTION,
  SUCCESS_UPDATE_TEACHERS_ACTION,
  FAILURE_UPDATE_TEACHERS_ACTION,
  START_DELETE_TEACHERS_ACTION,
  SUCCESS_DELETE_TEACHERS_ACTION,
  FAILURE_DELETE_TEACHERS_ACTION,
  START_TEACHERS_NON_PAGINATED_LIST_ACTION,
  ERROR_TEACHERS_NON_PAGINATED_LIST_ACTION,
  SUCCESS_TEACHERS_NON_PAGINATED_LIST_ACTION,
} from "../actions/teachers";
import {
  fetchTeachersListAction,
  fetchCreateTeacherRequest,
  fetchTeacherUpdateAction,
  deleteTeacherRequest,
  fetchTeachersNonPaginatedListAction,
} from "../apis/teachers";
import { toast } from "react-toastify";

export function* fetchTeachersListActionWatcher() {
  yield takeLatest(START_TEACHERS_LIST_ACTION, fetchTeachersListActionWorker);
}

function* fetchTeachersListActionWorker({ payload }) {
  const fetchTeachersListActionResponse = yield call(
    fetchTeachersListAction,
    payload
  );
  if (fetchTeachersListActionResponse.status) {
    yield put({
      type: SUCCESS_TEACHERS_LIST_ACTION,
      data: fetchTeachersListActionResponse.data.teachers,
      meta: fetchTeachersListActionResponse.data.meta,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_TEACHERS_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchTeachersNonPaginatedListActionWatcher() {
  yield takeLatest(
    START_TEACHERS_NON_PAGINATED_LIST_ACTION,
    fetchTeachersNonPaginatedListActionWorker
  );
}

function* fetchTeachersNonPaginatedListActionWorker() {
  const fetchTeachersListActionResponse = yield call(
    fetchTeachersNonPaginatedListAction
  );
  if (fetchTeachersListActionResponse.status) {
    yield put({
      type: SUCCESS_TEACHERS_NON_PAGINATED_LIST_ACTION,
      data: fetchTeachersListActionResponse.data.teachers,
    });
  } else {
    const errorMessage = "Something Went Wrong";
    yield put({
      type: ERROR_TEACHERS_NON_PAGINATED_LIST_ACTION,
      errorMessage,
    });
  }
}

export function* fetchTeacherCreateRequestWatcher() {
  yield takeLatest(START_CREATE_TEACHER_ACTION, fetchTeacherCreateWorker);
}

function* fetchTeacherCreateWorker({ payload }) {
  const { createTeacherData } = payload;
  const fetchTeacherCreateResponse = yield call(
    fetchCreateTeacherRequest,
    createTeacherData
  );
  if (fetchTeacherCreateResponse.status) {
    yield put({
      type: SUCCESS_CREATE_TEACHER_ACTION,
      data: fetchTeacherCreateResponse.data.teacher,
    });
    yield call(fetchTeachersListActionWorker, { payload: null });
    toast.success("Teacher Created Successfully");
  } else {
    const errorMessage =
      fetchTeacherCreateResponse?.message?.length > 0
        ? fetchTeacherCreateResponse.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_CREATE_TEACHER_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchTeacherUpdateActionWatcher() {
  yield takeLatest(
    START_UPDATE_TEACHERS_ACTION,
    fetchTeacherUpdateActionWorker
  );
}

function* fetchTeacherUpdateActionWorker({ payload }) {
  const { updateTeacherData, currentPage, setIsOpenEditFormModal } = payload;
  const updateTeacherRequestResponse = yield call(
    fetchTeacherUpdateAction,
    updateTeacherData
  );
  if (updateTeacherRequestResponse.status) {
    yield put({
      type: SUCCESS_UPDATE_TEACHERS_ACTION,
      data: updateTeacherRequestResponse.data,
    });
    setIsOpenEditFormModal(false);
    yield call(fetchTeachersListActionWorker, {
      payload: { page: currentPage },
    });
    toast.success("Teacher Updated Successfully");
  } else {
    const errorMessage =
      updateTeacherRequestResponse?.data?.message?.length > 0
        ? updateTeacherRequestResponse.data.message[0]
        : "Something Went Wrong";
    yield put({
      type: FAILURE_UPDATE_TEACHERS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* fetchTeacherDeleteRequestWatcher() {
  yield takeLatest(
    START_DELETE_TEACHERS_ACTION,
    fetchTeacherDeleteRequestWorker
  );
}

function* fetchTeacherDeleteRequestWorker({ payload }) {
  const { deleteTeacherRequestData, setIsOpenConfirmModal } = payload;
  const deleteTeacherRequestResponse = yield call(
    deleteTeacherRequest,
    deleteTeacherRequestData
  );
  if (deleteTeacherRequestResponse.status) {
    yield put({
      type: SUCCESS_DELETE_TEACHERS_ACTION,
      data: deleteTeacherRequestResponse.data,
    });
    setIsOpenConfirmModal(false);
    yield call(fetchTeachersListActionWorker, { payload: null });
    toast.success("Teacher Deleted Successfully");
  } else {
    const errorMessage =
      deleteTeacherRequestResponse?.message?.length > 0
        ? deleteTeacherRequestResponse.message
        : "Something Went Wrong";
    yield put({
      type: FAILURE_DELETE_TEACHERS_ACTION,
      errorMessage,
    });
    toast.error(errorMessage);
  }
}

import React from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_CREATE_SCHOOL_ACTION } from "../../../rest-client/actions/schools";

export default function AddSchoolForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialFormValues = {
    name: "",
    address: {
      street1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    },
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.name) {
      error.name = "Please enter School Name";
    }
    if (!values.address.city) {
      error.address = {
        ...error.address,
        city: "Please Enter City",
      };
    }
    if (!values.address.state) {
      error.address = {
        ...error.address,
        state: "Please Enter State",
      };
    }
    if (!values.address.zip) {
      error.address = {
        ...error.address,
        zip: "Please Enter Zip",
      };
    }
    if (!values.address.country) {
      error.address = {
        ...error.address,
        country: "Please Enter Country",
      };
    }
    if (!values.address.street1) {
      error.address = {
        ...error.address,
        street1: "Please Enter Address",
      };
    }
    return error;
  }, []);

  const createSchoolHandler = React.useCallback(
    (values, { resetForm }) => {
      const createSchoolData = {
        school: {
          name: values.name.trimStart().trimEnd(),
          address: {
            street1: values.address.street1.trimStart().trimEnd(),
            city: values.address.city.trimStart().trimEnd(),
            state: values.address.state.trimStart().trimEnd(),
            zip: values.address.zip.trimStart().trimEnd(),
            country: values.address.country.trimStart().trimEnd(),
          },
        },
      };
      dispatch({
        type: START_CREATE_SCHOOL_ACTION,
        payload: { createSchoolData, history },
      });
      resetForm();
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={createSchoolHandler}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form className="font-lato">
          <div className="flex flex-col md:flex-row box-border">
            <div className="flex-1 w-30">
              <div className="field-holder">
                <Field
                  type="text"
                  id="name"
                  className={classNames("input-class", {
                    "error-input-class": errors.name && touched.name,
                  })}
                  value={values.name}
                  onChange={(e) => {
                    setFieldDataHandler(setFieldValue, "name", e.target.value);
                  }}
                  maxLength={70}
                  tabIndex="1"
                  required
                />
                <label
                  htmlFor="name"
                  className={classNames("label-class", {
                    "error-label-class": errors.name && touched.name,
                  })}
                >
                  Name *
                </label>
                {errors.name && touched.name && (
                  <p className="error-label-class mt-25">
                    {errors.name ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="text"
                  id="city"
                  className={classNames("input-class", {
                    "error-input-class": errors.address && touched?.city,
                  })}
                  value={values.address.city}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      `address.city`,
                      e.target.value
                    );
                  }}
                  maxLength={20}
                  tabIndex="3"
                  required
                />
                <label
                  htmlFor="city"
                  className={classNames("label-class", {
                    "error-label-class": errors.address && touched?.city,
                  })}
                >
                  City *
                </label>
                {errors?.address?.city && touched?.city && (
                  <p className="error-label-class mt-25">
                    {errors?.address?.city ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="text"
                  id="zip"
                  className={classNames("input-class", {
                    "error-input-class": errors.address && touched?.zip,
                  })}
                  value={values.address.zip}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      `address.zip`,
                      e.target.value
                    );
                  }}
                  maxLength={8}
                  tabIndex="5"
                  required
                />
                <label
                  htmlFor="zip"
                  className={classNames("label-class", {
                    "error-label-class": errors.address && touched?.zip,
                  })}
                >
                  Zip Code *
                </label>
                {errors?.address?.zip && touched?.zip && (
                  <p className="error-label-class mt-25">
                    {errors?.address?.zip ?? "-"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex-1 w-full md:w-70 md:mx-10">
              <div className="field-holder">
                <Field
                  type="text"
                  id="street1"
                  className={classNames("input-class", {
                    "error-input-class": errors.address && touched?.street1,
                  })}
                  value={values.address.street1}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      `address.street1`,
                      e.target.value
                    );
                  }}
                  maxLength={50}
                  tabIndex="2"
                  required
                />
                <label
                  htmlFor="street1"
                  className={classNames("label-class", {
                    "error-label-class": errors.address && touched?.street1,
                  })}
                >
                  Address *
                </label>
                {errors?.address?.street1 && touched?.street1 && (
                  <p className="error-label-class mt-25">
                    {errors?.address?.street1 ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="text"
                  id="state"
                  className={classNames("input-class", {
                    "error-input-class": errors.address && touched?.state,
                  })}
                  value={values.address.state}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      `address.state`,
                      e.target.value
                    );
                  }}
                  maxLength={20}
                  tabIndex="4"
                  required
                />
                <label
                  htmlFor="state"
                  className={classNames("label-class", {
                    "error-label-class": errors.address && touched?.state,
                  })}
                >
                  State *
                </label>
                {errors?.address?.state && touched?.state && (
                  <p className="error-label-class mt-25">
                    {errors?.address?.state ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="text"
                  id="country"
                  className={classNames("input-class", {
                    "error-input-class": errors.address && touched?.country,
                  })}
                  value={values.address.country}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      `address.country`,
                      e.target.value
                    );
                  }}
                  maxLength={20}
                  tabIndex="6"
                  required
                />
                <label
                  htmlFor="country"
                  className={classNames("label-class", {
                    "error-label-class": errors.address && touched?.country,
                  })}
                >
                  Country *
                </label>
                {errors?.address?.country && touched?.country && (
                  <p className="error-label-class mt-25">
                    {errors?.address?.country ?? "-"}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5	md:justify-end md:mx-10">
            <DefaultButton label="Create" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

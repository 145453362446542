import React from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import "../../../styles/landingPage.scss";
import "../../../styles/module_sets.scss";
import classNames from "classnames";
import Icon from "../../../components/Button/Icon";
import {
  START_SHOW_MODULE_SET_ACTION,
  START_UPDATE_MODULE_SETS_ACTION,
} from "../../../rest-client/actions/module-sets";
import { useDispatch } from "react-redux";
import { useModuleSet } from "../../../rest-client/selectorHook";
import DefaultButton from "../../../components/Button/DefaultButton";
import { ClipLoader } from "react-spinners";
import Layout from "../../../components/Layout";

export default function EditSetForm() {
  const { url } = useRouteMatch(`/dashboard/learning`);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const isMainRecordUpdate = true;

  const backClickHandler = React.useCallback(() => {
    history.push(`${url}/index/module_sets`);
  }, [history, url]);

  React.useEffect(() => {
    dispatch({
      type: START_SHOW_MODULE_SET_ACTION,
      payload: { params },
    });
  }, [dispatch]);

  const {
    module_set_show_data: {
      isLoading: moduleSetShowLoading,
      data: moduleSetShowData,
    },
  } = useModuleSet();

  // To set Intial Values for module categories based on field type and is_main_query data
  const createInitialCategoryFieldValue = () => {
    const initialCategories = [];
    moduleSetShowData?.module_categories?.map((category) => {
      if (category.is_main_query && category.field_type === "integer") {
        initialCategories.push({
          ...category,
          name: category.name,
          tag_to_all_groups: true,
          tag_numeric_value: true,
        });
      } else if (category.is_main_query && category.field_type === "text") {
        initialCategories.push({
          ...category,
          name: category.name,
          tag_to_all_groups: true,
          tag_numeric_value: false,
        });
      } else if (!category.is_main_query && category.field_type === "text") {
        initialCategories.push({
          ...category,
          name: category.name,
          tag_to_all_groups: false,
          tag_numeric_value: false,
        });
      } else if (!category.is_main_query && category.field_type === "integer") {
        initialCategories.push({
          ...category,
          name: category.name,
          tag_to_all_groups: false,
          tag_numeric_value: true,
        });
      } else {
        initialCategories.push({
          name: "",
          tag_to_all_groups: false,
          tag_numeric_value: false,
        });
      }
    });
    return initialCategories;
  };

  const initialFormValues = {
    title: moduleSetShowData ? moduleSetShowData.title : "",
    nickname: moduleSetShowData ? moduleSetShowData.nickname : "",
    module_categories: createInitialCategoryFieldValue(),
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter Title";
    }
    if (!values.nickname) {
      error.nickname = "Please enter Nickname";
    }
    values.module_categories.forEach((module_category, index) => {
      if (!module_category.name) {
        error[`module_categories.${index}.name`] = "Please enter Category Name";
      }
    });
    return error;
  }, []);

  //To set field values of edited categories
  const createCategoryFieldValue = (module_category) => {
    if (
      module_category.tag_to_all_groups === true &&
      module_category.tag_numeric_value === true
    ) {
      return "integer";
    } else if (
      module_category.tag_to_all_groups === false &&
      module_category.tag_numeric_value === false
    ) {
      return "text";
    } else if (
      module_category.tag_to_all_groups === true &&
      module_category.tag_numeric_value === false
    ) {
      return "text";
    } else if (
      module_category.tag_to_all_groups === false &&
      module_category.tag_numeric_value === true
    ) {
      return "integer";
    } else {
      return "text";
    }
  };

  const updateModuleSetHandler = React.useCallback(
    (values) => {
      const module_set_categories = [];
      values.module_categories.map((module_category) => {
        if (module_category.id) {
          module_set_categories.push({
            ...module_category,
            field_type: createCategoryFieldValue(module_category),
            is_main_query:
              module_category.tag_to_all_groups === true ? true : false,
          });
        } else {
          module_set_categories.push({
            name: module_category.name.trimStart().trimEnd(),
            field_type: createCategoryFieldValue(module_category),
            is_main_query:
              module_category.tag_to_all_groups === true ? true : false,
          });
        }
      });

      const updateModuleSetRequestData = {
        module_set: {
          ...moduleSetShowData,
          title: values.title.trimStart().trimEnd(),
          nickname: values.nickname.trimStart().trimEnd(),
          module_categories: module_set_categories,
        },
      };
      dispatch({
        type: START_UPDATE_MODULE_SETS_ACTION,
        payload: { updateModuleSetRequestData, history, isMainRecordUpdate },
      });
    },
    [dispatch, moduleSetShowData]
  );

  const isModuleDocumentsAssigned =
    moduleSetShowData?.module_documents?.length > 0;

  return moduleSetShowLoading ? (
    <div className="flex flex-col mt-8 justify-center items-center h-32">
      <ClipLoader size={32} color={"#3e8ede"} />
      <p className="mt-2 font-bold font-muli text-blue-theme">
        Loading Module Set...
      </p>
    </div>
  ) : (
    <Layout>
      <div className="flex py-3 text-base font-lato px-6">
        <button
          className="border-none bg-transparent"
          onClick={backClickHandler}
        >
          <Icon
            label="arrow_back_ios"
            title=""
            className={classNames("text-light-gray")}
          />
        </button>
        <span
          className="text-slate-600 cursor-pointer"
          onClick={backClickHandler}
        >
          Back to Module Sets
        </span>
      </div>
      <div className="edit-profile-text"></div>
      <h2 className="form_title ml-10 mt-10">Module Set Information</h2>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={updateModuleSetHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato mx-10 mt-10">
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 w-30">
                <div className="field-holder">
                  <Field
                    type="text"
                    id="title"
                    className={classNames("input-class", {
                      "error-input-class": errors.title && touched.title,
                    })}
                    value={values.title}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "title",
                        e.target.value.trimStart()
                      );
                    }}
                    tabIndex="1"
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="title"
                    className={classNames("label-class", {
                      "error-label-class": errors.title && touched.title,
                    })}
                  >
                    Title *
                  </label>
                  {errors.title && touched.title && (
                    <p className="error-label-class mt-25">
                      {errors.title ?? "-"}
                    </p>
                  )}
                </div>
                <div className="field-holder">
                  <Field
                    type="text"
                    id="nickname"
                    className={classNames("input-class", {
                      "error-input-class": errors.nickname && touched.nickname,
                    })}
                    required
                    value={values.nickname}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "nickname",
                        e.target.value.trimStart()
                      );
                    }}
                    tabIndex="3"
                    maxLength={50}
                  />
                  <label
                    htmlFor="nickname"
                    className={classNames("label-class", {
                      "error-label-class": errors.nickname && touched.nickname,
                    })}
                  >
                    Nickname *
                  </label>
                  {errors.nickname && touched.nickname && (
                    <p className="error-label-class mt-25">
                      {errors.nickname ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-full md:w-70 md:mx-10">
                <FieldArray
                  name="module_categories"
                  render={(arrayHelpers) => {
                    const module_categories = values.module_categories;
                    return (
                      <>
                        {module_categories.map((module_category, index) => (
                          <div key={index}>
                            <div className="field-holder mt-5">
                              <Field
                                type="text"
                                name={`module_categories.${index}.name`}
                                className={classNames("input-class", {
                                  "error-input-class":
                                    errors[`module_categories.${index}.name`],
                                })}
                                maxLength={20}
                                tabIndex="2"
                                disabled={isModuleDocumentsAssigned}
                                required
                                value={values.module_categories[index].name}
                                onChange={(e) =>
                                  setFieldValue(
                                    `module_categories.${index}.name`,
                                    e.target.value.trimStart()
                                  )
                                }
                              />
                              <label
                                htmlFor="name"
                                className={classNames("label-class", {
                                  "error-label-class":
                                    errors[`module_categories.${index}.name`],
                                })}
                                disabled={isModuleDocumentsAssigned}
                              >
                                {isModuleDocumentsAssigned
                                  ? ""
                                  : "Enter Keyword Category Name *"}
                              </label>
                              {errors[`module_categories.${index}.name`] && (
                                <p className="error-label-class mt-25">
                                  {errors[`module_categories.${index}.name`] ??
                                    "-"}
                                </p>
                              )}
                            </div>
                            <div className="field-holder flex">
                              <label
                                className={`container-check text-sm justify-items-center ${
                                  isModuleDocumentsAssigned
                                    ? "!cursor-default"
                                    : ""
                                }`}
                                htmlFor={`check.${index}`}
                              >
                                <span>
                                  Apply tag to all groups in classroom
                                </span>
                                <input
                                  type="checkbox"
                                  name={`module_categories.${index}.tag_to_all_groups`}
                                  id={`check.${index}`}
                                  checked={
                                    values.module_categories[index]
                                      .tag_to_all_groups
                                  }
                                  tabIndex="4"
                                  onChange={() =>
                                    setFieldValue(
                                      `module_categories.${index}.tag_to_all_groups`,
                                      !values.module_categories[index]
                                        .tag_to_all_groups
                                    )
                                  }
                                  disabled={isModuleDocumentsAssigned}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <label
                                className={`container-check text-sm mx-4 ${
                                  isModuleDocumentsAssigned
                                    ? "!cursor-default"
                                    : ""
                                }`}
                                htmlFor={`check1.${index}`}
                              >
                                <span>Numerical Value</span>
                                <input
                                  type="checkbox"
                                  id={`check1.${index}`}
                                  name={`module_categories.${index}.tag_to_all_groups`}
                                  checked={
                                    values.module_categories[index]
                                      .tag_numeric_value
                                  }
                                  tabIndex="5"
                                  onChange={() =>
                                    setFieldValue(
                                      `module_categories.${index}.tag_numeric_value`,
                                      !values.module_categories[index]
                                        .tag_numeric_value
                                    )
                                  }
                                  disabled={isModuleDocumentsAssigned}
                                />
                                <span className="checkmark"></span>
                              </label>
                              {index > 0 && (
                                <button
                                  className={`material-symbols-outlined cursor-pointer ease-in-out border-none bg-transparent text-light-gray text-2xl ${
                                    isModuleDocumentsAssigned
                                      ? "cursor-default"
                                      : ""
                                  }`}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Delete"
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={isModuleDocumentsAssigned}
                                >
                                  delete_forever
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    );
                  }}
                />
                <button
                  className={`ml-0 submit-button cursor-pointer opacity-100 ${
                    isModuleDocumentsAssigned ? "!cursor-default" : ""
                  }`}
                  onClick={() => {
                    setFieldValue("module_categories", [
                      ...values.module_categories,
                      {
                        name: "",
                        tag_to_all_groups: false,
                        tag_numeric_value: false,
                      },
                    ]);
                  }}
                  disabled={isModuleDocumentsAssigned}
                >
                  Add Category
                </button>
                {isModuleDocumentsAssigned ? (
                  <p className="text-red-500">
                    You cannot edit categories while Modules are associated.
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex justify-center	md:justify-end md:mx-10 mt-10">
              <DefaultButton label="Update Module Set" />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}

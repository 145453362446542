export const VIEW_RESOURCE_NODE_REQUESTED = "VIEW_RESOURCE_NODE_REQUESTED";
export const VIEW_RESOURCE_NODE_SUCCESS = "VIEW_RESOURCE_NODE_SUCCESS";
export const VIEW_RESOURCE_NODE_FAILURE = "VIEW_RESOURCE_NODE_FAILURE";

export const SUBMIT_PAGE_RESOURCE_NODE_REQUESTED =
  "SUBMIT_PAGE_RESOURCE_NODE_REQUESTED";
export const SUBMIT_PAGE_RESOURCE_NODE_SUCCESS =
  "SUBMIT_PAGE_RESOURCE_NODE_SUCCESS";
export const SUBMIT_PAGE_RESOURCE_NODE_FAILURE =
  "SUBMIT_PAGE_RESOURCE_NODE_FAILURE";

export const SUBMIT_CANVAS_RESOURCE_NODE_REQUESTED =
  "SUBMIT_CANVAS_RESOURCE_NODE_REQUESTED";
export const SUBMIT_CANVAS_RESOURCE_NODE_SUCCESS =
  "SUBMIT_CANVAS_RESOURCE_NODE_SUCCESS";
export const SUBMIT_CANVAS_RESOURCE_NODE_FAILURE =
  "SUBMIT_CANVAS_RESOURCE_NODE_FAILURE";

export const SUBMIT_POLL_RESOURCE_NODE_REQUESTED =
  "SUBMIT_POLL_RESOURCE_NODE_REQUESTED";
export const SUBMIT_POLL_RESOURCE_NODE_SUCCESS =
  "SUBMIT_POLL_RESOURCE_NODE_SUCCESS";
export const SUBMIT_POLL_RESOURCE_NODE_FAILURE =
  "SUBMIT_POLL_RESOURCE_NODE_FAILURE";

export const START_ADD_CANVAS_IMAGE_UPLOAD = "START_ADD_CANVAS_IMAGE_UPLOAD";
export const SUCCESS_ADD_CANVAS_IMAGE_UPLOAD =
  "SUCCESS_ADD_CANVAS_IMAGE_UPLOAD";
export const FAILURE_ADD_CANVAS_IMAGE_UPLOAD =
  "FAILURE_ADD_CANVAS_IMAGE_UPLOAD";

export const START_POLL_QUESTIONS_ACTION = "START_POLL_QUESTIONS_ACTION";
export const SUCCESS_POLL_QUESTIONS_ACTION = "SUCCESS_POLL_QUESTIONS_ACTION";
export const FAILURE_POLL_QUESTIONS_ACTION = "FAILURE_POLL_QUESTIONS_ACTION";

export const START_DISCUSS_ENTRIES_ACTION = "START_DISCUSS_ENTRIES_ACTION";
export const SUCCESS_DISCUSS_ENTRIES_ACTION = "SUCCESS_DISCUSS_ENTRIES_ACTION";
export const FAILURE_DISCUSS_ENTRIES_ACTION = "FAILURE_DISCUSS_ENTRIES_ACTION";

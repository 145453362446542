import React from "react";
import SideNavBar from "./SideNavBar/SideNavBar";

// This is the main common layout which will be called in each child components which are being called after login
const Layout = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <div className="w-60">
        <header className="flex-shrink-0">
          <nav className="h-full">
            <SideNavBar />
          </nav>
        </header>
      </div>
      <main className="flex-1 ml-52 overflow-y-auto">{children}</main>
    </div>
  );
};

export default Layout;

import React, { useEffect, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useDispatch } from "react-redux";
import { START_UPDATE_CLASSROOMS_ACTION } from "../../../rest-client/actions/classrooms";
import { START_SCHOOLS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/schools";
import { START_MODULE_SET_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/module-sets";
import { START_TEACHERS_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/teachers";
import { useModuleSet, useTeachers, useSchools } from "../../../rest-client/selectorHook";
import MultiSelect from "../../../components/Dropdowns/MultiSelect";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import { ClipLoader } from "react-spinners";

export default function EditClassroomForm({
  editableObjectData,
  setIsOpenEditFormModal,
  currentPage,
}) {
  const classroomData = editableObjectData;
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch({
      type: START_SCHOOLS_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  useEffect(() => {
    if (userData?.role === "admin" || userData?.role === "superadmin") {
      dispatch({
        type: START_TEACHERS_NON_PAGINATED_LIST_ACTION,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: START_MODULE_SET_NON_PAGINATED_LIST_ACTION,
    });
  }, [dispatch]);

  const {
    schools_non_paginated_list_data: {
      isLoading: schoolListLoading,
      data: schoolListData,
    },
  } = useSchools();

  const {
    teachers_non_paginated_list_data: {
      isLoading: teacherListLoading,
      data: teacherListData,
    },
  } = useTeachers();

  const {
    module_sets_non_paginated_list_data: {
      isLoading: moduleSetListLoading,
      data: moduleSetListData,
    },
  } = useModuleSet();

  const initialModuleSetValues = (module_sets) => {
    const initialModuleSetArray = [];
    module_sets.map((module_set) => {
      initialModuleSetArray.push(module_set.id);
    });
    return initialModuleSetArray;
  };

  const initialFormValues = {
    title_classroom: classroomData?.title ? classroomData?.title : "",
    description_classroom: classroomData?.description
      ? classroomData?.description
      : "",
    teacher_classroom: classroomData?.teacher
      ? {
          label: classroomData?.teacher?.full_name,
          value: classroomData?.teacher?.id,
        }
      : "",
    school_classroom: classroomData?.school
      ? {
          label: classroomData?.school?.name,
          value: classroomData?.school?.id,
        }
      : "",
    module_sets_classroom: initialModuleSetValues(classroomData?.module_sets),
  };

  const setFieldDataHandler = useCallback((setFieldValue, field, value) => {
    setFieldValue(field, value);
  }, []);

  const validationHandler = useCallback((values) => {
    const error = {};
    if (!values.title_classroom) {
      error.title_classroom = "Please enter First Name";
    }
    if (!values.description_classroom) {
      error.description_classroom = "Please enter Description";
    }
    if (!values.school_classroom) {
      error.school_classroom = "Please Select School";
    }
    if (userData?.role !== "Teacher" && !values.teacher_classroom) {
      error.teacher_classroom = "Please Select Teacher";
    }
    if (values.module_sets_classroom.length == 0) {
      error.module_sets_classroom = "Please Select Module Sets";
    }
    return error;
  }, []);

  const formattedModuleSetValues = (module_sets) => {
    const moduleSetArray = [];
    module_sets.map((module_set_id) => {
      moduleSetArray.push({ id: module_set_id });
    });
    return moduleSetArray;
  };

  const updateClassroomHandler = React.useCallback(
    (values, { resetForm }) => {
      const updateClassroomData = {
        classroom: {
          ...classroomData,
          title: values.title_classroom.trimStart().trimEnd(),
          description: values.description_classroom.trimStart().trimEnd(),
          school_id: values.school_classroom?.value,
          teacher_id: values.teacher_classroom?.value,
          module_sets: formattedModuleSetValues(values.module_sets_classroom),
        },
      };
      dispatch({
        type: START_UPDATE_CLASSROOMS_ACTION,
        payload: { updateClassroomData, setIsOpenEditFormModal, currentPage },
      });
      resetForm();
    },
    [dispatch]
  );

  const options = moduleSetListData.map((module_set, index) => {
    return {
      label: module_set.title,
      value: module_set.id,
    };
  });

  const schoolOptions = schoolListData.map((school, index) => {
    return {
      label: school.name,
      value: school.id,
    };
  });

  const teacherOptions = teacherListData.map((teacher, index) => {
    return {
      label: teacher.full_name,
      value: teacher.id,
    };
  });

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={updateClassroomHandler}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="font-lato">
          <div className="mt-12">
            <div className="flex flex-row box-border">
              <div className="flex-1">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="title_classroom"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.title_classroom && touched.title_classroom,
                    })}
                    value={values.title_classroom}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "title_classroom",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="title_classroom"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.title_classroom && touched.title_classroom,
                    })}
                  >
                    Title
                  </label>
                  {errors.title_classroom && touched.title_classroom && (
                    <p className="error-label-class mt-25">
                      {errors.title_classroom ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 ml-10">
                <div className="relative p-0.5 my-2 mx-0 align-middle">
                  <Field
                    type="text"
                    id="description_classroom"
                    className={classNames("input-class !h-[26px]", {
                      "error-input-class":
                        errors.description_classroom &&
                        touched.description_classroom,
                    })}
                    value={values.description_classroom}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "description_classroom",
                        e.target.value.trimStart()
                      );
                    }}
                    maxLength={50}
                    required
                  />
                  <label
                    htmlFor="description_classroom"
                    className={classNames("label-class mt-1", {
                      "error-label-class":
                        errors.description_classroom &&
                        touched.description_classroom,
                    })}
                  >
                    Description
                  </label>
                  {errors.description_classroom &&
                    touched.description_classroom && (
                      <p className="error-label-class mt-25">
                        {errors.description_classroom ?? "-"}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="flex flex-row box-border">
              <div className="flex-1">
                {userData?.role !== "Teacher" && (
                  <div className="edit-dropdown-modal">
                    <Field name="teacher_classroom">
                      {({ field, form, meta }) => (
                        <SingleSelect
                          field={field}
                          form={form}
                          meta={meta}
                          options={teacherOptions}
                          placeholder="Teacher*"
                          isLoading={teacherListLoading}
                          loadingMessage={() => (
                            <ClipLoader size={32} color={"#3e8ede"} />
                          )}
                        />
                      )}
                    </Field>
                  </div>
                )}
              </div>
              <div className="flex-1 ml-10">
                <div className="edit-dropdown-modal">
                  <Field name="school_classroom">
                    {({ field, form, meta }) => (
                      <SingleSelect
                        field={field}
                        form={form}
                        meta={meta}
                        options={schoolOptions}
                        placeholder="School"
                        isLoading={schoolListLoading}
                        loadingMessage={() => (
                          <ClipLoader size={32} color={"#3e8ede"} />
                        )}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className="flex box-border w-full pr-10 my-8">
              <div className="relative p-0.5 mx-0 align-middle edit-dropdown-modal w-1/2">
                <Field name="module_sets_classroom">
                  {({ field, form, meta }) => (
                    <MultiSelect
                      field={field}
                      form={form}
                      meta={meta}
                      options={options}
                      placeholder="Assign Course"
                      isLoading={moduleSetListLoading}
                      loadingMessage={() => (
                        <ClipLoader size={32} color={"#3e8ede"} />
                      )}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="flex justify-end ml-10">
            <DefaultButton label="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

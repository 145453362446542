import React from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import classNames from "classnames";
import Icon from "../../../components/Button/Icon";
import DefaultButton from "../../../components/Button/DefaultButton";
import "../../../styles/landingPage.scss";
import { useDispatch } from "react-redux";
import {
  START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
  START_UPDATE_MODULE_DOCUMENT_ACTION,
} from "../../../rest-client/actions/module-documents";
import { START_MODULE_SET_NON_PAGINATED_LIST_ACTION } from "../../../rest-client/actions/module-sets";
import {
  useModuleDocuments,
  useModuleSet,
} from "../../../rest-client/selectorHook";
import { ClipLoader } from "react-spinners";
import SingleSelect from "../../../components/Dropdowns/SingleSelect";
import Layout from "../../../components/Layout";

export default function EditModuleForm() {
  const history = useHistory();
  const { url } = useRouteMatch(`/dashboard/learning`);
  const params = useParams();

  const backClickHandler = React.useCallback(() => {
    history.push(`${url}/index/modules`);
  }, [history, url]);

  const dispatch = useDispatch();

  // API call to fetch module set list and populate inside dropdown
  React.useEffect(() => {
    dispatch({
      type: START_MODULE_SET_NON_PAGINATED_LIST_ACTION,
    });
    dispatch({
      type: START_FETCHING_MODULE_DOCUMENT_BY_ID_ACTION,
      payload: {
        module_id: params.id,
      },
    });
  }, []);

  const {
    module_document_by_id_data: {
      isLoading: isLoadingModuleDocument,
      data: moduleDocumentData,
    },
  } = useModuleDocuments();

  const {
    module_sets_non_paginated_list_data: { data: moduleSetListData },
  } = useModuleSet();

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.title) {
      error.title = "Please enter Title";
    }
    if (!values.nickname) {
      error.nickname = "Please enter Nickname";
    }
    values.module_categories.forEach((module_category, index) => {
      if (!module_category.value) {
        error[
          `module_categories.${index}.value`
        ] = `Please enter value for ${module_category.name}`;
      }
    });
    return error;
  }, []);

  const initialFormValues = {
    title: moduleDocumentData ? moduleDocumentData.module_document?.title : "",
    nickname: moduleDocumentData
      ? moduleDocumentData.module_document?.nickname
      : "",
    module_set: moduleDocumentData
      ? {
          label: moduleDocumentData.module_document?.module_set?.title,
          value: moduleDocumentData.module_document?.module_set?.id,
        }
      : "",
    module_categories: moduleDocumentData
      ? moduleDocumentData.module_document?.module_categories
      : [],
  };

  const updateInitialValuesHandler = (selectedModuleSet, values) => {
    const selectedModuleSetObj = moduleSetListData.find(
      (obj) => obj["id"] === selectedModuleSet?.value
    );
    if (
      moduleDocumentData.module_document?.module_set?.title ===
      selectedModuleSetObj.title
    ) {
      values.module_categories =
        moduleDocumentData.module_document?.module_categories;
    } else {
      values.module_categories = [];
      selectedModuleSetObj.module_categories.map((category) =>
        values.module_categories.push({
          id: category.id,
          name: category.name.trimStart().trimEnd(),
          slug: category.slug.trimStart().trimEnd(),
          field_type: category.field_type,
          value: "",
        })
      );
    }
  };

  const updateModuleDocumentHandler = React.useCallback(
    (values) => {
      const module_set_categories = [];
      values.module_categories.map((module_category) => {
        module_set_categories.push({
          ...module_category,
          name: module_category.name,
          slug: module_category.slug,
          value: module_category.value.trimStart().trimEnd(),
        });
      });
      const updateModuleDocumentData = {
        module_document: {
          ...moduleDocumentData?.module_document,
          title: values.title.trimStart().trimEnd(),
          nickname: values.nickname.trimStart().trimEnd(),
          module_set_id: values.module_set?.value,
          module_categories: module_set_categories,
        },
      };
      dispatch({
        type: START_UPDATE_MODULE_DOCUMENT_ACTION,
        payload: { updateModuleDocumentData, history },
      });
    },
    [dispatch, moduleDocumentData]
  );

  const moduleSetOptions = moduleSetListData.map((module_set, index) => {
    return {
      label: module_set.title,
      value: module_set.id,
    };
  });

  return isLoadingModuleDocument ? (
    <div className="flex flex-col mt-8 justify-center items-center h-32">
      <ClipLoader size={32} color={"#3e8ede"} />
      <p className="mt-2 font-bold font-muli text-blue-theme">
        Loading Module...
      </p>
    </div>
  ) : (
    <Layout>
      <div className="flex py-3 text-base font-lato px-6">
        <button
          className="border-none bg-transparent"
          onClick={backClickHandler}
        >
          <Icon
            label="arrow_back_ios"
            title=""
            className={classNames("text-light-gray")}
          />
        </button>
        <span
          className="text-slate-600 cursor-pointer"
          onClick={backClickHandler}
        >
          Back to Modules
        </span>
      </div>
      <div className="edit-profile-text"></div>
      <h2 className="form_title ml-10 mt-10">Module Information</h2>
      <Formik
        initialValues={initialFormValues}
        validate={validationHandler}
        onSubmit={updateModuleDocumentHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form className="font-lato mx-10 mt-10">
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 w-30">
                <div className="field-holder">
                  <Field
                    type="text"
                    id="title"
                    className={classNames("input-class", {
                      "error-input-class": errors.title && touched.title,
                    })}
                    value={values.title}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "title",
                        e.target.value.trimStart()
                      );
                    }}
                    tabIndex="1"
                    required
                  />
                  <label
                    htmlFor="title"
                    className={classNames("label-class", {
                      "error-label-class": errors.title && touched.title,
                    })}
                  >
                    Title *
                  </label>
                  {errors.title && touched.title && (
                    <p className="error-label-class mt-25">
                      {errors.title ?? "-"}
                    </p>
                  )}
                </div>
                <div className="field-holder">
                  <Field
                    type="text"
                    id="nickname"
                    className={classNames("input-class", {
                      "error-input-class": errors.nickname && touched.nickname,
                    })}
                    required
                    value={values.nickname}
                    onChange={(e) => {
                      setFieldDataHandler(
                        setFieldValue,
                        "nickname",
                        e.target.value.trimStart()
                      );
                    }}
                    tabIndex="3"
                  />
                  <label
                    htmlFor="nickname"
                    className={classNames("label-class", {
                      "error-label-class": errors.nickname && touched.nickname,
                    })}
                  >
                    Nickname *
                  </label>
                  {errors.nickname && touched.nickname && (
                    <p className="error-label-class mt-25">
                      {errors.nickname ?? "-"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 w-full md:w-70 md:mx-10">
                <div className="mt-1 mb-10">
                  <Field name="module_set">
                    {({ field, form, meta }) => (
                      <SingleSelect
                        field={field}
                        form={form}
                        meta={meta}
                        options={moduleSetOptions}
                        placeholder="Module Set*"
                        onChange={(value) => {
                          updateInitialValuesHandler(value, form.values);
                        }}
                        tabIndex="2"
                      />
                    )}
                  </Field>
                </div>
                {values.module_set.value && (
                  <>
                    <FieldArray
                      name="module_categories"
                      render={(arrayHelpers) => {
                        const module_categories = values.module_categories;
                        return (
                          <>
                            {module_categories.map((module_category, index) => (
                              <div key={index}>
                                <div className="field-holder mt-5">
                                  <Field
                                    type="text"
                                    id={`module_categories.${index}.value`}
                                    name={`module_categories.${index}.value`}
                                    className={classNames("input-class", {
                                      "error-input-class":
                                        errors[
                                          `module_categories.${index}.value`
                                        ],
                                    })}
                                    tabIndex="4"
                                    required
                                    value={
                                      values.module_categories[index].value
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        `module_categories.${index}.value`,
                                        e.target.value.trimStart()
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={`module_categories.${index}.value`}
                                    className={classNames("label-class", {
                                      "error-label-class":
                                        errors[
                                          `module_categories.${index}.value`
                                        ],
                                    })}
                                  >
                                    {module_category.name} *
                                  </label>
                                  {errors[
                                    `module_categories.${index}.value`
                                  ] && (
                                    <p className="error-label-class mt-25">
                                      {errors[
                                        `module_categories.${index}.value`
                                      ] ?? "-"}
                                    </p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        );
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-center	md:justify-end md:mx-10 mt-10">
              <DefaultButton label="Update Module" />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}

import React from "react";
import { Formik, Form, Field } from "formik";
import "../../../styles/landingPage.scss";
import classNames from "classnames";
import DefaultButton from "../../../components/Button/DefaultButton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_CREATE_ADMINISTRATOR_ACTION } from "../../../rest-client/actions/administrators";

export default function AddAdministratorForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialFormValues = {
    first_name: "",
    last_name: "",
    email: "",
  };

  const setFieldDataHandler = React.useCallback(
    (setFieldValue, field, value) => {
      setFieldValue(field, value);
    },
    []
  );

  const validationHandler = React.useCallback((values) => {
    const error = {};
    if (!values.first_name) {
      error.first_name = "Please enter First Name";
    }
    if (!values.last_name) {
      error.last_name = "Please enter Last Name";
    }
    if (!values.email) {
      error.email = "Please enter Email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      error.email = "Invalid Email address";
    }
    return error;
  }, []);

  const createAdministratorHandler = React.useCallback(
    (values, { resetForm }) => {
      const createAdministratorData = {
        user: {
          first_name: values.first_name.trimStart().trimEnd(),
          last_name: values.last_name.trimStart().trimEnd(),
          email: values.email.trimStart().trimEnd(),
        },
      };
      dispatch({
        type: START_CREATE_ADMINISTRATOR_ACTION,
        payload: { createAdministratorData, history },
      });
      resetForm();
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validationHandler}
      onSubmit={createAdministratorHandler}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
      }) => (
        <Form className="font-lato">
          <div className="flex flex-row box-border">
            <div className="flex-1 w-30">
              <div className="field-holder">
                <Field
                  type="text"
                  id="first_name"
                  className={classNames("input-class", {
                    "error-input-class":
                      errors.first_name && touched.first_name,
                  })}
                  value={values.first_name}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "first_name",
                      e.target.value
                    );
                  }}
                  maxLength={50}
                  tabIndex="1"
                  required
                />
                <label
                  htmlFor="first_name"
                  className={classNames("label-class", {
                    "error-label-class":
                      errors.first_name && touched.first_name,
                  })}
                >
                  First Name *
                </label>
                {errors.first_name && touched.first_name && (
                  <p className="error-label-class mt-25">
                    {errors.first_name ?? "-"}
                  </p>
                )}
              </div>
              <div className="field-holder">
                <Field
                  type="email"
                  id="email"
                  className={classNames("input-class", {
                    "error-input-class": errors.email && touched.email,
                  })}
                  value={values.email}
                  onChange={(e) => {
                    setFieldDataHandler(setFieldValue, "email", e.target.value);
                  }}
                  maxLength={50}
                  tabIndex="3"
                  required
                />
                <label
                  htmlFor="email"
                  className={classNames("label-class", {
                    "error-label-class": errors.email && touched.email,
                  })}
                >
                  Email *
                </label>
                {errors.email && touched.email && (
                  <p className="error-label-class mt-25">
                    {errors.email ?? "-"}
                  </p>
                )}
              </div>
            </div>
            <div className="flex-1 w-70 mx-10">
              <div className="field-holder">
                <Field
                  type="text"
                  id="last_name"
                  className={classNames("input-class", {
                    "error-input-class": errors.last_name && touched.last_name,
                  })}
                  value={values.last_name}
                  onChange={(e) => {
                    setFieldDataHandler(
                      setFieldValue,
                      "last_name",
                      e.target.value
                    );
                  }}
                  maxLength={50}
                  tabIndex="2"
                  required
                />
                <label
                  htmlFor="last_name"
                  className={classNames("label-class", {
                    "error-label-class": errors.last_name && touched.last_name,
                  })}
                >
                  Last Name *
                </label>
                {errors.last_name && touched.last_name && (
                  <p className="error-label-class mt-25">
                    {errors.last_name ?? "-"}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end mx-10">
            <DefaultButton label="Create" />
          </div>
        </Form>
      )}
    </Formik>
  );
}

import React, { useMemo, useEffect, useRef, useState } from "react";
import Icon from "../../../components/Button/Icon";
import classNames from "classnames";
import { format } from "date-fns";
import AdministrationListTable from "../AdministrationListTable";
import EditFormModal from "../EditFormModal";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import {
  START_DELETE_CLASSROOMS_ACTION,
  START_EXPORT_CLASSROOM_ACTION,
} from "../../../rest-client/actions/classrooms";
import { useDispatch } from "react-redux";
import Pagination from "../../../components/Pagination/pagination";

export default function ListClassrooms({
  classroomListData,
  classroomListLoading,
  totalPages,
  currentPage,
  setCurrentPage,
}) {
  const [isOpenRowDropDown, setIsOpenRowDropDown] = useState();
  const [isOpenEditFormModal, setIsOpenEditFormModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [classroomRowData, setClassroomRowData] = useState({});
  const userData = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const classroomData = useMemo(() => {
    return classroomListData;
  }, [classroomListData]);

  const downloadCSVFile = (response) => {
    const link = document.createElement("a");
    link.href = response.media.url;
    link.target = "_blank";
    link.setAttribute("download", response.media.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenRowDropDown();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const openEditModalHandler = React.useCallback((value) => {
    setIsOpenEditFormModal(true);
    setClassroomRowData(value);
  }, []);

  const openConfirmModalHandler = React.useCallback((value) => {
    setIsOpenConfirmModal(true);
    setClassroomRowData(value);
  }, []);

  const deleteClassroomHandler = React.useCallback(() => {
    const deleteClassroomRequestData = {
      classroom: {
        ...classroomRowData,
      },
      id: classroomRowData.id,
    };
    dispatch({
      type: START_DELETE_CLASSROOMS_ACTION,
      payload: { deleteClassroomRequestData, setIsOpenConfirmModal },
    });
  }, [dispatch, classroomRowData]);

  const exportClassroomData = React.useCallback((value) => {
    dispatch({
      type: START_EXPORT_CLASSROOM_ACTION,
      payload: {
        id: value.id,
        downloadCSVFile,
      },
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Teacher",
        accessor: (d) => `${d.teacher?.full_name ? d.teacher?.full_name : ""}`,
      },
      {
        Header: "Module Sets",
        accessor: (d) =>
          d.module_sets.map((module_set) => module_set.title).join(",  "),
      },
      // ...(userData?.role === "admin" || userData?.role === "superadmin"
      //   ? [
      //       {
      //         Header: "Status",
      //         accessor: (d) =>
      //           `${d.deleted_at === null ? "Active" : "Inactive"}`,
      //       },
      //     ]
      //   : []),
      {
        Header: "Date Created",
        accessor: (d) =>
          format(new Date(d.created_at), "MM/dd/yyyy hh:mm a", {
            timeZone: "UTC",
          }),
      },
      {
        Header: "School",
        accessor: (d) => `${d.school?.name ? d.school?.name : ""}`,
      },
      {
        Header: "Options",
        accessor: (d, index) => (
          <>
            <button
              className="border-none bg-transparent"
              onClick={() => {
                setIsOpenRowDropDown(d.id);
              }}
            >
              <Icon
                label="settings"
                title=""
                className={classNames("text-slate-900")}
              />
            </button>
            {isOpenRowDropDown !== null && isOpenRowDropDown === d.id && (
              <div
                className={`absolute z-50 right-0 ${
                  index == 0 ? "top-0" : "bottom-1"
                } w-48 py-2 mt-2 bg-white rounded-md shadow-xl cursor-pointer`}
                ref={dropdownRef}
              >
                <div
                  className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100"
                  onClick={() => {
                    openEditModalHandler(d);
                    setIsOpenRowDropDown();
                  }}
                >
                  <Icon
                    label="edit"
                    title=""
                    className={classNames("text-light-gray mx-1")}
                  />
                  <span className="absolute">Edit Classroom</span>
                </div>
                <div
                  className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100"
                  onClick={() => {
                    openConfirmModalHandler(d);
                    setIsOpenRowDropDown();
                  }}
                >
                  <Icon
                    label="delete"
                    title=""
                    className={classNames("text-light-gray mx-1")}
                  />
                  <span className="absolute">Delete Classroom</span>
                </div>
                {userData?.role !== "Teacher" && (
                  <div
                    className="block px-2 py-3 text-sm text-gray-600 capitalize transition-colors duration-300 transform dark:text-gray-300 hover:bg-gray-100"
                    onClick={() => {
                      setIsOpenRowDropDown();
                      exportClassroomData(d);
                    }}
                  >
                    <Icon
                      label="ios_share"
                      title=""
                      className={classNames("text-light-gray mx-1")}
                    />
                    <span className="absolute">Export Data</span>
                  </div>
                )}
              </div>
            )}
          </>
        ),
      },
    ],
    [isOpenRowDropDown]
  );
  return (
    <>
      {isOpenEditFormModal && (
        <EditFormModal
          isOpenEditFormModal={isOpenEditFormModal}
          setIsOpenEditFormModal={setIsOpenEditFormModal}
          editableObjectData={classroomRowData}
          editableObjectName="Classroom"
          currentPage={currentPage}
        />
      )}

      {isOpenConfirmModal && (
        <ConfirmModal
          isOpenConfirmModal={isOpenConfirmModal}
          setIsOpenConfirmModal={setIsOpenConfirmModal}
          deleteRecordHandler={deleteClassroomHandler}
        />
      )}

      <AdministrationListTable
        columns={columns}
        dataListLoading={classroomListLoading}
        listData={classroomData}
        listFor={"Classrooms"}
      />
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
}

import api from "../api";

export function fetchModuleSetListAction(payload) {
  return api.get(
    `/module_sets?${payload?.page ? `page=${payload.page}` : ""}${
      payload?.query ? `&query=${payload.query}` : ""
    }`
  );
}

export function fetchModuleSetNonPaginatedListAction() {
  return api.get("/module_sets/list_all");
}

export function fetchModuleSetUpdateAction(payload) {
  return api.put(`/module_sets/${payload?.module_set?.id}`, {
    paramObj: payload,
  });
}

export function deleteModuleSetRequest(payload) {
  return api.delet(`/module_sets/${payload?.module_set?.id}`, {
    paramObj: payload,
  });
}

export function fetchModuleSetCloneRequest(payload) {
  return api.post(`/module_sets/${payload?.module_set?.id}/clone`, {
    paramObj: payload,
  });
}

export function fetchCreateModuleSetRequest(payload) {
  return api.post(`/module_sets`, {
    paramObj: payload,
  });
}

export function fetchModuleSetShowRequest(id) {
  return api.get(`/module_sets/${id}`);
}

export function fetchModuleSetByCategoriesRequest(payload) {
  const categories = JSON.stringify(payload.categories);
  const query = `query=${encodeURIComponent(`{"categories": ${categories}}`)}`;

  return api.get(
    `/module_sets/${payload?.module_set_id}/module_categories?${query}`
  );
}

export const START_MODULE_SET_LIST_ACTION = `START_MODULE_SET_LIST_ACTION`;
export const ERROR_MODULE_SET_LIST_ACTION = `ERROR_MODULE_SET_LIST_ACTION`;
export const SUCCESS_MODULE_SET_LIST_ACTION = `SUCCESS_MODULE_SET_LIST_ACTION`;

export const START_UPDATE_MODULE_SETS_ACTION = "START_UPDATE_MODULE_SETS";
export const SUCCESS_UPDATE_MODULE_SETS_ACTION = "SUCCESS_UPDATE_MODULE_SETS";
export const FAILURE_UPDATE_MODULE_SETS_ACTION = "FAILURE_UPDATE_MODULE_SETS";

export const START_DELETE_MODULE_SETS_ACTION =
  "START_DELETE_MODULE_SETS_ACTION";
export const SUCCESS_DELETE_MODULE_SETS_ACTION =
  "SUCCESS_DELETE_MODULE_SETS_ACTION";
export const FAILURE_DELETE_MODULE_SETS_ACTION =
  "FAILURE_DELETE_MODULE_SETS_ACTION";

export const START_CLONE_MODULE_SET_ACTION = "START_CLONE_MODULE_SET_ACTION";
export const SUCCESS_CLONE_MODULE_SET_ACTION =
  "SUCCESS_CLONE_MODULE_SET_ACTION";
export const FAILURE_CLONE_MODULE_SET_ACTION =
  "FAILURE_CLONE_MODULE_SET_ACTION";

export const START_CREATE_MODULE_SET_ACTION = "START_CREATE_MODULE_SET_ACTION";
export const SUCCESS_CREATE_MODULE_SET_ACTION =
  "SUCCESS_CREATE_MODULE_SET_ACTION";
export const FAILURE_CREATE_MODULE_SET_ACTION =
  "FAILURE_CREATE_MODULE_SET_ACTION";

export const START_SHOW_MODULE_SET_ACTION = "START_SHOW_MODULE_SET_ACTION";
export const SUCCESS_SHOW_MODULE_SET_ACTION = "SUCCESS_SHOW_MODULE_SET_ACTION";
export const FAILURE_SHOW_MODULE_SET_ACTION = "FAILURE_SHOW_MODULE_SET_ACTION";

export const START_MODULE_SET_BY_CATEGORIES_ACTION = `START_MODULE_SET_BY_CATEGORIES_ACTION`;
export const ERROR_MODULE_SET_BY_CATEGORIES_ACTION = `ERROR_MODULE_SET_BY_CATEGORIES_ACTION`;
export const SUCCESS_MODULE_SET_BY_CATEGORIES_ACTION = `SUCCESS_MODULE_SET_BY_CATEGORIES_ACTION`;

export const START_MODULE_SET_NON_PAGINATED_LIST_ACTION = `START_MODULE_SET_NON_PAGINATED_LIST_ACTION`;
export const ERROR_MODULE_SET_NON_PAGINATED_LIST_ACTION = `ERROR_MODULE_SET_NON_PAGINATED_LIST_ACTION`;
export const SUCCESS_MODULE_SET_NON_PAGINATED_LIST_ACTION = `SUCCESS_MODULE_SET_NON_PAGINATED_LIST_ACTION`;
